import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const getApplicationsState = (state: RootState) => state.applications;

export const getApplicationsList = createSelector(
  getApplicationsState,
  (applications) => {
    return applications.items;
  }
);
export const getApplicationsTotalCount = createSelector(
  getApplicationsState,
  (applications) => {
    return applications.totalCount;
  }
);
export const getApplicationState = createSelector(
  getApplicationsState,
  (applications) => {
    return applications.application;
  }
);
export const getSecret = createSelector(
  getApplicationsState,
  (applications) => {
    return applications.secret;
  }
);
export const getTenantId = createSelector(
  getApplicationsState,
  (applications) => {
    return applications.tenantId;
  }
);
