import styled from "styled-components";
import { Menu } from "@mui/material";

export const StyledHeaderDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid var(--silver);
  padding: 20px 2%;
`;

export const StyledProfileButton = styled.div`
  background: var(--white);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  margin-left: 25px;
  cursor: pointer;
  :hover {
    background: rgba(98, 102, 234, 0.2);
    box-shadow: 10px 10px 40px rgba(111, 138, 231, 0.25);
    path {
      opacity: 1;
    }
  }
`;

export const StyledIconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  path {
    opacity: 0.5;
  }
`;

export const StyledMenu = styled(Menu)`
  .MuiPaper-root.MuiPaper-elevation {
    border-radius: 20px;
    box-shadow: 10px 10px 40px rgba(111, 138, 231, 0.25);
    min-width: 180px;
  }
`;

export const StyledChevron = styled.div<{ open: boolean }>`
  svg {
    transition: 0.2s;
    transform: ${(props) => (props.open ? "rotateX(180deg)" : "rotateX(0deg)")};
    position: relative;
    top: 5px;
    path {
      fill: var(--primary);
    }
  }
`;
