import React, { useState } from "react";

import ActionButton from "../../ActionButton";

import { StyledActionWrap } from "./styles";
import { DeleteItem, StyledMenu, StyledItem } from "../../BulkActions/styles";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";

import { ActionButtonTypes } from "../../ActionButton/types";

interface TableActionsProps {
  handleDeleteTenant: (tenant: any) => void;
  handleEditTenant: (tenant: any) => void;
  handleSwitchTenant: (tenant: any) => void;
  handleRestore: (tenant: any) => void;
  tenant: any;
  isBlockView?: boolean;
  status: string;
}

const TableActions: React.FC<TableActionsProps> = ({
  handleDeleteTenant,
  handleEditTenant,
  handleSwitchTenant,
  handleRestore,
  tenant,
  isBlockView,
  status,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <StyledActionWrap>
      {status === "Archive" ? (
        <ActionButton
          type={ActionButtonTypes.RESTORE}
          title={"Restore tenant"}
          onClick={() => handleRestore(tenant)}
        />
      ) : (
        <>
          <>
            <ActionButton
              type={ActionButtonTypes.DOTTED}
              title={""}
              onClick={handleClick}
            />
            {!isBlockView && (
              <ActionButton
                type={ActionButtonTypes.ACTION}
                title={"Switch to Tenant"}
                onClick={() => handleSwitchTenant(tenant)}
              />
            )}
          </>
          <StyledMenu
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <StyledItem onClick={() => handleEditTenant(tenant)}>
              <EditIcon /> Edit Tenant
            </StyledItem>
            <DeleteItem onClick={() => handleDeleteTenant(tenant)}>
              <DeleteOutlineIcon /> Delete Tenant
            </DeleteItem>
          </StyledMenu>
        </>
      )}
    </StyledActionWrap>
  );
};

export default TableActions;
