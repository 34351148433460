import { useDispatch } from "react-redux";
import { useCallback } from "react";
import { applicationsActions } from "entities/applications";

export const useApplications = () => {
  const dispatch = useDispatch();

  const getApplications = useCallback(
    (params: any) => {
      dispatch({
        type: applicationsActions.GET_APPLICATIONS,
        data: params,
      });
    },
    [dispatch]
  );

  const deleteApplication = useCallback(
    (params: any) => {
      dispatch({
        type: applicationsActions.DELETE_APPLICATION,
        data: params,
      });
    },
    [dispatch]
  );

  const createApplication = useCallback(
    (params: any, callback: any) => {
      dispatch({
        type: applicationsActions.CREATE_APPLICATION,
        data: params,
        callback,
      });
    },
    [dispatch]
  );

  const regenerateClientSecret = useCallback(
    (params: any) => {
      dispatch({
        type: applicationsActions.REGENERATE_CLIENT_SECRET,
        data: params,
      });
    },
    [dispatch]
  );

  const getApplication = useCallback(
    (params: any) => {
      dispatch({
        type: applicationsActions.GET_APPLICATION,
        data: params,
      });
    },
    [dispatch]
  );

  return {
    getApplications,
    deleteApplication,
    createApplication,
    regenerateClientSecret,
    getApplication,
  };
};
