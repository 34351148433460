import React from "react";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
  Wrapper,
  Title,
  Header,
  Footer,
  Content,
  ScrollWrapper,
} from "./styles";
import { Spinner } from "../Spinner";

interface StyledModalProps {
  handleClose: () => void;
  id: string;
  title: string;
  children: React.ReactNode;
  footer: React.ReactNode;
  open: boolean;
  isLoading?: boolean;
}

export const Modal: React.FC<StyledModalProps> = ({
  handleClose,
  id,
  title,
  children,
  footer,
  open,
  isLoading,
}) => {
  return (
    <Wrapper id={id} onClose={handleClose} open={open} hideBackdrop={false}>
      <>
        <Header>
          <Title>{title}</Title>
          <IconButton
            onClick={handleClose}
            sx={{ color: "#000000" }}
            disableRipple={true}
          >
            <CloseIcon />
          </IconButton>
        </Header>
        <ScrollWrapper>
          <Content>{children}</Content>
        </ScrollWrapper>
        <Footer>
          {isLoading && <Spinner />}
          {footer}
        </Footer>
      </>
    </Wrapper>
  );
};
