import styled from "styled-components";
import { Modal } from "@mui/material";

export const Wrapper = styled(Modal)`
  &.MuiModal-root {
    z-index: 9;
    right: 0;
    top: 0;
    left: inherit;
    width: 560px;
    background: #ffffff;
    box-shadow: 10px 10px 40px rgba(31, 60, 100, 0.16),
      0px 4px 20px rgba(0, 0, 0, 0.16);
    display: flex;
    flex-direction: column;
    left: inherit;
    .MuiBackdrop-root {
      background-color: rgba(0, 0, 0, 0);
    }
  }
`;
export const Header = styled.div`
  padding: 20px 32px;
  border-bottom: 1px solid #dadae3;
  display: flex;
  justify-content: space-between;
`;
export const Footer = styled.div`
  padding: 20px 32px 24px;
  border-top: 1px solid #dadae3;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  position: relative;
  gap: 20px;
`;

export const Title = styled.h3`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 48px;
  color: #010101;
  margin: 0;
`;

export const Content = styled.div`
  padding: 32px;
`;

export const ScrollWrapper = styled.div`
  overflow: auto;
`;
