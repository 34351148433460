import { Column } from "../Table/types";

export const initialColumns: Column[] = [
  {
    id: "requestTypeName",
    label: "Request Type",
    minWidth: 150,
    visible: true,
    filtering: true,
  },
  {
    id: "id",
    label: "Request ID",
    minWidth: 150,
    visible: true,
    filtering: false,
  },
  {
    id: "requestStatus",
    label: "Status",
    minWidth: 150,
    visible: true,
    filtering: true,
  },
  {
    id: "requestTenant",
    label: "Request Tenant",
    minWidth: 150,
    visible: true,
    filtering: false,
  },
  {
    id: "createdDateUtc",
    label: "Creation",
    minWidth: 150,
    visible: true,
    filtering: false,
  },
  {
    id: "updatedDateUtc",
    label: "Update",
    minWidth: 150,
    visible: true,
    filtering: false,
  },
  {
    id: "actions",
    label: "Actions",
    minWidth: 100,
    visible: true,
    filtering: false,
  },
];
