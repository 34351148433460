import { ModuleTemplatesRespose } from "shared/api/moduleTemplates/types";
import { IXAdminApiPrivate } from "api/IXAdminApi";
import { AxiosResponse } from "axios";

export default class ModuleTemplatesApi {
  static async getModuleTemplates(
    params: any
  ): Promise<AxiosResponse<ModuleTemplatesRespose>> {
    return IXAdminApiPrivate.get("/api/ModuleTemplates", params);
  }
}
