import * as React from "react";
import {
  IconDiv,
  StyledRequestsIcon,
  StyledArrowIcon,
  StyledTriangleIcon,
} from "./styles";

const RequestIcon = () => {
  return (
    <IconDiv>
      <StyledRequestsIcon />
      <StyledArrowIcon />
      <StyledTriangleIcon />
    </IconDiv>
  );
};

export default RequestIcon;
