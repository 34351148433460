import React, { useState } from "react";
import {
  OutlinedInput,
  ListItemText,
  IconButton,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import {
  StyledInput,
  StyledButton,
  StyledCheckbox,
  StyledMenuItem,
  MenuItemHeader,
  StyledSelect,
  StyledPlaceholder,
} from "./styles";

interface SelectsProps {
  options: string[];
  id: string;
  title: string;
  value: string | string[];
  onChange: (e: any) => void;
  multiple?: boolean;
  error?: boolean;
  errorPlaceholder?: string;
  hideCreate?: boolean;
  disabled?: boolean;
}

const CustomizedSelects = ({
  options,
  id,
  onChange,
  value,
  title,
  multiple,
  error,
  errorPlaceholder,
  hideCreate,
  disabled,
}: SelectsProps) => {
  const [newGroup, setNewGroup] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(options);

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      const newOptions = [newGroup, ...selectedOptions];
      setSelectedOptions(newOptions);
      setNewGroup("");
      setOpen(false);
    }
  };
  const renderIconComponent = (props: any) => (
    <KeyboardArrowDownIcon {...props} />
  );

  return (
    <StyledSelect
      multiple={multiple}
      fullWidth
      id={id}
      value={value}
      disabled={disabled}
      onChange={onChange}
      input={<OutlinedInput placeholder="Please select" />}
      renderValue={(selected: any) => {
        if (errorPlaceholder && error) {
          return <StyledPlaceholder>{errorPlaceholder}</StyledPlaceholder>;
        }
        return Array.isArray(selected) ? selected.join(", ") : selected;
      }}
      displayEmpty={true}
      error={error}
      IconComponent={renderIconComponent}
    >
      {!hideCreate && (
        <MenuItemHeader onKeyDown={(e) => e.stopPropagation()}>
          {open ? (
            <StyledInput
              fullWidth
              autoFocus={true}
              id={`new_${id}`}
              value={newGroup}
              onChange={(event) => setNewGroup(event.target.value)}
              onKeyDown={handleKeyDown}
              placeholder={`Enter ${title} Name here...`}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle"
                    onClick={() => setOpen(false)}
                    onMouseDown={() => setOpen(false)}
                    edge="end"
                    data-testid="select_closeButton"
                  >
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          ) : (
            <StyledButton
              variant="text"
              onClick={() => setOpen(!open)}
              data-testid="select_addButton"
            >
              <AddIcon />
              Create {title}...
            </StyledButton>
          )}
        </MenuItemHeader>
      )}

      {selectedOptions.map((option) => (
        <StyledMenuItem
          key={option}
          value={option}
          data-testid={`select_${option}Option`}
        >
          <StyledCheckbox checked={value?.indexOf(option) > -1} />
          <ListItemText primary={option} />
        </StyledMenuItem>
      ))}
    </StyledSelect>
  );
};

export default CustomizedSelects;
