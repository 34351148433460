import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";

import { IconButton, Tooltip } from "@mui/material";
import { Delete, Settings } from "@mui/icons-material";

import { PageTitle } from "shared/ui/pageTitle";
import TablePaper from "shared/ui/TablePaper";

import { RoutesList } from "shared/routes";
import { getApplicationState } from "entities/applications";
import { useAppSelector } from "redux/store/hooks/hooks";
import { useApplications } from "features/applications/model/hooks/useApplications";
import { useTenant } from "features/tenants";
import { getTenant } from "entities/tenants";

import {
  GeneralInfoWrapper,
  StyledTitle,
  InfoBlock,
  InfoValues,
  InfoLabel,
  Row,
  Col,
  HeaderWrap,
  ControlButtonsWrap,
  Tag,
  TagsWrap,
} from "./styles";
import { infoData, InfoProps } from "./types";

import DeleteSimpleModal from "components/Modals/DeleteSimpleModal";
import CopyClientSecret from "components/Modals/CopyClientSecret";

const DistributionDetails = () => {
  const { deleteApplication, regenerateClientSecret, getApplication } =
    useApplications();
  const tenantHook = useTenant();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openClientSecret, setOpenClientSecret] = useState(false);

  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const found = location.pathname.split("/");
  const distributionId = found[found.length - 1];
  const tenantId = searchParams.get("tenantId");

  const application: any = useAppSelector(getApplicationState);
  const tenantsState = useAppSelector(getTenant);

  useEffect(() => {
    if (distributionId && tenantId) {
      getApplication({
        tenantId,
        id: distributionId,
      });
      tenantHook.getTenant(tenantId);
    }
  }, [distributionId]);

  const onRegenerateClientSecret = () => {
    setOpenClientSecret(true);
    regenerateClientSecret({
      tenantId,
      id: distributionId,
    });
  };
  const handleDelete = () => {
    setOpenDeleteModal(true);
  };
  const handleDeleteDistribution = () => {
    deleteApplication({
      tenantId,
      id: distributionId,
      name: application.name,
    });
    navigate(`${RoutesList.PRODUCT_CONFIGURATOR}/${RoutesList.DISTRIBUTIONS}`);
  };

  const closeClientSecretModal = () => {
    setOpenClientSecret(false);
  };

  const renderInfoBlock = (item: InfoProps) => {
    return (
      <InfoBlock key={item.id}>
        <InfoLabel>{item.label}:</InfoLabel>
        <InfoValues>
          {Array.isArray(application?.[item.id]) ? (
            <TagsWrap>
              {application[item.id].map((val: any) => (
                <Tag key={val.id}>{val}</Tag>
              ))}
            </TagsWrap>
          ) : (
            <span>
              {item.id === "tenant"
                ? tenantsState.companyName
                : application?.[item.id]?.toString()}
            </span>
          )}
        </InfoValues>
      </InfoBlock>
    );
  };
  return (
    <div data-testid={"distribution-details"}>
      <PageTitle
        title={"Distribution Details"}
        backLink={{
          to: `${RoutesList.PRODUCT_CONFIGURATOR}/${RoutesList.DISTRIBUTIONS}`,
          title: "Back to Distributions",
        }}
      />
      <TablePaper>
        <HeaderWrap>
          <StyledTitle>Distribution</StyledTitle>
          <ControlButtonsWrap>
            <Tooltip
              classes={{ popper: "white-tooltip" }}
              title={"Delete Distribution"}
            >
              <IconButton onClick={handleDelete}>
                <Delete />
              </IconButton>
            </Tooltip>
            <Tooltip
              classes={{ popper: "white-tooltip" }}
              title={"Regenerate Client Secret"}
            >
              <IconButton
                onClick={onRegenerateClientSecret}
                disabled={application.isPublic}
              >
                <Settings />
              </IconButton>
            </Tooltip>
          </ControlButtonsWrap>
        </HeaderWrap>
        <GeneralInfoWrapper>
          <Row>
            <Col>
              {infoData.leftCol.map((item: any) => renderInfoBlock(item))}
            </Col>
            <Col>
              {infoData.rightCol.map((item: any) => renderInfoBlock(item))}
            </Col>
          </Row>
        </GeneralInfoWrapper>
      </TablePaper>
      <DeleteSimpleModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(!openDeleteModal)}
        onSubmit={handleDeleteDistribution}
        title={`Are you sure you want to delete Distribution "${application.name}"?`}
      />
      <CopyClientSecret
        onClose={closeClientSecretModal}
        open={openClientSecret}
      />
    </div>
  );
};

export default DistributionDetails;
