import styled from "styled-components";
import { DialogContent, Typography } from "@mui/material";

export const Wrapper = styled(DialogContent)`
  &.MuiDialogContent-root {
    padding: 40px 50px;
    border-bottom: none;
    .MuiButton-containedSecondary {
      padding: 5px 24px;
      height: 40px;
      font-size: 12px;
      line-height: 16px;
    }
  }
`;

export const Block = styled.div<{ created: boolean }>`
  display: flex;
  flex-direction: column;
  height: auto;
  align-items: center;
  justify-content: center;
  width: calc(100% - 750px);
  margin: auto;
  margin-top: ${(props) => (props.created ? "120px" : "20px")};
  text-align: center;
`;
export const Text = styled(Typography)`
  &.MuiTypography-root {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--grey);
    margin-top: 10px;
  }
`;
export const Icon = styled.img`
  width: 80px;
  height: 80px;
`;
export const Title = styled.h2`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: #000000;
  margin-bottom: 56px;
  margin-top: 32px;
`;
export const Description = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  margin-bottom: 72px;
`;
export const ButtonsGroup = styled.div`
  .MuiButtonBase-root {
    margin: 0 16px;
  }
`;
