import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "redux/store/hooks/hooks";
import {
  applicationLinkToWidgetAction,
  applicationUnlinkFromWidgetAction,
  getWidgetActionLoading,
  getWidgetByIdAction,
  getWidgetDetailsApplications,
  getWidgetDetailsData,
  getWidgetDetailsDataLoading,
  getWidgetsAction,
  getWidgetsCount,
  getWidgetsItems,
  getWidgetsItemsLoading,
  widgetsActions,
} from "entities/widgets";
import {
  ApplicationLinkToWidgetPayload,
  GetWidgetPayload,
  WidgetsPayload,
} from "shared/api/widgets/types";

const useGetWidgetsItems = () => useAppSelector(getWidgetsItems);
const useGetWidgetsLoading = () => useAppSelector(getWidgetsItemsLoading);
const useGetWidgetsCount = () => useAppSelector(getWidgetsCount);
const useGetWidgetDetailsData = () => useAppSelector(getWidgetDetailsData);
const useGetWidgetActionLoading = () => useAppSelector(getWidgetActionLoading);
const useGetWidgetDetailsApplications = () =>
  useAppSelector(getWidgetDetailsApplications);
const useGetWidgetDetailsDataLoading = () =>
  useAppSelector(getWidgetDetailsDataLoading);

export const useWidgets = () => {
  const dispatch = useAppDispatch();
  const data = useGetWidgetsItems();
  const isLoading = useGetWidgetsLoading();
  const totalCount = useGetWidgetsCount();
  const getAllWidgets = useCallback((payload: WidgetsPayload) => {
    dispatch(getWidgetsAction(payload));
  }, []);
  return {
    getAllWidgets,
    data,
    isLoading,
    totalCount,
  };
};

export const useWidgetActions = () => {
  const dispatch = useAppDispatch();

  const applicationLinkToWidget = useCallback(
    (data: ApplicationLinkToWidgetPayload) => {
      dispatch(applicationLinkToWidgetAction(data));
    },
    []
  );
  return {
    applicationLinkToWidget,
  };
};

export const useWidgetDetails = () => {
  const dispatch = useAppDispatch();
  const isLoading = useGetWidgetDetailsDataLoading();
  const data = useGetWidgetDetailsData();
  const applications = useGetWidgetDetailsApplications();
  const actionLoading = useGetWidgetActionLoading();

  const getWidgetById = useCallback((data: GetWidgetPayload) => {
    dispatch(getWidgetByIdAction(data));
  }, []);

  const clearWidgetDetailsData = useCallback(() => {
    dispatch(widgetsActions.setWidgetDetails(null));
    dispatch(widgetsActions.setWidgetDetailsApplications([]));
  }, []);

  const applicationUnlinkFromWidget = useCallback(
    (data: ApplicationLinkToWidgetPayload) => {
      dispatch(applicationUnlinkFromWidgetAction(data));
    },
    []
  );
  return {
    getWidgetById,
    clearWidgetDetailsData,
    isLoading,
    data,
    applications,
    applicationUnlinkFromWidget,
    actionLoading,
  };
};
