import styled from "styled-components";
import { Button } from "@mui/material";

export const SortLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #383874;
`;

export const ButtonWrap = styled.strong`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 5px;
  span {
    color: #6266ea;
  }
`;

export const MenuWrapper = styled.div`
  display: flex;
  min-width: 250px;
  flex-direction: column;
`;

export const StyledButton = styled(Button)<{ isActive?: boolean }>`
  &.MuiButton-root {
    justify-content: space-between;
    padding: 10px 16px;
    text-transform: none;
    color: ${(props) => (props.isActive ? "var(--secondary)" : "var(--black)")};
    :hover {
      color: ${(props) =>
        props.isActive ? "var(--secondary)" : "var(--black)"};
      background: var(--ghost);
    }
    div {
      display: flex;
      align-items: center;
      > strong {
        margin-right: 5px;
      }
    }
  }
  svg.MuiSvgIcon-root {
    width: 20px;
    height: 20px;
    position: relative;
    bottom: 1px;
  }
`;
