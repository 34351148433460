import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { InitialState } from "../types/types";
import { WidgetItemDetails, WidgetsResponse } from "shared/api/widgets/types";
import { ApplicationResponse } from "shared/api/applications/types";

const initialState: InitialState = {
  items: [],
  totalCount: 0,
  isLoading: false,
  widgetActionLoading: null,
  widgetDetails: null,
  widgetDetailsLoading: false,
  widgetDetailsApplications: [],
};
const widgetsSlice = createSlice({
  name: "tenants",
  initialState,
  reducers: {
    setItems: (state, action: PayloadAction<WidgetsResponse>) => {
      return {
        ...state,
        isLoading: false,
        items: action.payload.items,
        totalCount: action.payload.totalCount,
      };
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isLoading: action.payload,
      };
    },
    setWidgetActionLoading: (state, action: PayloadAction<string | null>) => {
      state.widgetActionLoading = action.payload;
    },
    setWidgetDetails: (
      state,
      action: PayloadAction<WidgetItemDetails | null>
    ) => {
      state.widgetDetails = action.payload;
      state.widgetDetailsLoading = false;
    },
    setWidgetDetailsLoading: (state, action: PayloadAction<boolean>) => {
      state.widgetDetailsLoading = action.payload;
    },
    setWidgetDetailsApplications: (
      state,
      action: PayloadAction<ApplicationResponse[]>
    ) => {
      state.widgetDetailsApplications = action.payload;
    },
    removeUnlinkedApplication: (state, action: PayloadAction<string>) => {
      state.widgetDetailsApplications = state.widgetDetailsApplications.filter(
        (item) => item.id !== action.payload
      );
    },
  },
});

export const { reducer: widgetsReduce, actions: widgetsActions } = widgetsSlice;
