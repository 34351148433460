import React, { useEffect, useState } from "react";

import Modal from "../CenteredModal";
import { Title, Container, Text, StyledIconButton } from "./styles";
import { Grid, InputAdornment, IconButton, OutlinedInput } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Label from "../../FormComponents/Label";
import { useAppSelector } from "redux/store/hooks/hooks";
import { getSecret } from "entities/applications";

interface ClientSecretModalProps {
  open: boolean;
  onClose: () => void;
}

const CopyClientSecretModal = ({ open, onClose }: ClientSecretModalProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const clientSecret: any = useAppSelector(getSecret);

  useEffect(() => {
    let timeoutId: string | number | NodeJS.Timeout | undefined;
    if (open) {
      timeoutId = setTimeout(() => {
        onClose();
      }, 15000);
    }

    return () => clearTimeout(timeoutId);
  }, [open]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleCopyToClipboard = (copyText?: string) => {
    if (copyText) {
      navigator.clipboard.writeText(copyText);
    }
  };

  return (
    <Modal id="clientSecretModal" onClose={onClose} open={open}>
      <StyledIconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "var(--black)",
        }}
      >
        <CloseIcon />
      </StyledIconButton>
      <Container>
        <Title>Copy Your Client Secret</Title>
        <Text>Your clint access has been successfully created</Text>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Label label="Client Id" />
            <OutlinedInput
              id="clientId"
              type={"text"}
              value={clientSecret?.clientId}
              fullWidth
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      handleCopyToClipboard(clientSecret?.clientId)
                    }
                    edge="end"
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Label label="Client Secret" />
            <OutlinedInput
              id="clientSecret"
              type={showPassword ? "text" : "password"}
              value={clientSecret?.clientSecret}
              fullWidth
              endAdornment={
                <>
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        handleCopyToClipboard(clientSecret?.clientSecret)
                      }
                      edge="end"
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </InputAdornment>
                </>
              }
            />
          </Grid>
        </Grid>
      </Container>
    </Modal>
  );
};

export default CopyClientSecretModal;
