import React from "react";

type Align = "right" | "inherit" | "left" | "center" | "justify" | undefined;

export enum Density {
  COMPACT = "Compact",
  COMFORTABLE = "Comfortable",
  BIG = "Big",
}

export interface Column {
  id: string;
  label: string;
  visible: boolean;
  filtering: boolean;
  minWidth?: number;
  align?: Align;
  format?: (value: number) => string;
  [key: string]: any;
}

export type Order = "asc" | "desc";

export type TenantsRows = Data[];

export interface EnhancedTableProps {
  columns: Column[];
  rows: TenantsRows;
  checkboxes: boolean;
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
  showAvatarsLabel?: boolean;
}

export interface Data {
  companyName: string;
  companyType: string;
  createdDateUtc: string;
  currency: string;
  id: string;
  language: string;
  logo: string;
  taxResidency: number | null;
  updatedDateUtc: string;
}

export interface TableProps {
  rows: TenantsRows;
  columns: Column[];
  density: Density;
  setDensity: (density: Density) => void;
  handleEditTenant: (tenant: any) => void;
  handleRestore: (tenant: any) => void;
  activeTab?: string;
  checkboxes?: boolean;
  controlsHeader?: boolean;
  status: string;
  resetting?: boolean;
}

export interface TableCellProps {
  columnId: string;
  colValue: any;
  tenant: Data;
  handleDeleteTenant: (tenant: string) => void;
  handleEditTenant: (tenant: any) => void;
  handleSwitchTenant: (tenant: any) => void;
  handleRestore: (tenant: any) => void;
  status: string;
}
