import { authSlice } from "./auth";
import { loadingSlice } from "./loading";
import { systemFieldIdSlice } from "./systemIds";
import { archivedAttributesSlice, archivedCategoriesSlice } from "./archives";
import { alertsSlice } from "./alerts";
import { draftItemsSlice } from "./draftItems";
import { modalsSlice } from "./modals";
import { requestsSlice } from "entities/requests/model/slice/requestsSlice";
import { platformNotificationsSlice } from "entities/platformNotifications";
import { tenantsReducer } from "entities/tenants";
import { filesReducer } from "entities/files";
import { moduleTemplatesSlice } from "entities/moduleTemplates";
import { applicationsSlice } from "entities/applications";
import { widgetsReduce } from "entities/widgets";
import { attributesSlice } from "entities/attributes";
import { categoriesSlice, categorySlice } from "entities/productCategories";

export const reducer = {
  auth: authSlice.reducer,
  tenants: tenantsReducer,
  isLoading: loadingSlice.reducer,
  requests: requestsSlice.reducer,
  attributes: attributesSlice.reducer,
  archivedAttributes: archivedAttributesSlice.reducer,
  archivedCategories: archivedCategoriesSlice.reducer,
  categories: categoriesSlice.reducer,
  category: categorySlice.reducer,
  alerts: alertsSlice.reducer,
  draftItems: draftItemsSlice.reducer,
  modals: modalsSlice.reducer,
  systemFieldIds: systemFieldIdSlice.reducer,
  platformNotifications: platformNotificationsSlice.reducer,
  files: filesReducer,
  moduleTemplates: moduleTemplatesSlice.reducer,
  applications: applicationsSlice.reducer,
  widgets: widgetsReduce,
};
