export const emailValid = (email: string) => {
  const regex = new RegExp(
    /^[a-zA-Z0-9]+(?:[._-][a-zA-Z0-9]+)*@[a-zA-Z0-9]+\.[a-zA-Z]{2,}$/
  );

  return regex.test(email);
};

export const nameValid = (name: string) => {
  const regex = new RegExp(/^[a-z ,.'-]+$/i);

  return regex.test(name);
};
