import React, { ChangeEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Modal from "../StyledModal";
import Button from "../../Button";

import {
  Block,
  ErrorBlock,
  StyledAvatar,
  StyledHelperText,
  Text,
  Wrapper,
} from "./styles";

import { Box, Grid } from "@mui/material";
import { ReactComponent as DownloadIcon } from "assets/icons/download.svg";
import { ReactComponent as ImageIcon } from "assets/icons/image.svg";
import FormGroup from "../../FormComponents/FormGroup";
import Label from "../../FormComponents/Label";
import CustomSelect from "../../FormComponents/Select";
import { useAppSelector } from "redux/store/hooks/hooks";
import {
  createTenantAction,
  getTenantActionItem,
  getTenantActionLoading,
  updateTenantAction,
  getTenant,
} from "entities/tenants";
import { Spinner } from "shared/ui/Spinner";
import { useTenantAction } from "features/tenants";

interface CreateTenantProps {
  open: boolean;
  onClose: () => void;
  setCreatedTenantName?: (name: string) => void;
  tenant?: any;
}

interface ErrorsProps {
  companyName?: boolean;
  companyType?: boolean;
  language?: boolean;
  currency?: boolean;
}

const allowedFileTypes = [
  "text/plain",
  "image/png",
  "image/jpeg",
  "application/json",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

const maxFileSize = 5120000;

const TenantModal = ({
  open,
  onClose,
  setCreatedTenantName,
  tenant,
}: CreateTenantProps) => {
  const dispatch = useDispatch();
  const isActionLoading = useAppSelector(getTenantActionLoading);
  const tenantActionItem = useAppSelector(getTenantActionItem);
  const tenantDetail = useAppSelector(getTenant);
  const { unsetActionItem, setActionItemLoading } = useTenantAction();
  const [companyName, setCompanyName] = useState("");
  const [countryOfBusiness, setCountryOfBusiness] = useState<string>("US");
  const [image, setImage] = React.useState<File | null>(null);
  const [imageError, setImageError] = useState("");
  const inputEl: any = React.useRef(null);
  const [companyType, setCompanyType] = useState<string>("");
  const [currency, setCurrency] = useState<string>("USD");
  const [language, setLanguage] = useState<string>("EN");
  const [errors, setErrors] = useState<ErrorsProps>({});

  useEffect(() => {
    if (tenant) {
      setCompanyName(tenant.companyName);
      setCountryOfBusiness(tenant.countryOfBusiness);
      setImage(tenant.image);
      setCompanyType(tenant.companyType);
      setCurrency(tenant.currency);
      setLanguage(tenant.language);
    }
  }, [tenant, open]);
  useEffect(() => {
    if (!open) {
      setCompanyName("");
      setImageError("");
      setCountryOfBusiness("US");
      setImage(null);
      setCompanyType("");
      setCurrency("USD");
      setLanguage("EN");
    }
  }, [open]);

  useEffect(() => {
    if (Object.keys(tenantDetail).length) {
      setCountryOfBusiness(tenantDetail.countryOfBusiness);
      setLanguage(tenantDetail.language);
    }
  }, [tenantDetail]);

  const validateForm = () => {
    if (companyName && companyType && currency && language) {
      return true;
    } else {
      const err: ErrorsProps = {};
      if (!companyName) err.companyName = true;
      if (!companyType) err.companyType = true;
      if (!currency) err.currency = true;
      if (!language) err.language = true;
      setErrors({ ...errors, ...err });
    }
    return false;
  };

  const onSubmit = () => {
    const valid = validateForm();
    if (valid && !tenant && setCreatedTenantName) {
      dispatch(
        createTenantAction({
          data: {
            companyName,
            currency,
            language,
            companyType,
            countryOfBusiness,
          },
          ...(image ? { imageLogo: image } : null),
        })
      );
      setCreatedTenantName(companyName);
    }
    if (valid && tenant) {
      dispatch(
        updateTenantAction({
          data: {
            companyName,
            currency,
            language,
            companyType,
            countryOfBusiness,
          },
          ...(image ? { imageLogo: image } : null),
          tenantId: tenant.id,
        })
      );
    }
  };

  const handleClose = () => {
    setErrors({
      companyName: false,
      companyType: false,
      language: false,
      currency: false,
    });
    onClose();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value, id },
    } = event;
    const validLength = value.length <= 250;

    setErrors({
      ...errors,
      [id]: false,
    });
    if (validLength) setCompanyName(value);
  };

  const handleCountryChange = (value: string) => {
    setErrors({
      ...errors,
    });
    setCountryOfBusiness(value);
  };

  const handleTypeChange = (value: string) => {
    setErrors({
      ...errors,
      companyType: false,
    });
    setCompanyType(value);
  };

  const handleCurrencyChange = (value: string) => {
    setErrors({
      ...errors,
      currency: false,
    });
    setCurrency(value);
  };

  const handleLanguageChange = (value: string) => {
    setErrors({
      ...errors,
      language: false,
    });
    setLanguage(value);
  };

  useEffect(() => {
    return () => {
      setImageError("");
    };
  }, []);

  const imageHandleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      setImageError("File can't be found");
      return;
    }
    setImageError("");
    const file = e.target.files[0];
    const img = new Image();
    const objectUrl = URL.createObjectURL(file);
    img.onload = function () {
      if (img.width > 400 || img.height > 400) {
        setImage(null);
        setImageError("dimension of 400w x 400h ");
        return;
      }
      if (!allowedFileTypes.includes(file.type)) {
        setImageError("Please upload a .jpg or a .png file");
        return;
      }
      if (file.size > maxFileSize) {
        setImageError("File should not exceeding 5MB");
        return;
      }
      if (allowedFileTypes.includes(file.type) && file.size <= maxFileSize) {
        setImage(file);
        setImageError("");
      }
      URL.revokeObjectURL(objectUrl);
    };
    img.src = objectUrl;
  };

  const handleUpload = () => {
    inputEl.current.click();
  };

  useEffect(() => {
    if (tenantActionItem) {
      onClose();
    }
  }, [tenantActionItem]);

  useEffect(() => {
    return () => {
      unsetActionItem();
      setActionItemLoading(false);
    };
  }, []);

  return (
    <Modal
      id="editTenantModal"
      onClose={handleClose}
      open={open}
      title={`${tenant ? "Edit Tenant" : "Create New Tenant"}`}
      submitButton={
        <Button
          variant="contained"
          onClick={onSubmit}
          data-testid="editTenantModal_button"
        >
          {tenant ? "Update Tenant" : "Create Tenant"}
        </Button>
      }
    >
      <Wrapper>
        {isActionLoading && <Spinner />}
        <Grid
          container
          spacing={3}
          alignItems="center"
          sx={{ paddingBottom: "40px", paddingTop: "40px" }}
        >
          <Grid item xs={3}>
            <StyledAvatar
              src={image ? URL.createObjectURL(image) : ""}
              data-testid="editTenantModal_logo"
            >
              <ImageIcon />
            </StyledAvatar>
          </Grid>
          <Grid item xs={9}>
            <input
              type="file"
              name="myImage"
              hidden
              onChange={imageHandleChange}
              ref={inputEl}
            />
            <Button
              variant="contained"
              onClick={handleUpload}
              color="secondary"
              startIcon={<DownloadIcon />}
              data-testid="editTenantModal_changeLogoButton"
            >
              {image ? "Change Logo" : "Upload Logo"}
            </Button>
            <Text sx={{ textAlign: "left" }}>
              Please upload a .jpg or a .png file with a minimum dimension of
              400w x 400h not exceeding 5MB.
            </Text>
            {imageError && <ErrorBlock>{imageError}</ErrorBlock>}
          </Grid>
        </Grid>
        <Block>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormGroup
                placeholder="Enter Company Name"
                value={companyName}
                id="companyName"
                onChange={handleChange}
                label="Company Name"
                error={errors?.companyName}
                danger
              />
              <Box sx={{ textAlign: "right" }}>
                <Text>{companyName.length}/250 characters</Text>
              </Box>
            </Grid>
            <Grid sx={{ position: "relative" }} item xs={12}>
              <Label label="Company Type" danger />
              <CustomSelect
                value={companyType}
                id="companyType"
                options={["Carrier"]}
                onChange={(event) => handleTypeChange(event.target.value)}
                title="Company Type"
                errorPlaceholder="Choose Company Type"
                hideCreate={true}
                error={errors?.companyType}
              />
              {errors?.companyType && (
                <StyledHelperText error>
                  This field is required.
                </StyledHelperText>
              )}
            </Grid>
            <Grid sx={{ position: "relative" }} item xs={6}>
              <Label label="Currency" danger />
              <CustomSelect
                value={currency}
                id="currency"
                options={["CAD", "USD", "EUR"]}
                onChange={(event) => handleCurrencyChange(event.target.value)}
                title="Currency"
                errorPlaceholder="Choose Currency"
                hideCreate={true}
                error={errors?.currency}
              />
              {errors?.currency && (
                <StyledHelperText error>
                  This field is required.
                </StyledHelperText>
              )}
            </Grid>
            <Grid sx={{ position: "relative" }} item xs={6}>
              <Label label="Language" danger />
              <CustomSelect
                value={language}
                id="language"
                options={["EN", "ES"]}
                onChange={(event) => handleLanguageChange(event.target.value)}
                title="Language"
                errorPlaceholder="Choose Language"
                hideCreate={true}
                error={errors?.language}
              />
              {errors?.language && (
                <StyledHelperText error>
                  This field is required.
                </StyledHelperText>
              )}
            </Grid>
            <Grid sx={{ position: "relative" }} item xs={12}>
              <Label label="Country of Business" />
              <CustomSelect
                value={countryOfBusiness}
                id="countryOfBusiness"
                options={["US"]}
                onChange={(event) => handleCountryChange(event.target.value)}
                title="Country of Business"
                hideCreate={true}
              />
            </Grid>
          </Grid>
        </Block>
      </Wrapper>
    </Modal>
  );
};

export default TenantModal;
