import { createSlice } from "@reduxjs/toolkit";

export const attributesSlice = createSlice({
  name: "attributes",
  initialState: {
    items: [],
    totalCount: 0,
  },
  reducers: {
    getAttributesSlice: (state, action) => {
      return action.payload;
    },
  },
});

export const { getAttributesSlice } = attributesSlice.actions;
