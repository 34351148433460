import React, { useEffect, useState } from "react";
import { Grid, Table, TableBody, TableCell, TableRow } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { Modal } from "shared/ui/Modal";
import Button from "shared/ui/Button";
import Label from "components/FormComponents/Label";
import { StyledButton } from "components/Archive/styles";
import { InfoBlock, Col, SubHeading, Text, Paragraph } from "./styles";

import { useAppSelector } from "redux/store/hooks/hooks";

import { getNotification } from "entities/platformNotifications";

import { useFiles } from "features/files/model/hooks/useFiles";
import {
  RecipientData,
  FileProps,
} from "shared/api/platformNotifications/types";

interface ModalProps {
  handleClose: () => void;
  open: boolean;
  recipients?: RecipientData[];
}

const tabList = [
  {
    label: "Description",
  },
  {
    label: "All Uploaded Files",
  },
];

export const NotificationDetail = ({
  handleClose,
  open,
  recipients,
}: ModalProps) => {
  const [selected, setSelected] = useState("Description");
  const notification = useAppSelector(getNotification);

  const { retrieveFile } = useFiles();

  useEffect(() => {
    if (!open) {
      setSelected("Description");
    }
  }, [open]);

  const handleArchivedSelect = (e: React.MouseEvent<HTMLElement>) => {
    const value = e.currentTarget.id;

    setSelected(value);
  };

  const handleClick = (file: FileProps) => {
    retrieveFile(file.fileId, callback);
  };
  const callback = (data: any) => {
    if (data?.id) {
      const a = document.createElement("a");
      a.href = data.uri;
      a.download = data.fileName;
      a.click();
    }
  };

  return (
    <Modal
      title="Notification Detail"
      open={open}
      handleClose={handleClose}
      id="addAttributesModal"
      footer={
        <Button
          variant="outlined"
          onClick={handleClose}
          sx={{
            marginRight: "24px",
            width: "100%",
          }}
        >
          Close
        </Button>
      }
    >
      <InfoBlock>
        <Col>
          <SubHeading>TYPE:</SubHeading>
          <Text>{notification?.notificationType}</Text>
        </Col>
        <Col>
          <SubHeading>TITLE:</SubHeading>
          <Text>{notification?.title}</Text>
        </Col>
        <Col>
          <SubHeading>ID:</SubHeading>
          <Text>{notification?.id}</Text>
        </Col>
        <Col>
          <SubHeading>CREATION DATE:</SubHeading>
          <Text>{notification?.createdDateUtc}</Text>
        </Col>
        <Col>
          <SubHeading>Tenants:</SubHeading>
          {recipients?.map((item: RecipientData) => (
            <Text key={item.companyName}>{item.companyName}</Text>
          ))}
        </Col>
      </InfoBlock>

      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {tabList.map((tab, index) => {
            return (
              <StyledButton
                variant={selected === tab.label ? "contained" : "outlined"}
                onClick={handleArchivedSelect}
                key={tab.label}
                id={tab.label}
                sx={{
                  width: "50%",
                  textTransform: "uppercase !important",
                  borderRadius: index === 0 ? "8px 0 0 8px" : "0 8px 8px 0",
                }}
              >
                {tab.label}
              </StyledButton>
            );
          })}
        </Grid>

        {selected === "Description" ? (
          <Grid item xs={12}>
            <Label label={"Description"} danger={true} />
            <Paragraph>{notification?.description}</Paragraph>
          </Grid>
        ) : (
          <Grid item xs={12}>
            {notification.files.length ? (
              <Table size="small">
                <TableBody>
                  {notification.files.map((file: FileProps) => (
                    <TableRow key={file.fileId}>
                      <TableCell
                        sx={{
                          maxWidth: "400px",
                          overflow: "scroll",
                          "&::-webkit-scrollbar": {
                            display: "none",
                          },
                        }}
                      >
                        {file.fileName}
                      </TableCell>
                      <TableCell sx={{ textAlign: "right" }}>
                        <DownloadIcon
                          onClick={() => handleClick(file)}
                          sx={{
                            cursor: "pointer",
                            color: "var(--secondary)",
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : null}
          </Grid>
        )}
      </Grid>
    </Modal>
  );
};
