import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Box, SvgIcon } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { ReactComponent as DashboardIcon } from "assets/icons/dashboard.svg";
import { ReactComponent as TenantManagement } from "assets/icons/user-statistics.svg";
import { ReactComponent as PlatformMembers } from "assets/icons/platform-members.svg";
import { ReactComponent as ProductsIcon } from "assets/icons/products.svg";
import { ReactComponent as AnalyticsIcon } from "assets/icons/analytics.svg";
import { ReactComponent as NotificationsIcon } from "assets/icons/notifications.svg";
import ChevronIcon from "assets/icons/chevron.svg";
import Logo from "assets/icons/logo.svg";
import NewRequestIcon from "assets/icons/requestIcon";

import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledDrawer,
  StyledFooterDiv,
  StyledFooterLogoWrap,
  StyledLink,
  StyledLogoDiv,
  StyledLogoText,
  StyledPanelDiv,
  StyledPoliciesWrap,
  StyledSidebarLogo,
  StyledSubLink,
  StyledToggleDiv,
  StyledToggleImg,
} from "./styles";
import { countNew } from "entities/requests";
import { useAppSelector } from "redux/store/hooks/hooks";
import { RoutesList } from "shared/routes";

const menuLinks = [
  {
    label: "Dashboard",
    icon: DashboardIcon,
    path: RoutesList.HOME,
  },
  {
    label: "Tenant Management",
    icon: TenantManagement,
    path: RoutesList.TENANT_MANAGEMENT,
  },
  {
    label: "Platform Members",
    icon: PlatformMembers,
    path: RoutesList.PLATFORM_MEMBERS,
  },
  {
    label: "Product Configurator",
    icon: ProductsIcon,
    path: RoutesList.PRODUCT_CONFIGURATOR,
    subLinks: [
      {
        label: "Categories",
        path: `/${RoutesList.CATEGORIES}`,
      },
      {
        label: "Module Templates",
        path: `/${RoutesList.MODULE_TEMPLATES}`,
      },
      {
        label: "Distributions",
        path: `/${RoutesList.DISTRIBUTIONS}`,
      },
      {
        label: "Widgets",
        path: `/${RoutesList.WIDGETS}`,
      },
    ],
  },
  {
    label: "Requests",
    icon: NewRequestIcon,
    path: RoutesList.REQUESTS,
  },
  {
    label: "Notifications",
    icon: NotificationsIcon,
    path: RoutesList.NOTIFICATIONS,
  },
  {
    label: "Analytics",
    icon: AnalyticsIcon,
    path: RoutesList.ANALYTICS,
  },
];

interface Props {
  expanded: boolean;
  sidebarWidth: number;
  rightSidebarPanel: number;
  defaultSpacing: number;
  toggleSidebar: () => void;
}

const Sidebar: React.FC<Props> = ({
  expanded,
  sidebarWidth,
  rightSidebarPanel,
  defaultSpacing,
  toggleSidebar,
}) => {
  const location = useLocation();
  const [newCount, setNewCount] = useState(0);
  const count = useAppSelector(countNew);

  // list of tabs have sublinks
  const list = [RoutesList.TENANT_MANAGEMENT, RoutesList.PRODUCT_CONFIGURATOR];

  useEffect(() => {
    if (newCount == 0 && count) {
      setNewCount(count);
    }
    if (newCount !== count) {
      setNewCount(count);
    }
  }, [count]);

  const tabRequestLabel = (label: string) => {
    if (label === "Requests") {
      if (newCount == 0) {
        return label;
      } else {
        return `Requests (${newCount})`;
      }
    } else {
      return label;
    }
  };

  const setIsActive = (path: string) => {
    const found = location.pathname
      .split("/")
      .find((element: string) => `/${element}` === path);
    return !!found || location.pathname === path;
  };

  const subLinkPath = (path: string) => {
    switch (path) {
      case RoutesList.TENANT_MANAGEMENT:
        return `${path}/${RoutesList.TENANTS}`;
      case RoutesList.PRODUCT_CONFIGURATOR:
        return `${path}/${RoutesList.CATEGORIES}`;
      default:
        return path;
    }
  };

  const currentTabExpandable = (tab: string) => {
    const path = location.pathname;
    const selectedTab = list.filter((e) => path.includes(e));

    if (selectedTab[0] && selectedTab[0] === tab) {
      return true;
    }
    return false;
  };

  return (
    <StyledDrawer variant="permanent" open={expanded} data-testid="sidebar">
      <Box
        sx={{
          width: sidebarWidth,
          overflow: "hidden",
          position: "relative",
          transition: ".1s ease",
          height: "100vh",
          boxSizing: "content-box",
          padding: `0 ${
            defaultSpacing + rightSidebarPanel
          }px 0 ${defaultSpacing}px`,
        }}
      >
        <StyledLogoDiv data-testid="sidebar_logo">
          <StyledSidebarLogo src={Logo} alt="Logo" />
          {expanded && (
            <StyledLogoText>
              IX<span>admin</span>
            </StyledLogoText>
          )}
        </StyledLogoDiv>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            whiteSpace: "nowrap",
          }}
        >
          {menuLinks.map((link, index) =>
            !link.subLinks ? (
              <StyledLink
                isActive={setIsActive(link.path)}
                to={link.path}
                key={`${link.label}_${index}`}
                data-testid={`sidebar_${link.label}Link`}
              >
                {link.icon && <SvgIcon component={link.icon} />}
                {expanded && <p>{tabRequestLabel(link.label)}</p>}
              </StyledLink>
            ) : (
              <StyledAccordion
                expanded={currentTabExpandable(link.path) && expanded}
                key={`${link.label}_${index}`}
              >
                <StyledAccordionSummary
                  aria-controls="panel2d-content"
                  id="panel2d-header"
                >
                  <StyledLink
                    isActive={setIsActive(link.path)}
                    to={subLinkPath(link.path)}
                    key={`${link.label}_${index}`}
                    expanded={expanded ? "true" : undefined}
                  >
                    <SvgIcon component={link.icon} />
                    {expanded && (
                      <>
                        <p>{link.label}</p>
                        <ExpandMoreIcon
                          sx={{
                            fontSize: "16px",
                            position: "absolute",
                            right: "10px",
                          }}
                        />
                      </>
                    )}
                  </StyledLink>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                  {link.subLinks.map((sublink) => {
                    const combinedPath = link.path + sublink.path;
                    return (
                      <StyledSubLink
                        isActive={
                          setIsActive(combinedPath) || combinedPath == ""
                        }
                        to={combinedPath}
                        key={combinedPath}
                        data-testid={`sidebar_${sublink.label}SubLink`}
                      >
                        {sublink.label}
                      </StyledSubLink>
                    );
                  })}
                </StyledAccordionDetails>
              </StyledAccordion>
            )
          )}
        </Box>
        {expanded ? (
          <StyledFooterDiv data-testid="sidebar_footer">
            <StyledFooterLogoWrap data-testid="sidebar_footerLogo">
              <span>Powered by</span>
              <img src={Logo} alt="logo" />
              <StyledLogoText isFooter={true}>
                IX<span>admin</span>
              </StyledLogoText>
            </StyledFooterLogoWrap>
            <StyledPoliciesWrap data-testid="sidebar_footerPolicies">
              <span>Privacy</span>
              <div></div>
              <span>Policy</span>
              <div></div>
              <span>T&C</span>
            </StyledPoliciesWrap>
          </StyledFooterDiv>
        ) : (
          <StyledFooterDiv>
            <StyledFooterLogoWrap data-testid="sidebar_footerLogo">
              <img src={Logo} alt="logo" />
            </StyledFooterLogoWrap>
            <StyledPoliciesWrap />
          </StyledFooterDiv>
        )}
      </Box>
      <StyledPanelDiv rightSidebarPanel={rightSidebarPanel}>
        <StyledToggleDiv
          onClick={toggleSidebar}
          expanded={expanded}
          data-testid="sidebar_toggleButton"
        >
          <StyledToggleImg expanded={expanded} src={ChevronIcon} />
        </StyledToggleDiv>
      </StyledPanelDiv>
    </StyledDrawer>
  );
};

export default Sidebar;
