import * as React from "react";

import { StyledButton } from "./styles";

interface ButtonProps {
  onClick: () => void;
  children: React.ReactNode;
  variant: "text" | "outlined" | "contained" | undefined;
  endIcon?: React.ReactNode;
  startIcon?: React.ReactNode;
  component?: string;
  minWidth?: number;
  disabled?: boolean;
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
}

const Button = ({
  children,
  onClick,
  variant,
  endIcon,
  color,
  startIcon,
  minWidth,
  disabled,
  ...props
}: ButtonProps) => {
  return (
    <StyledButton
      variant={variant}
      onClick={onClick}
      endIcon={endIcon}
      color={color}
      disabled={disabled}
      startIcon={startIcon}
      {...props}
      sx={{ minWidth: minWidth ?? minWidth }}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
