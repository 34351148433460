import React, { useState, useEffect } from "react";
import { TextField, InputAdornment, Divider } from "@mui/material";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import ClearIcon from "@mui/icons-material/Clear";

import {
  OptionsWrap,
  FieldWrap,
  NoItemsWrap,
  ButtonsWrap,
  ControlsWrap,
  StyledButton,
  StyledCheckbox,
} from "./styles";
import TextMatchHighlight from "../TextMatchHighlight";
import { Column, DataRows } from "../../Table/types";

interface SelectsProps {
  headCell: Column;
  rows: DataRows;
  handleClose: () => void;
  onChange?: (searchValue: any) => void;
  id: string;
}

const SearchMultiSelect: React.FC<SelectsProps> = ({
  rows,
  headCell,
  handleClose,
  onChange,
  id,
}) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedOptions, setSelectedOptions] = useState<any[] | undefined>([]);
  const [checked, setChecked] = useState<any[]>([]);

  useEffect(() => {
    if (searchValue) {
      const filteredOpts = filterOptions(rows);
      setSelectedOptions(filteredOpts);
    } else {
      setSelectedOptions([]);
    }
  }, [searchValue]);

  const handleCFilter = (value: string) => {
    const values = [...checked];
    if (checked.indexOf(value) === -1) {
      values.push(value);
    } else {
      values.splice(values.indexOf(value), 1);
    }
    setChecked(values);
  };

  const filterOptions = (rows: DataRows) => {
    const options = rows.map((row: any) => row[headCell?.id]);
    const uniqueOptions = options.filter((option, index, self) => {
      return self.indexOf(option) === index;
    });
    return uniqueOptions.filter((option) =>
      option?.toLowerCase().startsWith(searchValue?.toLowerCase())
    );
  };

  const handleApply = () => {
    handleClose();
    if (onChange) {
      onChange(selectedOptions);
    }
  };

  const handleClear = () => {
    setSearchValue("");
    if (onChange) {
      onChange([]);
    }
  };

  return (
    <>
      <FieldWrap onKeyDown={(e) => e.stopPropagation()}>
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon width={25} height={25} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment onClick={() => setSearchValue("")} position="end">
                <ClearIcon sx={{ cursor: "pointer" }} />
              </InputAdornment>
            ),
          }}
          value={searchValue}
          id={id}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </FieldWrap>
      {searchValue && (
        <OptionsWrap>
          {selectedOptions?.length ? (
            selectedOptions.map((option, key) => {
              return (
                <ButtonsWrap
                  key={`${option}_${key}`}
                  data-testid={`multiSearch_value_${option}`}
                >
                  <StyledButton onClick={() => handleCFilter(option)}>
                    <StyledCheckbox checked={checked.indexOf(option) !== -1} />
                    {TextMatchHighlight(option, searchValue)}
                  </StyledButton>
                </ButtonsWrap>
              );
            })
          ) : (
            <NoItemsWrap data-testid="noItemsFound">No items found</NoItemsWrap>
          )}
        </OptionsWrap>
      )}
      {selectedOptions?.length ? (
        <>
          <Divider />
          <ControlsWrap>
            <button
              onClick={handleClear}
              data-testid="multiSearch_clearAllButton"
            >
              Clear All
            </button>
            <button onClick={handleApply} data-testid="multiSearch_applyButton">
              Apply
            </button>
          </ControlsWrap>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default SearchMultiSelect;
