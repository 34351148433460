import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useDebounce } from "use-debounce";
// components
import AttributesTable from "../Table";
import TableControls from "../TableControls";
import EditAttributeModal from "../Modals/CreateAttribute";
import EditParentAttributesModal from "../Modals/AddAttributes";
import DeleteSimpleModal from "../Modals/DeleteSimpleModal";
import TablePaper from "shared/ui/TablePaper";
// actions
import { attributesActions } from "entities/attributes";
import { systemFieldActions } from "redux/actions/systemFields";
// types
import { initialColumns } from "./types";
import { AttributesData, AttributesPayload, PaginationParams } from "api/types";

const initialPagingData = {
  totalCount: 0,
  page: 0,
  limit: 10,
  offset: 0,
  SearchExpression: "",
};

const Attributes: React.FC<any> = () => {
  const attributes = useSelector((state: any) => state.attributes);
  const isLoading = useSelector((state: any) => state.isLoading);

  const [openEditAttributeModal, setOpenEditAttributeModal] = useState(false);
  const [openEditParentAttributeModal, setOpenEditParentAttributeModal] =
    useState(false);
  const [openDeleteAttributeModal, setOpenDeleteAttributeModal] =
    useState(false);
  const [attribute, setAttribute] = useState<any>();
  const [parentId, setParentId] = useState<any>();
  const dispatch = useDispatch();
  const [renderedItems, setRenderedItems] = useState<AttributesData[]>([]);
  const [attributrPayload, setAttributrPayload] = useState<AttributesPayload>();
  const [filterParams, setFilterParams] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [created, setCreated] = useState(false);
  const [pagingParams, setPagingParams] =
    useState<PaginationParams>(initialPagingData);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [touched, setTouched] = useState(false);

  const [searchQuery] = useDebounce(searchValue, 2000);

  useEffect(() => {
    getData();
    dispatch({ type: systemFieldActions.GET_SYSTEMIDS });
  }, []);

  useEffect(() => {
    if (!isLoading && pagingParams) getData();
  }, [pagingParams]);

  useEffect(() => {
    if (filterParams) getData();
  }, [filterParams]);

  useEffect(() => {
    if (attributes?.items) {
      if (attributes.totalCount !== totalCount) {
        setTotalCount(attributes.totalCount);
      }
      setRenderedItems([...attributes.items]);
    }
  }, [attributes]);

  useEffect(() => {
    if (searchQuery) {
      if (!touched) setTouched(true);
      setPagingParams({
        ...initialPagingData,
        SearchExpression: searchQuery,
      });
    } else if (!searchQuery && touched) {
      setPagingParams({
        ...initialPagingData,
        SearchExpression: "",
      });
    }
  }, [searchQuery]);

  const getData = () => {
    dispatch({
      type: attributesActions.GET_ATTRIBUTES,
      data: {
        params: filterPayload(filterParams),
      },
    });
  };

  const filterPayload = (filterParams: any) => {
    const params: any = {
      ShowArchived: false,
      limit: pagingParams.limit,
      offset: pagingParams.offset,
      SearchExpression: pagingParams.SearchExpression,
    };
    let filterOptions = "";
    if (filterParams.order === "desc") filterOptions = "Descending";
    if (filterParams.order === "asc") filterOptions = "Ascending";
    if (filterOptions !== "") {
      params["SortingPreferences.Kind"] = filterOptions;
      params["SortingPreferences.SortingProperty"] = "AttributeName";
    }
    return params;
  };

  const handleEditAttribute = (attribute: any) => {
    setOpenEditAttributeModal(true);
    setAttribute(attribute);
  };

  const handleDeleteAttribute = useCallback(
    (attribute: any, parentId: any) => {
      setOpenDeleteAttributeModal(true);
      setAttribute(attribute);
      parentId && setParentId(parentId);
    },
    [attribute]
  );

  const handleClose = () => {
    setOpenDeleteAttributeModal(false);
    setParentId(undefined);
    setAttribute(null);
    setOpenEditParentAttributeModal(false);
    getData();
  };

  const handleSubmit = () => {
    if (attribute && parentId) {
      dispatch({
        type: attributesActions.DETACH_ATTRIBUTE,
        data: {
          id: parentId,
          attributeId: attribute.id,
          name: attribute.attributeName,
        },
      });
    } else {
      dispatch({
        type: attributesActions.DELETE_ATTRIBUTE,
        data: {
          id: attribute.id,
          name: attribute.attributeName,
        },
      });
    }
    handleClose();
  };

  const onOpenAddAttributes = (payload: AttributesPayload) => {
    setAttributrPayload(payload);
    setOpenEditParentAttributeModal(!openEditParentAttributeModal);
  };

  const notifyCreated = () => {
    setCreated(!created);
  };

  const closeCreateAttribute = () => {
    setOpenEditAttributeModal(false);
    getData();
    if (created) setCreated(!created);
  };

  const openCreateAttributeModal = () => {
    setOpenEditAttributeModal(true);
    if (attribute) setAttribute(undefined);
  };

  return (
    <TablePaper>
      <TableControls
        activeTab={"Attributes"}
        handleOpen={openCreateAttributeModal}
        handleSearch={(e) => setSearchValue(e.target.value)}
        searchValue={searchValue}
      />
      <AttributesTable
        rows={renderedItems}
        columns={initialColumns}
        activeTab={"Attributes"}
        onEdit={handleEditAttribute}
        onDelete={handleDeleteAttribute}
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        pagingParams={pagingParams}
        setPagingParams={setPagingParams}
        totalRows={totalCount}
      />
      <DeleteSimpleModal
        open={openDeleteAttributeModal}
        onClose={handleClose}
        onSubmit={handleSubmit}
        title="Are you sure you want to delete the Attribute?"
      />
      <EditAttributeModal
        onClose={closeCreateAttribute}
        open={openEditAttributeModal}
        attribute={attribute}
        openAttachAttribute={() => setOpenEditParentAttributeModal(true)}
        onOpenAddAttributes={onOpenAddAttributes}
        justCreated={created}
        pagingParams={pagingParams}
        filterParams={filterParams}
      />
      <EditParentAttributesModal
        onClose={handleClose}
        open={openEditParentAttributeModal}
        attribute={attribute}
        payload={attributrPayload}
        notifyCreated={notifyCreated}
      />
    </TablePaper>
  );
};

export default Attributes;
