import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useDebounce } from "use-debounce";
// components
import TablePaper from "shared/ui/TablePaper";
import CategoriesTable from "../Table";
import TableControls from "../TableControls";
import DeleteSimpleModal from "../Modals/DeleteSimpleModal";
import EditCategoryModal from "../Modals/CategoryModal";
import CreateAttributeModal from "../Modals/CreateAttribute";
// actions
import { categoriesActions } from "entities/productCategories";
import { attributesActions } from "entities/attributes";
// types
import { CategoriesData, PaginationParams } from "api/types";
import { initialColumns } from "./types";

const initialPagingData = {
  totalCount: 0,
  page: 0,
  limit: 10,
  offset: 0,
  SearchExpression: "",
};

const Categories: React.FC<any> = () => {
  const categories = useSelector((state: any) => state.categories);
  const isLoading = useSelector((state: any) => state.isLoading);

  const dispatch = useDispatch();
  const [category, setCategory] = useState<any>();
  const [editCategory, setEditCategory] = useState<CategoriesData>();
  const [openDeleteCategoryModal, setOpenDeleteCategoryModal] = useState(false);
  const [openEditCategoryModal, setOpenEditCategoryModal] = useState(false);
  const [openCreateAttributesModal, setOpenCreateAttributesModal] =
    useState(false);
  const [renderedItems, setRenderedItems] = useState<CategoriesData[]>([]);
  const [filterParams, setFilterParams] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [reset, setReset] = React.useState(false);
  const [pagingParams, setPagingParams] =
    useState<PaginationParams>(initialPagingData);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [touched, setTouched] = useState(false);

  const [searchQuery] = useDebounce(searchValue, 2000);

  useEffect(() => {
    getData();
    dispatch({
      type: attributesActions.GET_ATTRIBUTES,
      data: {
        params: {
          ShowArchived: false,
          limit: pagingParams.limit,
          offset: pagingParams.offset,
        },
      },
    });
  }, []);

  useEffect(() => {
    if (reset) setReset(false);
  }, [reset]);

  useEffect(() => {
    if (!isLoading && pagingParams) getData();
  }, [pagingParams]);

  useEffect(() => {
    if (filterParams) getData();
  }, [filterParams]);

  useEffect(() => {
    if (categories?.items) {
      if (categories.totalCount !== totalCount) {
        setTotalCount(categories.totalCount);
      }
      setRenderedItems([...categories.items]);
    }
  }, [categories]);

  useEffect(() => {
    if (searchQuery) {
      if (!touched) setTouched(true);
      setPagingParams({
        ...initialPagingData,
        SearchExpression: searchQuery,
      });
    } else if (!searchQuery && touched) {
      setPagingParams({
        ...initialPagingData,
        SearchExpression: "",
      });
    }
  }, [searchQuery]);

  useEffect(() => {
    if (!openEditCategoryModal && !reset) setReset(true);
  }, [openEditCategoryModal]);

  const getData = () => {
    dispatch({
      type: categoriesActions.GET_CATEGORIES,
      data: {
        params: filterPayload(filterParams),
      },
    });
  };

  const filterPayload = (filterParams: any) => {
    const params: any = {
      ShowArchived: false,
      limit: pagingParams.limit,
      offset: pagingParams.offset,
      SearchExpression: pagingParams.SearchExpression,
    };
    let filterOptions = "";
    if (filterParams.order === "desc") filterOptions = "Descending";
    if (filterParams.order === "asc") filterOptions = "Ascending";
    if (filterOptions !== "") {
      params["SortingPreferences.Kind"] = filterOptions;
      params["SortingPreferences.SortingProperty"] = "CategoryName";
    }
    return params;
  };

  const handleDeleteCategory = useCallback(
    (category: any) => {
      setOpenDeleteCategoryModal(true);
      setCategory(category);
    },
    [category]
  );

  const handleClose = () => {
    setOpenDeleteCategoryModal(false);
  };

  const openCreateAttr = () => {
    setOpenCreateAttributesModal(true);
    setOpenEditCategoryModal(false);
  };

  const setCurrentCategory = (item: CategoriesData) => {
    setEditCategory(item);
    setOpenEditCategoryModal(true);
    if (reset) setReset(!reset);
  };

  const handleSubmit = useCallback(() => {
    if (category) {
      dispatch({
        type: categoriesActions.DELETE_CATEGORY,
        data: {
          id: category.id,
          name: category.categoryName,
        },
      });
      handleClose();
    }
  }, [category]);

  const handleOpen = () => {
    setReset(true);
    setEditCategory(undefined);
    setOpenEditCategoryModal(true);
  };

  return (
    <TablePaper>
      <>
        <TableControls
          activeTab={"Categories"}
          handleOpen={handleOpen}
          handleSearch={(e) => setSearchValue(e.target.value)}
          searchValue={searchValue}
        />
        <CategoriesTable
          rows={renderedItems}
          columns={initialColumns}
          activeTab={"Categories"}
          onEdit={setCurrentCategory}
          onDelete={handleDeleteCategory}
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          pagingParams={pagingParams}
          setPagingParams={setPagingParams}
          totalRows={totalCount}
        />
        <DeleteSimpleModal
          open={openDeleteCategoryModal}
          onClose={handleClose}
          onSubmit={handleSubmit}
          title="Are you sure you want to delete the category?"
        />
        <EditCategoryModal
          onClose={() => setOpenEditCategoryModal(!openEditCategoryModal)}
          open={openEditCategoryModal}
          category={editCategory}
          type={"Category"}
          onOpenCreateAttributeModal={openCreateAttr}
          resetting={reset}
        />
        <CreateAttributeModal
          onClose={() =>
            setOpenCreateAttributesModal(!openCreateAttributesModal)
          }
          open={openCreateAttributesModal}
        />
      </>
    </TablePaper>
  );
};

export default Categories;
