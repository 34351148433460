import React, { useEffect, useState } from "react";
import { OrderTypes } from "shared/config/types/Sorting";
import { DistributionConnect, useWidgets } from "features/widgets";
import { format } from "date-fns";
import TableControls from "components/TableControls";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";
import { IXTableRow, TableHeader } from "shared/ui/Table";
import SortingDefault, {
  SortRenderProps,
} from "shared/ui/Table/SortingDefault/SortingDefault";
import { initialColumns } from "./columns";
import TablePlaceholder from "shared/ui/TablePlaceholder";
import { TableSkeleton } from "shared/ui/TableSkeleton";
import { DateFormat } from "shared/config/enums/DateFormat";
import { ActionButton, ActionButtonsWrapper } from "shared/ui/ActionButton";
import { ActionButtonTypes } from "shared/ui/ActionButton/types";
import { TableContainerWrapper } from "shared/ui/Table/styles";
import {
  useTableProps,
  useTableSelectedItems,
} from "shared/lib/hooks/Table/useTableProps";
import OutlinedCheckbox from "shared/ui/OutlinedCheckbox";
import DeleteSimpleModal from "components/Modals/DeleteSimpleModal";
import { Widget } from "shared/api/widgets/types";
import { ReactComponent as InfoIcon } from "assets/icons/info.svg";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { RoutesList } from "shared/routes";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} sx={{ boxShadow: 1 }}>
    {props.children}
  </Tooltip>
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    maxWidth: "240px",
    fontSize: "12px",
    fontFamily: "Inter, sans-serif",
    padding: "10px",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
}));

interface Props {
  isArchive?: boolean;
}
export const WidgetsTable = (props: Props) => {
  const navigate = useNavigate();
  const { isArchive } = props;
  const [addDistribution, setAddDistribution] = useState<Widget | null>(null);
  const [deleteModal, setDeleteModal] = useState<string | null>(null);
  const { getAllWidgets, data, isLoading, totalCount } = useWidgets();
  const {
    handleChangeRowsPerPage,
    page,
    handleChangePage,
    rowsPerPage,
    order,
    setOrder,
    value,
    setSearchValue,
    searchValue,
  } = useTableProps();

  const { isSelected, handleSelectAllClick, handleClick, selected } =
    useTableSelectedItems(data);

  useEffect(() => {
    if (addDistribution) return;
    const offset = page * rowsPerPage;
    getAllWidgets({
      offset: offset,
      limit: rowsPerPage,
      isArchive,
      search: value,
      ...(order.label && { sortPreference: order.label }),
      ...(order.label && { sortKind: order.type }),
    });
  }, [rowsPerPage, page, order, value, isArchive, addDistribution]);

  const handleAddDistributionToTheWidget = (item: Widget) => () => {
    setAddDistribution(item);
  };
  const handleCloseDistributionToTheWidget = () => {
    setAddDistribution(null);
  };

  const handleViewWidget = (widgetId: string, tenantId: string) => () => {
    navigate(
      `${RoutesList.PRODUCT_CONFIGURATOR}/${RoutesList.TENANTS}/${tenantId}/${RoutesList.WIDGETS}/${widgetId}`
    );
  };
  return (
    <>
      <TableControls
        activeTab={"Create Widget"}
        buttonLabel={"Create Request"}
        searchValue={searchValue}
        handleSearch={(e: any) => setSearchValue(e.target.value)}
      />
      <TableContainerWrapper data-testid="tableContainer" size="sm">
        <Table stickyHeader>
          <TableHeader
            hideCheckbox={false}
            columns={initialColumns}
            numSelected={selected.length}
            rowCount={data.length}
            onSelectAllClick={handleSelectAllClick}
          >
            {(props: SortRenderProps) => (
              <SortingDefault
                setOrder={(data: OrderTypes) => setOrder(data)}
                order={order}
                {...props}
              />
            )}
          </TableHeader>
          <TableBody data-testid="tableBody">
            {isLoading && (
              <TableSkeleton
                lines={5}
                checkbox={true}
                columns={[...initialColumns]}
              />
            )}
            {!isLoading && data.length === 0 && (
              <TablePlaceholder colSpan={initialColumns.length + 1} />
            )}
            {!isLoading &&
              data.length > 0 &&
              data.map((row) => {
                return (
                  <IXTableRow
                    selected={selected.indexOf(row.id) !== -1}
                    hover
                    tabIndex={-1}
                    key={row.id}
                    data-testid={`tableRow_${row.id}`}
                  >
                    <TableCell sx={{ padding: "0 0 0 4px !important" }}>
                      <OutlinedCheckbox
                        onClick={(event) => handleClick(event, row.id)}
                        checked={isSelected(row.id)}
                      />
                    </TableCell>
                    <TableCell sx={{ fontWeight: 700 }}>
                      <Box display="flex" alignItems="center" gap={1}>
                        {row.name}
                        {row.applications.length <= 0 && (
                          <Box
                            sx={{ flex: "1 0 10%" }}
                            display="flex"
                            alignItems="center"
                          >
                            <LightTooltip
                              placement="right-end"
                              title={
                                "Adding the widget to distribution allows it to work with any product that you create"
                              }
                              PopperProps={{
                                modifiers: [
                                  {
                                    name: "offset",
                                    options: {
                                      offset: [50, 10],
                                    },
                                  },
                                ],
                              }}
                            >
                              <InfoIcon />
                            </LightTooltip>
                          </Box>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>{row.widgetType}</TableCell>
                    <TableCell>{row.tenantName ?? ""}</TableCell>
                    <TableCell>{row.applications.length}</TableCell>
                    <TableCell>
                      {format(new Date(row.createdDateUtc), DateFormat.DEFAULT)}
                    </TableCell>
                    <TableCell>
                      {format(new Date(row.updatedDateUtc), DateFormat.DEFAULT)}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      <ActionButtonsWrapper>
                        <ActionButton
                          title={"Clone Widget"}
                          type={ActionButtonTypes.CLONE}
                        />
                        <ActionButton
                          title={"Edit Widget"}
                          type={ActionButtonTypes.EDIT}
                        />
                        <ActionButton
                          title={"Delete Widget"}
                          type={ActionButtonTypes.DELETE}
                          onClick={() => setDeleteModal(row.name)}
                        />
                        <ActionButton
                          title={"Add to the Distribution"}
                          onClick={handleAddDistributionToTheWidget(row)}
                          type={ActionButtonTypes.ADD}
                        />
                        <ActionButton
                          title={"View Widget"}
                          type={ActionButtonTypes.VIEW}
                          onClick={handleViewWidget(row.id, row.tenantId)}
                        />
                      </ActionButtonsWrapper>
                    </TableCell>
                  </IXTableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainerWrapper>
      <TablePagination
        sx={{ marginTop: "30px" }}
        rowsPerPageOptions={[10, 25]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        data-testid="table_pagination"
      />
      {deleteModal && (
        <DeleteSimpleModal
          open={!!deleteModal}
          title={`Are you sure you want to delete Widget "${deleteModal}"?`}
          onSubmit={() => console.log("Delete")}
          onClose={() => setDeleteModal(null)}
        />
      )}
      {addDistribution && (
        <DistributionConnect
          handleClose={handleCloseDistributionToTheWidget}
          open={!!addDistribution}
          item={addDistribution}
        />
      )}
    </>
  );
};
