import { createSlice } from "@reduxjs/toolkit";
import { InitialState } from "../types/types";

const initialState: InitialState = {
  totalCount: 0,
  items: [],
  application: {},
  secret: {},
  tenantId: "",
  isLoading: false,
};
export const applicationsSlice = createSlice({
  name: "applications",
  initialState,
  reducers: {
    getApplications: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    loadApplication: (state, action) => {
      return {
        ...state,
        application: action.payload,
      };
    },
    loadSecret: (state, action) => {
      return {
        ...state,
        secret: action.payload,
      };
    },
  },
});

export const { getApplications, loadApplication, loadSecret } =
  applicationsSlice.actions;
