import React from "react";
import { DetailedInfoRow, InfoLabel, InfoValues } from "./styles";

interface Props {
  label: string;
  value?: string | number;
}
export const DetailsInfo = ({ label, value }: Props) => {
  return (
    <DetailedInfoRow>
      <InfoLabel>{label}:</InfoLabel>
      {value && <InfoValues>{value}</InfoValues>}
    </DetailedInfoRow>
  );
};
