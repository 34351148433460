import React from "react";
import { FormControlLabel } from "@mui/material";
import { StyledSwitch } from "./styles";

interface SwitchProps {
  checked: boolean;
  onChecked: () => void;
  label: string;
}

const Switch = ({ checked, onChecked, label }: SwitchProps) => {
  return (
    <FormControlLabel
      control={<StyledSwitch checked={checked} onChange={onChecked} />}
      label={label}
    />
  );
};

export default Switch;
