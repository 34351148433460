import React, { ReactNode } from "react";
import { ButtonProps } from "@mui/material/Button/Button";
import { StyledButton } from "./styles";

interface Props extends Omit<ButtonProps, "size"> {
  children?: ReactNode;
}

const Button = (props: Props) => {
  const { onClick, variant, children, ...otherProps } = props;
  return (
    <StyledButton variant={variant} onClick={onClick} {...otherProps}>
      {children}
    </StyledButton>
  );
};

export default Button;
