import styled from "styled-components";
import { Button } from "@mui/material";

export const StyledButton = styled(Button)`
  &.MuiButton-root {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 2px;
    border-radius: 100px;
    padding: 16px 38px;
    text-transform: none;
    min-width: 230px;
  }
  &.MuiButton-outlined {
    border: 1px solid #9696a9;
    color: var(--secondary);
  }
  &.MuiButton-contained {
    background: var(--secondary);
    border: 1px solid var(--secondary);
    color: #ffffff;
  }
  &.MuiButton-containedError {
    background: var(--mediumred);
    box-shadow: 10px 10px 40px rgba(241, 65, 65, 0.25);
    margin-left: 20px;
  }
  .MuiSvgIcon-root {
    margin-right: 10px;
  }
`;
