import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { ReactComponent as QuestionIcon } from "assets/icons/question.svg";

import Modal from "../CenteredModal";
import Button from "../../Button";

import { Title, Text, Container, StyledActions, Danger } from "./styles";

import { modalsActions } from "redux/actions/modals";
import { ModalProps } from "redux/store/reducers/modals";

import { PORTAL_PATH } from "../../../constants";

const SwitchToTenantModal = () => {
  const dispatch = useDispatch();
  const switchToTenantModal: ModalProps = useSelector(
    (state: any) => state.modals?.switchToTenant
  );

  const { isModalOpen, tenant } = switchToTenantModal;

  const onClose = () => {
    dispatch({ type: modalsActions.CLOSE_MODAL });
  };

  const onSubmit = () => {
    onClose();
    window.open(`${PORTAL_PATH}?tenant=${tenant.companyName}`, "_blank");
  };
  return (
    <Modal id="switchToTenantModal" onClose={onClose} open={!!isModalOpen}>
      <Container>
        <QuestionIcon />
        <Title>Are you sure you want to access as Tenant Admin Account?</Title>
        <Text>
          By continuing you are going to be able to <Danger>EDIT</Danger>,{" "}
          <Danger>DELETE</Danger> and <Danger>CHANGE</Danger> sensible tenant
          data.
        </Text>
      </Container>

      <StyledActions>
        <Button
          onClick={onClose}
          variant="outlined"
          data-testid="switchToTenantModal_cancelButton"
        >
          No, Continue Here
        </Button>
        <Button
          variant="contained"
          onClick={onSubmit}
          color="primary"
          data-testid="switchToTenantModal_yesButton"
        >
          Yes, Open Tenant Admin
        </Button>
      </StyledActions>
    </Modal>
  );
};

export default SwitchToTenantModal;
