import React, { useState, useEffect } from "react";
import { useDebounce } from "use-debounce";

import DistributionForm from "./DistributionForm";
import Button from "../../Button";
import Modal from "../StyledModal";
import { useAppSelector } from "redux/store/hooks/hooks";
import { getTenants } from "entities/tenants";
import { TenantItem } from "shared/api/tenant/types";
import { CreateApplicationRequest } from "shared/api/applications/types";

interface CreateAttributeProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: {
    data: CreateApplicationRequest;
    tenantId: string;
  }) => void;
}

interface ErrorsProps {
  name?: boolean;
  provider?: boolean;
  location?: boolean;
  distributionType?: boolean;
  channel?: boolean;
  tenant?: boolean;
}

const CreateDistribution = ({
  open,
  onClose,
  onSubmit,
}: CreateAttributeProps) => {
  const [name, setName] = useState("");
  const [provider, setProvider] = useState("");
  const [location, setLocation] = useState("");
  const [distributionType, setDistributionType] = useState("");
  const [channel, setChannel] = useState("");
  const [allowedDomains, setAllowedDomains] = useState<string[]>([]);
  const [scopes, setScopes] = useState<string[]>([]);
  const [tenant, setTenant] = useState("");
  const [tenantId, setTenantId] = useState("");
  const [errors, setErrors] = useState<ErrorsProps>({});
  const [isPublic, setIsPublic] = useState(false);
  const [valueEntered, setValueEntered] = useState("");
  const [scope, setScope] = useState("");
  const [tenants, setTenants] = useState<TenantItem[]>([]);

  const [currentValue] = useDebounce(valueEntered, 100);
  const [scopeValue] = useDebounce(scope, 100);

  const tenantsState = useAppSelector(getTenants);

  const resetForm = () => {
    setErrors({});
    setName("");
    setProvider("");
    setLocation("");
    setDistributionType("");
    setChannel("");
    setTenant("");
    setValueEntered("");
    setScope("");
    setAllowedDomains([]);
    setScopes([]);
    setErrors({});
    setIsPublic(false);
  };

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);

  useEffect(() => {
    if (tenant) {
      const result = tenants.filter((item: any) => item.companyName === tenant);
      setTenantId(result[0].id);
    } else {
      setTenantId("");
    }
  }, [tenant]);

  useEffect(() => {
    const result = tenantsState.items.filter(
      (item: TenantItem) => item.state === "Active"
    );
    setTenants(result);
  }, [tenantsState]);

  const handleKeyPress = (event: any) => {
    const {
      target: { id },
      code,
    } = event;
    if (code === "Enter") {
      if (id === "allowedDomains") {
        const newValues = [...allowedDomains];
        newValues.push(currentValue);
        setAllowedDomains(newValues);
        setValueEntered("");
      } else if (id === "scopes") {
        const newValues = [...scopes];
        newValues.push(scopeValue);
        setScopes(newValues);
        setScope("");
      }
    }
  };

  const validateForm = () => {
    if (name && provider && location && distributionType && channel && tenant) {
      return true;
    } else {
      const err: ErrorsProps = {};
      if (!name) err.name = true;
      if (!provider) err.provider = true;
      if (!location) err.location = true;
      if (!distributionType) err.distributionType = true;
      if (!channel) err.channel = true;
      if (!tenant) err.tenant = true;
      setErrors({ ...errors, ...err });
    }
    return false;
  };

  const handleSubmit = () => {
    const valid = validateForm();
    if (valid) {
      onSubmit({
        data: {
          name,
          provider,
          location,
          isPublic,
          allowedDomains,
          scopes,
          distributionType,
          channel,
        },
        tenantId,
      });
    }
  };

  const handleClose = () => {
    onClose();
  };

  const deleteValue = (valueToRemove: string, type: string) => {
    if (type === "allowedDomains") {
      setAllowedDomains((allowedDomains) =>
        allowedDomains.filter((value) => value !== valueToRemove)
      );
    } else if (type === "scopes") {
      setScopes((scopes) => scopes.filter((value) => value !== valueToRemove));
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value, id, name },
    } = event;

    setErrors({
      ...errors,
      [id || name]: false,
    });

    if (id === "name") setName(value);
    if (id === "provider") setProvider(value);
    if (id === "location") setLocation(value);
    if (name === "distributionType") setDistributionType(value);
    if (name === "channel") setChannel(value);
    if (name === "tenant") {
      setTenant(value);
    }
  };

  return (
    <Modal
      id="distributionModal"
      onClose={handleClose}
      open={open}
      title={"Create New Distribution"}
      submitButton={
        <Button
          variant="contained"
          onClick={handleSubmit}
          data-testid="distributionModal_button"
        >
          Create Distribution
        </Button>
      }
      fullScreen={true}
    >
      <DistributionForm
        name={name}
        provider={provider}
        location={location}
        distributionType={distributionType}
        channel={channel}
        allowedDomains={allowedDomains}
        scopes={scopes}
        tenant={tenant}
        errors={errors}
        isPublic={isPublic}
        scope={scope}
        tenants={tenants}
        setScope={setScope}
        setIsPublic={() => setIsPublic(!isPublic)}
        valueEntered={valueEntered}
        setValueEntered={setValueEntered}
        deleteValue={deleteValue}
        handleChange={handleChange}
        handleKeyPress={handleKeyPress}
      />
    </Modal>
  );
};

export default CreateDistribution;
