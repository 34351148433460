import React, { useEffect, useMemo } from "react";
import Button from "shared/ui/Button";
import { Modal } from "shared/ui/Modal";
import { Controller, useForm } from "react-hook-form";
import Label from "components/FormComponents/Label";
import Select from "shared/ui/Select";
import { Box, ListItemText, OutlinedInput } from "@mui/material";
import { Widget } from "shared/api/widgets/types";
import { useAppSelector } from "redux/store/hooks/hooks";
import { getApplicationsList } from "entities/applications";
import { StyledMenuItem, StyledSelect } from "shared/ui/Select/styles";
import { useApplications } from "../../../applications/model/hooks/useApplications";
import { StyledHelperText } from "components/Modals/TenantModal/styles";
import { useWidgetActions } from "../../model/hooks/useWidgets";
import { ApplicationResponse } from "shared/api/applications/types";

interface ModalProps {
  handleClose: () => void;
  open: boolean;
  item: Widget;
}

interface IFormInput {
  tenant: string;
  distribution: ApplicationResponse;
}
export const DistributionConnect = (props: ModalProps) => {
  const { open, handleClose, item } = props;
  const { getApplications } = useApplications();
  const { applicationLinkToWidget } = useWidgetActions();
  const applications = useAppSelector(getApplicationsList);
  const { control, handleSubmit } = useForm<IFormInput>({
    defaultValues: {
      tenant: item.tenantName,
    },
  });
  useEffect(() => {
    getApplications({ offset: 0, limit: 100, id: item.tenantId });
  }, []);
  const handleCloseModal = () => {
    handleClose();
  };
  const onSubmit = () => {
    handleSubmit((data: IFormInput) => {
      applicationLinkToWidget({
        tenantId: item.tenantId,
        applicationId: data.distribution.id,
        widgetId: item.id,
        applicationName: data.distribution.name,
        widgetName: item.name,
        cb: handleCloseModal,
      });
    })();
  };
  const applicationsData = useMemo(() => {
    if (item.applications.length <= 0) return applications;
    const appsData = item.applications.reduce<Record<string, string>>(
      (accum, item) => {
        accum[item.applicationId] = item.name;
        return accum;
      },
      {}
    );
    return applications.filter((item) => !appsData[item.id]);
  }, [applications]);
  return (
    <Modal
      title="Connect Widget with Distribution"
      open={open}
      handleClose={handleClose}
      id="addAttributesModal"
      footer={
        <>
          <Button variant="outlined" onClick={handleClose} fullWidth={true}>
            Cancel
          </Button>
          <Button variant="outlined" onClick={onSubmit} fullWidth={true}>
            Add
          </Button>
        </>
      }
    >
      <form>
        <Box mb={2}>
          <Controller
            name="tenant"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <>
                  <Label label="Tenant" danger={true} />
                  <Select
                    onChange={onChange}
                    disabled={true}
                    options={[item.tenantName]}
                    id={"type"}
                    error={!!error}
                    value={value || ""}
                    multiple={false}
                  />
                </>
              );
            }}
          />
        </Box>

        <Controller
          name="distribution"
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <>
                <Label label="Distribution" danger={true} />
                <StyledSelect
                  onChange={onChange}
                  input={<OutlinedInput placeholder="Please select" />}
                  fullWidth={true}
                  renderValue={(value: any) => {
                    return value.name ?? "Please choose an option";
                  }}
                  id={"type"}
                  error={!!error}
                  value={value || ""}
                  multiple={false}
                >
                  <StyledMenuItem value="">
                    <ListItemText primary={"Please choose an option"} />
                  </StyledMenuItem>
                  {applicationsData.map((item: any) => {
                    return (
                      <StyledMenuItem key={item.id} value={item}>
                        {item.name}
                      </StyledMenuItem>
                    );
                  })}
                </StyledSelect>
                {error && (
                  <StyledHelperText error>
                    This field is required.
                  </StyledHelperText>
                )}
              </>
            );
          }}
        />
      </form>
    </Modal>
  );
};
