import styled from "styled-components";
import {
  DialogContent,
  Typography,
  FormHelperText,
  Avatar,
} from "@mui/material";

export const Wrapper = styled(DialogContent)`
  position: relative;
  &.MuiDialogContent-root {
    padding: 40px 50px;
    border-bottom: none;
    .MuiButton-containedSecondary {
      padding: 5px 24px;
      height: 40px;
      font-size: 12px;
      line-height: 16px;
    }
  }
`;
export const Text = styled(Typography)`
  &.MuiTypography-root {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--grey);
    margin-top: 10px;
  }
`;
export const Block = styled.div`
  padding: 40px 0;
  margin: 40px 0 0;
  border-top: 1px solid var(--silver);
  &:last-child {
    margin: 0;
  }
`;
export const ErrorBlock = styled.div`
  color: var(--mediumred);
  margin-top: 10px;
  font-size: 14px;
`;
export const StyledHelperText = styled(FormHelperText)``;

export const StyledAvatar = styled(Avatar)`
  &.MuiAvatar-root {
    width: 100%;
    background: var(--ghost);
    height: 80px;
    border-radius: 15px;
    line-height: 32px;
  }
  svg path {
    fill: rgba(98, 102, 234, 0.5);
  }
`;
