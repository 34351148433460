import { AxiosResponse } from "axios";
import { IXAdminApiPrivate } from "api/IXAdminApi";
import {
  ApplicationLinkToWidgetPayload,
  GetWidgetPayload,
  WidgetItemDetails,
  WidgetsPayload,
  WidgetsProductUpdatePayload,
  WidgetsResponse,
} from "./types";

export default class WidgetsApi {
  static async getAll({
    search,
    sortKind,
    sortPreference,
    offset,
    limit,
    isArchive,
  }: WidgetsPayload): Promise<AxiosResponse<WidgetsResponse>> {
    return IXAdminApiPrivate.get("/api/Widgets", {
      params: {
        SearchExpression: search,
        "SortingPreferences.Kind": sortKind,
        "SortingPreferences.SortingProperty": sortPreference,
        ShowArchived: !!isArchive,
        offset,
        limit,
      },
    });
  }

  static async updateWidgetProducts({
    productIds,
    id,
  }: WidgetsProductUpdatePayload): Promise<AxiosResponse<{ id: string }>> {
    return IXAdminApiPrivate.put(`/api/Widgets/${id}/products/bulk`, {
      productIds,
    });
  }

  static async linkApplicationToWidget({
    applicationId,
    widgetId,
    tenantId,
  }: Omit<
    ApplicationLinkToWidgetPayload,
    "widgetName" | "applicationName"
  >): Promise<AxiosResponse<{ id: string }>> {
    return IXAdminApiPrivate.post(
      `/api/Tenants/${tenantId}/Widgets/${widgetId}/application`,
      `"${applicationId}"`,
      { headers: { "Content-Type": "text/json" } }
    );
  }

  static async getWidgetById({
    tenantId,
    id,
  }: GetWidgetPayload): Promise<AxiosResponse<WidgetItemDetails>> {
    return IXAdminApiPrivate.get(`/api/Tenants/${tenantId}/Widgets/${id}`);
  }
  static async unlinkApplicationFromWidget({
    tenantId,
    widgetId,
    applicationId,
  }: Omit<
    ApplicationLinkToWidgetPayload,
    "widgetName" | "applicationName"
  >): Promise<AxiosResponse<{ id: string }>> {
    return IXAdminApiPrivate.delete(
      `/api/Tenants/${tenantId}/Widgets/${widgetId}/application/${applicationId}`
    );
  }
}
