export const requestsActions = {
  CREATE_REQUEST: "CREATE_REQUEST",
  GET_REQUESTS: "GET_REQUESTS",
  GET_REQUEST: "GET_REQUEST",
  DELETE_REQUEST: "DELETE_REQUEST",
  RESTORE_REQUEST: "RESTORE_REQUEST",
  ADD_COMMENT: "ADD_COMMENT",
  GET_COMMENTS: "GET_COMMENTS",
  GET_REQUEST_TYPES: "GET_REQUEST_TYPES",
  GET_REQUEST_TYPES_FAILED: "GET_REQUEST_TYPES_FAILED",
  GET_TYPES: "GET_TYPES",
  UPDATE_STATUS: "UPDATE_STATUS",
  COUNT_NEW_REQUESTS: "COUNT_NEW_REQUESTS",
  COUNT_ACTIVE_REQUESTS: "COUNT_ACTIVE_REQUESTS",
  COUNT_COMPLETED_REQUESTS: "COUNT_COMPLETED_REQUESTS",
  COUNT_ARCHIVED_REQUESTS: "COUNT_ARCHIVED_REQUESTS",
  GET_COMPLETED: "GET_COMPLETED",
  GET_ARCHIVED: "GET_ARCHIVED",
};
