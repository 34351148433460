import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Modal from "../StyledModal";
import Button from "../../Button";

import { Block, Form, Subtitle } from "./styles";

import { Grid, FormHelperText } from "@mui/material";
import FormGroup from "../../FormComponents/FormGroup";
import Label from "../../FormComponents/Label";
import CustomSelect from "../../FormComponents/Select";
import { alertActions } from "redux/actions/alerts";
import ModalNotification from "../ModalNotification";
import { modalsActions } from "redux/actions/modals";
import { ReactComponent as AddUserIcon } from "assets/icons/user-plus.svg";
import { emailValid, nameValid } from "utils/validate";

interface AddTenantAdminProps {
  isModalOpen: boolean;
  type: string;
  tenant: string;
}

interface ErrorsProps {
  firstName?: boolean;
  lastName?: boolean;
  role?: boolean;
  email?: boolean;
}

const AddTenantAdmin: React.FC<{ createdTenantName: string }> = ({
  createdTenantName,
}) => {
  const addAdminModal: AddTenantAdminProps = useSelector(
    (state: any) => state.modals?.addAdmin
  );
  const { isModalOpen } = addAdminModal;

  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [role, setRole] = useState<string[]>(["Tenant Admin"]);
  const [group, setGroup] = useState<string[]>([]);
  const [errors, setErrors] = useState<ErrorsProps>({});

  const validateForm = () => {
    if (firstName && lastName && email && role) {
      return userInfoValid();
    } else {
      const err: ErrorsProps = {};
      if (!firstName) err.firstName = true;
      if (!lastName) err.lastName = true;
      if (!email) err.email = true;
      if (!role.length) err.role = true;
      setErrors({ ...errors, ...err });
    }
    return false;
  };

  const userInfoValid = () => {
    const firstNameValid = nameValid(firstName);
    const lastNameValid = nameValid(lastName);
    const emailIsValid = emailValid(email);

    if (firstNameValid && lastNameValid && emailIsValid) {
      return true;
    } else {
      const err: ErrorsProps = {};
      if (!firstNameValid) err.firstName = true;
      if (!lastNameValid) err.lastName = true;
      if (!emailIsValid) err.email = true;
      setErrors({ ...errors, ...err });
      return false;
    }
  };

  const onClose = () => {
    dispatch({ type: modalsActions.CLOSE_MODAL });
    setErrors({});
  };

  const onSubmit = () => {
    const valid = validateForm();

    if (valid) {
      dispatch({
        type: alertActions.SHOW_ALERT,
        data: {
          type: "addTenantAdmin",
          name: firstName + " " + lastName,
          email,
        },
      });
      onClose();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value, id },
    } = event;
    setErrors({
      ...errors,
      [id]: false,
    });
    if (id === "firstName") setFirstName(value);
    if (id === "lastName") setLastName(value);
    if (id === "title") setTitle(value);
    if (id === "email") setEmail(value);
    if (id === "phone") setPhone(value);
  };

  const selectRole = (event: any) => {
    const {
      target: { value },
    } = event;
    setRole(typeof value === "string" ? value.split(",") : value);
    setErrors({
      ...errors,
      role: false,
    });
  };

  const selectGroup = (event: any) => {
    const {
      target: { value },
    } = event;
    setGroup(typeof value === "string" ? value.split(",") : value);
  };

  useEffect(() => {
    if (!isModalOpen) {
      setFirstName("");
      setLastName("");
      setEmail("");
      setTitle("");
      setPhone("");
      setRole(["Tenant Admin"]);
      setGroup([]);
      setErrors({});
    }
  }, [isModalOpen]);

  return (
    <Modal
      id="addNewTenantModal"
      data-testid="addNewTenantModal"
      onClose={onClose}
      open={isModalOpen}
      title="Add New Tenant Admin"
      submitButton={
        <Button
          startIcon={<AddUserIcon />}
          variant="contained"
          onClick={onSubmit}
          data-testid="addNewTenantModal_button"
        >
          Add Tenant Admin
        </Button>
      }
      cancelButtonLabel={"Skip"}
    >
      <Form dividers>
        <ModalNotification
          type={"createdTenant"}
          subjectName={createdTenantName}
        />
        <Subtitle>General Information</Subtitle>
        <Grid container spacing={3} sx={{ marginBottom: "40px" }}>
          <Grid item xs={6}>
            <FormGroup
              danger
              placeholder="John"
              value={firstName}
              id="firstName"
              onChange={handleChange}
              error={errors?.firstName}
              label="First Name"
              errorText="Invalid First Name"
            />
          </Grid>
          <Grid item xs={6}>
            <FormGroup
              danger
              placeholder="Smith"
              value={lastName}
              id="lastName"
              onChange={handleChange}
              error={errors?.lastName}
              label="Last Name"
              errorText="Invalid Last Name"
            />
          </Grid>
          <Grid item xs={12}>
            <FormGroup
              disabled
              value={(firstName || lastName) && `${firstName} ${lastName}`}
              placeholder="Full Name"
              id="fullName"
              label="Full Name"
            />
          </Grid>
          <Grid item xs={6}>
            <FormGroup
              placeholder="Enter Title"
              value={title}
              id="title"
              onChange={handleChange}
              label="Title"
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <Label label="Role" danger />
            <CustomSelect
              value={role}
              multiple
              id="role"
              options={["Tenant Admin", "Role 2", "Role 3"]}
              onChange={selectRole}
              title="Role"
              error={errors?.role}
            />
            {errors?.role && (
              <FormHelperText error>This field is required.</FormHelperText>
            )}
          </Grid>
          <Grid item xs={6}>
            <Label label="Groups" disabled />
            <CustomSelect
              value={group}
              disabled
              multiple
              id="group"
              options={["Group 1", "Group 2", "Group 3"]}
              onChange={selectGroup}
              title="Group"
            />
          </Grid>
        </Grid>
        <Block>
          <Subtitle>Contacts</Subtitle>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormGroup
                danger
                label="Email"
                type="email"
                placeholder="Enter Email"
                value={email}
                id="email"
                onChange={handleChange}
                error={errors?.email}
                errorText="Invalid Email"
              />
            </Grid>
            <Grid item xs={6}>
              <FormGroup
                label="Phone"
                placeholder="Enter Phone Number"
                value={phone}
                id="phone"
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Block>
      </Form>
    </Modal>
  );
};

export default AddTenantAdmin;
