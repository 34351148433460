import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

export const InputGroup = styled("div")`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--black);
  border: 1px solid var(--primary);
  border-radius: 4px;
  padding: 5.3px 14px;
  display: flex;
  flex-wrap: wrap;
  .MuiChip-root {
    margin: 5px 5px 5px 0;
  }
`;

export const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
    "& .MuiOutlinedInput-input": {
      padding: "0",
      lineHeight: "31.99px",
      height: "31.99px",
      margin: "5px 5px 5px 0",
    },
  },
});
