import { takeLatest, put } from "redux-saga/effects";
import { signIn } from "../store/reducers/auth";
import { authActions } from "../actions/auth";

export function* signInWorker(payload: any) {
  try {
    yield put(signIn(payload.data));
  } catch (e) {
    yield put({ type: "SIGN_IN_FAILED" });
  }
}

export function* signInWatcher() {
  yield takeLatest(authActions.SIGN_IN, signInWorker);
}
