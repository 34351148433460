import styled from "styled-components";
import { DialogContent, Typography } from "@mui/material";

export const Wrapper = styled(DialogContent)`
  &.MuiDialogContent-root {
    padding: 40px 50px;
    border-bottom: none;
    .MuiButton-containedSecondary {
      padding: 5px 24px;
      height: 40px;
      font-size: 12px;
      line-height: 16px;
    }
  }
`;
export const Text = styled(Typography)`
  &.MuiTypography-root {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--grey);
    margin-top: 10px;
  }
`;
export const Block = styled.div`
  padding: 40px 0;
  margin: 40px 0 0;
  border-top: 1px solid var(--silver);
  &:last-child {
    margin: 0;
  }
`;

export const Subtitle = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: var(--secondary);
    margin-bottom: 24px;
  }
`;
export const Form = styled(DialogContent)`
  &.MuiDialogContent-root {
    padding: 40px 51px;
    border-bottom: none;
  }
`;
