import styled from "styled-components";
import { ReactComponent as Rectangle } from "assets/icons/rectangle.svg";
import { Button, Avatar } from "@mui/material";

export const GridBlock = styled.div`
  width: 434px;
  position: relative;
  &:before {
    content: "";
    background: #0033a0;
    border-radius: 0px 12px 12px 12px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    filter: drop-shadow(0px 0px 24px rgba(56, 56, 116, 0.25));
  }
`;

export const StyledGrid = styled.div`
  border-radius: 10px;
  background: #ffffff;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const StyledRectangle = styled(Rectangle)`
  position: absolute;
  top: -32px;
  left: 0;
  path {
    fill: #0033a0;
  }
`;

export const DateBlock = styled.div`
  background: #f2f2fc;
  padding: 10px 24px;
  display: flex;
  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #9696a9;
    margin-right: 24px;
  }
`;

export const CompanyInfoTop = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px;
  align-items: center;
`;
export const CompanyInfoBottom = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  align-items: center;
`;

export const AdminBlock = styled.div`
  padding: 16px 24px 28px;
  display: flex;
  align-items: center;
  p {
    font-family: "Inter";
    font-style: normal;
    font-size: 14px;
    line-height: 24px;
  }
`;

export const CompanyName = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #010101;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 65%;
  white-space: nowrap;
`;
export const AdminEmail = styled.p`
  font-weight: 400;
  color: #6266ea;
`;
export const AdminName = styled.p`
  font-weight: 700;
  color: #010101;
`;

export const Users = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #6266ea;
  white-space: nowrap;
  display: flex;
  align-items: center;
  svg {
    margin-right: 4px;
  }
`;
export const Capacity = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #6266ea;
  white-space: nowrap;
`;
export const CapacityLabel = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #383874;
  opacity: 0.5;
`;
export const CapacityBlock = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 24px 22px;
  align-items: center;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 4px;
    background: #383874;
    opacity: 0.25;
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 4px;
    background: #6266ea;
    width: 190px;
  }
`;

export const TenantButton = styled(Button)`
  &.MuiButton-contained {
    position: absolute;
    width: 76px;
    height: 76px;
    right: 0px;
    bottom: 0px;
    background: #6266ea;
    border-radius: 40px 0px 10px;
  }
`;

export const AdminAvatar = styled(Avatar)`
  &.MuiAvatar-root {
    font-size: 0.75rem;
    font-weight: 700;
    color: var(--secondary);
    background: var(--silver);
    opacity: 0.5;
    margin-right: 10px;
  }
`;
