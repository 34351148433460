import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TenantsTable from "../TenantsTable";
import EditTenant from "../Modals/TenantModal";
import SwitchToTenant from "../Modals/SwitchToTenant";
import RestoreModal from "../Modals/RestoreModal";
import { tenantsActions } from "entities/tenants/model/actions/tenants";
import { Density } from "../TenantsTable/types";
import { getTenantsAction } from "entities/tenants";
import { useTenant } from "features/tenants";
import TablePaper from "shared/ui/TablePaper";
import { initialColumns } from "./types";

export interface TenantsPros {
  status?: string;
}

const Tenants: React.FC<any> = ({ status }) => {
  const [density, setDensity] = useState<Density>(Density.BIG);
  const tenants = useSelector((state: any) => state.tenants.items);
  const [tenantToEdit, setTenantToEdit] = useState<any>();
  const [openEditTenantModal, setOpenEditTenantModal] = useState(false);
  const [rows, setRows] = useState([]);
  const [openRestoreModal, setOpenRestoreModal] = useState(false);
  const [reset, setReset] = useState(false);
  const { getTenant } = useTenant();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTenantsAction());
  }, []);

  useEffect(() => {
    if (reset) setReset(false);
  }, [reset]);

  useEffect(() => {
    let result;
    if (tenants.length) {
      if (status === "Archive") {
        setReset(true);
        result = tenants.filter((tenant: any) => tenant.state === "Deleted");
      } else {
        setReset(true);
        result = tenants.filter((tenant: any) => tenant.state === "Active");
      }
      setRows(result);
    }
  }, [status, tenants]);

  const handleEditTenant = (tenant: any) => {
    setTenantToEdit(tenant);
    getTenant(tenant.id);
    setOpenEditTenantModal(true);
  };

  const handleRestore = (tenant: any) => {
    setTenantToEdit(tenant);
    setOpenRestoreModal(!openRestoreModal);
  };

  const restoreTenant = () => {
    dispatch({
      type: tenantsActions.RESTORE_TENANT,
      tenantId: tenantToEdit?.id,
      data: tenantToEdit,
    });
    setOpenRestoreModal(!openRestoreModal);
    setTenantToEdit(undefined);
  };

  return (
    <TablePaper>
      <TenantsTable
        rows={rows}
        columns={initialColumns}
        density={status === "Archive" ? Density.COMFORTABLE : density}
        setDensity={setDensity}
        activeTab={"Tenants"}
        checkboxes={true}
        controlsHeader={status !== "Archive"}
        handleEditTenant={handleEditTenant}
        handleRestore={handleRestore}
        status={status}
        resetting={reset}
      />
      {openEditTenantModal && (
        <EditTenant
          onClose={() => setOpenEditTenantModal(false)}
          open={openEditTenantModal}
          tenant={tenantToEdit}
        />
      )}
      <SwitchToTenant />
      <RestoreModal
        onClose={() => setOpenRestoreModal(false)}
        open={openRestoreModal}
        restoreType={`Tenant ${tenantToEdit?.companyName}`}
        handleSubmit={restoreTenant}
      />
    </TablePaper>
  );
};

export default Tenants;
