import React from "react";
import { format } from "date-fns";
import DemoLogo from "assets/demo-logo.png";

import TenantLogo from "../TenantLogo";
import TableActions from "../TableActions";
import { TableCellProps } from "../types";
// styles
import { TableText, HighlightedText, Name, Email } from "../styles";
const currencies: any = {
  USD: "$",
  CAD: "$",
};

const TableCell = ({
  columnId,
  colValue,
  tenant,
  handleDeleteTenant,
  handleEditTenant,
  handleSwitchTenant,
  handleRestore,
  status,
}: TableCellProps) => {
  const logo = tenant.companyName === "IX-Demo 1" ? DemoLogo : tenant.logo;
  switch (columnId) {
    case "logo":
      return (
        <TenantLogo
          url={tenant.companyName === "AmTrust" ? DemoLogo : logo}
          maxHeight={"40px"}
        />
      );
    case "companyName":
      return (
        <strong>
          <TableText>{colValue}</TableText>
        </strong>
      );
    case "createdDateUtc":
    case "updatedDateUtc":
      return (
        <TableText>{format(Date.parse(colValue), "MM/dd/yyyy")}</TableText>
      );
    case "actions":
      return (
        <TableActions
          handleDeleteTenant={handleDeleteTenant}
          handleEditTenant={handleEditTenant}
          handleSwitchTenant={handleSwitchTenant}
          handleRestore={handleRestore}
          tenant={tenant}
          status={status}
        />
      );
    case "capacity":
      return (
        <HighlightedText>
          {tenant.currency && currencies[tenant.currency]}
          {colValue}
        </HighlightedText>
      );
    case "usersCount":
      return <HighlightedText>{colValue}</HighlightedText>;
    case "tenantAdmin":
      return (
        <>
          <Name>{colValue?.firstName + " " + colValue?.lastName}</Name>
          <Email>{colValue?.email}</Email>
        </>
      );
    default:
      return <TableText>{colValue}</TableText>;
  }
};

export default TableCell;
