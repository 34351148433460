import React, { useState, ChangeEvent, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";

import Modal from "./NotificationModal";
import RequestButton from "../../Button";
import { Wrapper } from "./styles";
import CreateSection from "./CreateSection";
import FileUploadSection from "./FileUploadSection";

import { platformNotificationsActions } from "entities/platformNotifications";

interface CreateRequestProps {
  open: boolean;
  handleClose: () => void;
}
export interface ErrorsProps {
  notificationType?: boolean;
  title?: boolean;
  description?: boolean;
  recipients?: boolean;
  effectiveDate?: boolean;
}

const notitficationTypes: any = {
  "General IX Platform Alert": "General",
  "Emergency Maintenance Notice": "Emergency",
  "Scheduled Maintenance Notice": "Scheduled",
};

const CreateNotification = ({ open, handleClose }: CreateRequestProps) => {
  const [notificationType, setNotificationType] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [recipients, setRecipients] = useState<string[]>([]);
  const [recipientsOptions, setRecipientsOptions] = useState([]);
  const [effectiveDate, setEffectiveDate] = useState(dayjs(new Date()));
  const [files, setFiles] = useState<File[]>([]);
  const [errors, setErrors] = useState<ErrorsProps>({});

  const dispatch = useDispatch();

  const tenants = useSelector((state: any) => state.tenants.items);

  useEffect(() => {
    const result = tenants.filter((tenant: any) => tenant.state === "Active");
    const tenantsNames = result.map((tenant: any) => {
      return tenant.companyName;
    });
    setRecipientsOptions(tenantsNames);
  }, [tenants]);

  useEffect(() => {
    if (!open) {
      setNotificationType("");
      setTitle("");
      setDescription("");
      setEffectiveDate(dayjs(new Date()));
      setErrors({});
      setFiles([]);
      setRecipients([]);
    }
  }, [open]);

  useEffect(() => {
    if (notificationType) setErrors({ ...errors, notificationType: false });
  }, [notificationType]);
  useEffect(() => {
    if (title) setErrors({ ...errors, title: false });
  }, [title]);
  useEffect(() => {
    if (recipients) setErrors({ ...errors, recipients: false });
  }, [recipients]);
  useEffect(() => {
    if (effectiveDate) {
      setErrors({ ...errors, effectiveDate: false });
    }
  }, [effectiveDate]);
  useEffect(() => {
    if (description) setErrors({ ...errors, description: false });
  }, [description]);

  const allowedFileTypes = [
    "text/plain",
    "image/png",
    "image/jpeg",
    "application/json",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];
  const maxFileSize = 5120000;

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const uploadedfiles = e.target.files;
    const validFiles = [];

    if (uploadedfiles && uploadedfiles.length > 0) {
      for (let i = 0; i < uploadedfiles.length; i++) {
        const uploadedFile = uploadedfiles[i];
        const fileType = uploadedFile.type;
        if (
          allowedFileTypes.includes(fileType) &&
          uploadedFile.size <= maxFileSize
        ) {
          validFiles.push(uploadedFile);
        }
        if (i === uploadedfiles.length - 1) setFiles(files?.concat(validFiles));
      }
    }
  };

  const validateForm = () => {
    if (
      title &&
      description &&
      notificationType &&
      effectiveDate &&
      recipients.length
    ) {
      return true;
    } else {
      const err: ErrorsProps = {};
      if (!notificationType) err.notificationType = true;
      if (!title) err.title = true;
      if (!description) err.description = true;
      if (!effectiveDate) err.effectiveDate = true;
      if (!recipients.length) err.recipients = true;
      setErrors({ ...errors, ...err });
    }
    return false;
  };

  const handleSubmit = () => {
    const valid = validateForm();
    if (valid) {
      onSubmit();
      handleClose();
    }
  };

  const onSubmit = () => {
    const data = selectData();
    dispatch({
      type: platformNotificationsActions.CREATE_PLATFORM_NOTIFICATION,
      data,
      files,
    });
  };

  const selectData = () => {
    const result: string[] = [];
    tenants.map((tenant: any) => {
      if (recipients.includes(tenant.companyName)) {
        result.push(tenant.id);
      }
    });

    return {
      title,
      description,
      effectiveDate: effectiveDate.format("YYYY-MM-DD"),
      notificationType: notitficationTypes[notificationType],
      recipients: result,
    };
  };

  const removeFile = (file: File) => {
    setFiles(files.filter((f: File) => f.name !== file.name));
  };

  const handleChangeDescription = (e: any) => {
    if (e.target.value.length < 251) setDescription(e.target.value);
  };

  const handleRecipients = (e: any) => {
    if (e.target.value.includes("All")) {
      if (
        recipients.includes("All") &&
        e.target.value.length !== recipients.length
      ) {
        const value = e.target.value;
        value.shift();
        setRecipients(value);
      } else {
        setRecipients(["All", ...recipientsOptions]);
      }
    } else if (!e.target.value.includes("All") && recipients.includes("All")) {
      setRecipients([]);
    } else {
      setRecipients(e.target.value);
    }
  };

  return (
    <Modal
      onClose={handleClose}
      open={open}
      title={"Create Notification"}
      submitButton={
        <RequestButton
          variant="contained"
          onClick={handleSubmit}
          data-testid="createNotificationModal_button"
          minWidth={230}
        >
          {"Create Notification"}
        </RequestButton>
      }
    >
      <Wrapper>
        <CreateSection
          title={title}
          recipients={recipients}
          description={description}
          notificationType={notificationType}
          setNotificationType={(e) => setNotificationType(e.target.value)}
          setTitle={(e) => setTitle(e.target.value)}
          setRecipients={handleRecipients}
          setEffectiveDate={(e) => setEffectiveDate(e)}
          setDescription={handleChangeDescription}
          errors={errors}
          effectiveDate={effectiveDate}
          recipientsOptions={recipientsOptions}
        />
        <FileUploadSection
          files={files}
          handleFileUpload={handleFileUpload}
          removeFile={removeFile}
        />
      </Wrapper>
    </Modal>
  );
};

export default CreateNotification;
