import { call, put, takeLatest } from "redux-saga/effects";
import { widgetsActions } from "../slice/widgetsSlice";
import { AxiosResponse } from "axios";
import WidgetsApi from "shared/api/widgets/widgetsApi";
import { sagaErrorBoundary } from "shared/utils/sagaErrorBoundary";
import {
  applicationLinkToWidgetAction,
  applicationUnlinkFromWidgetAction,
  getWidgetByIdAction,
  getWidgetsAction,
} from "../actions/actions";
import { WidgetItemDetails, WidgetsResponse } from "shared/api/widgets/types";
import { Toaster } from "shared/ui/Toast/Toast";
import { ApplicationsResponse } from "shared/api/applications/types";
import ApplicationsApi from "shared/api/applications/applicationsApi";

function* getWidgetItems(action: ReturnType<typeof getWidgetsAction>) {
  try {
    const { payload } = action;
    yield put(widgetsActions.setLoading(true));
    const response: AxiosResponse<WidgetsResponse> = yield call(
      WidgetsApi.getAll,
      payload
    );
    yield put(widgetsActions.setItems(response.data));
  } catch (e) {
    sagaErrorBoundary(e);
    yield put(widgetsActions.setLoading(false));
  }
}
function* applicationLinkToWidget(
  action: ReturnType<typeof applicationLinkToWidgetAction>
) {
  try {
    const { cb, widgetName, applicationName, ...data } = action.payload;
    yield put(widgetsActions.setWidgetActionLoading(data.widgetId));
    const response: AxiosResponse<{ id: string }> = yield call(
      WidgetsApi.linkApplicationToWidget,
      { ...data }
    );
    if (response.data && cb) {
      cb();
    }
    Toaster.infoMessage(
      `Widget ${widgetName} has been linked with Distribution ${applicationName}`
    );
  } catch (e) {
    sagaErrorBoundary(e);
  } finally {
    yield put(widgetsActions.setWidgetActionLoading(null));
  }
}
function* applicationUnlinkFromWidget(
  action: ReturnType<typeof applicationUnlinkFromWidgetAction>
) {
  try {
    const { cb, widgetName, applicationName, ...data } = action.payload;
    yield put(widgetsActions.setWidgetActionLoading(data.applicationId));
    const response: AxiosResponse<{ id: string }> = yield call(
      WidgetsApi.unlinkApplicationFromWidget,
      { ...data }
    );
    if (response.data && cb) {
      cb();
    }
    if (response.data) {
      yield put(widgetsActions.removeUnlinkedApplication(data.applicationId));
    }
    Toaster.infoMessage(
      `Distribution ${applicationName} has been unlinked from widget ${widgetName}`
    );
  } catch (e) {
    sagaErrorBoundary(e);
  } finally {
    yield put(widgetsActions.setWidgetActionLoading(null));
  }
}

function* getWidgetByIdSagaDetails(
  action: ReturnType<typeof getWidgetByIdAction>
) {
  try {
    yield put(widgetsActions.setWidgetDetailsLoading(true));
    const response: AxiosResponse<WidgetItemDetails> = yield call(
      WidgetsApi.getWidgetById,
      action.payload
    );
    if (response.data) {
      yield put(widgetsActions.setWidgetDetails(response.data));
      const params = new URLSearchParams();
      response.data.applications.forEach((app) =>
        params.append("ApplicationsIds", app.applicationId)
      );
      const responseApplications: AxiosResponse<ApplicationsResponse> =
        yield call(ApplicationsApi.getApplications, {
          data: {
            params: {
              limit: 1000,
              offset: 0,
              page: 0,
            },
          },
          id: action.payload.tenantId,
          ids: params.toString(),
        });
      if (responseApplications.data) {
        yield put(
          widgetsActions.setWidgetDetailsApplications(
            responseApplications.data.items
          )
        );
      }
    }
  } catch (e) {
    const { cb } = action.payload;
    sagaErrorBoundary(e);
    cb?.();
  } finally {
    yield put(widgetsActions.setWidgetDetailsLoading(false));
  }
}

export function* widgetsWatcher() {
  yield takeLatest(getWidgetsAction, getWidgetItems);
  yield takeLatest(applicationLinkToWidgetAction, applicationLinkToWidget);
  yield takeLatest(
    applicationUnlinkFromWidgetAction,
    applicationUnlinkFromWidget
  );
  yield takeLatest(getWidgetByIdAction, getWidgetByIdSagaDetails);
}
