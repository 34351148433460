import styled from "styled-components";
import {
  Typography,
  Grid,
  Button,
  Collapse,
  TableRow,
  TableCell,
} from "@mui/material";
import { ReactComponent as ConnectIcon } from "assets/icons/connect.svg";
import FilterListIcon from "@mui/icons-material/FilterList";

export const StyledCollapse = styled(Collapse)`
  &.MuiCollapse-root {
    width: 100%;
    background-color: var(--ghost);
  }
`;

export const FilterIcon = styled(FilterListIcon)`
  &.MuiSvgIcon-root {
    font-size: 20px;
  }
`;

export const StyledConnectIcon = styled(ConnectIcon)`
  margin-top: 6px;
`;

export const ExpandTitle = styled.p`
  margin-top: 6px;
`;

export const StyledTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    border-bottom: none;
    text-align: left;
    padding: 0;
    min-width: 50px;
  }

  &.grey {
    color: var(--grey);
  }

  &.filtered {
    display: flex;
    align-items: center;
  }

  &.topSpacing {
    margin-top: 5px;
  }

  &.largeMinWidth {
    min-width: 180px;
  }

  &.noWidth {
    width: 0% !important;
  }

  &.width {
    width: 20%;
  }

  &.collapsePadding {
    padding: 10px 3px 10px 0px;
  }

  &.largePadding {
    padding: 20px 6px 20px 0px;
  }

  &.left {
    text-align: left;
  }

  &.right {
    text-align: right;
  }
`;

export const TableWrapper = styled.div<{ fullSize: boolean }>`
  height: ${(props) =>
    props.fullSize ? "calc(100% - 100px)" : "calc(100% - 350px)"};
  background-color: var(--ghost);
  width: calc(100% - 100px);
  margin-left: 50px;
  padding: 10px 20px;
  overflow: scroll;
`;

export const TableHeader = styled.div`
  height: 30px;
  width: 100%;
  background-color: var(--ghost);
  display: flex;
  margin-bottom: 20px;
`;

export const StyledTableRow = styled(TableRow)`
  &.MuiTableRow-root {
    background-color: #fff;
  }

  &.header {
    text-transform: uppercase;
  }

  &.transparent {
    background-color: transparent;
    position: relative;
  }
`;

export const AddAttrButton = styled(Button)`
  width: 35ch !important;
  color: var(--secondary) !important;
  font-size: 16px !important;
  border-radius: 100px !important;
  border: 1px solid var(--secondary) !important;
  padding: 10px !important;

  &.remove {
    background-color: var(--white);
  }
`;

export const StyledButton = styled(Button)`
  &.MuiButtonBase-root {
    font-family: "Inter";
    font-style: normal;
    text-transform: none;
    background-color: #fff;
  }

  &.text-button {
    display: contents;
    font-size: 16px;
    color: var(--secondary);
  }

  &.expand {
    margin-left: 20px;
    color: var(--secondary);
    border: 1px solid var(--secondary);
  }
`;

export const Text = styled(Typography)`
  &.MuiTypography-root {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 100;
    font-size: 12px;
    line-height: 16px;
    margin-top: 10px;
    color: var(--grey);
  }

  &.header {
    width: 19%;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase !important;
    text-align: center;
  }
`;

export const StyledGrid = styled(Grid)`
  padding: 10px 50px;
`;

export const Title = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  padding: 10px 50px;

  &.gray {
    color: var(--grey);
    opacity: 0.5;
  }

  &.spacing {
    margin-top: 10px;
  }

  &.bold {
    font-weight: 600;
    padding: 20px 10px 0px 20px;
  }
`;

export const Description = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  padding: 10px 50px;

  &.attrDesc {
    padding: 5px 50px 0px 20px;
  }
`;

export const Block = styled.div`
  position: relative;
  width: 100%;
  height: 77%;
`;

export const AddedWrapper = styled.div`
  width: 100%;
  height: 77%;
  overflow: auto;
`;

export const AddCategory = styled.div`
  position: absolute;
  width: 75%;
  height: 100%;
`;

export const PurpleBlock = styled.div`
  width: 100%;
  height: 5px;
  background-color: var(--lightPurple);
  margin-top: 10px;
  margin-bottom: 10px;

  &.blockPos {
    position: absolute;
    height: 5px;
    top: -5px;
  }
`;

export const AddAttrDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  height: 23%;

  &.left {
    float: left;
  }
`;

export const EditCategory = styled.div`
  position: absolute;
  width: 25%;
  height: 100%;
  left: 75%;
  border-left: 1px solid var(--silver);
  overflow: scroll;
`;
