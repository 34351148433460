import React, { ChangeEvent } from "react";
import { Grid } from "@mui/material";
import Label from "../../../FormComponents/Label";
import FormGroup from "../../../FormComponents/FormGroup";
import CustomSelect from "shared/ui/Select";

interface ErrorsProps {
  requestType?: boolean;
  requestTitle?: boolean;
  description?: boolean;
}

interface CreateSectionProps {
  errors: ErrorsProps;
  description: string;
  requestTitle: string;
  requestType: string;
  handleTypeChange: (event: any) => void;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const CreateSection = ({
  errors,
  requestTitle,
  requestType,
  description,
  handleTypeChange,
  handleChange,
}: CreateSectionProps) => {
  return (
    <>
      <Grid item xs={10}>
        <Label label="Request Type" danger />
        <CustomSelect
          value={requestType}
          id="requestType"
          options={[
            "Attribute",
            "Distribution",
            "Data Enrichment",
            "API Integration",
            "Outcomes",
            "Widget",
            "Other",
          ]}
          onChange={(event) => handleTypeChange(event.target.value)}
          errorPlaceholder="Choose Request Type"
          error={errors?.requestType}
        />
      </Grid>
      <Grid item xs={10}>
        <FormGroup
          value={requestTitle}
          id="requestTitle"
          onChange={handleChange}
          label="Title"
          error={errors?.requestTitle}
          danger
        />
      </Grid>
      <Grid item xs={10}>
        <FormGroup
          value={description}
          id="description"
          onChange={handleChange}
          label="Description"
          error={errors?.description}
          danger
        />
      </Grid>
    </>
  );
};

export default CreateSection;
