import React from "react";
// styles
import { StyledButton } from "./styles";
// icons
import EastIcon from "@mui/icons-material/East";
import CheckIcon from "@mui/icons-material/Check";
// types
import { Order } from "../types";

interface Props {
  type: Order;
  label?: string;
  order: OrderTypes | null;
  handleChange: (filterValue: any) => void;
}

interface OrderTypes {
  type: Order;
  label?: string;
}

enum Orders {
  ASC = "asc",
  DESC = "desc",
}

const FilterButton: React.FC<Props> = ({
  type,
  label,
  order,
  handleChange,
}) => {
  const isActive = order?.type === type && order?.label === label;
  return (
    <StyledButton
      disableRipple
      onClick={() =>
        handleChange({
          type,
          label,
        })
      }
      isActive={isActive}
    >
      <div>
        <strong>Alphabetically </strong>
        {type === Orders.ASC ? (
          <>
            A <EastIcon width={20} height={20} /> Z
          </>
        ) : (
          <>
            Z <EastIcon width={20} height={20} /> A
          </>
        )}
      </div>
      {isActive && <CheckIcon />}
    </StyledButton>
  );
};

export default FilterButton;
