import { IXAdminApiPrivate } from "api/IXAdminApi";
import { AxiosResponse } from "axios";
import { SuccessResponse } from "api/types";
import { FileDetails } from "./types";

export default class FilesApi {
  static async uploadFile(
    ReferenceId: string,
    ReferenceType: string,
    file: File
  ): Promise<AxiosResponse<SuccessResponse>> {
    const formData = new FormData();
    formData.append("file", file);
    return IXAdminApiPrivate.post(
      `/api/Files?ReferenceId=${ReferenceId}&ReferenceType=${ReferenceType}`,
      formData
    );
  }

  static async retrieveFile(id: string): Promise<AxiosResponse<FileDetails>> {
    return IXAdminApiPrivate.get(`/api/Files/${id}`);
  }
}
