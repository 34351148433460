import React, { useState, useEffect } from "react";
import { Menu, Divider } from "@mui/material";
import { IconWrap, MenuWrapper, SortLabel } from "./styles";
import { ReactComponent as FilterIcon } from "assets/icons/filter.svg";
import SearchMultiSelect from "./SearchMultiSelect";
import MultiSelect from "./MultiSelect";
import FilterSelect from "./FilterSelect";
import { Column, Order, DataRows } from "../Table/types";
import CustomDateRangePicker from "./DateRangePicker";
import FilterButton from "./FilterButton";
import RangeButton from "./RangeButton";

interface Props {
  headCell: Column;
  rows: DataRows;
  filterParams?: any;
  setFilterParams?: (params: any) => void;
}

interface OrderTypes {
  type: Order;
  label?: string;
}

enum Orders {
  ASC = "asc",
  DESC = "desc",
}

const sortOrders: OrderTypes[] = [
  {
    type: Orders.ASC,
  },
  {
    type: Orders.DESC,
  },
];

const FilterModal: React.FC<Props> = ({
  headCell,
  rows,
  filterParams,
  setFilterParams,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [order, setOrder] = useState<OrderTypes | null>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (order && setFilterParams)
      setFilterParams({ ...filterParams, order: order.type });
  }, [order]);

  useEffect(() => {
    if (filterParams?.order === "") setOrder(null);
  }, [filterParams]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderFilters = (headCell: any) => {
    switch (headCell.id) {
      case "username": {
        const labels = ["First Name", "Last Name"];
        const customSortOrders: OrderTypes[] = [
          {
            type: Orders.ASC,
            label: labels[0],
          },
          {
            type: Orders.DESC,
            label: labels[0],
          },
          {
            type: Orders.ASC,
            label: labels[1],
          },
          {
            type: Orders.DESC,
            label: labels[1],
          },
        ];

        return (
          <MenuWrapper data-testid="filterModal_menu">
            {customSortOrders.map((item: OrderTypes) => (
              <FilterButton
                key={`${item.type}_${item.label}`}
                type={item.type}
                label={item.label}
                order={order}
                handleClick={() =>
                  setOrder({ type: item.type, label: item.label })
                }
              />
            ))}
            <Divider sx={{ marginBottom: "15px" }} />
            <SearchMultiSelect
              headCell={headCell}
              id={`${headCell.id}_search`}
              rows={rows}
              handleClose={handleClose}
            />
          </MenuWrapper>
        );
      }
      case "admin": {
        const labels = ["Fullname", "Email"];
        const customSortOrders: OrderTypes[] = [
          {
            type: Orders.ASC,
            label: labels[0],
          },
          {
            type: Orders.DESC,
            label: labels[0],
          },
          {
            type: Orders.ASC,
            label: labels[1],
          },
          {
            type: Orders.DESC,
            label: labels[1],
          },
        ];

        return (
          <MenuWrapper data-testid="filterModal_menu">
            {customSortOrders.map((item: OrderTypes) => (
              <FilterButton
                key={`${item.type}_${item.label}`}
                type={item.type}
                label={item.label}
                order={order}
                handleClick={() =>
                  setOrder({ type: item.type, label: item.label })
                }
              />
            ))}
            <Divider sx={{ marginBottom: "15px" }} />
            <SearchMultiSelect
              headCell={headCell}
              id={`${headCell.id}_search`}
              rows={rows}
              handleClose={handleClose}
            />
          </MenuWrapper>
        );
      }
      case "email":
      case "groupname":
      case "owner":
      case "groupOrUser":
      case "companyName":
      case "companyType":
      case "location":
        return (
          <MenuWrapper data-testid="filterModal_menu">
            {sortOrders.map((item: OrderTypes) => (
              <FilterButton
                key={`${item.type}_${item.label}`}
                type={item.type}
                label={item.label}
                order={order}
                handleClick={() =>
                  setOrder({ type: item.type, label: item.label })
                }
              />
            ))}
            <Divider sx={{ marginBottom: "15px" }} />
            <SearchMultiSelect
              headCell={headCell}
              id={`${headCell.id}_search`}
              rows={rows}
              handleClose={handleClose}
            />
          </MenuWrapper>
        );
      case "role": {
        const options = ["Role 1", "Role 2", "Role 3", "Role 4"];
        return (
          <MenuWrapper data-testid="filterModal_menu">
            <MultiSelect options={options} handleClose={handleClose} />
          </MenuWrapper>
        );
      }
      case "recordType": {
        const options = ["User", "Group"];
        return (
          <MenuWrapper data-testid="filterModal_menu">
            <MultiSelect options={options} handleClose={handleClose} />
          </MenuWrapper>
        );
      }
      case "groups": {
        const options = ["Group 1", "Group 2", "Group 3"];
        return (
          <MenuWrapper data-testid="filterModal_menu">
            {sortOrders.map((item: OrderTypes) => (
              <FilterButton
                key={`${item.type}_${item.label}`}
                type={item.type}
                label={item.label}
                order={order}
                handleClick={() =>
                  setOrder({ type: item.type, label: item.label })
                }
              />
            ))}
            <MultiSelect options={options} handleClose={handleClose} />
          </MenuWrapper>
        );
      }
      case "requestStatus": {
        const options = filterParams.states;
        return (
          <MenuWrapper data-testid="filterModal_menu">
            <MultiSelect
              options={options}
              handleClose={handleClose}
              filterKey={headCell.id}
              filterParams={filterParams}
              setFilterParams={setFilterParams}
            />
          </MenuWrapper>
        );
      }
      case "status": {
        const options = ["New", "InProgress", "Pending"];
        return (
          <MenuWrapper data-testid="filterModal_menu">
            <MultiSelect
              options={options}
              handleClose={handleClose}
              filterKey={headCell.id}
              filterParams={filterParams}
              setFilterParams={setFilterParams}
            />
          </MenuWrapper>
        );
      }
      case "notificationType": {
        const options = ["Alert", "General", "Emergency", "Scheduled"];
        return (
          <MenuWrapper data-testid="filterModal_menu">
            <FilterSelect
              options={options}
              handleClose={handleClose}
              filterKey={headCell.id}
              filterParams={filterParams}
              setFilterParams={setFilterParams}
            />
          </MenuWrapper>
        );
      }
      case "createdDate":
      case "updatedDate":
      case "deletedDate":
      case "createdDateUtc":
      case "updatedDateUtc":
        return (
          <MenuWrapper data-testid="filterModal_menu">
            <CustomDateRangePicker onCancel={handleClose} />
          </MenuWrapper>
        );
      case "taxResidency":
      case "users":
        return (
          <MenuWrapper data-testid="filterModal_menu">
            {sortOrders.map((item: OrderTypes) => (
              <RangeButton
                key={`${item.type}_${item.label}`}
                type={item.type}
                label={item.label}
                order={order}
                handleClick={() =>
                  setOrder({ type: item.type, label: item.label })
                }
              />
            ))}
          </MenuWrapper>
        );
      default:
        return (
          <MenuWrapper data-testid="filterModal_menu">
            {sortOrders.map((item: OrderTypes) => (
              <FilterButton
                key={`${item.type}_${item.label}`}
                type={item.type}
                label={item.label}
                order={order}
                handleClick={() =>
                  setOrder({ type: item.type, label: item.label })
                }
              />
            ))}
          </MenuWrapper>
        );
    }
  };

  return (
    <div data-testid="filterModal">
      <SortLabel data-testid="filterModal_label">
        {headCell.label}
        <IconWrap
          key={headCell.id}
          open={open}
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          <FilterIcon />
        </IconWrap>
      </SortLabel>
      <Menu
        keepMounted={true}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        data-testid="filterModal_menu"
      >
        {renderFilters(headCell)}
      </Menu>
    </div>
  );
};

export default FilterModal;
