import React from "react";
import { format } from "date-fns";
// components
import { Typography, Tooltip } from "@mui/material";
import TableActions from "../TableActions";
import TenantLogo from "../TenantLogo";
import DemoLogo from "assets/demo-logo.png";
// icons
import EastIcon from "@mui/icons-material/East";
import PersonIcon from "@mui/icons-material/Person";
// styles
import {
  StyledGrid,
  StyledRectangle,
  GridBlock,
  DateBlock,
  AdminBlock,
  TenantButton,
  CompanyName,
  Users,
  CapacityBlock,
  Capacity,
  CapacityLabel,
  CompanyInfoBottom,
  CompanyInfoTop,
  AdminEmail,
  AdminName,
  AdminAvatar,
} from "./styles";

const TenantBlock: React.FC<any> = ({
  tenant,
  handleDeleteTenant,
  handleEditTenant,
  handleSwitchTenant,
  status,
  handleRestore,
}) => {
  const logo = tenant.companyName === "IX-Demo 1" ? DemoLogo : tenant.logo;
  return (
    <GridBlock data-testid="tetantBlock">
      <StyledRectangle />
      <StyledGrid>
        <div>
          <CompanyInfoTop>
            <TenantLogo
              url={tenant.companyName === "AmTrust" ? DemoLogo : logo}
              height={"42px"}
              maxWidth={"135px"}
            />
            <TableActions
              handleDeleteTenant={handleDeleteTenant}
              handleEditTenant={handleEditTenant}
              handleSwitchTenant={handleSwitchTenant}
              handleRestore={handleRestore}
              tenant={tenant}
              status={status}
              isBlockView={true}
            />
          </CompanyInfoTop>
          <CompanyInfoBottom>
            <CompanyName>{tenant.companyName}</CompanyName>
            <Users>
              <PersonIcon /> {tenant.usersCount}
            </Users>
          </CompanyInfoBottom>
        </div>
        <CapacityBlock>
          <Capacity>${tenant.capacity}</Capacity>
          <CapacityLabel>capacity</CapacityLabel>
        </CapacityBlock>
        <AdminBlock>
          <AdminAvatar />
          {tenant.tenantAdmin && (
            <div>
              <AdminName>
                {tenant.tenantAdmin.firstName +
                  " " +
                  tenant.tenantAdmin.lastName}
              </AdminName>
              <AdminEmail>{tenant.tenantAdmin.email}</AdminEmail>
            </div>
          )}
        </AdminBlock>
        <DateBlock>
          <Typography>
            Creation: {format(Date.parse(tenant.createdDateUtc), "MM-dd-yyyy")}
          </Typography>
          <Typography>
            Last updates:{" "}
            {format(Date.parse(tenant.createdDateUtc), "MM-dd-yyyy")}
          </Typography>
        </DateBlock>
        <Tooltip
          classes={{ popper: "white-tooltip" }}
          title="Switch to Tenant"
          data-testid="tooltip_switchToTenant"
        >
          <TenantButton
            variant="contained"
            onClick={() => handleSwitchTenant(tenant)}
          >
            <EastIcon />
          </TenantButton>
        </Tooltip>
      </StyledGrid>
    </GridBlock>
  );
};

export default TenantBlock;
