import styled from "styled-components";

export const StyledTextarea = styled.textarea`
  padding: 14px;
  width: 100%;
  background: var(--white);
  border: 1px solid var(--primary);
  border-radius: 4px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--headings);
  outline: none;
  max-width: 100%;
  min-width: 100%;
  min-height: 86px;
  &.has-error {
    background: var(--lightred);
    border: 1px solid var(--darkred);
    color: var(--headings);
  }
  &:focus {
    border: 1px solid var(--secondary);
    box-shadow: 0px 0px 10px rgba(98, 102, 234, 0.5);
  }
  &::placeholder {
    color: var(--grey);
  }
`;
