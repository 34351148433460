import React, { FC } from "react";

interface Props {
  list?: string[];
}
export const ErrorList: FC<Props> = ({ list }) => {
  return (
    <ul style={{ listStyle: "none", paddingLeft: 0 }}>
      {list?.map((item) => (
        <li key={item}>{item}</li>
      ))}
    </ul>
  );
};
