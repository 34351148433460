import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {},
  reducers: {
    signIn: (state, action) => {
      return {
        ...action.payload,
      };
    },
  },
});

export const { signIn } = authSlice.actions;
