import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { InitialState } from "../types/types";
import { TenantsResponse, TenantDetail } from "shared/api/tenant/types";

const initialState: InitialState = {
  items: [],
  totalCount: 0,
  tenantActionLoading: false,
  tenantActionItem: null,
  tenant: <TenantDetail>{},
};
const tenantsSlice = createSlice({
  name: "tenants",
  initialState,
  reducers: {
    getTenantsSlice: (state, action: PayloadAction<TenantsResponse>) => {
      return {
        ...state,
        items: action.payload.items,
        totalCount: action.payload.totalCount,
      };
    },
    getTenantSlice: (state, action: PayloadAction<TenantDetail>) => {
      return {
        ...state,
        tenant: action.payload,
      };
    },
    setTenantActionItem: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        tenantActionItem: action.payload,
        tenantActionLoading: false,
      };
    },
    setTenantActionLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        tenantActionLoading: action.payload,
      };
    },
  },
});

export const { reducer: tenantsReducer, actions } = tenantsSlice;
