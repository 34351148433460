import styled from "styled-components";
import { IconButton } from "@mui/material";
import { ActionButtonTypes } from "./types";

const handleColorType = (type: string | undefined) => {
  switch (type) {
    case ActionButtonTypes.EDIT:
      return { background: "var(--lightorange)", fill: "var(--mediumorange)" };
    case ActionButtonTypes.DELETE:
      return { background: "var(--lightred)", fill: "var(--darkred)" };
    default:
      return { background: "var(--lightgreen)", fill: "var(--mediumgreen)" };
  }
};

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 0 10px;
  position: relative;
  align-items: center;
  button {
    margin-left: 0 !important;
  }
`;
export const StyledIconButton = styled(IconButton)<{ actionType: string }>`
  &.MuiIconButton-root {
    margin-left: 10px;

    path:first-child,
    path:last-child {
      fill: var(--grey);
    }
    path:nth-child(2) {
      stroke: var(--grey);
    }

    :first-child {
      margin-left: 0;
    }
    &:hover {
      background-color: ${({ actionType }) =>
        handleColorType(actionType).background};
      path {
        transition: 0.2s;

        &:first-child,
        &:last-child {
          fill: ${({ actionType }) => handleColorType(actionType).fill};
        }
        &:nth-child(2) {
          stroke: ${({ actionType }) => handleColorType(actionType).fill};
        }
      }
    }
  }
`;
