import {
  ApplicationsResponse,
  CreateApplicationResponse,
  ApplicationResponse,
} from "./types";
import { IXAdminApiPrivate } from "api/IXAdminApi";
import { AxiosResponse } from "axios";

export default class ApplicationsApi {
  static async getApplications(
    params: any
  ): Promise<AxiosResponse<ApplicationsResponse>> {
    return IXAdminApiPrivate.get(
      `/api/Tenants/${params.id}/Applications?${params.ids}`,
      params.data
    );
  }
  static async createApplication(
    params: any
  ): Promise<AxiosResponse<CreateApplicationResponse>> {
    return IXAdminApiPrivate.post(
      `/api/Tenants/${params.tenantId}/Applications`,
      params.data
    );
  }
  static async getApplication(
    params: any
  ): Promise<AxiosResponse<ApplicationResponse>> {
    return IXAdminApiPrivate.get(
      `/api/Tenants/${params.tenantId}/Applications/${params.id}`
    );
  }
  static async deleteApplication(
    params: any
  ): Promise<AxiosResponse<ApplicationResponse>> {
    return IXAdminApiPrivate.delete(
      `/api/Tenants/${params.tenantId}/Applications/${params.id}`
    );
  }
  static async regenerateClientSecret(
    params: any
  ): Promise<AxiosResponse<CreateApplicationResponse>> {
    return IXAdminApiPrivate.put(
      `/api/Tenants/${params.tenantId}/Applications/${params.id}/secret`
    );
  }
}
