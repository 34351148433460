import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";

import Modal from "../CenteredModal";
import Button from "../../Button";
import FormGroup from "../../FormComponents/FormGroup";

import {
  Title,
  Text,
  Container,
  StyledActions,
  StyledInputWrap,
} from "./styles";

import { modalsActions } from "redux/actions/modals";
import { tenantsActions } from "entities/tenants/model/actions/tenants";
import { ModalProps } from "redux/store/reducers/modals";
import ModalNotification from "../ModalNotification";

interface TypeProps {
  title: string;
  text: any;
  subText?: string;
}
interface ModalTypesProps {
  tenant: TypeProps;
  tenants: TypeProps;
  [key: string]: any;
}

const DeleteModal = () => {
  const dispatch = useDispatch();
  const [matchName, setMatchName] = useState("");
  const [error, setError] = useState(false);
  const deleteModal: ModalProps = useSelector(
    (state: any) => state.modals?.delete
  );
  const { isModalOpen, type, tenant } = deleteModal;

  const deleteTenant = (name: string) => (
    <Text>
      Are you sure you want to delete tenant <b>{name}</b>?
    </Text>
  );
  const deleteTenants = () => (
    <Text>Are you sure you want to delete selected?</Text>
  );

  const deleteModalTypes: ModalTypesProps = {
    tenant: {
      title: "Delete Tenant?",
      text: deleteTenant,
      subText:
        "The tenant will be moved to Archive. You can restore it anytyme.",
    },
    tenants: {
      title: "Delete selected tenants?",
      text: deleteTenants,
      subText:
        "The tenant will be moved to Archive. You can restore it anytyme.",
    },
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError(false);
    setMatchName(event.target.value);
  };

  const onClose = () => {
    dispatch({ type: modalsActions.CLOSE_MODAL });
    setError(false);
    setMatchName("");
  };

  const validateDelete = (tenantName: string) => {
    return tenantName === matchName;
  };

  const onSubmit = () => {
    if (validateDelete(tenant.companyName)) {
      dispatch({
        type: tenantsActions.DELETE_TENANT,
        data: {
          id: tenant.id,
          name: tenant.companyName,
        },
      });
      onClose();
    } else {
      setError(true);
    }
  };

  return (
    <Modal id={`deleteModal-${type}`} onClose={onClose} open={!!isModalOpen}>
      <Container>
        <DeleteIcon />
        <Title>{type && deleteModalTypes[type].title}</Title>

        {type && deleteModalTypes[type].text(tenant.companyName)}
        <ModalNotification type={"deleteTenant"} />
        <StyledInputWrap>
          <FormGroup
            value={matchName}
            id="matchName"
            onChange={handleInputChange}
            error={error}
            errorText={
              matchName.length
                ? "Entered Tenant's name doesn’t match. Please check. "
                : ""
            }
            label="Enter Tenant Name to proceed"
          />
        </StyledInputWrap>
        {type && deleteModalTypes[type].subText && (
          <Text>{deleteModalTypes[type].subText}</Text>
        )}
      </Container>

      <StyledActions>
        <Button
          onClick={onClose}
          variant="outlined"
          data-testid="deleteModal_cancelButton"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={onSubmit}
          color="error"
          data-testid="deleteModal_yesButton"
        >
          Yes, Delete
        </Button>
      </StyledActions>
    </Modal>
  );
};

export default DeleteModal;
