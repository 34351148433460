import React, { ChangeEvent } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  StyledTypography,
  StyledButton,
  StyledAvatar,
  FileWrapper,
} from "../styles";
import { ReactComponent as UploadIcon } from "assets/icons/upload.svg";
import { ReactComponent as ImageIcon } from "assets/icons/image.svg";

import { Grid, Table, TableBody, TableRow, TableCell } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

interface FileUploadSectionProps {
  files: File[];
  removeFile: (file: File) => void;
  handleFileUpload: (e: ChangeEvent<HTMLInputElement>) => void;
}

const FileUploadSection = ({
  files,
  handleFileUpload,
  removeFile,
}: FileUploadSectionProps) => {
  return (
    <FileWrapper>
      <Grid
        container
        spacing={3}
        sx={{
          justifyContent: "center",
          width: "auto",
        }}
      >
        <Grid item xs={5}>
          <StyledAvatar
            // src={image ? URL.createObjectURL(image) : ""}
            data-testid="editTenantModal_logo"
          >
            <ImageIcon />
          </StyledAvatar>
        </Grid>
        <Grid item xs={7}>
          <StyledButton
            variant="outlined"
            startIcon={<UploadIcon />}
            component="label"
          >
            {"Upload File"}
            <input type="file" onChange={handleFileUpload} hidden multiple />
          </StyledButton>
          <StyledTypography className="warnings">
            Please upload a .xlsx, .txt, .json, .jpg or a .png file with a
            minimum dimension of 400w x 400h not exceeding 5MB.
          </StyledTypography>
        </Grid>
        <Grid item xs={12}>
          {files?.length > 0 && (
            <Table size="small">
              <TableBody>
                {files.map((file) => (
                  <TableRow key={uuidv4()}>
                    <TableCell
                      sx={{
                        maxWidth: "400px",
                        overflow: "scroll",
                        "&::-webkit-scrollbar": {
                          display: "none",
                        },
                      }}
                    >
                      {file.name}
                    </TableCell>
                    <TableCell
                      onClick={() => removeFile(file)}
                      sx={{ textAlign: "right" }}
                    >
                      <DeleteForeverIcon
                        sx={{
                          cursor: "pointer",
                          color: "var(--secondary)",
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Grid>
      </Grid>
    </FileWrapper>
  );
};

export default FileUploadSection;
