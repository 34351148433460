import React, { useMemo } from "react";

import { SvgIcon, Tooltip } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { ReactComponent as RestoreIcon } from "assets/icons/restore.svg";
import { ReactComponent as DottedIcon } from "assets/icons/dots.svg";
import { ReactComponent as HugIcon } from "assets/icons/hug.svg";
import { ReactComponent as EyeIcon } from "assets/icons/eye.svg";
import { ReactComponent as CloneIcon } from "assets/icons/clone.svg";

import { StyledIconButton } from "./styles";

import { ActionButtonTypes } from "./types";

import { ReactComponent } from "*.svg";

interface Props {
  type: ActionButtonTypes;
  title: string;
  onClick: (param: any) => void;
}

type IconsMap = {
  [key in ActionButtonTypes]: typeof SvgIcon | typeof ReactComponent;
};

const iconsMap: IconsMap = {
  [ActionButtonTypes.EDIT]: EditIcon,
  [ActionButtonTypes.DELETE]: DeleteIcon,
  [ActionButtonTypes.DOTTED]: DottedIcon,
  [ActionButtonTypes.ACTION]: EastIcon,
  [ActionButtonTypes.RESTORE]: RestoreIcon,
  [ActionButtonTypes.COPY]: HugIcon,
  [ActionButtonTypes.VIEW]: EyeIcon,
  [ActionButtonTypes.CLONE]: CloneIcon,
};

const ActionButton: React.FC<Props> = ({ title, type, onClick }) => {
  const renderIcon = useMemo(() => {
    const IconComponent = iconsMap[type];
    return <IconComponent data-testid={`tooltipIcon_${type}`} />;
  }, [type]);

  return (
    <Tooltip
      classes={{ popper: "white-tooltip" }}
      title={title}
      data-testid={`tooltip_${type}`}
    >
      <StyledIconButton
        actiontype={type}
        aria-label={type}
        onClick={onClick}
        data-testid={`tooltipButton_${type}`}
      >
        {renderIcon}
      </StyledIconButton>
    </Tooltip>
  );
};

export default ActionButton;
