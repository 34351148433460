import { takeLatest, put, call } from "redux-saga/effects";
import {
  getAttributes,
  createAttribute,
  deleteAttribute,
  restoreAttribute,
  updateAttribute,
  attachAttribute,
  detachAttributeFromAttribute,
} from "api/IXAdminApi";
import { setLoading } from "redux/store/reducers/loading";
import { getArchivedAttributesSlice } from "redux/store/reducers/archives";
import { attributesActions, getAttributesSlice } from "entities/attributes";
import { ReduxAction } from "redux/sagas/types";
import { alertActions } from "redux/actions/alerts";

const initialPagingData = {
  totalCount: 0,
  page: 0,
  limit: 10,
  offset: 0,
};

export interface ResponseGenerator {
  id: any;
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

interface Action {
  data: any;
  type: string;
  attributeId: string;
}

export function* getAttributesWorker(action: ReduxAction) {
  let requestError: any;
  yield put(setLoading(true));
  const response: ResponseGenerator = yield call(
    async () =>
      await getAttributes(action.data)
        .then((response) => response)
        .catch((error) => {
          requestError = error;
        })
  );
  if (response) {
    yield put(setLoading(false));
    yield put(getAttributesSlice(response.data));
  } else {
    yield put(setLoading(false));
    yield put({
      type: attributesActions.GET_ATTRIBUTES_FAILURE,
      data: requestError.response.data,
    });
  }
}

export function* getArchivedAttributesWorker(action: ReduxAction) {
  let requestError: any;
  yield put(setLoading(true));
  const response: ResponseGenerator = yield call(
    async () =>
      await getAttributes(action.data)
        .then((response) => response)
        .catch((error) => {
          requestError = error;
        })
  );
  if (response) {
    yield put(setLoading(false));
    yield put(getArchivedAttributesSlice(response.data));
  } else {
    yield put(setLoading(false));
    yield put({
      type: attributesActions.GET_ARCHIVED_ATTRIBUTES_FAILURE,
      data: requestError.response.data,
    });
  }
}

export function* restoreAttributeWorker(action: ReduxAction) {
  let requestError: any;
  const attribute = action.data;

  const response: ResponseGenerator = yield call(
    async () =>
      await restoreAttribute(attribute.id)
        .then((response) => response)
        .catch((error) => {
          requestError = error;
        })
  );
  if (response) {
    yield put({
      type: alertActions.SHOW_ALERT,
      data: {
        undo: false,
        type: "attributeRestored",
        name: attribute.attributeName,
      },
    });
    yield put({
      type: attributesActions.GET_ARCHIVED_ATTRIBUTES,
      data: {
        params: {
          ShowArchived: true,
          limit: initialPagingData.limit,
          offset: initialPagingData.offset,
        },
      },
    });
  } else {
    yield put({
      type: attributesActions.RESTORE_ATTRIBUTES_FAILURE,
      data: requestError.response.data,
    });
  }
}

function* createAttributeWorker(action: ReduxAction) {
  let requestError: any;
  const attributePayload = action.data;
  const { valueType } = attributePayload;
  try {
    const response: ResponseGenerator = yield call(
      async () =>
        await createAttribute(attributePayload)
          .then((response) => response)
          .catch((error) => {
            requestError = error;
          })
    );

    if (response.data) {
      if (action.callback) {
        action.callback(response.data);
      }
      if (valueType !== "CompoundAttribute") {
        yield put({
          type: alertActions.SHOW_ALERT,
          data: {
            type: "createAttribute",
            name: attributePayload.attributeName,
          },
        });
      }
    }
  } catch (e) {
    yield put({
      type: attributesActions.CREATE_ATTRIBUTE_FAILURE,
      data: requestError.response.data,
    });
  }
}

function* attachAttributeWorker(action: ReduxAction) {
  let requestError: any;
  const attributePayload = action.data;

  const response: ResponseGenerator = yield call(
    async () =>
      await attachAttribute(attributePayload.id, attributePayload)
        .then((response) => response)
        .catch((error) => {
          requestError = error;
        })
  );
  if (response) {
    yield put({
      type: attributesActions.GET_ATTRIBUTES,
      data: { params: { ShowArchived: false } },
    });
    yield put({
      type: attributesActions.ATTACH_ATTRIBUTE_SUCCESS,
    });
  } else {
    yield put({
      type: attributesActions.ATTACH_ATTRIBUTE_FAILURE,
      data: requestError.response.data,
    });
  }
}

function* detachAttributeWorker(action: ReduxAction) {
  let requestError: any;
  const { id, attributeId, name } = action.data;
  const response: ResponseGenerator = yield call(
    async () =>
      await detachAttributeFromAttribute(id, attributeId)
        .then((response) => response)
        .catch((error) => {
          requestError = error;
        })
  );
  if (response) {
    yield put({
      type: attributesActions.GET_ATTRIBUTES,
      data: { params: { ShowArchived: false } },
    });
    yield put({
      type: alertActions.SHOW_ALERT,
      data: {
        undo: false,
        type: "detachAttribute",
        name,
      },
    });
  } else {
    yield put({
      type: attributesActions.DETACH_ATTRIBUTE_FAILURE,
      data: requestError.response.data,
    });
  }
}

function* updateAttributeWorker(action: ReduxAction) {
  let requestError: any;
  const attributePayload = action.data;

  const response: ResponseGenerator = yield call(
    async () =>
      await updateAttribute(attributePayload.id, attributePayload)
        .then((response) => response)
        .catch((error) => {
          requestError = error;
        })
  );

  if (response) {
    if (attributePayload.valueType !== "CompoundAttribute") {
      yield put({
        type: alertActions.SHOW_ALERT,
        data: {
          type: "editAttribute",
          name: attributePayload.attributeName,
        },
      });
    }
    yield put({
      type: attributesActions.GET_ATTRIBUTES,
      data: {
        params: {
          ShowArchived: false,
          offset: action.pagingParams.offset,
          limit: action.pagingParams.limit,
          "SortingPreferences.Kind":
            action.filterParams === "asc" ? "Ascending" : "Descending",
        },
      },
    });
  } else {
    yield put({
      type: attributesActions.UPDATE_ATTRIBUTE_FAILURE,
      data: requestError.response.data,
    });
  }
}

function* deleteAttributeWorker(action: Action) {
  const { id, name } = action.data;
  let requestError: any;

  const response: ResponseGenerator = yield call(
    async () =>
      await deleteAttribute(id)
        .then((response) => response)
        .catch((error) => {
          requestError = error;
        })
  );
  if (response) {
    yield put({
      type: attributesActions.GET_ATTRIBUTES,
      data: { params: { ShowArchived: false } },
    });
    yield put({
      type: alertActions.SHOW_ALERT,
      data: {
        undo: false,
        type: "deleteAttribute",
        name,
      },
    });
  } else {
    yield put({
      type: attributesActions.DELETE_ATTRIBUTE_FAILURE,
      data: requestError.response.data,
    });
  }
}

export function* AttributesWatcher() {
  yield takeLatest(attributesActions.DELETE_ATTRIBUTE, deleteAttributeWorker);
  yield takeLatest(
    attributesActions.RESTORE_ATTRIBUTES,
    restoreAttributeWorker
  );
  yield takeLatest(
    attributesActions.GET_ARCHIVED_ATTRIBUTES,
    getArchivedAttributesWorker
  );
  yield takeLatest(attributesActions.GET_ATTRIBUTES, getAttributesWorker);
  yield takeLatest(attributesActions.DETACH_ATTRIBUTE, detachAttributeWorker);
  yield takeLatest(attributesActions.ATTACH_ATTRIBUTE, attachAttributeWorker);
  yield takeLatest(attributesActions.UPDATE_ATTRIBUTE, updateAttributeWorker);
  yield takeLatest(attributesActions.CREATE_ATTRIBUTE, createAttributeWorker);
}
