import React, { useState, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { Box } from "@mui/material";
import Tabs from "components/Tabs";
import { RoutesList } from "shared/routes";

import { TabNames } from "../types";

const tabList = [
  {
    label: TabNames.NEW,
    link: RoutesList.NEW,
  },
  {
    label: TabNames.COMPLETED,
    link: RoutesList.COMPLETED,
  },
  {
    label: TabNames.ARCHIVE,
    link: RoutesList.ARCHIVE,
  },
];

const Requests = () => {
  const [activeTab, setActiveTab] = useState(TabNames.NEW);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname == RoutesList.REQUESTS) {
      setActiveTab(TabNames.NEW);
      navigate(RoutesList.NEW);
    }
  }, [location]);

  return (
    <Box sx={{ marginTop: "20px" }} data-testid="requests">
      <Tabs
        setActiveTab={setActiveTab}
        tabList={tabList}
        activeTab={activeTab}
        basePath={RoutesList.REQUESTS}
        handleOpen={() => console.log("handleOpen")}
        showCreateButton={false}
      >
        <Outlet />
      </Tabs>
    </Box>
  );
};

export default Requests;
