import styled from "styled-components";

export const StyledLogo = styled.img<{
  maxWidth?: string;
  width?: string;
  maxHeight?: string;
  height?: string;
}>`
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth}`};
  ${(props) => props.width && `max-height: ${props.width}`};
  ${(props) => props.maxHeight && `max-height: ${props.maxHeight}`};
  ${(props) => props.height && `max-height: ${props.height}`};
`;
