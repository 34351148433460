import React from "react";

import { Box } from "@mui/material";
import Tabs from "components/Tabs";
import BulkActions from "components/BulkActions";
import { DistributionsTable } from "widgets/DistributionsTable";
import TablePaper from "shared/ui/TablePaper";

import { RoutesList } from "shared/routes";
import { TabNames } from "../types";

const tabList = [
  {
    label: TabNames.DISTRIBUTIONS,
    link: RoutesList.DISTRIBUTIONS,
  },
];

const Distributions = () => {
  return (
    <Box sx={{ marginTop: "20px" }} data-testid="distributions">
      <Tabs
        setActiveTab={() => console.log("setActiveTab")}
        tabList={tabList}
        activeTab={TabNames.DISTRIBUTIONS}
        basePath={RoutesList.PRODUCT_CONFIGURATOR}
        handleOpen={() => console.log("handleOpen")}
        showCreateButton={false}
      >
        <TablePaper>
          <DistributionsTable />
        </TablePaper>
      </Tabs>
      <BulkActions activeTab={TabNames.DISTRIBUTIONS} />
    </Box>
  );
};

export default Distributions;
