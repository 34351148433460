import * as React from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  StyledDialog,
  StyledActions,
  StyledTitle,
  StyledIconButton,
} from "../styles";
import Button from "../../../Button";

interface ModalProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  submitButton: React.ReactNode;
  title: string;
}

interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <StyledTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <StyledIconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "var(--black)",
          }}
        >
          <CloseIcon />
        </StyledIconButton>
      ) : null}
    </StyledTitle>
  );
};

const NotificationModal = ({
  open,
  onClose,
  submitButton,
  title,
  children,
}: ModalProps) => {
  return (
    <StyledDialog onClose={onClose} open={open}>
      <BootstrapDialogTitle id={"notification-modal-title"} onClose={onClose}>
        {title}
      </BootstrapDialogTitle>
      {children}
      <StyledActions>
        <Button onClick={onClose} variant="outlined" minWidth={230}>
          {"Cancel"}
        </Button>
        {submitButton}
      </StyledActions>
    </StyledDialog>
  );
};

export default NotificationModal;
