import { Column } from "shared/config/types/Table";

export const initialColumns: Column[] = [
  {
    id: "Name",
    label: "Name",
    minWidth: 300,
    filtering: true,
  },
  {
    id: "widgetType",
    label: "Widget type",
    minWidth: 200,
    filtering: false,
  },
  {
    id: "TenantName",
    label: "Tenant",
    minWidth: 200,
    filtering: false,
  },
  {
    id: "distribution",
    label: "Distribution",
    minWidth: 200,
    filtering: false,
  },
  {
    id: "CreatedDateUtc",
    label: "Creation",
    minWidth: 150,
    filtering: false,
  },
  {
    id: "UpdatedDateUtc",
    label: "Update",
    minWidth: 150,
    filtering: false,
  },
  {
    id: "actions",
    label: "Actions",
    minWidth: 100,
    filtering: false,
    align: "center",
  },
];
