import { createAction } from "@reduxjs/toolkit";
import {
  ApplicationLinkToWidgetPayload,
  GetWidgetPayload,
  WidgetsPayload,
} from "shared/api/widgets/types";
export const getWidgetsAction = createAction<WidgetsPayload>("widgets/getAll");
export const getWidgetByIdAction =
  createAction<GetWidgetPayload>("widgets/get/id");
export const applicationLinkToWidgetAction =
  createAction<ApplicationLinkToWidgetPayload>("widgets/application/link");
export const applicationUnlinkFromWidgetAction =
  createAction<ApplicationLinkToWidgetPayload>("widgets/application/unlink");
