import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip, Box, MenuItem, Typography, Divider } from "@mui/material";
import { useMsal } from "@azure/msal-react";

import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  StyledChevron,
  StyledHeaderDiv,
  StyledIconWrap,
  StyledMenu,
  StyledProfileButton,
} from "./styles";
import { RoutesList } from "shared/routes";

const settings = ["Profile", "Settings", "Contact us"];

const Header = () => {
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const anchorEl = document.getElementById("profile");

  const [open, setOpen] = useState<boolean>(false);

  const logOut = (instance: any) => {
    const logoutRequest = {
      account: instance.getAccountByHomeId(accounts[0].homeAccountId),
    };
    instance.logoutRedirect(logoutRequest);
  };

  return (
    <StyledHeaderDiv data-testid="header">
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <StyledProfileButton
          data-testid="header_notificationButton"
          onClick={() => navigate(RoutesList.NOTIFICATIONS)}
        >
          <Tooltip title="Open notifications">
            <StyledIconWrap>
              <NotificationsOutlinedIcon />
            </StyledIconWrap>
          </Tooltip>
        </StyledProfileButton>
        <StyledProfileButton
          onClick={() => setOpen(!open)}
          data-testid="header_profileButton"
        >
          <Tooltip title="Open settings">
            <StyledIconWrap id={"profile"}>
              <PersonOutlineOutlinedIcon />
            </StyledIconWrap>
          </Tooltip>
          <StyledMenu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            data-testid="header_profileMenu"
          >
            <Box sx={{ padding: "10px 15px" }}>
              <Box sx={{ color: "var(--secondary)" }}>
                <Typography
                  sx={{ marginBottom: 0, fontWeight: 700 }}
                  paragraph
                  data-testid="header_userName"
                >
                  Rosa Indriana
                </Typography>
                <Typography
                  sx={{ marginBottom: "10px", fontWeight: 300 }}
                  data-testid="header_userRole"
                >
                  Admin
                </Typography>
              </Box>
              <Divider />
              {settings.map((setting) => (
                <MenuItem
                  sx={{ padding: "5px", margin: "5px 0", fontSize: "18px" }}
                  key={setting}
                  onClick={() => setOpen(false)}
                  data-testid={`headerMenu_${setting}Button`}
                >
                  <Typography>{setting}</Typography>
                </MenuItem>
              ))}
              <Divider />
              <MenuItem
                sx={{ padding: "5px", margin: "5px 0", fontSize: "18px" }}
                onClick={() => logOut(instance)}
                data-testid="headerMenu_logoutButton"
              >
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            </Box>
          </StyledMenu>
        </StyledProfileButton>
        <StyledChevron
          open={open}
          onClick={() => setOpen(!open)}
          data-testid="header_expandMoreButton"
        >
          <ExpandMoreIcon />
        </StyledChevron>
      </Box>
    </StyledHeaderDiv>
  );
};

export default Header;
