import { put, takeEvery } from "redux-saga/effects";
import { addDraftitem } from "../store/reducers/draftItems";
import { draftItemsActions } from "../actions/draftItems";

export function* addDraftItemWorker(payload: any) {
  try {
    yield put(addDraftitem(payload.data));
  } catch (e) {
    yield put({ type: "ADD_DRAFT_ITEM_FAILED" });
  }
}

export function* addDraftItemWatcher() {
  yield takeEvery(draftItemsActions.ADD_DRAFT_ITEM, addDraftItemWorker);
}
