import React, { useState, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import Tabs from "components/Tabs";
import BulkActions from "components/BulkActions";
import CreateTenant from "components/Modals/TenantModal";
import AddTenantAdmin from "components/Modals/AddTenantAdmin";

import { TabNames } from "pages/types";

import { RoutesList } from "shared/routes";

const tabList = [
  {
    label: TabNames.TENANTS,
    link: RoutesList.TENANTS,
  },
  {
    label: TabNames.ARCHIVE,
    link: RoutesList.ARCHIVE,
  },
];

const TenantManagement = () => {
  const [activeTab, setActiveTab] = useState(TabNames.TENANTS);
  const [createdTenantName, setCreatedTenantName] = useState("");
  const [openCreateTenantModal, setOpenCreateTenantModal] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleOpen = () => {
    setOpenCreateTenantModal(true);
  };
  const handleClose = () => {
    setOpenCreateTenantModal(false);
  };

  useEffect(() => {
    if (pathname == RoutesList.TENANT_MANAGEMENT) {
      setActiveTab(TabNames.TENANTS);
      navigate(RoutesList.TENANTS);
    }
  }, [pathname]);

  return (
    <div style={{ marginTop: "40px" }} data-testid="tenantManagement">
      <Tabs
        handleOpen={handleOpen}
        setActiveTab={setActiveTab}
        tabList={tabList}
        activeTab={activeTab}
        basePath={RoutesList.TENANT_MANAGEMENT}
        showCreateButton={true}
      >
        <Outlet />
      </Tabs>
      <CreateTenant
        onClose={handleClose}
        open={openCreateTenantModal}
        setCreatedTenantName={setCreatedTenantName}
      />
      <AddTenantAdmin createdTenantName={createdTenantName} />
      <BulkActions activeTab={activeTab} />
    </div>
  );
};

export default TenantManagement;
