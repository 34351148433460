import styled from "styled-components";

export const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 50%;
`;

export const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledTitleWrap = styled.h3`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  margin: 0 0 30px 0;
  font-size: 24px;
  line-height: 32px;
  color: #010101;
`;

export const Wrapper = styled.div`
  background: var(--ghost);
  border: 3px solid #a6a6a6;
  border-radius: 6px;
  padding: 30px 10px;
`;

export const InfoBlock = styled.div`
  background: #ffffff;
  border: 1px solid #dadae3;
  border-radius: 6px;
  padding: 20px;
`;
