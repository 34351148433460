import { createAction } from "@reduxjs/toolkit";

export const uploadFileAction = createAction<{
  ReferenceId: string;
  ReferenceType: string;
  file: File;
}>("files/upload");

export const uploadFilesAction = createAction<{
  ReferenceId: string;
  ReferenceType: string;
  files: File[];
}>("files/uploadMulti");

export const getFileAction = createAction<{
  id: string;
  callback: (data: any) => void;
}>("files/retrieve");
