export const attributesActions = {
  GET_ATTRIBUTES: "GET_ATTRIBUTES",
  GET_ATTRIBUTES_FAILURE: "GET_ATTRIBUTES_FAILURE",
  GET_ARCHIVED_ATTRIBUTES: "GET_ARCHIVED_ATTRIBUTES",
  GET_ARCHIVED_ATTRIBUTES_FAILURE: "GET_ARCHIVED_ATTRIBUTES_FAILURE",
  RESTORE_ATTRIBUTES: "RESTORE_ATTRIBUTES",
  RESTORE_ATTRIBUTES_FAILURE: "RESTORE_ATTRIBUTES_FAILURE",
  DELETE_ATTRIBUTE: "DELETE_ATTRIBUTE",
  DELETE_ATTRIBUTE_SUCCESS: "DELETE_ATTRIBUTE_SUCCESS",
  DELETE_ATTRIBUTE_FAILURE: "DELETE_ATTRIBUTE_FAILURE",
  CREATE_ATTRIBUTE: "CREATE_ATTRIBUTE",
  CREATE_ATTRIBUTE_SUCCESS: "CREATE_ATTRIBUTE_SUCCESS",
  CREATE_ATTRIBUTE_FAILURE: "CREATE_ATTRIBUTE_FAILURE",
  UPDATE_ATTRIBUTE: "UPDATE_ATTRIBUTE",
  UPDATE_ATTRIBUTE_FAILURE: "UPDATE_ATTRIBUTE_FAILURE",
  ATTACH_ATTRIBUTE: "ATTACH_ATTRIBUTE",
  ATTACH_ATTRIBUTE_SUCCESS: "ATTACH_ATTRIBUTE_SUCCESS",
  ATTACH_ATTRIBUTE_FAILURE: "ATTACH_ATTRIBUTE_FAILURE",
  DETACH_ATTRIBUTE: "DETACH_ATTRIBUTE",
  DETACH_ATTRIBUTE_SUCCESS: "DETACH_ATTRIBUTE_SUCCESS",
  DETACH_ATTRIBUTE_FAILURE: "DETACH_ATTRIBUTE_FAILURE",
};
