import React, { useMemo } from "react";
import { SvgIcon, Tooltip } from "@mui/material";

import { StyledIconButton } from "./styles";
import { ActionButtonTypes } from "./types";

// icons
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ReactComponent as RestoreIcon } from "assets/icons/restore.svg";
import { ReactComponent as CloneIcon } from "assets/icons/clone.svg";
import { ReactComponent as HugIcon } from "assets/icons/hug.svg";
import { ReactComponent as CommentIcon } from "assets/icons/addComment.svg";
import SettingsIcon from "@mui/icons-material/Settings";
import AddIcon from "@mui/icons-material/Add";
import { ReactComponent } from "*.svg";

interface Props {
  type: ActionButtonTypes;
  title: string;
  disabled?: boolean;
  onClick?: (e: any) => void;
}

type IconsMap = {
  [key in ActionButtonTypes]: typeof SvgIcon | typeof ReactComponent;
};

const iconsMap: IconsMap = {
  [ActionButtonTypes.EDIT]: EditIcon,
  [ActionButtonTypes.DELETE]: DeleteIcon,
  [ActionButtonTypes.RESTORE]: RestoreIcon,
  [ActionButtonTypes.CLONE]: CloneIcon,
  [ActionButtonTypes.COPY]: HugIcon,
  [ActionButtonTypes.COMMENT]: CommentIcon,
  [ActionButtonTypes.VIEW]: VisibilityIcon,
  [ActionButtonTypes.SETTINGS]: SettingsIcon,
  [ActionButtonTypes.ADD]: AddIcon,
};

export const ActionButton: React.FC<Props> = ({
  title,
  type,
  onClick,
  disabled = false,
}) => {
  const renderIcon = useMemo(() => {
    const IconComponent = iconsMap[type];
    return <IconComponent data-testid={`tooltipIcon_${type}`} />;
  }, [type]);

  return (
    <Tooltip
      classes={{ popper: "white-tooltip" }}
      title={title}
      data-testid={`tooltip_${type}`}
    >
      <span>
        <StyledIconButton
          actionType={type}
          aria-label={type}
          onClick={onClick}
          disabled={disabled}
          data-testid={`tooltipButton_${type}`}
        >
          {renderIcon}
        </StyledIconButton>
      </span>
    </Tooltip>
  );
};
