import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const getPlatformNotifications = (state: RootState) =>
  state.platformNotifications;

export const getNotificationsItems = createSelector(
  getPlatformNotifications,
  (platformNotifications) => {
    return platformNotifications.items;
  }
);

export const getNotificationsTotalCount = createSelector(
  getPlatformNotifications,
  (platformNotifications) => {
    return platformNotifications.totalCount;
  }
);

export const getNotification = createSelector(
  getPlatformNotifications,
  (platformNotifications) => {
    return platformNotifications.notification;
  }
);
