import styled from "styled-components";
import { TableRow, Button } from "@mui/material";
import { Link } from "react-router-dom";

import { Density } from "./types";

export const StyledTableRow = styled(TableRow)<{
  density: string;
}>`
  &.MuiTableRow-root {
    background-color: transparent;
  }

  &.MuiTableRow-root.Mui-selected {
    background-color: var(--ghost);
    .MuiAvatar-root {
      background: var(--white);
    }
  }
  &.MuiTableRow-hover.MuiTableRow-root:hover {
    box-shadow: 10px 10px 40px rgba(111, 138, 231, 0.25);
    background-color: transparent;
    position: relative;
    z-index: 1;
  }

  &.MuiTableRow-hover.Mui-selected.MuiTableRow-root:hover {
    background-color: var(--ghost);
  }
  td {
    padding: ${(props) =>
      props.density === Density.COMPACT ? "15px 16px" : "23px 16px"};
    font-family: "Inter", sans-serif;
  }

  td.MuiTableCell-paddingCheckbox {
    padding: 0 0 0 4px;
  }

  .MuiCheckbox-root {
    path {
      fill: var(--primary);
    }
  }
`;

export const StyledControlHeader = styled.div`
  height: 45px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const DenseIconsWrap = styled.div`
  display: flex;
  svg {
    margin-left: 0;
    fill: transparent;
  }
`;

export const DensityButton = styled(Button)`
  &.MuiButton-root {
    margin-right: 10px;
    border-radius: 6px;
    min-width: 0;
    width: 40px;
    height: 40px;
    padding: 0;
  }

  &.Mui-disabled {
    background: var(--ghost);
  }
`;

export const TableText = styled.p`
  color: var(--headings);
`;

export const CountNumber = styled.p`
  color: var(--secondary);
  font-weight: 700;
`;

export const OwnerWrap = styled.div`
  display: flex;
  flex-direction: column;
  strong {
    color: var(--headings);
  }
  a {
    color: var(--secondary);
    text-decoration: none;
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const NoDataWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      font-weight: 400;
      color: var(--grey);
      font-size: 24px;
      line-height: 29px;
      margin-top: 20px;
    }
  }
`;

export const TenantImage = styled.img`
  max-height: 40px;
`;

export const GridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding-top: 32px;
  row-gap: 56px;
  column-gap: 24px;
`;

export const HighlightedText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #6266ea;
`;

export const Name = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #010101;
`;

export const Email = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #6266ea;
`;
