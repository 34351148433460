import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { StyledAlert, Container, StyledButton } from "./styles";

import { alertActions } from "redux/actions/alerts";
import { draftItemsActions } from "redux/actions/draftItems";
import { NotificationProps } from "redux/store/reducers/alerts";
import { messages } from "./Messages";

export interface AlertProps {
  notification: NotificationProps;
}
function Alert({ notification }: Readonly<AlertProps>) {
  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch({ type: alertActions.CLOSE_ALERT });
    }, 3000);

    dispatch({ type: draftItemsActions.ADD_DRAFT_ITEM, data: [] });

    return () => clearTimeout(timer);
  }, []);

  const handleClick = () => {
    return true;
  };

  const handleReload = () => {
    window.location.reload();
  };

  const MessageComponent = messages[notification.type];

  return (
    <StyledAlert
      severity={messages[notification.type].severity || "info"}
      data-testid={`alert_${notification.type}`}
    >
      <MessageComponent notification={notification} />{" "}
      {notification.undo && (
        <StyledButton onClick={handleClick} data-testid="alert-button">
          UNDO
        </StyledButton>
      )}
      {notification.reload && (
        <StyledButton onClick={handleReload} data-testid="alert-button">
          RELOAD
        </StyledButton>
      )}
    </StyledAlert>
  );
}

const BasicAlerts = () => {
  const alerts = useSelector((state: any) => state.alerts);

  return (
    <Container data-testid="alerts">
      {alerts.map((item: any) => (
        <Alert notification={item} key={uuidv4()} />
      ))}
    </Container>
  );
};

export default BasicAlerts;
