import { useCallback } from "react";
import { useAppDispatch } from "redux/store/hooks/hooks";
import { actions, getTenantAction } from "entities/tenants";

export const useTenants = () => {
  const getTenantName = useCallback((tenants: any, id: string) => {
    if (tenants.length) {
      const tenant: any = tenants.filter((tenant: any) => tenant.id === id);
      if (tenant) return tenant[0].companyName;
    } else {
      return "";
    }
  }, []);
  return {
    getTenantName,
  };
};

export const useTenantAction = () => {
  const dispatch = useAppDispatch();

  const setActionItemLoading = useCallback((flag: boolean) => {
    dispatch(actions.setTenantActionLoading(flag));
  }, []);

  const unsetActionItem = useCallback(() => {
    dispatch(actions.setTenantActionItem(null));
  }, []);

  return {
    unsetActionItem,
    setActionItemLoading,
  };
};

export const useTenant = () => {
  const dispatch = useAppDispatch();

  const getTenant = useCallback(
    (tenantId: string) => {
      dispatch(getTenantAction({ tenantId }));
    },
    [dispatch]
  );
  return { getTenant };
};
