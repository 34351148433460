import React from "react";

import { StyledTextarea } from "./styles";

interface TextareaProps {
  value: string;
  placeholder: string;
  id: string;
  onChange: (e: any) => void;
  minRows: number;
  error?: boolean;
}

const TextareaComponent = ({
  value,
  placeholder,
  id,
  onChange,
  minRows,
  error,
}: TextareaProps) => {
  return (
    <StyledTextarea
      placeholder={placeholder}
      value={value}
      id={id}
      onChange={onChange}
      rows={minRows}
      className={error ? "has-error" : ""}
    />
  );
};

export default TextareaComponent;
