import React from "react";

import { Row, StyledControlHeader, StyledRightButton } from "./styles";
import SearchField from "../SearchField";
import { TabNames } from "pages/types";
import { FormControl, ListItemText, Select } from "@mui/material";
import Label from "components/FormComponents/Label";

// types
import { TableControlsProps } from "../Table/types";
import { StyledCheckbox, StyledMenuItem } from "shared/ui/Select/styles";

const TableControls: React.FC<TableControlsProps> = ({
  activeTab,
  handleOpen,
  searchValue,
  handleSearch,
  hideCreate,
  handleSelect,
  options = [],
  value,
}) => {
  const createTabName = () => {
    switch (activeTab) {
      case TabNames.CATEROGIES:
        return "Create Category";
      case TabNames.ATTRIBUTES:
        return "Create Attribute";
      case TabNames.REQUESTS:
        return "Create Request";
      case TabNames.MODULE_TEMPLATES:
        return "Create Module";
      case TabNames.DISTRIBUTIONS:
        return "Create Distribution";
      default:
        return activeTab;
    }
  };

  return (
    <StyledControlHeader data-testid="tableControlHeader">
      <SearchField
        value={searchValue}
        onChange={handleSearch}
        id={`searchField-${activeTab}`}
        placeholder={`Search ${activeTab}`}
        sx={{ width: "384px" }}
      />
      <Row>
        {TabNames.DISTRIBUTIONS && handleSelect && (
          <FormControl>
            {!value && <Label label="Select Tenant" disabled />}
            <Select
              onChange={handleSelect}
              id={"tenant"}
              value={value || ""}
              multiple={false}
              label={!value ? "Select Tenant" : ""}
              renderValue={(value: string) => {
                return value || "";
              }}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <StyledMenuItem value="">
                <ListItemText primary={"Please choose an option"} />
              </StyledMenuItem>
              {options.map((name) => (
                <StyledMenuItem
                  key={name}
                  value={name}
                  data-testid={`select_${name}Option`}
                >
                  <StyledCheckbox checked={value === name} />
                  <ListItemText primary={name} />
                </StyledMenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {!hideCreate && (
          <StyledRightButton
            color={"secondary"}
            onClick={handleOpen}
            data-testid={`create-${activeTab}-button`}
          >
            {createTabName()}
          </StyledRightButton>
        )}
      </Row>
    </StyledControlHeader>
  );
};

export default TableControls;
