import { createSelector } from "@reduxjs/toolkit";
import { getWidgets } from "./getWidgets";

export const getWidgetsItems = createSelector(getWidgets, (widgets) => {
  return widgets.items;
});
export const getWidgetsCount = createSelector(getWidgets, (widgets) => {
  return widgets.totalCount;
});
export const getWidgetsItemsLoading = createSelector(getWidgets, (widgets) => {
  return widgets.isLoading;
});

export const getWidgetDetailsData = createSelector(getWidgets, (widgets) => {
  return widgets.widgetDetails;
});
export const getWidgetActionLoading = createSelector(getWidgets, (widgets) => {
  return widgets.widgetActionLoading;
});
export const getWidgetDetailsDataLoading = createSelector(
  getWidgets,
  (widgets) => {
    return widgets.widgetDetailsLoading;
  }
);
export const getWidgetDetailsApplications = createSelector(
  getWidgets,
  (widgets) => {
    return widgets.widgetDetailsApplications;
  }
);
