import styled from "styled-components";
import { DateRangePicker } from "react-date-range";
import { Button } from "@mui/material";

export const StyledDateRangePicker = styled(DateRangePicker)`
  .rdrCalendarWrapper {
    position: relative;
  }

  div.rdrDefinedRangesWrapper {
    min-width: 180px;
    order: 1;
    .rdrInputRanges {
      display: none;
    }
  }
  .rdrMonthAndYearPickers {
    display: none;
  }
  // arrows
  .rdrMonthAndYearWrapper {
    padding: 0 20px;
    position: absolute;
    top: 75px;
    width: 100%;
  }

  .rdrNextButton {
    background: transparent;
  }

  .rdrPprevButton {
    background: transparent;
  }

  .rdrMonth {
    width: 300px;
  }

  .rdrWeekDay {
    font-size: 12px;
    display: flex;
    justify-content: center;
    width: 38px;
    height: 20px;
  }

  .rdrDays {
    display: flex;
    font-size: 12px;
    color: var(--primary);
    flex-wrap: wrap;
  }

  .rdrDayStartOfMonth {
    .rdrInRange,
    .rdrDayInPreview {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }

    .rdrDayInPreview {
      border-left: 1px solid var(--secondary);
    }
  }

  .rdrDayEndOfMonth {
    .rdrInRange,
    .rdrDayInPreview {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }
    .rdrDayInPreview {
      border-right: 1px solid var(--secondary);
    }
  }

  .rdrInRange + .rdrDayNumber span {
    color: var(--primary);
  }
  .rdrDay {
    padding: 0;
    width: 38px;
    border: none;
    background: transparent;
    position: relative;
    height: 38px;
  }
  .rdrDayStartPreview {
    border: 1px solid var(--secondary);
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-right: none;
    position: absolute;
    height: 28px;
    top: 5px;
    left: 0;
    width: 100%;
    box-sizing: border-box;
  }
  .rdrDayEndPreview {
    border: 1px solid var(--secondary);
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-left: none;
    position: absolute;
    height: 28px;
    top: 5px;
    left: 0;
    width: 100%;
    box-sizing: border-box;
  }
  .rdrDayInPreview {
    border-top: 1px solid var(--secondary);
    border-bottom: 1px solid var(--secondary);
    position: absolute;
    height: 28px;
    top: 5px;
    left: 0;
    width: 100%;
  }
  .rdrDayStartPreview.rdrDayEndPreview {
    border-left: 1px solid var(--secondary);
  }
  .rdrDayPassive {
    pointer-events: none;
    > .rdrInRange {
      display: none;
    }
    &.rdrDay {
      .rdrDayNumber {
        background: var(--white);
        border-radius: 0;
        span {
          color: var(--darksilver);
        }
      }
    }

    .rdrStartEdge {
      display: none;
      ~ .rdrDayNumber {
        background: var(--white);
        span {
          color: var(--darksilver);
        }
      }
    }
    .rdrDayInPreview {
      display: none;
    }
    .rdrDayStartPreview {
      display: none;
    }
    .rdrDayEndPreview {
      display: none;
    }

    .rdrDayNumber {
      span {
        color: var(--darksilver);
      }
    }
  }
  .rdrStartEdge ~ .rdrDayNumber,
  .rdrEndEdge ~ .rdrDayNumber {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    background: var(--secondary);
    span {
      color: var(--white);
    }
  }
  .rdrInRange {
    position: absolute;
    top: 5px;
    background: var(--ghost);
    left: 0;
    width: 100%;
    height: 28px;
  }

  .rdrStartEdge,
  .rdrEndEdge {
    width: 50%;
    position: absolute;
    background: var(--ghost);
    height: 28px;
    top: 5px;
    z-index: 0;
  }
  .rdrStartEdge {
    right: 0;
  }
  .rdrEndEdge {
    left: 0;
  }
  .rdrEndEdge.rdrStartEdge {
    display: none;
  }

  .rdrNextPrevButton {
    display: block;
    width: 24px;
    height: 24px;
    border: none;
  }

  .rdrNextPrevButton i {
    display: block;
    text-align: center;
    border-style: solid;
    margin: auto;
    transform: translate(-3px, 0px);
  }

  .rdrPprevButton i {
    border-width: 4px 6px 4px 4px;
    border-color: transparent rgb(52, 73, 94) transparent transparent;
    transform: translate(-3px, 0px);
  }
  .rdrNextButton i {
    border-width: 4px 4px 4px 6px;
    border-color: transparent transparent transparent rgb(52, 73, 94);
    transform: translate(3px, 0px);
  }
  div.rdrDateDisplay {
    margin: 0;
  }
  div.rdrMonths {
    padding: 15px;
  }

  div.rdrMonth {
    padding: 15px;
    border: 1px solid #b8b4cb;
    border-radius: 5px;
  }
  .rdrMonthName {
    padding: 0;
    margin-bottom: 15px;
    text-align: center;
    text-transform: uppercase;
    color: var(--primary);
    font-size: 14px;
    font-weight: 700;
  }
  .rdrWeekDays {
    display: flex;
  }

  .rdrWeekDay {
    color: var(--primary);
  }

  .rdrMonth + .rdrMonth {
    margin-left: 15px;
  }

  // inputs
  div.rdrDateDisplayWrapper {
    background-color: var(--white);
    padding: 0 15px;

    .rdrDateInput {
      text-align: left;
      width: 300px;
      border-bottom: 1px solid #b8b4cb;
      padding-bottom: 5px;
      :nth-child(1)::before,
      :nth-child(2)::before {
        font-weight: 700;
        color: var(--primary);
        margin-left: 2px;
        margin-bottom: 5px;
        display: block;
        font-size: 12px;
      }

      :nth-child(1)::before {
        content: "Start Date";
      }

      :nth-child(2)::before {
        content: "End Date";
      }

      input {
        text-align: left;
        color: var(--secondary);
        font-size: 16px;
        cursor: pointer;
        border: none;
        background: transparent;
        width: 100%;
      }

      &.rdrDateDisplayItemActive {
        border-bottom: 1px solid var(--secondary);
      }

      .rdrDateInput + .rdrDateInput {
        margin-left: 15px;
      }
    }
  }
  .rdrStaticRange {
    text-align: left;
    padding: 0;
    font-size: 14px;
    background: var(--white);
    border: none;
    margin-bottom: 5px;
    cursor: pointer;
    letter-spacing: 2px;
    position: relative;
    .rdrStaticRangeLabel {
      padding: 0 18px;
      display: flex;
      height: 40px;
      align-items: center;
      border: 1px solid var(--grey);
      border-radius: 6px;
      color: var(--secondary);
    }
    &.rdrStaticRangeSelected {
      background: var(--primary);
      border-color: var(--primary);
      border-radius: 6px;

      .rdrStaticRangeLabel {
        color: var(--white);
      }
    }
  }
  .rdrDefinedRangesWrapper {
    padding-right: 20px;
  }
`;

export const StyledWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledButtonsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 10px;
  right: 0;
  width: 180px;
  padding-right: 20px;
`;

export const StyledContainer = styled.div`
  padding-bottom: 40px;
`;

export const StyledButton = styled(Button)<{ color: string }>`
  &.MuiButton-root {
    width: 48%;
    font-size: 14px;
    text-transform: none;
    border: 1px solid var(--grey);
    background-color: var(--secondary);
    :hover {
      background-color: var(--secondary);
    }
    :last-child {
      background-color: var(--white);
      color: var(--secondary);
    }
  }
`;
