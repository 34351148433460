import { createSlice } from "@reduxjs/toolkit";

export const categoriesSlice = createSlice({
  name: "categories",
  initialState: {
    items: [],
    totalCount: 0,
  },
  reducers: {
    getCategoriesSlice: (_, action) => {
      return action.payload;
    },
  },
});

export const categorySlice = createSlice({
  name: "category",
  initialState: {},
  reducers: {
    getCategorySlice: (_, action) => {
      return action.payload;
    },
  },
});

export const { getCategoriesSlice } = categoriesSlice.actions;
export const { getCategorySlice } = categorySlice.actions;
