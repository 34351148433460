import { takeLatest, put, call } from "redux-saga/effects";
import {
  getCategories,
  deleteCategory,
  restoreCategory,
  createCategory,
  updateCategory,
  getCategory,
  updateCategoryNames,
  detachAttributeFromCategory,
} from "api/IXAdminApi";
import { setLoading } from "redux/store/reducers/loading";
import { getArchivedCategoriesSlice } from "redux/store/reducers/archives";
import {
  categoriesActions,
  getCategoriesSlice,
  getCategorySlice,
} from "entities/productCategories";
import { ReduxAction } from "redux/sagas/types";
import { alertActions } from "redux/actions/alerts";

const initialPagingData = {
  totalCount: 0,
  page: 0,
  limit: 10,
  offset: 0,
};

export interface ResponseGenerator {
  id: any;
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

interface Action {
  data: any;
  type: string;
  categoryId: string;
}

export function* createCategoryWorker(action: ReduxAction) {
  let requestError: any;
  const params = action.data;

  const response: ResponseGenerator = yield call(
    async () =>
      await createCategory(params)
        .then((response) => response)
        .catch((error) => {
          requestError = error;
        })
  );
  if (response) {
    if (response.data && action.callback) {
      action.callback(response.data);
    }
  } else {
    yield put({
      type: categoriesActions.CREATE_CATEGORY_FAILURE,
      data: requestError.response.data,
    });
  }
}

export function* updateCategoryNameWorker(action: ReduxAction) {
  let requestError: any;
  const { id, displayName, categoryName } = action.data;
  const payload = { displayName, categoryName };

  const response: ResponseGenerator = yield call(
    async () =>
      await updateCategoryNames(id, payload)
        .then((response) => response)
        .catch((error) => {
          requestError = error;
        })
  );
  if (response) {
    yield put({
      type: categoriesActions.UPDATE_CATEGORY_NAME_SUCCESS,
      data: response.data,
    });
  } else {
    yield put({
      type: categoriesActions.UPDATE_CATEGORY_NAME_FAILURE,
      data: requestError.response.data,
    });
  }
}

export function* updateCategoryWorker(action: ReduxAction) {
  let requestError: any;
  const { id, attributeId } = action.data;
  const param = { attributeId };
  const response: ResponseGenerator = yield call(
    async () =>
      await updateCategory(id, param)
        .then((response) => response)
        .catch((error) => {
          requestError = error;
        })
  );
  if (response) {
    yield put({
      type: categoriesActions.UPDATE_CATEGORY_SUCCESS,
      data: response.data,
    });
  } else {
    yield put({
      type: categoriesActions.UPDATE_CATEGORY_FAILURE,
      data: requestError.response.data,
    });
  }
}

function* getCategoryWorker(action: ReduxAction) {
  const categoryId = action.data;
  let requestError: any;
  const response: ResponseGenerator = yield call(
    async () =>
      await getCategory(categoryId)
        .then((response) => response)
        .catch((error) => {
          requestError = error;
        })
  );
  if (response) {
    yield put(getCategorySlice(response.data));
  } else {
    yield put({
      type: categoriesActions.GET_CATEGORY_FAILURE,
      data: requestError.response.data,
    });
  }
}

export function* getCategoriesWorker(action: ReduxAction) {
  let requestError: any;
  yield put(setLoading(true));
  const response: ResponseGenerator = yield call(
    async () =>
      await getCategories(action.data)
        .then((response) => response)
        .catch((error) => {
          requestError = error;
        })
  );
  if (response) {
    yield put(getCategoriesSlice(response.data));
    yield put(setLoading(false));
  } else {
    yield put({
      type: categoriesActions.GET_CATEGORIES_FAILURE,
      data: requestError.response.data,
    });
    yield put(setLoading(false));
  }
}

export function* getArchivedCategoriesWorker(action: ReduxAction) {
  let requestError: any;
  yield put(setLoading(true));
  const response: ResponseGenerator = yield call(
    async () =>
      await getCategories(action.data)
        .then((response) => response)
        .catch((error) => {
          requestError = error;
        })
  );
  if (response) {
    yield put(setLoading(false));
    yield put(getArchivedCategoriesSlice(response.data));
  } else {
    yield put(setLoading(false));
    yield put({
      type: categoriesActions.GET_ARCHIVED_CATEGORIES_FAILURE,
      data: requestError.response.data,
    });
  }
}

export function* restoreCategoryWorker(action: ReduxAction) {
  let requestError: any;
  const category = action.data;
  const response: ResponseGenerator = yield call(
    async () =>
      await restoreCategory(category.id)
        .then((response) => response)
        .catch((error) => {
          requestError = error;
        })
  );
  if (response) {
    yield put({
      type: alertActions.SHOW_ALERT,
      data: {
        undo: false,
        type: "categoryRestored",
        name: category.displayName,
      },
    });
    yield put({
      type: categoriesActions.GET_ARCHIVED_CATEGORIES,
      data: {
        params: {
          ShowArchived: true,
          limit: initialPagingData.limit,
          offset: initialPagingData.offset,
        },
      },
    });
  } else {
    yield put({
      type: categoriesActions.RESTORE_CATEGORY_FAILURE,
      data: requestError.response.data,
    });
  }
}

function* detachAttributeFromCategoryWorker(action: Action) {
  const { id, attributeId } = action.data;
  let requestError: any;

  const response: ResponseGenerator = yield call(
    async () =>
      await detachAttributeFromCategory(id, attributeId)
        .then((response) => response)
        .catch((error) => {
          requestError = error;
        })
  );
  if (response) {
    yield put({
      type: categoriesActions.DELETE_ATTRIBUTE_FROM_CATEGORY_SUCCESS,
    });
  } else {
    yield put({
      type: categoriesActions.DELETE_ATTRIBUTE_FROM_CATEGORY_FAILURE,
      data: requestError.response.data,
    });
  }
}

function* deleteCategoryWorker(action: Action) {
  const { id, name } = action.data;
  let requestError: any;

  const response: ResponseGenerator = yield call(
    async () =>
      await deleteCategory(id)
        .then((response) => response)
        .catch((error) => {
          requestError = error;
        })
  );
  if (response) {
    yield put({ type: categoriesActions.GET_CATEGORIES });
    yield put({
      type: alertActions.SHOW_ALERT,
      data: {
        undo: false,
        type: "deleteCategory",
        name,
      },
    });
  } else {
    yield put({
      type: categoriesActions.DELETE_CATEGORY_FAILURE,
      data: requestError.response.data,
    });
  }
}

export function* ProductCategoriesWatcher() {
  yield takeLatest(categoriesActions.GET_CATEGORIES, getCategoriesWorker);
  yield takeLatest(categoriesActions.GET_CATEGORY, getCategoryWorker);
  yield takeLatest(categoriesActions.RESTORE_CATEGORY, restoreCategoryWorker);
  yield takeLatest(
    categoriesActions.GET_ARCHIVED_CATEGORIES,
    getArchivedCategoriesWorker
  );
  yield takeLatest(categoriesActions.DELETE_CATEGORY, deleteCategoryWorker);
  yield takeLatest(
    categoriesActions.DELETE_ATTRIBUTE_FROM_CATEGORY,
    detachAttributeFromCategoryWorker
  );
  yield takeLatest(categoriesActions.CREATE_CATEGORY, createCategoryWorker);
  yield takeLatest(categoriesActions.UPDATE_CATEGORY, updateCategoryWorker);
  yield takeLatest(
    categoriesActions.UPDATE_CATEGORY_NAME,
    updateCategoryNameWorker
  );
}
