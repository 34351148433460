import { Column } from "../TenantsTable/types";

export const initialColumns: Column[] = [
  {
    id: "logo",
    label: "",
    minWidth: 150,
    visible: true,
    filtering: false,
  },
  {
    id: "companyName",
    label: "Tenant Name",
    minWidth: 150,
    visible: true,
    filtering: true,
  },
  {
    id: "companyType",
    label: "Type",
    minWidth: 150,
    visible: true,
    filtering: true,
  },
  {
    id: "tenantAdmin",
    label: "Tenant Admin",
    minWidth: 200,
    visible: true,
    filtering: true,
  },
  {
    id: "capacity",
    label: "capacity",
    minWidth: 150,
    visible: true,
    filtering: true,
  },
  {
    id: "usersCount",
    label: "Users",
    minWidth: 100,
    visible: true,
    filtering: true,
  },
  {
    id: "createdDateUtc",
    label: "creation",
    minWidth: 100,
    visible: true,
    filtering: true,
  },
  {
    id: "updatedDateUtc",
    label: "updates",
    minWidth: 100,
    visible: true,
    filtering: true,
  },
  {
    id: "actions",
    label: "Actions",
    minWidth: 100,
    align: "right",
    visible: true,
    filtering: false,
  },
];
