export enum ActionButtonTypes {
  DOTTED = "dotted",
  ACTION = "action",
  RESTORE = "restore",
  EDIT = "edit",
  COPY = "copy",
  VIEW = "view",
  DELETE = "delete",
  CLONE = "clone",
}
