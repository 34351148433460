import styled from "styled-components";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

export const BackLink = styled(Link)`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 48px;
  letter-spacing: 0.01em;
  color: #190a3a;
  text-decoration: none;
  display: flex;
  align-items: center;
  width: fit-content;
`;

export const StyledTitle = styled(Typography)`
  &.MuiTypography-root {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;
    letter-spacing: 0.01em;
    color: #190a3a;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const PaddingDiv = styled.div`
  padding: 40px 0;
`;
