import styled from "styled-components";
import { TableRow } from "@mui/material";
import { Link } from "react-router-dom";

export const StyledTableRow = styled(TableRow)<{
  borderCollapse?: boolean;
}>`
  &.MuiTableRow-root {
    background-color: transparent;
  }

  &:nth-of-type(odd): {
    background-color: transparent;
  }
  &:nth-of-type(even) {
    background-color: rgba(249, 249, 249, 0.8);
  }
  ${(props) =>
    props.borderCollapse
      ? `
    background-color: #fff;
    &:nth-of-type(even) {
      background-color: #fff;
    }
    .MuiTableCell-root {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #010101;
  
      border-bottom: none;
      text-align: left;
      padding: 7px 8px;
  
      border-top: 1px solid #dadae3;
      border-bottom: 1px solid #dadae3;
  
      &:first-child {
        border-left: 1px solid #dadae3;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      &:last-child {
        border-right: 1px solid #dadae3;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
`
      : ""}

  &.MuiTableRow-root.Mui-selected {
    background-color: var(--ghost);
    .MuiAvatar-root {
      background: var(--white);
    }
  }
  &.MuiTableRow-hover.MuiTableRow-root:hover {
    box-shadow: 10px 10px 40px rgba(111, 138, 231, 0.25);
    background-color: transparent;
    position: relative;
    z-index: 1;
  }

  &.MuiTableRow-hover.Mui-selected.MuiTableRow-root:hover {
    background-color: var(--ghost);
  }
  td {
    padding: 15px 16px;
    font-family: "Inter", sans-serif;
  }

  td.MuiTableCell-paddingCheckbox {
    padding: 0 0 0 4px;
  }

  .MuiCheckbox-root {
    path {
      fill: var(--primary);
    }
  }
`;

export const TableText = styled.p`
  color: var(--headings);
`;

export const CountNumber = styled.p`
  color: var(--secondary);
  font-weight: 700;
`;

export const OwnerWrap = styled.div`
  display: flex;
  flex-direction: column;
  strong {
    color: var(--headings);
  }
  a {
    color: var(--secondary);
    text-decoration: none;
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const NoDataWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      font-weight: 400;
      color: var(--grey);
      font-size: 24px;
      line-height: 29px;
      margin-top: 20px;
    }
  }
`;
