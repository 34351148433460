import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const getModuleTemplates = (state: RootState) => state.moduleTemplates;

export const getModuleTemplatesList = createSelector(
  getModuleTemplates,
  (moduleTemplates) => {
    return moduleTemplates.items;
  }
);
export const getModuleTemplatesTotalCount = createSelector(
  getModuleTemplates,
  (moduleTemplates) => {
    return moduleTemplates.totalCount;
  }
);
