import { takeLatest, put, call } from "redux-saga/effects";
import { getSystemFieldIds } from "api/IXAdminApi";
import { getSystemFieldIdSlice } from "../store/reducers/systemIds";
import { systemFieldActions } from "../actions/systemFields";

export interface ResponseGenerator {
  id: any;
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

export function* getSystemFieldIdsWorker() {
  let requestError: any;
  const response: ResponseGenerator = yield call(
    async () =>
      await getSystemFieldIds()
        .then((response) => response)
        .catch((error) => {
          requestError = error;
        })
  );
  if (response) {
    yield put(getSystemFieldIdSlice(response.data));
  } else {
    yield put({
      type: systemFieldActions.GET_SYSTEMIDS_FAILURE,
      data: requestError.response.data,
    });
  }
}

export function* getSystemFieldIdsWatcher() {
  yield takeLatest(systemFieldActions.GET_SYSTEMIDS, getSystemFieldIdsWorker);
}
