import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { RequestSchema } from "shared/api/requests/types";

export const getRequests = (state: RootState) => state.requests;

export const getRequestList = createSelector(getRequests, (requests) => {
  return requests.new;
});

export const getDeletedRequest = createSelector(getRequests, (requests) => {
  return requests.requestDeleted;
});

export const getCompletedRequestsList = createSelector(
  getRequests,
  (requests) => {
    return requests.completed;
  }
);

export const countNew = createSelector(getRequests, (requests) => {
  return requests.newPendingCount;
});

export const countActive = createSelector(getRequests, (requests) => {
  return requests.newCount;
});

export const filteredCount = createSelector(getRequests, (requests) => {
  return requests.totalCount;
});

export const countCompleted = createSelector(getRequests, (requests) => {
  return requests.completedCount;
});

export const countArchived = createSelector(getRequests, (requests) => {
  return requests.archivedCount;
});

export const getCurrentRequest = createSelector(getRequests, (requests) => {
  return requests.request;
});

export const loadComments = createSelector(getRequests, (requests) => {
  return requests.comments;
});

export const loadFileInfos = createSelector(getCurrentRequest, (request) => {
  return request?.files;
});

export const loadTypes = createSelector(getRequests, (requests) => {
  return requests.types;
});

export const getNewRequestList = createSelector(getRequestList, (requests) => {
  return requests.filter(
    (request: RequestSchema) =>
      request.status === "New" ||
      request.status === "Pending" ||
      request.status === "InProgress"
  );
});

export const getArchivedRequestList = createSelector(
  getRequests,
  (requests) => {
    return requests.archived;
  }
);
