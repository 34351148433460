import React from "react";
import { InputAdornment } from "@mui/material";

import { StyledSearchIcon, StyledTextField } from "./styles";

interface SearchFiledProps {
  id: string;
  value: string;
  placeholder: string;
  onChange: (row: any) => void;
}

const SearchFiled: React.FC<any> = ({
  id,
  value,
  onChange,
  placeholder,
  ...props
}: SearchFiledProps) => {
  return (
    <StyledTextField
      id={id}
      placeholder={placeholder}
      value={value}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <StyledSearchIcon />
          </InputAdornment>
        ),
      }}
      onChange={onChange}
      {...props}
    />
  );
};

export default SearchFiled;
