import styled from "styled-components";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Typography,
  Avatar,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export const StyledButton = styled(Button)<{ component: string }>`
  &.MuiButtonBase-root {
    border-radius: 100px;
    padding: 7px 29px;
    color: var(--secondary);
    border: 1px solid var(--secondary);
    text-transform: none;
    margin-bottom: 10px;
  }
`;
export const StyledDialog = styled(Dialog)`
  .MuiDialog-container {
    justify-content: flex-end;
    font-family: "Inter";
  }
  .MuiDialog-paper {
    max-height: 100vh;
    width: 560px;
    margin: inherit;
    position: relative;
    overflow: hidden;
  }
`;
export const StyledTypography = styled(Typography)<{ danger?: boolean }>`
  font-size: 0.75rem !important;

  &.warnings {
    color: ${(props) => (props.danger ? "var(--darkred)" : "var(--grey)")};
  }

  &.scroll {
    width: calc(100% - 24px);
    overflow: auto;
  }

  &.top {
    margin-top: 0.75rem !important;
  }
`;
export const StyledActions = styled(DialogActions)`
  &.MuiDialogActions-root {
    border-top: 1px solid #dadae3;
    padding: 20px 20px;
    justify-content: center;
    width: 100%;
  }
`;
export const StyledTitle = styled(DialogTitle)`
  &.MuiTypography-root {
    font-weight: 600;
    font-size: 24px;
    line-height: 48px;
    color: var(--headings);
    padding: 20px 50px;
    border-bottom: 1px solid var(--silver);
  }
`;
export const StyledIconButton = styled(IconButton)`
  &.MuiButtonBase-root {
    top: 25px;
    right: 47px;
  }
`;
export const Wrapper = styled.div`
  height: calc(100vh - 182px);
  overflow-y: scroll;
  padding: 32px;
`;

export const FileUpload = styled.div`
  height: 50%;
`;

export const FileWrapper = styled.div`
  border-top: 1px solid #dadae3;
  padding: 24px 0 0;
  margin-top: 24px;
`;

export const StyledAvatar = styled(Avatar)`
  &.MuiAvatar-root {
    width: 100%;
    background: var(--ghost);
    height: 118px;
    border-radius: 15px;
    line-height: 32px;
  }
  svg path {
    fill: rgba(98, 102, 234, 0.5);
  }
`;
export const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  .MuiOutlinedInput-notchedOutline {
    border-color: var(--primary);
  }
`;
