import { useDispatch } from "react-redux";
import { useCallback } from "react";
import { moduleTemplatesActions } from "entities/moduleTemplates";
import { PaginationParams } from "api/types";

export const useModuleTemplates = () => {
  const dispatch = useDispatch();

  const getModuleTemplates = useCallback(
    (pagingParams: PaginationParams) => {
      dispatch({
        type: moduleTemplatesActions.GET_MODULE_TEMPLATES,
        data: { params: pagingParams },
      });
    },
    [dispatch]
  );

  return {
    getModuleTemplates,
  };
};
