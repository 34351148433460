import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// components
import {
  Box,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Tooltip,
} from "@mui/material";

import TableHeader from "./TableHeader";
import TenantBlock from "./TenantBlock";
import OutlinedCheckbox from "../FormComponents/OutlinedCheckbox";
import FilterModal from "./TenantFilterModal";
import TableCellComponent from "./TableCell";
// styles
import {
  DenseIconsWrap,
  DensityButton,
  StyledControlHeader,
  StyledTableRow,
  NoDataWrapper,
  GridContainer,
} from "./styles";
// actions
import { draftItemsActions } from "redux/actions/draftItems";
import { modalsActions } from "redux/actions/modals";
// icons
import NoDataIconSrc from "assets/icons/no-tenants.svg";
import { ReactComponent as Density3Icon } from "assets/icons/row-density-3.svg";
import { ReactComponent as Density2Icon } from "assets/icons/row-density-2.svg";
import { ReactComponent as DensityIcon } from "assets/icons/row-density.svg";
// types
import { Density, TableProps, TenantsRows, Data } from "./types";

const popperClass = "white-tooltip";
const densityButtons = [
  {
    value: Density.COMPACT,
    icon: Density2Icon,
  },
  {
    value: Density.COMFORTABLE,
    icon: DensityIcon,
  },
  {
    value: Density.BIG,
    icon: Density3Icon,
  },
];

const DataTable: React.FC<TableProps> = ({
  rows,
  columns,
  density,
  setDensity,
  checkboxes,
  controlsHeader,
  handleEditTenant,
  handleRestore,
  status,
  resetting,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selected, setSelected] = useState<string[]>([]);
  const [tenants, setTenants] = useState(rows);

  const dispatch = useDispatch();
  const draftItems = useSelector((state: any) => state.draftItems);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDeleteTenant = (tenant: string) => {
    dispatch({
      type: modalsActions.OPEN_MODAL,
      data: { delete: { type: "tenant", isModalOpen: true, tenant } },
    });
  };

  const handleSwitchTenant = (tenant: any) => {
    dispatch({
      type: modalsActions.OPEN_MODAL,
      data: { switchToTenant: { isModalOpen: true, tenant } },
    });
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedRows = [...selected];
    if (isSelected(id)) {
      const elementIndex = selectedRows.indexOf(id);
      selectedRows.splice(elementIndex, 1);
      setSelected(selectedRows);
    } else {
      setSelected([...selectedRows, id]);
    }
  };

  const isSelected = (id: string) => {
    return selected.some((x) => x === id);
  };

  const handleCompanyNameFilter = (filterValue: any) => {
    if (filterValue.searchValue) {
      if (filterValue.searchValue.length) {
        const sortedTenants: TenantsRows = [];
        filterValue.searchValue.map((item: any) => {
          const found: Data | undefined = rows.find(
            (element: any) => element.companyName === item
          );
          if (found) sortedTenants.push(found);
        });
        setTenants(sortedTenants);
      } else {
        setTenants(rows);
      }
    } else {
      // eslint-disable-next-line prefer-const
      let sortedTenants: TenantsRows = [...rows];
      if (filterValue.sortOrder === "asc") {
        sortedTenants.sort(function (a, b) {
          if (a.companyName > b.companyName) {
            return 1;
          }
          if (a.companyName < b.companyName) {
            return -1;
          }
          return 0;
        });
      } else {
        sortedTenants.sort(function (a, b) {
          if (a.companyName < b.companyName) {
            return 1;
          }
          if (a.companyName > b.companyName) {
            return -1;
          }
          return 0;
        });
      }
      setTenants(sortedTenants);
    }
  };

  useEffect(() => {
    dispatch({ type: draftItemsActions.ADD_DRAFT_ITEM, data: selected });
  }, [selected]);

  useEffect(() => {
    if (draftItems.length !== selected.length) {
      setSelected([]);
    }
  }, [draftItems]);

  useEffect(() => {
    setTenants(rows);
  }, [rows]);

  useEffect(() => {
    if (resetting) setPage(0);
  }, [resetting]);

  return (
    <>
      {controlsHeader && (
        <StyledControlHeader data-testid="tableControlHeader">
          {rows.length ? (
            <>
              <DenseIconsWrap>
                {densityButtons.map((item, key) => (
                  <DensityButton
                    key={`${density}_${key}`}
                    onClick={() => setDensity(item.value)}
                    disabled={density === item.value}
                    data-testid={`tableDensityButton_${item.value}`}
                  >
                    <Tooltip
                      classes={{ popper: popperClass }}
                      title={`${item.value} view`}
                    >
                      <SvgIcon component={item.icon} />
                    </Tooltip>
                  </DensityButton>
                ))}
              </DenseIconsWrap>
              {density === Density.BIG && (
                <FilterModal
                  headCell={{
                    label: "Alphabetically ",
                    id: "companyName",
                    visible: true,
                    filtering: true,
                  }}
                  rows={rows}
                  onChange={handleCompanyNameFilter}
                />
              )}
            </>
          ) : (
            <></>
          )}
        </StyledControlHeader>
      )}
      <TableContainer
        sx={{
          height: controlsHeader
            ? "calc(100vh - 405px)"
            : "calc(100vh - 425px)",
        }}
        data-testid="tableContainer"
      >
        {rows.length ? (
          <>
            {density === Density.BIG ? (
              <GridContainer data-testid="tableBody">
                {tenants.length &&
                  tenants
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item) => (
                      <TenantBlock
                        tenant={item}
                        handleDeleteTenant={handleDeleteTenant}
                        handleEditTenant={handleEditTenant}
                        handleSwitchTenant={handleSwitchTenant}
                        key={item.id}
                        status={status}
                        handleRestore={handleRestore}
                      />
                    ))}
              </GridContainer>
            ) : (
              <Table stickyHeader>
                <TableHeader
                  checkboxes={true}
                  columns={columns}
                  rows={rows}
                  numSelected={selected.length}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={rows.length}
                />
                <TableBody data-testid="tableBody">
                  {tenants.length &&
                    tenants
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row: any) => (
                        <StyledTableRow
                          density={density}
                          selected={selected.indexOf(row.id) !== -1}
                          hover
                          tabIndex={-1}
                          key={row.id}
                          data-testid={`tableRow_${row.id}`}
                        >
                          {checkboxes && (
                            <TableCell padding="checkbox">
                              <OutlinedCheckbox
                                onClick={(event) => handleClick(event, row.id)}
                                checked={isSelected(row.id)}
                              />
                            </TableCell>
                          )}
                          {columns.map((column) => {
                            const colValue = row[column.id];
                            return (
                              column.visible && (
                                <TableCell key={column.id} align={column.align}>
                                  <TableCellComponent
                                    columnId={column.id}
                                    colValue={colValue}
                                    tenant={row}
                                    handleDeleteTenant={handleDeleteTenant}
                                    handleEditTenant={handleEditTenant}
                                    handleSwitchTenant={handleSwitchTenant}
                                    handleRestore={handleRestore}
                                    status={status}
                                  />
                                </TableCell>
                              )
                            );
                          })}
                        </StyledTableRow>
                      ))}
                </TableBody>
              </Table>
            )}
          </>
        ) : (
          <NoDataWrapper data-testid="table_nothingToShow">
            <div>
              <img src={NoDataIconSrc} alt="no data" />
              <p>Nothing to show.</p>
            </div>
          </NoDataWrapper>
        )}
      </TableContainer>
      <Box sx={{ height: "55px" }}>
        {rows.length ? (
          <TablePagination
            rowsPerPageOptions={[10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            data-testid="table_pagination"
          />
        ) : (
          <></>
        )}
      </Box>
    </>
  );
};

export default DataTable;
