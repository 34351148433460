import React from "react";
import dayjs, { Dayjs } from "dayjs";
import { Grid, Box } from "@mui/material";
import Label from "../../../FormComponents/Label";
import FormGroup from "../../../FormComponents/FormGroup";
import CustomSelect from "shared/ui/Select";
import Textarea from "../../../FormComponents/Textarea";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { ErrorsProps } from "../";
import { StyledTypography, StyledDatePicker } from "../styles";

interface CreateSectionProps {
  notificationType: string;
  setNotificationType: (data: any) => void;
  errors: ErrorsProps;
  title: string;
  setTitle: (data: any) => void;
  recipients: string[];
  setRecipients: (data: any) => void;
  effectiveDate: Dayjs;
  setEffectiveDate: (data: any) => void;
  description: string;
  setDescription: (data: any) => void;
  recipientsOptions: string[];
}

const CreateSection = ({
  notificationType,
  setNotificationType,
  errors,
  title,
  setTitle,
  recipients,
  setRecipients,
  effectiveDate,
  setEffectiveDate,
  description,
  setDescription,
  recipientsOptions,
}: CreateSectionProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid
        container
        spacing={3}
        sx={{
          justifyContent: "center",
          padding: "0",
          width: "auto",
          flexDirection: "column",
        }}
      >
        <Grid item xs={12}>
          <Label label="Notification Type" danger />
          <CustomSelect
            id="notificationType"
            options={[
              "General IX Platform Alert",
              "Emergency Maintenance Notice",
              "Scheduled Maintenance Notice",
            ]}
            errorPlaceholder="Choose Notification Type"
            value={notificationType}
            error={errors?.notificationType}
            onChange={setNotificationType}
          />
        </Grid>
        <Grid item xs={12}>
          <FormGroup
            id="title"
            value={title}
            onChange={setTitle}
            label="Title"
            error={errors?.title}
            danger
          />
        </Grid>
        <Grid item xs={12}>
          <Label label="Recipients" danger />
          <CustomSelect
            id="recipients"
            options={["All", ...recipientsOptions]}
            errorPlaceholder="Choose Recipients"
            error={errors?.recipients}
            value={recipients}
            onChange={setRecipients}
            multiple={true}
          />
        </Grid>
        <Grid item xs={12}>
          <Label label="Publish Date" danger />
          <StyledDatePicker
            defaultValue={dayjs(new Date())}
            onChange={setEffectiveDate}
            disablePast={true}
            value={effectiveDate}
          />
        </Grid>
        <Grid item xs={12}>
          <Label label="Description" danger />
          <Textarea
            value={description}
            id="description"
            onChange={setDescription}
            error={errors?.description}
            placeholder={""}
            minRows={0}
          />
          <Box sx={{ textAlign: "right", marginTop: "6px" }}>
            <StyledTypography className="warnings">
              {description.length}/250 characters
            </StyledTypography>
          </Box>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

export default CreateSection;
