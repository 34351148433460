import React, { useState } from "react";
import {
  TabButton,
  TabButtonWrap,
  TabPanelWrap,
  TabsHeader,
} from "components/Tabs/styles";
import TablePaper from "shared/ui/TablePaper";
import { WidgetsTable } from "widgets/WidgetsTable";
import { Box } from "@mui/material";
import { TabNames } from "../types";
import BulkActions from "../../components/BulkActions";

const WidgetsPage = () => {
  const [activeTab, setActiveTab] = useState("list");
  const handleActiveTab = (val: string) => {
    setActiveTab(val);
  };
  return (
    <Box sx={{ marginTop: "20px" }} data-testid="widgets">
      <TabsHeader>
        <TabButtonWrap>
          <TabButton
            isActive={"list" === activeTab}
            onClick={() => handleActiveTab("list")}
          >
            Widgets
          </TabButton>
          <TabButton
            isActive={"archive" === activeTab}
            onClick={() => handleActiveTab("archive")}
          >
            Archive
          </TabButton>
        </TabButtonWrap>
      </TabsHeader>
      <TabPanelWrap>
        <TablePaper>
          <WidgetsTable isArchive={"archive" === activeTab} />
        </TablePaper>
        <BulkActions activeTab={TabNames.WIDGETS} />
      </TabPanelWrap>
    </Box>
  );
};

export default WidgetsPage;
