import { put, takeEvery } from "redux-saga/effects";
import { openModal, closeModal } from "../store/reducers/modals";
import { modalsActions } from "../actions/modals";

export function* openModalWorker(payload: any) {
  try {
    yield put(openModal(payload.data));
  } catch (e) {
    yield put({ type: "OPEN_MODAL_FAILED" });
  }
}

export function* openModalWatcher() {
  yield takeEvery(modalsActions.OPEN_MODAL, openModalWorker);
}

export function* closeModalWorker(payload: any) {
  try {
    yield put(closeModal(payload.data));
  } catch (e) {
    yield put({ type: "CLOSE_MODAL_FAILED" });
  }
}

export function* closeModalWatcher() {
  yield takeEvery(modalsActions.CLOSE_MODAL, closeModalWorker);
}
