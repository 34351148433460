import { Column } from "../Table/types";

export const initialColumns: Column[] = [
  {
    id: "displayName",
    label: "Category Name",
    minWidth: 150,
    visible: true,
    filtering: true,
  },
  {
    id: "attributesCount",
    label: "Attributes",
    minWidth: 150,
    visible: true,
    filtering: false,
  },
  {
    id: "productsCount",
    label: "Product",
    minWidth: 150,
    visible: true,
    filtering: false,
  },
  {
    id: "createdDateUtc",
    label: "Creation",
    minWidth: 150,
    visible: true,
    filtering: false,
  },
  {
    id: "updatedDateUtc",
    label: "Update",
    minWidth: 200,
    visible: true,
    filtering: false,
  },
  {
    id: "actions",
    label: "Actions",
    minWidth: 150,
    visible: true,
    filtering: false,
  },
];
