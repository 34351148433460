import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// components
import TableHeader from "./TableHeader";
import TableRow from "./TableRow";
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from "@mui/material";
// styles
import { NoDataWrapper } from "./styles";
// icons
import NoDataIconSrc from "assets/icons/no-tenants.svg";
// types
import { TableProps } from "./types";
// actions
import { draftItemsActions } from "redux/actions/draftItems";

const DataTable: React.FC<TableProps> = ({
  activeTab,
  rows,
  columns,
  onEdit,
  onDelete,
  onView,
  filterParams,
  setFilterParams,
  hideCheckboxes,
  borderCollapse,
  pagingParams,
  setPagingParams,
  totalRows,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selected, setSelected] = useState<(string | number)[]>([]);

  const dispatch = useDispatch();

  const draftItems = useSelector((state: any) => state.draftItems);

  useEffect(() => {
    if (pagingParams?.offset === 0) setPage(0);
  }, [pagingParams]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    if (setPagingParams) {
      setPagingParams({
        ...pagingParams,
        page: newPage,
        offset: rowsPerPage * newPage,
      });
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    if (setPagingParams) {
      setPagingParams({
        ...pagingParams,
        page: 0,
        limit: event.target.value,
        offset: 0,
      });
    }
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedRows = [...selected];
    if (isSelected(id)) {
      const elementIndex = selectedRows.indexOf(id);
      selectedRows.splice(elementIndex, 1);
      setSelected(selectedRows);
    } else {
      setSelected([...selectedRows, id]);
    }
  };

  const isSelected = (id: string) => {
    return selected.some((x) => x === id);
  };

  useEffect(() => {
    dispatch({ type: draftItemsActions.ADD_DRAFT_ITEM, data: selected });
  }, [selected]);

  useEffect(() => {
    if (draftItems.length !== selected.length) {
      setSelected([]);
    }
  }, [draftItems]);

  return (
    <>
      <TableContainer
        sx={{ height: "calc(100vh - 405px)" }}
        data-testid="tableContainer"
      >
        {rows.length ? (
          <Table
            stickyHeader
            sx={
              borderCollapse
                ? {
                    borderCollapse: "separate",
                    borderSpacing: "0 10px",
                  }
                : {}
            }
          >
            <TableHeader
              columns={columns}
              rows={rows}
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={rows.length}
              filterParams={filterParams}
              setFilterParams={setFilterParams}
              hideCheckbox={hideCheckboxes}
            />
            <TableBody data-testid="tableBody">
              {rows.map((row: any) => (
                <TableRow
                  key={row.id}
                  row={row}
                  activeTab={activeTab}
                  columns={columns}
                  selected={selected}
                  hideCheckboxes={hideCheckboxes}
                  handleClick={handleClick}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  onView={onView}
                />
              ))}
            </TableBody>
          </Table>
        ) : (
          <NoDataWrapper data-testid="table_nothingToShow">
            <div>
              <img src={NoDataIconSrc} alt="no data" />
              <p>Nothing to show.</p>
            </div>
          </NoDataWrapper>
        )}
      </TableContainer>
      <Box sx={{ height: "55px" }}>
        {rows.length ? (
          <TablePagination
            rowsPerPageOptions={[10, 25]}
            component="div"
            count={totalRows || rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            data-testid="table_pagination"
          />
        ) : (
          <></>
        )}
      </Box>
    </>
  );
};

export default DataTable;
