import { createSlice } from "@reduxjs/toolkit";

export const platformNotificationsSlice = createSlice({
  name: "platformNotifications",
  initialState: {
    items: [],
    totalCount: undefined,
    notification: {
      description: "",
      createdDateUtc: "",
      id: null,
      files: [],
      title: "",
      notificationType: "",
    },
  },
  reducers: {
    loadNotifications: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    loadNotification: (state, action) => {
      return {
        ...state,
        notification: {
          ...action.payload,
        },
      };
    },
  },
});

export const { loadNotifications, loadNotification } =
  platformNotificationsSlice.actions;
