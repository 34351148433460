import styled from "styled-components";
import { IconButton } from "@mui/material";
import { ActionButtonTypes } from "./types";

export const StyledIconButton = styled(IconButton)<{ actiontype: string }>`
  &.MuiIconButton-root {
    margin-left: 10px;
    width: 40px;
    height: 40px;

    ${({ actiontype }) => {
      return actiontype === ActionButtonTypes.ACTION && "background: #6266EA;";
    }}

    path:first-child,
    path:last-child {
      fill: ${({ actiontype }) => {
        return actiontype === ActionButtonTypes.ACTION
          ? "#ffffff"
          : "var(--grey)";
      }};
    }
    path:nth-child(2) {
      stroke: var(--grey);
    }

    :first-child {
      margin-left: 0;
    }
    &:hover {
      opacity: 0.5;
      ${({ actiontype }) => {
        return (
          actiontype === ActionButtonTypes.ACTION && "background: #6266EA;"
        );
      }}
    }
  }
`;
