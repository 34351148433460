import React, { useEffect, useRef, useState } from "react";
import { Grid } from "@mui/material";
import {
  StyledSpan,
  StyledTypography,
  StyledHeader,
  DescripGrid,
} from "../styles";
import { format } from "date-fns";
import Label from "../../../FormComponents/Label";
import CustomSelect from "../../../FormComponents/Select";
import { StyledButton } from "../../../Archive/styles";
import { RequestDetailSchema } from "shared/api/requests/types";

interface ErrorsProps {
  requestStatus?: boolean;
}
interface ViewRequestSectionProps {
  errors: ErrorsProps;
  request: RequestDetailSchema;
  requestStatus: string;
  handleStatusChange: (event: any) => void;
  viewFiles: (viewFile: boolean) => void;
}

const tabList = [
  {
    label: "Conversation",
  },
  {
    label: "All Uploaded Files",
  },
];

const ViewRequestSection = ({
  errors,
  request,
  requestStatus,
  viewFiles,
  handleStatusChange,
}: ViewRequestSectionProps) => {
  const [selected, setSelected] = useState("Conversation");
  const [viewAll, setViewAll] = useState(false);

  const descripEndRef = useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () => {
    descripEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (viewAll) scrollToBottom();
  }, [viewAll]);

  const handleArchivedSelect = (e: React.MouseEvent<HTMLElement>) => {
    const value = e.currentTarget.id;

    setSelected(value);
    if (value === "All Uploaded Files") viewFiles(true);
    else viewFiles(false);
  };

  const renderDescription = () => {
    const textLength = request.description.length;
    if (textLength > 100) {
      const subSection = request.description
        .substring(0, 100)
        .replace(/[{()}"]/g, "");
      return (
        <div>
          <StyledTypography>
            {subSection}&nbsp;...&nbsp;
            <StyledSpan onClick={() => setViewAll(true)}>read more</StyledSpan>
          </StyledTypography>
        </div>
      );
    } else {
      return (
        <StyledTypography>
          {request.description.replace(/[{()}"]/g, "")}
        </StyledTypography>
      );
    }
  };

  const handleViewAll = () => {
    return (
      <StyledTypography className="scroll">
        {request.description.replace(/[{()}"]/g, "")}&nbsp;
        <StyledSpan onClick={() => setViewAll(false)} ref={descripEndRef}>
          read less
        </StyledSpan>
      </StyledTypography>
    );
  };
  return (
    <>
      <Grid item xs={10}>
        <Label label="Status" />
        <CustomSelect
          value={requestStatus}
          id="requestStatus"
          options={["InProgress", "Completed"]}
          onChange={(event) => handleStatusChange(event.target.value)}
          title="Request Status"
          hideCreate={true}
          disabled={requestStatus === "Completed"}
          error={errors?.requestStatus}
        />
      </Grid>
      <Grid
        container
        spacing={3}
        sx={{
          justifyContent: "center",
          width: "100%",
          paddingLeft: "24px",
          margin: "16px 0 0 0",
        }}
      >
        <Grid
          item
          xs={10}
          sx={{
            height: "180px",
            backgroundColor: "var(--ghost)",
            padding: "0 !important",
            marginTop: "12px",
            overflowX: "hidden",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <Grid container spacing={1} sx={{ margin: "12px 0 0 0" }}>
            <Grid item xs={6}>
              <StyledHeader>Request Id:</StyledHeader>
            </Grid>
            <Grid item xs={6}>
              <StyledHeader>Request Type:</StyledHeader>
            </Grid>
            <DescripGrid item xs={6}>
              <StyledTypography>{request?.id}</StyledTypography>
            </DescripGrid>
            <DescripGrid item xs={6}>
              <StyledTypography>{request?.requestTypeName}</StyledTypography>
            </DescripGrid>
            <Grid item xs={6}>
              <StyledHeader>Created:</StyledHeader>
            </Grid>
            <Grid item xs={6}>
              <StyledHeader>Last Updated:</StyledHeader>
            </Grid>
            <DescripGrid item xs={6}>
              <StyledTypography>
                {request &&
                  format(new Date(request.createdDateUtc), "dd-MM-yyyy")}
              </StyledTypography>
            </DescripGrid>
            <DescripGrid item xs={6}>
              <StyledTypography>
                {request &&
                  format(new Date(request.updatedDateUtc), "dd-MM-yyyy")}
              </StyledTypography>
            </DescripGrid>
            <Grid item xs={6}>
              <StyledHeader>Description:</StyledHeader>
            </Grid>
            <DescripGrid item xs={12} sx={{ paddingRight: "16px" }}>
              {!viewAll ? renderDescription() : handleViewAll()}
            </DescripGrid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={10}
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {tabList.map((tab, index) => {
          return (
            <StyledButton
              variant={selected === tab.label ? "contained" : "outlined"}
              onClick={handleArchivedSelect}
              key={tab.label}
              id={tab.label}
              sx={{
                width: "50%",
                textTransform: "uppercase !important",
                borderRadius: index === 0 ? "8px 0 0 8px" : "0 8px 8px 0",
              }}
            >
              {tab.label}
            </StyledButton>
          );
        })}
      </Grid>
    </>
  );
};

export default ViewRequestSection;
