import { createSelector } from "@reduxjs/toolkit";
import { getTenants } from "./getTenants";

export const getTenantActionLoading = createSelector(getTenants, (tenants) => {
  return tenants.tenantActionLoading;
});

export const getTenantActionItem = createSelector(getTenants, (tenants) => {
  return tenants.tenantActionItem;
});

export const getTenant = createSelector(getTenants, (tenants) => {
  return tenants.tenant;
});
