import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { initialColumns } from "./types";

import TablePaper from "shared/ui/TablePaper";
import NotificationsTable from "../Table";
import DeleteSimpleModal from "../Modals/DeleteSimpleModal";

import { NotificationDetail } from "features/notification-detail";

import {
  platformNotificationsActions,
  getNotificationsItems,
  getNotificationsTotalCount,
} from "entities/platformNotifications";

import { PlatformNotificationRespose } from "shared/api/platformNotifications/types";

import { useAppSelector } from "redux/store/hooks/hooks";

import { PaginationParams } from "api/types";

const initialPagingData = {
  limit: 10,
  offset: 0,
};

const Notifications: React.FC<any> = () => {
  const dispatch = useDispatch();

  const notifications = useAppSelector(getNotificationsItems);
  const totalCount = useAppSelector(getNotificationsTotalCount);

  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [selectedNotification, setSelectedNotification] =
    useState<PlatformNotificationRespose>();
  const [filterParams, setFilterParams] = useState({
    notificationType: "",
    order: undefined,
  });
  const [pagingParams, setPagingParams] =
    useState<PaginationParams>(initialPagingData);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  useEffect(() => {
    if (filterParams) {
      dispatch({
        type: platformNotificationsActions.GET_PLATFORM_NOTIFICATIONS,
        data: {
          params: {
            NotificationType: filterParams.notificationType,
            ...pagingParams,
            "SortingPreferences.SortingProperty": "Id",
            "SortingPreferences.Kind":
              filterParams.order === "asc" ? "Ascending" : "Descending",
          },
        },
      });
    }
  }, [filterParams, pagingParams, openDeleteModal]);

  const handleClose = () => {
    setOpenDetailModal(false);
  };

  const handleView = (notificaion: PlatformNotificationRespose) => {
    setSelectedNotification(notificaion);
    setOpenDetailModal(true);
    dispatch({
      type: platformNotificationsActions.GET_PLATFORM_NOTIFICATION,
      data: notificaion?.id,
    });
  };

  const handleDelete = (notification: PlatformNotificationRespose) => {
    setOpenDeleteModal(!openDeleteModal);
    setSelectedNotification(notification);
  };

  const callback = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  const deleteSelectedNotification = () => {
    dispatch({
      type: platformNotificationsActions.DELETE_PLATFORM_NOTIFICATION,
      data: selectedNotification?.id,
      callback,
    });
  };

  return (
    <TablePaper>
      <NotificationsTable
        rows={notifications}
        columns={initialColumns}
        activeTab="Notifications"
        hideCheckboxes={true}
        borderCollapse={true}
        totalRows={totalCount}
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        pagingParams={pagingParams}
        setPagingParams={setPagingParams}
        onView={handleView}
        onDelete={handleDelete}
      />
      <NotificationDetail
        open={openDetailModal}
        handleClose={handleClose}
        recipients={selectedNotification?.recipients}
      />
      <DeleteSimpleModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(!openDeleteModal)}
        onSubmit={deleteSelectedNotification}
        title={`Are you sure you want to delete Notification ${selectedNotification?.id}?`}
      />
    </TablePaper>
  );
};

export default Notifications;
