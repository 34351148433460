import styled from "styled-components";
import { Button } from "@mui/material";
export const SortLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const IconWrap = styled.div<{ open: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background: ${(props) => (props.open ? "var(--secondary)" : "transparent")};
  cursor: pointer;
  margin-left: 5px;

  svg {
    transform: rotateZ(0deg);
    transition: 0.2s;
    opacity: ${(props) => (props.open ? "1" : "0.5")};
    path {
      transition: 0.2s;
      fill: ${(props) =>
        props.open ? "var(--white)" : "rgba(56, 56, 116, 0.5)"};
    }
  }
`;

export const MenuWrapper = styled.div`
  display: flex;
  min-width: 250px;
  flex-direction: column;
`;

export const StyledButton = styled(Button)<{ isActive?: boolean }>`
  &.MuiButton-root {
    justify-content: space-between;
    padding: 10px 16px;
    text-transform: none;
    color: ${(props) => (props.isActive ? "var(--secondary)" : "var(--black)")};
    :hover {
      color: ${(props) =>
        props.isActive ? "var(--secondary)" : "var(--black)"};
      background: var(--ghost);
    }
    div {
      display: flex;
      align-items: center;
      > strong {
        margin-right: 5px;
      }
    }
  }
  svg.MuiSvgIcon-root {
    width: 20px;
    height: 20px;
    position: relative;
    bottom: 1px;
  }
`;
