import { Column } from "../Table/types";

export const initialColumns: Column[] = [
  {
    id: "attributeName",
    label: "Attribute Name",
    minWidth: 150,
    visible: true,
    filtering: true,
  },
  {
    id: "displayName",
    label: "Display Name",
    minWidth: 150,
    visible: true,
    filtering: false,
  },
  {
    id: "valueType",
    label: "Value Type",
    minWidth: 150,
    visible: true,
    filtering: false,
  },
  {
    id: "attributeType",
    label: "Attribute Type",
    minWidth: 150,
    visible: true,
    filtering: false,
  },
  {
    id: "isRequired",
    label: "Required",
    minWidth: 150,
    visible: true,
    filtering: false,
  },
  {
    id: "subAttributes",
    label: "Component Type",
    minWidth: 200,
    visible: true,
    filtering: false,
  },
  {
    id: "actions",
    label: "Actions",
    minWidth: 150,
    visible: true,
    filtering: false,
  },
];
