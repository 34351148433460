export enum TabNames {
  ATTRIBUTES = "Attributes",
  ARCHIVE = "Archive",
  CATEROGIES = "Categories",
  TENANTS = "Tenants",
  NEW = "New",
  COMPLETED = "Completed",
  REQUESTS = "Requests",
  NOTIFICATIONS = "Notifications",
  MODULE_TEMPLATES = "Module Templates",
  WIDGETS = "Widgets",
  DISTRIBUTIONS = "Distributions",
}
