import React, {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { OrderTypes } from "../../../config/types/Sorting";
import { SORT } from "../../../config/enums/Sorting";
import { useDebounce } from "use-debounce";
import { draftItemsActions } from "../../../../redux/actions/draftItems";
import { useAppDispatch } from "../../../../redux/store/hooks/hooks";
import { useSelector } from "react-redux";

interface Props {
  initialRowsPerPage?: number;
  initialOrder?: OrderTypes;
  debounceDelay?: number;
}

export const useTableProps = (
  props: Props = {
    initialRowsPerPage: 10,
    initialOrder: { type: SORT.ASC, label: "" },
    debounceDelay: 500,
  }
) => {
  const {
    initialRowsPerPage = 10,
    initialOrder = { type: SORT.ASC, label: "" },
    debounceDelay = 500,
  } = props;
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<OrderTypes>(initialOrder);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
  const [searchValue, setSearchValue] = useState("");
  const [value] = useDebounce(searchValue, debounceDelay);

  const handleChangePage = useCallback(
    (event: MouseEvent<HTMLButtonElement> | null, page: number) => {
      setPage(page);
    },
    []
  );
  const handleChangeRowsPerPage = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setRowsPerPage(Number(event.target.value));
    },
    []
  );

  return {
    page,
    handleChangePage,
    order,
    setOrder,
    rowsPerPage,
    handleChangeRowsPerPage,
    setSearchValue,
    searchValue,
    value,
  };
};

export const useTableSelectedItems = <T extends Record<"id", string>>(
  data: T[]
) => {
  const [selected, setSelected] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const draftItems = useSelector((state: any) => state.draftItems);
  useEffect(() => {
    if (draftItems.length !== selected.length) {
      setSelected([]);
    }
  }, [draftItems]);

  const handleSelectAllClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        const newSelected: string[] = data.map((n: any) => n.id);
        setSelected(newSelected);
        return;
      }
      setSelected([]);
    },
    [data]
  );

  const isSelected = useCallback(
    (id: string) => {
      return selected.some((x) => x === id);
    },
    [selected]
  );
  useEffect(() => {
    dispatch({ type: draftItemsActions.ADD_DRAFT_ITEM, data: selected });
  }, [selected]);
  const handleClick = useCallback(
    (event: React.MouseEvent<unknown>, id: string) => {
      const selectedRows = [...selected];
      if (isSelected(id)) {
        const elementIndex = selectedRows.indexOf(id);
        selectedRows.splice(elementIndex, 1);
        setSelected(selectedRows);
      } else {
        setSelected([...selectedRows, id]);
      }
    },
    [selected]
  );

  return {
    selected,
    handleSelectAllClick,
    isSelected,
    handleClick,
  };
};
