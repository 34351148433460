import styled from "styled-components";

const handleColorType = (type: string | undefined) => {
  switch (type) {
    case "createdTenant":
      return { background: "#E5F6FD", fill: "var(--darkblue)" };
    case "deleteTenant":
      return { background: "#FDEDED", fill: "var(--darkred)" };
    default:
      return { background: "#fff4e6", fill: "var(--darkorange)" };
  }
};

export const WarningWrap = styled.div<{ type: string }>`
  background: ${({ type }) => handleColorType(type).background};
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 12px;
  > div {
    color: ${({ type }) => handleColorType(type).fill};
    > div {
      svg path {
        fill: ${({ type }) => handleColorType(type).fill};
      }
    }
  }
`;

export const WarningTextWrap = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  width: 93%;
`;

export const WarningIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  width: 5%;
`;
