// sortByOrder: sort an ObjectArray by A-Z or Z-A by any given key within an object array.
export const sortByOrder = (items: any, filters: any) => {
  const sortKey = filters.name;
  const option = filters.sortKey;
  if (!sortKey && !option) return items;

  const sortedRows = items.toSorted((a: any, b: any) => {
    const itemA = String(a[sortKey]);
    const itemB = String(b[sortKey]);

    return itemA.toLowerCase() > itemB.toLowerCase() ? 1 : -1;
  });

  switch (option) {
    case "asc":
      return sortedRows;
    case "desc":
      return sortedRows.reverse();
    default:
      return sortedRows;
  }
};

// updateValueByKey: update an objectArray certain element by value and key
// => const objectArray = [{name: 'Allen'}, {name: 'Eleven'}];
// => updateValueByKey(objectArray, 'name', 'Allen', 'Ellen');
// => [{name: 'Ellen'}, {name: 'Eleven'}]
export const updateValueByKey = (
  items: Array<object>,
  key: string,
  oldValue: string,
  newValue: string
) => {
  const outputItems = items.map((item: any) =>
    item[key] == oldValue ? { ...item, [key]: newValue } : item
  );
  return outputItems;
};

// SearchbyValue: search an objectArray by given value of same key.
// => objectArray = [{name: 'Allen'}, {name: 'Mike'}, {name: 'Eleven'}];
// => searchByValue(objectArray, 'name', 'Allen');
// => [{name: 'Allen'}] // can also be multiple if matches.
export const searchByValue = (
  items: Array<any>,
  key: string,
  search: string
) => {
  const searchString = search.toLowerCase();
  const outputItems = items.filter(
    (item: any) => item[key].toLowerCase().indexOf(searchString) !== -1
  );
  return outputItems;
};

export const groupedByAttributes = (items: Array<object>) => {
  const groupByCategory = items.reduce((group: any, item: any) => {
    const { groupName } = item;
    group[groupName] = group[groupName] ?? [];
    group[groupName].push(item);
    return group;
  }, {});
  const result = [];
  for (const groupBy in groupByCategory) {
    result.push({ groupBy, items: groupByCategory[groupBy] });
  }
  return result;
};

export const filterByKeys = (
  items: Array<any>,
  filter: string,
  keys: string[]
) => {
  const sortKeys = [...keys];
  if (sortKeys.length == 0) return items;

  const filteredResult = [...items].filter((item: any) => {
    return sortKeys.includes(item[filter]);
  });
  return filteredResult;
};
