import { put, takeLatest } from "redux-saga/effects";

import { alertActions } from "../actions/alerts";
import { tenantsActions } from "entities/tenants/model/actions/tenants";
import { attributesActions } from "entities/attributes";
import { categoriesActions } from "entities/productCategories";

export const actionsList = [
  tenantsActions.GET_TENANTS_FAILURE,
  tenantsActions.CREATE_TENANT_FAILURE,
  tenantsActions.DELETE_TENANT_FAILURE,
  tenantsActions.EDIT_TENANT_FAILURE,
  attributesActions.GET_ARCHIVED_ATTRIBUTES_FAILURE,
  attributesActions.GET_ATTRIBUTES_FAILURE,
  attributesActions.RESTORE_ATTRIBUTES_FAILURE,
  attributesActions.DELETE_ATTRIBUTE_FAILURE,
  attributesActions.CREATE_ATTRIBUTE_FAILURE,
  attributesActions.UPDATE_ATTRIBUTE_FAILURE,
  attributesActions.ATTACH_ATTRIBUTE_FAILURE,
  attributesActions.DETACH_ATTRIBUTE_FAILURE,
  categoriesActions.CREATE_CATEGORY_FAILURE,
  categoriesActions.UPDATE_CATEGORY_FAILURE,
  categoriesActions.UPDATE_CATEGORY_NAME_FAILURE,
  categoriesActions.DELETE_ATTRIBUTE_FROM_CATEGORY_FAILURE,
  categoriesActions.DELETE_CATEGORY_FAILURE,
  categoriesActions.GET_CATEGORIES_FAILURE,
  categoriesActions.GET_CATEGORY_FAILURE,
  categoriesActions.RESTORE_CATEGORY_FAILURE,
  categoriesActions.GET_ARCHIVED_CATEGORIES_FAILURE,
];

export function* AppExceptionsWorker({ data }: any) {
  try {
    let alertData;
    if (data?.status > 399 && data?.status < 500) {
      alertData = {
        type: "requestError",
        name:
          data?.errors?.Validations.join(", ") || data?.title || data?.message,
      };
    } else {
      alertData = {
        type: "serverError",
        reload: true,
      };
    }
    yield put({
      type: alertActions.SHOW_ALERT,
      data: alertData,
    });
  } catch (e) {
    console.error(e);
  }
}

export function* AppExceptionsWatcher() {
  yield takeLatest(actionsList, AppExceptionsWorker as any);
}
