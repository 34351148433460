import React, { useState } from "react";
import Logo from "assets/logo.png";
import NotFound from "assets/icons/image-not-found.svg";
import { StyledLogo } from "./styles";

const TenantLogo = ({
  url,
  maxHeight,
  height,
  maxWidth,
  width,
}: {
  url: string;
  maxHeight?: string;
  height?: string;
  maxWidth?: string;
  width?: string;
}) => {
  const [imgError, setImgError] = useState(false);

  const handleImgError = () => {
    setImgError(true);
  };

  const errorUrl = imgError ? NotFound : url;
  return (
    <StyledLogo
      src={!url ? Logo : errorUrl}
      onError={handleImgError}
      maxHeight={maxHeight}
      height={height}
      maxWidth={maxWidth}
      width={width}
    />
  );
};

export default TenantLogo;
