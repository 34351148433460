import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";

import {
  StyledDialog,
  StyledActions,
  StyledTitle,
  StyledIconButton,
} from "./styles";
import Button from "../../Button";

interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}
interface ModalProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  submitButton: React.ReactNode;
  cancelButtonLabel?: string;
  id: string;
  title: string;
  actions?: boolean;
  fullScreen?: boolean;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <StyledTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <StyledIconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "var(--black)",
          }}
        >
          <CloseIcon />
        </StyledIconButton>
      ) : null}
    </StyledTitle>
  );
};

const Modal = ({
  open,
  onClose,
  children,
  id,
  title,
  submitButton,
  cancelButtonLabel,
  actions = true,
  fullScreen,
}: ModalProps) => {
  return (
    <StyledDialog
      onClose={onClose}
      aria-labelledby={`${id}-title`}
      open={open}
      fullScreen={fullScreen}
    >
      <BootstrapDialogTitle id={`${id}-title`} onClose={onClose}>
        {title}
      </BootstrapDialogTitle>
      {children}
      {actions && (
        <StyledActions
          sx={{
            padding: fullScreen
              ? "15px 50px 20px !important"
              : "20px 50px 40px",
          }}
        >
          <Button onClick={onClose} variant="outlined">
            {cancelButtonLabel ?? "Cancel"}
          </Button>
          {submitButton}
        </StyledActions>
      )}
    </StyledDialog>
  );
};

export default Modal;
