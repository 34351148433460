import { createSlice } from "@reduxjs/toolkit";

export type modalsTypes = "tenant" | "tenants";

export type ModalProps = {
  isModalOpen?: boolean;
  type?: modalsTypes;
  tenant?: any;
};

export interface ModalsState {
  delete: ModalProps;
  addAdmin: ModalProps;
  switchToTenant: ModalProps;
}
const initialState: ModalsState = {
  delete: {},
  addAdmin: {},
  switchToTenant: {},
};
export const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    openModal: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    closeModal: (state, action) => {
      return initialState;
    },
  },
});

export const { openModal, closeModal } = modalsSlice.actions;
