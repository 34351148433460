import React, { ChangeEvent } from "react";
import { StyledTypography, StyledButton } from "../styles";
import { ReactComponent as UploadIcon } from "assets/icons/upload.svg";
import { Grid, Table, TableBody, TableRow, TableCell } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

interface FileUploadSectionProps {
  files: File[];
  removeFile: (file: File) => void;
  handleFileUpload: (e: ChangeEvent<HTMLInputElement>) => void;
}

const FileUploadSection = ({
  files,
  handleFileUpload,
  removeFile,
}: FileUploadSectionProps) => {
  return (
    <Grid
      container
      spacing={3}
      sx={{
        justifyContent: "center",
        width: "calc(100% - 100px)",
        margin: "54px auto",
      }}
    >
      <Grid
        item
        xs={4}
        sx={{
          height: "50px",
          justifyContent: "center",
          display: "flex",
          padding: "0 !important",
          alignItems: "center",
        }}
      >
        <StyledButton
          variant="outlined"
          startIcon={<UploadIcon />}
          component="label"
        >
          {"Upload File"}
          <input type="file" onChange={handleFileUpload} hidden multiple />
        </StyledButton>
      </Grid>
      <Grid item xs={8} sx={{ height: "30px", padding: "0 !important" }}>
        <StyledTypography className="warnings">
          Please upload a .xlsx, .txt, .json, .jpg or a .png file with a minimum
          dimension of 400w x 400h not exceeding 5MB.
        </StyledTypography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          height: `${window.innerHeight - 620}px`,
          padding: "0 !important",
          overflow: "auto",
        }}
      >
        {files?.length > 0 && (
          <Table size="small">
            <TableBody>
              {files.map((file, index) => (
                <TableRow key={`${file.name}_${index}`}>
                  <TableCell
                    sx={{
                      maxWidth: "400px",
                      overflow: "scroll",
                      "&::-webkit-scrollbar": {
                        display: "none",
                      },
                    }}
                  >
                    {file.name}
                  </TableCell>
                  <TableCell
                    onClick={() => removeFile(file)}
                    sx={{ textAlign: "right" }}
                  >
                    <DeleteForeverIcon
                      sx={{
                        cursor: "pointer",
                        color: "var(--secondary)",
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Grid>
    </Grid>
  );
};

export default FileUploadSection;
