import React from "react";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import { BackLink, PaddingDiv, StyledTitle } from "./styles";

interface PageTitleProps {
  title: string;
  backLink: {
    to: string;
    title: string;
  };
}

const PageTitle: React.FC<any> = ({ title, backLink }: PageTitleProps) => {
  return (
    <PaddingDiv>
      {backLink && (
        <BackLink to={backLink.to}>
          <KeyboardArrowLeftIcon /> {backLink.title}
        </BackLink>
      )}
      <StyledTitle variant={"h5"}>{title}</StyledTitle>
    </PaddingDiv>
  );
};

export default PageTitle;
