import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";
import {
  InputAdornment,
  TableHead,
  Menu,
  MenuItem,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  Paper,
} from "@mui/material";
import { ReactComponent as ConnectIcon } from "assets/icons/connect.svg";
import { StyledSearchIcon, StyledTextField } from "../../Archive/styles";
import { AttributesData, AttributesPayload } from "api/types";
import { sortByOrder } from "utils/array";

import Modal from "../StyledModal";
import Button from "../../Button";
import {
  AddAttrDiv,
  AddCategory,
  Block,
  Description,
  EditCategory,
  FilterIcon,
  StyledButton,
  Title,
  AddAttrButton,
  TableWrapper,
  PurpleBlock,
  StyledTableRow,
  StyledTableCell,
} from "../CategoryModal/styles";
import OutlinedCheckbox from "../../FormComponents/OutlinedCheckbox";
import { attributesActions } from "entities/attributes";
import { alertActions } from "redux/actions/alerts";

interface AddAttributesProps {
  open: boolean;
  onClose: () => void;
  attribute: AttributesData;
  payload?: AttributesPayload;
  notifyCreated?: (created: boolean) => void;
}

const AddAttributes = ({
  open,
  onClose,
  attribute,
  payload,
  notifyCreated,
}: AddAttributesProps) => {
  const [reset, setReset] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [availableAttributes, setAvailableAttributes] = useState(
    Array<AttributesData>
  );
  // all the available attributes regardless of added/removed
  const [allAttributes, setAllAttributes] = useState(Array<AttributesData>);

  // attributes to be rendered on the right based on edit
  const [renderedAttributes, setRenderedAttributes] = useState(
    Array<AttributesData>
  );
  // attributes added or pending to be added.
  const [addedAttributes, setAddedAttributes] = useState(Array<AttributesData>);

  // attributes already added before edit.
  const [currentAddedAttributes, setCurrentAddedAttributes] = useState<
    Array<AttributesData>
  >([]);
  const [attributeDisplayName, setAttributeDisplayName] = React.useState("");
  const [selected, setSelected] = useState(Array<AttributesData>);
  const [removeSelected, setRemoveSelected] = useState(Array<AttributesData>);
  const [attributeData, setAttributeData] = useState<AttributesPayload>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [searchQuery] = useDebounce(searchValue, 2000);

  const dispatch = useDispatch();
  const attributes = useSelector((state: any) => state.attributes);

  useEffect(() => {
    loadAttributes();
  }, [open]);

  useEffect(() => {
    loadAttributes();
  }, [attributes]);

  const loadAttributes = () => {
    if (attributes && attributes.items.length > 0) {
      const allAttributes = attributes.items.reduce(
        (output: Array<AttributesData>, item: AttributesData) => {
          if (attribute?.id !== item.id) {
            output.push(item);
          }
          return output;
        },
        []
      );
      setAllAttributes(allAttributes);
      setAvailableAttributes(allAttributes);
    }
  };

  useEffect(() => {
    setRenderedAttributes(availableAttributes);
  }, [availableAttributes]);

  useEffect(() => {
    if (renderedAttributes.length == 0) {
      setRenderedAttributes(allAttributes);
    }
  }, [renderedAttributes]);

  useEffect(() => {
    if (payload) {
      setAttributeDisplayName(payload.displayName);
      setAttributeData(payload);
    }
  }, [payload]);

  useEffect(() => {
    if (open && attribute && attribute.subAttributes.length > 0) {
      const childAttributes = [...attribute.subAttributes];
      const currentAvailableAttributes = [...availableAttributes];
      setAddedAttributes(childAttributes);
      setCurrentAddedAttributes(childAttributes);

      for (const item of childAttributes) {
        const elementIndex = indexOfItem(currentAvailableAttributes, item);
        currentAvailableAttributes.splice(elementIndex, 1);
      }

      // remove itself from AvailableAttributes
      const selfIndex = indexOfItem(currentAvailableAttributes, attribute);
      if (selfIndex >= 0) {
        currentAvailableAttributes.splice(selfIndex, 1);
      }

      setAvailableAttributes(currentAvailableAttributes);
    }
  }, [attribute, open]);

  useEffect(() => {
    if (selected && selected.length == 0 && reset) {
      setReset(false);
    }
  }, [selected]);

  useEffect(() => {
    if (reset) {
      setAddedAttributes([]);
      setSelected([]);
      setRemoveSelected([]);
      setRenderedAttributes([]);
      setReset(false);
    }
  }, [reset]);

  useEffect(() => {
    if (searchQuery) {
      dispatch({
        type: attributesActions.GET_ATTRIBUTES,
        data: {
          params: {
            ShowArchived: false,
            SearchExpression: searchQuery,
          },
        },
      });
    }
  }, [searchQuery]);

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const addSelectedAttributes = () => {
    const currentSelected = [...selected];
    const currentAddedOutput = [...addedAttributes].concat([...selected]);
    const newAvailableAttributes = [...availableAttributes];

    if (currentAddedOutput && currentAddedOutput.length > 0) {
      for (const item of currentSelected) {
        const elementIndex = indexOfItem(newAvailableAttributes, item);
        newAvailableAttributes.splice(elementIndex, 1);
      }
      // add/remove attributes left and right
      setAvailableAttributes(newAvailableAttributes);
      setAddedAttributes(currentAddedOutput);

      // reset the collaspebable items
      setSelected([]);
    }
  };

  const removeSelectedAttributes = () => {
    const selectedRemoveItems = [...addedAttributes];
    const itemToAddBack = [...removeSelected];
    const newAvailableAttributes = itemToAddBack.concat([
      ...availableAttributes,
    ]);

    for (const item of [...removeSelected]) {
      const elementIndex = indexOfItem(selectedRemoveItems, item);
      selectedRemoveItems.splice(elementIndex, 1);
    }

    setAvailableAttributes(newAvailableAttributes);

    setAddedAttributes(selectedRemoveItems);
    setRemoveSelected([]);
  };

  const sendAlerts = (type: string, name: string) => {
    dispatch({
      type: alertActions.SHOW_ALERT,
      data: {
        type,
        name,
      },
    });
  };

  const handleSubmitAction = () => {
    if (attribute) {
      updateAttribute();
    } else {
      dispatch({
        type: attributesActions.CREATE_ATTRIBUTE,
        data: attributeData,
        callback,
      });
    }
  };

  const distinctAttributes = (
    firstArray: Array<AttributesData>,
    secondArray: Array<AttributesData>
  ) => {
    const distinctAttrs = firstArray.filter(
      (attribute: AttributesData) =>
        !secondArray
          .map((addedAttribute: AttributesData) => addedAttribute.id)
          .includes(attribute.id)
    );
    return distinctAttrs;
  };

  const updateAttribute = () => {
    const newAdditions = distinctAttributes(
      addedAttributes,
      currentAddedAttributes
    );
    const newRemovals = distinctAttributes(
      currentAddedAttributes,
      addedAttributes
    );
    if (newAdditions.length > 0) {
      for (const item of newAdditions) {
        dispatch({
          type: attributesActions.ATTACH_ATTRIBUTE,
          data: {
            id: attribute.id,
            attributeId: item.id,
          },
        });
      }
    }
    if (newRemovals.length > 0) {
      for (const item of newRemovals) {
        dispatch({
          type: attributesActions.DETACH_ATTRIBUTE,
          data: {
            id: attribute.id,
            attributeId: item.id,
          },
        });
      }
    }
    sendAlerts("editAttribute", attribute.attributeName);
    handleClose();
  };

  const callback = (data: any) => {
    const attributeId = data.id;

    if (attributeId) {
      if (addedAttributes.length > 0) {
        for (const item of addedAttributes) {
          dispatch({
            type: attributesActions.ATTACH_ATTRIBUTE,
            data: {
              id: data.id,
              attributeId: item.id,
              displayName: attributeDisplayName,
            },
          });
        }
      }

      if (payload) sendAlerts("createAttribute", payload.attributeName);
      if (notifyCreated) notifyCreated(true);
    }

    handleClose();
  };

  const handleItemSelect = (item: AttributesData) => {
    const selectedItems = [...selected];

    if (isSelected(item, selectedItems)) {
      const elementIndex = indexOfItem(selectedItems, item);
      selectedItems.splice(elementIndex, 1);
      setSelected(selectedItems);
    } else {
      setSelected([...selectedItems, item]);
    }
  };

  const handleRemoveItemSelect = (item: AttributesData) => {
    const selectedRemoveItems = [...removeSelected];

    if (isSelected(item, selectedRemoveItems)) {
      const elementIndex = indexOfItem(selectedRemoveItems, item);
      selectedRemoveItems.splice(elementIndex, 1);
      setRemoveSelected(selectedRemoveItems);
    } else {
      setRemoveSelected([...selectedRemoveItems, item]);
    }
  };

  const isSelected = (item: AttributesData, items: Array<AttributesData>) => {
    return items.some((x) => x.id === item.id);
  };

  const indexOfItem = (items: Array<object>, item: object) => {
    let index = 0;
    for (const element of items) {
      if (JSON.stringify(element) === JSON.stringify(item)) {
        return index;
      }
      index += 1;
    }
    return -1;
  };

  const sortByName = (order: string) => {
    handleFilterClose();

    const filterByName = { name: "attributeName", sortKey: order };
    const attrsToFilter = [...addedAttributes];
    const filtered = sortByOrder([...attrsToFilter], filterByName);
    if (filtered) {
      setAddedAttributes(filtered);
    }
  };

  const navigate = useNavigate();

  const handleClose = () => {
    setReset(true);
    onClose();
  };

  return (
    <Modal
      id="subAttributesModal"
      onClose={handleClose}
      open={open}
      title={"Added Attributes"}
      submitButton={
        <Button
          variant="contained"
          onClick={handleSubmitAction}
          data-testid="subAttributesModal_button"
        >
          {attribute ? "Update Attribute" : "Create Attribute"}
        </Button>
      }
      fullScreen={true}
    >
      <Block>
        <AddCategory>
          <Title className="spacing">Added Attributes</Title>
          {addedAttributes.length > 0 && (
            <TableWrapper fullSize={true}>
              <TableContainer
                component={Paper}
                sx={{
                  boxShadow: "none",
                }}
              >
                <Table
                  aria-label="collapsible table"
                  sx={{
                    borderCollapse: "separate",
                    borderSpacing: "0px 10px",
                    backgroundColor: "var(--ghost)",
                  }}
                >
                  <TableHead>
                    <StyledTableRow className="transparent header">
                      <StyledTableCell className="header left" />
                      <StyledTableCell className="header left grey">
                        Attribute Name
                        <IconButton
                          aria-controls="filter-menu"
                          aria-haspopup="true"
                          onClick={handleFilterClick}
                          aria-label="search by name"
                          title="search by name"
                        >
                          <FilterIcon />
                        </IconButton>
                        <Menu
                          id="filter-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleFilterClose}
                        >
                          <MenuItem onClick={() => sortByName("asc")}>
                            Sort A-Z
                          </MenuItem>
                          <MenuItem onClick={() => sortByName("desc")}>
                            Sort Z-A
                          </MenuItem>
                        </Menu>
                      </StyledTableCell>
                      <StyledTableCell className="header left grey">
                        Display Name
                      </StyledTableCell>
                      <StyledTableCell className="header left grey">
                        Required
                      </StyledTableCell>
                      <StyledTableCell className="header left grey">
                        Description
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow
                      sx={{ height: "10px" }}
                      className="transparent"
                    >
                      <PurpleBlock className="blockPos" />
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {addedAttributes.map((item: AttributesData) => (
                      <StyledTableRow key={item.id}>
                        <StyledTableCell className="collapsePadding filtered topSpacing">
                          <OutlinedCheckbox
                            checked={isSelected(item, removeSelected)}
                            onClick={() => handleRemoveItemSelect(item)}
                          />
                          <ConnectIcon />
                        </StyledTableCell>
                        <StyledTableCell className="collapsePadding">
                          {item.attributeName}
                        </StyledTableCell>
                        <StyledTableCell className="largePadding">
                          {item.displayName}
                        </StyledTableCell>
                        <StyledTableCell className="largePadding">
                          {item.isRequired ? "Yes" : "No"}
                        </StyledTableCell>
                        <StyledTableCell className="largePadding">
                          {item.attributeDescription}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <AddAttrDiv className="left">
                <AddAttrButton
                  variant="outlined"
                  onClick={removeSelectedAttributes}
                  className="remove"
                >
                  {`Remove ${removeSelected.length} Attributes`}
                </AddAttrButton>
              </AddAttrDiv>
            </TableWrapper>
          )}
        </AddCategory>
        <EditCategory>
          <Title className="bold">Available Attributes</Title>
          <Description className="attrDesc">
            If you can&apos;t find the attribute you need request one&nbsp;
            <StyledButton
              className="text-button"
              onClick={() => navigate("/product-configurator/attributes")}
            >
              here.
            </StyledButton>
          </Description>
          <StyledTextField
            id="search_attribute"
            placeholder="Search Attribute"
            sx={{ padding: "20px" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <StyledSearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <TableContainer
            component={Paper}
            sx={{
              padding: "10px 20px 0px 10px",
              boxShadow: "none",
              maxHeight: "350px",
            }}
          >
            <Table
              aria-label="available attributes table"
              sx={{
                borderCollapse: "separate",
                borderSpacing: "0px 3px",
              }}
            >
              <TableBody>
                {renderedAttributes.map((item: AttributesData) => (
                  <StyledTableRow key={item.id}>
                    <StyledTableCell padding="checkbox">
                      <OutlinedCheckbox
                        checked={isSelected(item, selected)}
                        onClick={() => handleItemSelect(item)}
                      />
                    </StyledTableCell>
                    <StyledTableCell className="collsPadding collapsePadding">
                      {item.displayName}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <AddAttrDiv>
            <AddAttrButton variant="outlined" onClick={addSelectedAttributes}>
              {`Add ${selected.length} Attributes`}
            </AddAttrButton>
          </AddAttrDiv>
        </EditCategory>
      </Block>
    </Modal>
  );
};

export default AddAttributes;
