import React, { memo, useEffect, useState } from "react";
import { format } from "date-fns";
import { useDebounce } from "use-debounce";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  ApplicationResponse,
  CreateApplicationRequest,
} from "shared/api/applications/types";

// components
import { Table, TableBody, TableCell, TablePagination } from "@mui/material";
import { IXTableRow, TableHeader } from "shared/ui/Table";
import OutlinedCheckbox from "shared/ui/OutlinedCheckbox";
import TablePlaceholder from "shared/ui/TablePlaceholder";
import SortingDefault, {
  SortRenderProps,
} from "shared/ui/Table/SortingDefault/SortingDefault";
// types
import { OrderTypes } from "shared/config/types/Sorting";
import { SORT } from "shared/config/enums/Sorting";
import { DateFormat } from "shared/config/enums/DateFormat";

import { initialColumns } from "./types";
import { useApplications } from "features/applications/model/hooks/useApplications";
import {
  getApplicationsList,
  getApplicationsTotalCount,
  getSecret,
  loadSecret,
  getTenantId,
} from "entities/applications";
import { useAppSelector } from "redux/store/hooks/hooks";
import { PaginationParams } from "api/types";
import { draftItemsActions } from "redux/actions/draftItems";
import { getTenants } from "entities/tenants";
import { TabNames } from "pages/types";
import { RoutesList } from "shared/routes";

import TableActions from "components/Table/TableActions";
import TableControls from "components/TableControls";
import DeleteSimpleModal from "components/Modals/DeleteSimpleModal";
import CreateDistribution from "components/Modals/CreateDistribution";
import CopyClientSecret from "components/Modals/CopyClientSecret";
import { TableContainerWrapper } from "shared/ui/Table/styles";
import { TenantItem } from "shared/api/tenant/types";

const initialPagingData = {
  limit: 10,
  offset: 0,
  page: 0,
};

export const DistributionsTable = memo(function DistributionsTable() {
  const { getApplications, deleteApplication, createApplication } =
    useApplications();
  const [order, setOrder] = useState<OrderTypes>({ type: SORT.ASC, label: "" });
  const [renderedItems, setRenderedItems] = useState<ApplicationResponse[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [pagingParams, setPagingParams] =
    useState<PaginationParams>(initialPagingData);
  const [searchValue, setSearchValue] = useState("");
  const [touched, setTouched] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedApplication, setSelectedApplication] =
    useState<ApplicationResponse>();
  const [selected, setSelected] = useState<string[]>([]);
  const [selectedTenant, setSelectedTenant] = useState<any>();
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openClientSecret, setOpenClientSecret] = useState(false);
  const [tenantsList, setTenantsList] = useState<TenantItem[]>([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const draftItems = useSelector((state: any) => state.draftItems);

  const moduleTemplates = useAppSelector(getApplicationsList);
  const total = useAppSelector(getApplicationsTotalCount);
  const tenants = useAppSelector(getTenants);
  const clientSecret: any = useAppSelector(getSecret);
  const tenantId: any = useAppSelector(getTenantId);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = renderedItems.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  useEffect(() => {
    const result: TenantItem[] = tenants.items.filter(
      (tenant) => tenant.state === "Active"
    );
    setTenantsList(result);
  }, [tenants]);

  useEffect(() => {
    dispatch({ type: draftItemsActions.ADD_DRAFT_ITEM, data: selected });
  }, [selected]);

  useEffect(() => {
    if (tenantId && !selectedTenant) {
      const selectedItem = tenants.items.find(
        (tenant: any) => tenant.id === tenantId
      );
      setSelectedTenant(selectedItem);
    }
  }, [tenantId]);

  useEffect(() => {
    if (draftItems.length !== selected.length) {
      setSelected([]);
    }
  }, [draftItems]);

  const openCreateDistributionModal = () => {
    setOpenCreateModal(true);
  };

  useEffect(() => {
    if (selectedTenant) {
      getApplications({
        data: { params: pagingParams },
        id: selectedTenant.id,
      });
    }
  }, [pagingParams, selectedTenant]);

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  useEffect(() => {
    if (order) {
      const sortingProperty = order?.label
        ? capitalizeFirstLetter(order.label)
        : "";
      setPagingParams({
        ...pagingParams,
        ...(sortingProperty && {
          "SortingPreferences.Kind": order.type,
          "SortingPreferences.SortingProperty": sortingProperty,
        }),
      });
    }
  }, [order]);

  useEffect(() => {
    setRenderedItems([...moduleTemplates]);
  }, [moduleTemplates]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    if (setPagingParams) {
      setPagingParams({
        ...pagingParams,
        page: newPage,
        offset: rowsPerPage * newPage,
      });
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    if (setPagingParams) {
      setPagingParams({
        ...pagingParams,
        page: 0,
        limit: Number(event.target.value),
        offset: 0,
      });
    }
  };

  const [searchQuery] = useDebounce(searchValue, 2000);

  useEffect(() => {
    if (searchQuery) {
      if (!touched) setTouched(true);
      setPagingParams({
        ...initialPagingData,
        SearchExpression: searchQuery,
      });
    } else if (!searchQuery && touched) {
      setPagingParams({
        ...initialPagingData,
        SearchExpression: "",
      });
    }
  }, [searchQuery]);

  const deleteSelectedApplication = () => {
    deleteApplication({
      tenantId: selectedTenant?.id,
      id: selectedApplication?.id,
      name: selectedApplication?.name,
    });
    setOpenDeleteModal(false);
  };

  const handleDelete = (item: ApplicationResponse) => {
    setOpenDeleteModal(true);
    setSelectedApplication(item);
  };

  const handleView = (item: ApplicationResponse) => {
    navigate(
      `${RoutesList.PRODUCT_CONFIGURATOR}/${RoutesList.DISTRIBUTIONS}/${item.id}?tenantId=${selectedTenant.id}`
    );
  };

  const isSelected = (id: string) => {
    return selected.some((x) => x === id);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedRows = [...selected];
    if (isSelected(id)) {
      const elementIndex = selectedRows.indexOf(id);
      selectedRows.splice(elementIndex, 1);
      setSelected(selectedRows);
    } else {
      setSelected([...selectedRows, id]);
    }
  };

  const onTenantSelect = (e: any) => {
    const selectedItem = tenantsList.find(
      (tenant: any) => tenant.companyName === e.target.value
    );
    setSelectedTenant(selectedItem);
  };

  const closeClientSecretModal = () => {
    navigate(
      `${RoutesList.PRODUCT_CONFIGURATOR}/${RoutesList.DISTRIBUTIONS}/${clientSecret.id}?tenantId=${selectedTenant.id}`
    );
    setOpenClientSecret(false);
    dispatch(loadSecret(undefined));
  };

  const handleCreateDistribution = (data: {
    data: CreateApplicationRequest;
    tenantId: string;
  }) => {
    const selectedItem = tenantsList.find(
      (tenant: any) => tenant.id === data.tenantId
    );
    setSelectedTenant(selectedItem);
    createApplication(data, callback);
  };

  const callback = (response: any) => {
    const { data, tenantId } = response;
    if (data?.id) {
      setOpenCreateModal(false);
      if (data.clientSecret) {
        setOpenClientSecret(true);
      } else {
        navigate(
          `${RoutesList.PRODUCT_CONFIGURATOR}/${RoutesList.DISTRIBUTIONS}/${data?.id}?tenantId=${tenantId}`
        );
      }
    }
  };

  return (
    <>
      <TableControls
        activeTab={TabNames.DISTRIBUTIONS}
        handleOpen={openCreateDistributionModal}
        handleSearch={(e) => setSearchValue(e.target.value)}
        searchValue={searchValue}
        options={tenantsList.map((tenant) => tenant.companyName)}
        handleSelect={onTenantSelect}
        value={selectedTenant?.companyName}
      />
      <TableContainerWrapper data-testid="tableContainer" size="sm">
        <Table stickyHeader>
          <TableHeader
            hideCheckbox={false}
            columns={initialColumns}
            onSelectAllClick={handleSelectAllClick}
            numSelected={selected.length}
            rowCount={renderedItems.length}
          >
            {(props: SortRenderProps) => (
              <SortingDefault
                setOrder={(data: OrderTypes) => setOrder(data)}
                order={order}
                {...props}
              />
            )}
          </TableHeader>
          <TableBody data-testid="tableBody">
            {renderedItems.length === 0 ? (
              <TablePlaceholder colSpan={initialColumns.length + 1} />
            ) : (
              renderedItems.map((row) => {
                return (
                  <IXTableRow
                    hover
                    tabIndex={-1}
                    key={row.id}
                    data-testid={`tableRow_${row.id}`}
                  >
                    <TableCell sx={{ padding: "0 0 0 4px !important" }}>
                      <OutlinedCheckbox
                        onClick={(event) => handleClick(event, row.id)}
                        checked={isSelected(row.id)}
                      />
                    </TableCell>
                    <TableCell sx={{ fontWeight: 700 }}>{row.name}</TableCell>
                    <TableCell>{row.provider}</TableCell>
                    <TableCell>{row.location}</TableCell>
                    <TableCell>{row.channel}</TableCell>
                    <TableCell>{row.distributionType}</TableCell>
                    <TableCell>{selectedTenant?.companyName}</TableCell>
                    <TableCell>
                      {format(new Date(row.createdDateUtc), DateFormat.DEFAULT)}
                    </TableCell>
                    <TableCell>
                      {row?.updatedDateUtc &&
                        format(
                          new Date(row.updatedDateUtc),
                          DateFormat.DEFAULT
                        )}
                    </TableCell>
                    <TableCell>
                      <TableActions
                        activeTab={TabNames.DISTRIBUTIONS}
                        handleDelete={() => handleDelete(row)}
                        handleView={() => handleView(row)}
                      />
                    </TableCell>
                  </IXTableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainerWrapper>
      <TablePagination
        sx={{ marginTop: "30px" }}
        rowsPerPageOptions={[10, 25]}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        data-testid="table_pagination"
      />
      <DeleteSimpleModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(!openDeleteModal)}
        onSubmit={deleteSelectedApplication}
        title={`Are you sure you want to delete Distribution "${selectedApplication?.name}"?`}
      />
      <CreateDistribution
        onClose={() => setOpenCreateModal(false)}
        open={openCreateModal}
        onSubmit={handleCreateDistribution}
      />
      <CopyClientSecret
        onClose={closeClientSecretModal}
        open={openClientSecret}
      />
    </>
  );
});
