import React from "react";
import { OutlinedInput, ListItemText } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import {
  StyledCheckbox,
  StyledMenuItem,
  StyledSelect,
  StyledPlaceholder,
} from "./styles";

interface SelectsProps {
  options: string[];
  id: string;
  value: string | string[];
  onChange: (e: any) => void;
  multiple?: boolean;
  error?: boolean;
  errorPlaceholder?: string;
  disabled?: boolean;
  disabledOptions?: string[];
}
const CustomizedSelects = ({
  options,
  id,
  onChange,
  value,
  multiple,
  error,
  errorPlaceholder,
  disabled,
  disabledOptions,
}: SelectsProps) => {
  const renderIconComponent = (props: any) => (
    <KeyboardArrowDownIcon {...props} />
  );

  return (
    <StyledSelect
      multiple={multiple}
      fullWidth
      id={id}
      name={id}
      value={value}
      disabled={disabled}
      onChange={onChange}
      input={<OutlinedInput placeholder="Please select" />}
      renderValue={(selected: any) => {
        if (errorPlaceholder && error) {
          return <StyledPlaceholder>{errorPlaceholder}</StyledPlaceholder>;
        }
        return Array.isArray(selected) ? selected.join(", ") : selected;
      }}
      displayEmpty={true}
      error={error}
      IconComponent={renderIconComponent}
    >
      {!multiple && (
        <StyledMenuItem value="">
          <ListItemText primary={"Please choose an option"} />
        </StyledMenuItem>
      )}
      {options.map((option) => (
        <StyledMenuItem
          key={option}
          value={option}
          data-testid={`select_${option}Option`}
          disabled={disabledOptions?.includes(option)}
        >
          <StyledCheckbox
            checked={
              !!(value && value instanceof Array
                ? value.indexOf(option) > -1
                : value === option)
            }
          />
          <ListItemText primary={option} />
        </StyledMenuItem>
      ))}
    </StyledSelect>
  );
};

export default CustomizedSelects;
