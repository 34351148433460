import React from "react";

import ActionButton from "../../ActionButton";
import { Divider, StyledActionWrap } from "./styles";

import { ActionButtonTypes } from "../../ActionButton/types";
import { TabNames } from "pages/types";

interface TableActionsProps {
  activeTab: string | undefined;
  handleEdit?: () => void;
  handleDelete: (data?: any) => void;
  handleRestore?: () => void;
  handleView: () => void;
  handleClone?: () => void;
}

interface ActionProps {
  title: string;
  onClick: any;
  type: ActionButtonTypes;
  divider?: boolean;
}

const TableActions: React.FC<TableActionsProps> = ({
  activeTab,
  handleDelete,
  handleEdit,
  handleRestore,
  handleView,
  handleClone,
}) => {
  const table_actions: any = {
    [TabNames.REQUESTS]: [
      {
        type: ActionButtonTypes.DELETE,
        title: "Delete Request",
        onClick: handleDelete,
      },
      {
        type: ActionButtonTypes.VIEW,
        title: "View Request",
        onClick: handleView,
      },
    ],
    [TabNames.CATEROGIES]: [
      {
        type: ActionButtonTypes.EDIT,
        title: "Edit Category",
        onClick: handleEdit,
      },
      {
        type: ActionButtonTypes.DELETE,
        title: "Delete Category",
        onClick: handleDelete,
      },
    ],
    [TabNames.ATTRIBUTES]: [
      {
        type: ActionButtonTypes.EDIT,
        title: "Edit Attribute",
        onClick: handleEdit,
      },
      {
        type: ActionButtonTypes.DELETE,
        title: "Delete Attribute",
        onClick: handleDelete,
      },
    ],
    [TabNames.NOTIFICATIONS]: [
      {
        type: ActionButtonTypes.DELETE,
        title: "Delete Notification",
        onClick: handleDelete,
      },
      {
        type: ActionButtonTypes.VIEW,
        title: "View Notification",
        onClick: handleView,
      },
    ],
    [TabNames.ARCHIVE]: [
      {
        type: ActionButtonTypes.RESTORE,
        title: "Restore",
        onClick: handleRestore,
      },
    ],
    [TabNames.MODULE_TEMPLATES]: [
      {
        type: ActionButtonTypes.CLONE,
        title: "Clone Module Templates",
        onClick: handleClone,
        divider: true,
      },
      {
        type: ActionButtonTypes.EDIT,
        title: "Edit Module Templates",
        onClick: handleEdit,
      },
      {
        type: ActionButtonTypes.DELETE,
        title: "Delete Module Templates",
        onClick: handleDelete,
      },
      {
        type: ActionButtonTypes.VIEW,
        title: "View Module Templates",
        onClick: handleView,
      },
    ],
    [TabNames.DISTRIBUTIONS]: [
      {
        type: ActionButtonTypes.DELETE,
        title: "Delete Distribution",
        onClick: handleDelete,
      },
      {
        type: ActionButtonTypes.VIEW,
        title: "View Distribution",
        onClick: handleView,
      },
    ],
  };

  return (
    <StyledActionWrap>
      {activeTab &&
        table_actions[activeTab].map((item: ActionProps) => (
          <React.Fragment key={item.type}>
            <ActionButton
              key={item.type}
              type={item.type}
              title={item.title}
              onClick={item.onClick}
            />
            {item.divider && <Divider />}
          </React.Fragment>
        ))}
    </StyledActionWrap>
  );
};

export default TableActions;
