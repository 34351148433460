import { createSlice } from "@reduxjs/toolkit";

export const loadingSlice = createSlice({
  name: "loading",
  initialState: false,
  reducers: {
    setLoading: (_, action) => {
      return action.payload;
    },
  },
});

export const { setLoading } = loadingSlice.actions;
