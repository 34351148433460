import React from "react";
import { NotificationProps } from "redux/store/reducers/alerts";

const CreateTenant = (notification: NotificationProps) => {
  return (
    <p>
      New Tenant <b>{notification.name}</b> has been created!
    </p>
  );
};
const DeleteTenant = (notification: NotificationProps) => {
  return (
    <p>
      <b>{notification.name}</b> Tenant has been removed. You can find it in
      Archived tab.
    </p>
  );
};
const EditTenant = (notification: NotificationProps) => {
  return (
    <p>
      <b>{notification.name}</b> Tenant details have been updated!
    </p>
  );
};
const RestoreTenant = (notification: NotificationProps) => {
  return (
    <p>
      <b>{notification.name}</b> Tenant has been restored.
    </p>
  );
};
const AddTenantAdmin = (notification: NotificationProps) => {
  return (
    <p>
      Tenant admin <b>{notification.name}</b> <b>{notification.email}</b> has
      been added. An invitation to Sign Up has been sent.
    </p>
  );
};
const RequestError = (notification: NotificationProps) => {
  return (
    <p>
      Request Failed: Error: <b>{notification.name}</b>
    </p>
  );
};
const ServerError = () => {
  return <p>Unable to load data. Please try again later or reload webpage.</p>;
};
const RequestCreated = (notification: NotificationProps) => {
  return (
    <p>
      Request <b>{notification.name}</b> has been created.
    </p>
  );
};
const RequestDeleted = (notification: NotificationProps) => {
  return (
    <p>
      Request <b>{notification.name}</b> is deleted.
    </p>
  );
};
const RequestRestored = (notification: NotificationProps) => {
  return (
    <p>
      Request <b>{notification.name}</b> is restored.
    </p>
  );
};
const CreateAttribute = (notification: NotificationProps) => {
  return (
    <p>
      Attribute <b>{notification.name}</b> has been created.
    </p>
  );
};
const EditAttribute = (notification: NotificationProps) => {
  return (
    <p>
      Attribute <b>{notification.name}</b> has been updated.
    </p>
  );
};
const AttributeRestored = (notification: NotificationProps) => {
  return (
    <p>
      Attribute <b>{notification.name}</b> has been restored.
    </p>
  );
};
const CategoryRestored = (notification: NotificationProps) => {
  return (
    <p>
      Category <b>{notification.name}</b> has been restored.
    </p>
  );
};
const CreateCategory = (notification: NotificationProps) => {
  return (
    <p>
      New Category <b>{notification.name}</b> has been created.
    </p>
  );
};
const UpdateCategory = (notification: NotificationProps) => {
  return (
    <p>
      Category <b>{notification.name}</b> has been updated.
    </p>
  );
};
const DeleteAttribute = (notification: NotificationProps) => {
  return (
    <p>
      Attribute <b>{notification.name}</b> has been deleted.
    </p>
  );
};
const DetachAttribute = (notification: NotificationProps) => {
  return (
    <p>
      Attribute <b>{notification.name}</b> has been detached.
    </p>
  );
};
const DeleteCategory = (notification: NotificationProps) => {
  return (
    <p>
      Category <b>{notification.name}</b> has been deleted.
    </p>
  );
};

export const messages: any = {
  createTenant: CreateTenant,
  deleteTenant: DeleteTenant,
  editTenant: EditTenant,
  restoreTenant: RestoreTenant,
  addTenantAdmin: AddTenantAdmin,
  requestError: RequestError,
  serverError: ServerError,
  requestCreated: RequestCreated,
  requestDeleted: RequestDeleted,
  requestRestored: RequestRestored,
  createAttribute: CreateAttribute,
  editAttribute: EditAttribute,
  attributeRestored: AttributeRestored,
  categoryRestored: CategoryRestored,
  createCategory: CreateCategory,
  updateCategory: UpdateCategory,
  deleteAttribute: DeleteAttribute,
  detachAttribute: DetachAttribute,
  deleteCategory: DeleteCategory,
};
