import { createSlice } from "@reduxjs/toolkit";
import { AlertColor } from "@mui/material/Alert/Alert";

export interface NotificationProps {
  type: string;
  severity?: AlertColor;
  name?: string;
  email?: string;
  undo?: boolean;
  reload?: boolean;
}
type AlertsState = Array<NotificationProps>;
const initialState: AlertsState = [];

export const alertsSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    showAlert: (state, action) => {
      return [...state, action.payload];
    },
    closeAlert: (state, action) => {
      const existingAlerts = JSON.parse(JSON.stringify(state));
      existingAlerts.shift();
      return [...existingAlerts];
    },
  },
});

export const { showAlert, closeAlert } = alertsSlice.actions;
