import React from "react";

import { TableCell, TableRow } from "@mui/material";
import FilterModal from "../../FilterModal";
import OutlinedCheckbox from "../../FormComponents/OutlinedCheckbox";

import { StyledTableHead } from "./styles";

import { EnhancedTableProps } from "../types";

const TableHeader = (props: EnhancedTableProps) => {
  const {
    onSelectAllClick,
    numSelected,
    rowCount,
    columns,
    rows,
    filterParams,
    setFilterParams,
    hideCheckbox,
  } = props;

  return (
    <StyledTableHead data-testid="tableHeader">
      <TableRow>
        {!hideCheckbox && (
          <TableCell padding="checkbox">
            <OutlinedCheckbox
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
        )}
        {columns.map(
          (headCell) =>
            headCell.visible && (
              <TableCell
                key={headCell.id}
                align={headCell.align ? headCell.align : "left"}
                sx={{
                  minWidth: headCell.minWidth,
                  textAlign: headCell.id == "actions" ? "center" : "left",
                }}
              >
                {headCell.filtering ? (
                  <FilterModal
                    headCell={headCell}
                    rows={rows}
                    filterParams={filterParams}
                    setFilterParams={setFilterParams}
                  />
                ) : (
                  <span>{headCell.label}</span>
                )}
              </TableCell>
            )
        )}
      </TableRow>
    </StyledTableHead>
  );
};

export default TableHeader;
