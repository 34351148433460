import React from "react";

import { TableCell, TableRow } from "@mui/material";
import FilterModal from "../../FilterModal";
import OutlinedCheckbox from "../../FormComponents/OutlinedCheckbox";

import { StyledTableHead } from "./styles";

import { EnhancedTableProps } from "../types";

const TableHeader = (props: EnhancedTableProps) => {
  const { checkboxes, onSelectAllClick, numSelected, rowCount, columns, rows } =
    props;

  return (
    <StyledTableHead data-testid="tableHeader">
      <TableRow>
        {checkboxes && (
          <TableCell padding="checkbox">
            <OutlinedCheckbox
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
        )}
        {columns.map(
          (headCell) =>
            headCell.visible && (
              <TableCell
                key={headCell.id}
                align={headCell.align ? headCell.align : "left"}
                sx={{ minWidth: headCell.minWidth }}
              >
                {headCell.filtering ? (
                  <FilterModal headCell={headCell} rows={rows} />
                ) : (
                  <span>{headCell.label}</span>
                )}
              </TableCell>
            )
        )}
      </TableRow>
    </StyledTableHead>
  );
};

export default TableHeader;
