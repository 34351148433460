import { all, call } from "redux-saga/effects";

import * as authSaga from "./authSaga";
import * as tenantsSaga from "entities/tenants/model/saga/tenantsSaga";
import * as systemFieldIdSaga from "./systemIdSaga";
import * as alertsSaga from "./alertsSaga";
import * as draftitemsSaga from "./draftitemsSaga";
import * as modalsSaga from "./modalsSaga";
import * as appSaga from "./appSaga";
import { AttributesWatcher } from "entities/attributes";
import { ProductCategoriesWatcher } from "entities/productCategories";
import { RequestsWatcher } from "entities/requests";
import { FilesWatcher } from "entities/files";
import { PlatformNotificationsWatcher } from "entities/platformNotifications";
import { ModuleTemplatesWatcher } from "entities/moduleTemplates";
import { ApplicationsWatcher } from "entities/applications";
import { widgetsWatcher } from "../../entities/widgets";

export default function* rootSaga() {
  yield all([
    call(appSaga.AppExceptionsWatcher),
    call(tenantsSaga.getTenantsWatcher),
    call(tenantsSaga.createTenantWatcher),
    call(tenantsSaga.deleteTenantWatcher),
    call(tenantsSaga.updateTenantWatcher),
    call(tenantsSaga.restoreTenantWatcher),
    call(tenantsSaga.getTenantWatcher),
    call(authSaga.signInWatcher),
    call(alertsSaga.showAlertWatcher),
    call(systemFieldIdSaga.getSystemFieldIdsWatcher),
    call(alertsSaga.closeAlertWatcher),
    call(draftitemsSaga.addDraftItemWatcher),
    call(modalsSaga.openModalWatcher),
    call(modalsSaga.closeModalWatcher),
    call(AttributesWatcher),
    call(ProductCategoriesWatcher),
    call(RequestsWatcher),
    call(FilesWatcher),
    call(PlatformNotificationsWatcher),
    call(ModuleTemplatesWatcher),
    call(ApplicationsWatcher),
    call(widgetsWatcher),
  ]);
}
