import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import themeSwitcher from "theme-switcher";

import { Box } from "@mui/material";

import Sidebar from "components/Sidebar";
import Header from "components/Header";
import Alerts from "components/Alerts";
import DeleteModal from "components/Modals/DeleteModal";

import { StyledDiv } from "./styles";

import { platformNotificationsActions } from "entities/platformNotifications";
import { getTenantsAction } from "entities/tenants";
import { useRequests } from "features/requests/model/hooks/useRequests";

// sidebar sizes
// change these 3 params to change the width of sidebar and paddings
const initialSidebarWidth = 290;
const rightSidebarPanel = 30;
const defaultSpacing = 20;
enum Themes {
  LIGHT = "light",
  DARK = "dark",
}

const Home = () => {
  const dispatch = useDispatch();

  const { switcher } = themeSwitcher({
    themeMap: {
      dark: "/tenants/dark.css",
      light: "/tenants/light.css",
    },
  });

  const [expanded, setExpanded] = useState(true);
  const [sidebarWidth, setSidebarWidth] = useState(initialSidebarWidth);
  const [sidebarSpacing, setSidebarSpacing] = useState(defaultSpacing);
  const [theme, setTheme] = useState(Themes.LIGHT);
  const { countNewRequest } = useRequests();

  useEffect(() => {
    dispatch({
      type: platformNotificationsActions.GET_PLATFORM_NOTIFICATIONS,
      data: {
        params: {
          limit: 10,
          offset: 0,
        },
      },
    });
    dispatch(getTenantsAction());
    countNewRequest();
  }, []);

  useEffect(() => {
    switcher({
      theme,
    });
  }, [theme]);

  useEffect(() => {
    // sizes for expanded/collapsed sidebar
    expanded ? setSidebarWidth(initialSidebarWidth) : setSidebarWidth(70);
    expanded ? setSidebarSpacing(defaultSpacing) : setSidebarSpacing(5);
  }, [expanded]);

  const toggleSidebar = () => {
    setExpanded(!expanded);
  };
  const computedWidth = sidebarWidth + sidebarSpacing * 2 + rightSidebarPanel;
  return (
    <Box
      sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}
      data-testid="homePage"
    >
      <Sidebar
        expanded={expanded}
        sidebarWidth={sidebarWidth}
        rightSidebarPanel={rightSidebarPanel}
        defaultSpacing={sidebarSpacing}
        toggleSidebar={toggleSidebar}
      />
      <Box
        component="main"
        sx={{
          width: `calc(100% - ${computedWidth}px)`,
          transition: ".1s ease",
        }}
      >
        <StyledDiv>
          <Header />
          <Box sx={{ padding: "0 2% 20px" }}>
            <Outlet />
          </Box>
          <Alerts />
          <DeleteModal />
        </StyledDiv>
      </Box>
    </Box>
  );
};

export default Home;
