interface InfoDataProps {
  leftCol: Array<InfoProps>;
  rightCol: Array<InfoProps>;
}
export interface InfoProps {
  label: string;
  id: string;
}
export const infoData: InfoDataProps = {
  leftCol: [
    {
      label: "Distribution name",
      id: "name",
    },
    {
      label: "Distribution id",
      id: "id",
    },
    {
      label: "Distribution type",
      id: "distributionType",
    },
    {
      label: "Channel",
      id: "channel",
    },
    {
      label: "Is Public",
      id: "isPublic",
    },
    {
      label: "Products",
      id: "productIds",
    },
  ],
  rightCol: [
    {
      label: "Tenant",
      id: "tenant",
    },
    {
      label: "Provider",
      id: "provider",
    },
    {
      label: "Location",
      id: "location",
    },
    {
      label: "Allow Domains",
      id: "allowedDomains",
    },
    {
      label: "Scopes",
      id: "scopes",
    },
  ],
};
