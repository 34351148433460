import { call, put, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";

import { Toaster } from "shared/ui/Toast/Toast";
import FilesApi from "shared/api/files/filesApi";
import { requestsActions } from "entities/requests";
import { FileDetails } from "shared/api/files/types";
import { sagaErrorBoundary } from "shared/utils/sagaErrorBoundary";

import { uploadFilesAction, getFileAction } from "../actions";
import { setFileUploading, setFile } from "../slice/filesSlice";

export function* uploadFilesSaga(action: ReturnType<typeof uploadFilesAction>) {
  const { ReferenceId, ReferenceType, files } = action.payload;
  yield put(setFileUploading(true));

  for (let index = 0; index < files.length; index++) {
    const file = files[index];
    try {
      yield call(FilesApi.uploadFile, ReferenceId, ReferenceType, file);
      if (index === files.length - 1) {
        if (ReferenceType === "Request") {
          yield put({
            type: requestsActions.GET_REQUEST,
            data: ReferenceId,
          });
        }
        yield put(setFileUploading(false));
        Toaster.infoMessage("Files uploaded Successfully");
      }
    } catch (e) {
      sagaErrorBoundary(e);
    }
  }
}

export function* retrieveFileSaga(action: ReturnType<typeof getFileAction>) {
  const { id } = action.payload;
  try {
    const response: AxiosResponse<FileDetails> = yield call(
      FilesApi.retrieveFile,
      id
    );
    yield put(setFile(response.data));
    if (response.data && action.payload.callback) {
      action.payload.callback(response.data);
    }
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* FilesWatcher() {
  yield takeLatest(uploadFilesAction, uploadFilesSaga);
  yield takeLatest(getFileAction, retrieveFileSaga);
}
