import React, { useState } from "react";
import {
  ButtonsWrap,
  ControlsWrap,
  NoItemsWrap,
  OptionsWrap,
  StyledButton,
  StyledCheckbox,
} from "./styles";

const tableKeyToFilterKey: { [key: string]: string } = {
  status: "status",
  requestStatus: "status",
};

const MultiSelect: React.FC<any> = ({
  filterKey,
  options,
  handleClose,
  filterParams,
  setFilterParams,
  setApplied,
}) => {
  const [checked, setChecked] = useState<any[]>([]);

  const handleCFilter = (value: string) => {
    const values = [...checked];
    if (checked.indexOf(value) === -1) {
      values.push(value);
    } else {
      values.splice(values.indexOf(value), 1);
    }
    setChecked(values);
  };

  const applyFilters = () => {
    handleClose();
    setFilterParams({
      ...filterParams,
      [tableKeyToFilterKey[filterKey]]: checked,
    });
    if (checked.length) {
      setApplied(true);
    }
  };

  const clearFilters = () => {
    handleClose();
    setChecked([]);
    setFilterParams({
      ...filterParams,
      [tableKeyToFilterKey[filterKey]]: [],
    });
    setApplied(false);
  };

  return (
    <>
      <OptionsWrap>
        <ButtonsWrap>
          <StyledButton
            onClick={() => {
              if (
                checked.length === 0 ||
                (checked.length >= 1 && checked.length !== options.length)
              ) {
                setChecked(options);
              } else {
                setChecked([]);
              }
            }}
            data-testid="multiSelect_AllButton"
          >
            <StyledCheckbox checked={checked.length === options.length} />
            <p>All</p>
          </StyledButton>
          {options?.length ? (
            options.map((option: any, key: number) => {
              return (
                <StyledButton
                  key={`${option}_${key}`}
                  onClick={() => handleCFilter(option)}
                  data-testid={`multiSelect_${option}Button`}
                >
                  <StyledCheckbox checked={checked.indexOf(option) !== -1} />
                  <p>{option}</p>
                </StyledButton>
              );
            })
          ) : (
            <NoItemsWrap data-testid="noItemsFound">No items found</NoItemsWrap>
          )}
        </ButtonsWrap>
      </OptionsWrap>
      {options?.length ? (
        <ControlsWrap>
          <button
            onClick={() => clearFilters()}
            data-testid="multiSelect_clearAll"
          >
            Clear All
          </button>
          <button
            onClick={() => applyFilters()}
            data-testid="multiSelect_apply"
          >
            Apply
          </button>
        </ControlsWrap>
      ) : (
        <></>
      )}
    </>
  );
};

export default MultiSelect;
