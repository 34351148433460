import { createAction } from "@reduxjs/toolkit";
export interface TenantActionPayload {
  data: {
    companyName: string;
    logo?: string;
    currency: string;
    language: string;
    companyType: string;
    countryOfBusiness: string;
  };
  imageLogo?: File;
}
export const getTenantsAction = createAction("tenant/get/all");

export const createTenantAction =
  createAction<TenantActionPayload>("tenant/create");

export const getTenantAction = createAction<{
  tenantId: string;
}>("tenant/get");

export const updateTenantAction = createAction<
  TenantActionPayload & { tenantId: string }
>("tenant/update");
