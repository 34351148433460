import { PlatformNotificationsRespose } from "./types";
import { IXAdminApiPrivate } from "api/IXAdminApi";
import { AxiosResponse } from "axios";

export default class PlatformNotificationsApi {
  static async getPlatformNotifications(
    params: any
  ): Promise<AxiosResponse<PlatformNotificationsRespose>> {
    return IXAdminApiPrivate.get("/api/PlatformNotifications", params);
  }

  static async getPlatformNotification(
    id: string
  ): Promise<AxiosResponse<PlatformNotificationsRespose>> {
    return IXAdminApiPrivate.get(`/api/PlatformNotifications/${id}`);
  }

  static async deletePlatformNotification(
    id: string
  ): Promise<AxiosResponse<PlatformNotificationsRespose>> {
    return IXAdminApiPrivate.delete(`/api/PlatformNotifications/${id}`);
  }

  static async createPlatformNotification(
    params: any
  ): Promise<AxiosResponse<PlatformNotificationsRespose>> {
    return IXAdminApiPrivate.post("/api/PlatformNotifications", params);
  }
}
