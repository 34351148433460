export const tenantsActions = {
  GET_TENANTS: "GET_TENANTS",
  GET_TENANTS_FAILURE: "GET_TENANTS_FAILURE",
  GET_TENANT_FAILURE: "GET_TENANT_FAILURE",
  CREATE_TENANT: "CREATE_TENANT",
  CREATE_TENANT_SUCCESS: "CREATE_TENANT_SUCCESS",
  CREATE_TENANT_FAILURE: "CREATE_TENANT_FAILURE",
  DELETE_TENANT: "DELETE_TENANT",
  DELETE_TENANT_SUCCESS: "DELETE_TENANT_SUCCESS",
  DELETE_TENANT_FAILURE: "DELETE_TENANT_FAILURE",
  EDIT_TENANT: "EDIT_TENANT",
  EDIT_TENANT_SUCCESS: "EDIT_TENANT_SUCCESS",
  EDIT_TENANT_FAILURE: "EDIT_TENANT_FAILURE",
  RESTORE_TENANT: "RESTORE_TENANT",
  RESTORE_TENANT_SUCCESS: "RESTORE_TENANT_SUCCESS",
  RESTORE_TENANT_FAILURE: "RESTORE_TENANT_FAILURE",
};
