import { toast, Slide, Theme, ToastOptions } from "react-toastify";
import { ActionButtonTypes } from "../../ui/ActionButton/types";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { ReactNode } from "react";

export const ICONS: Partial<Record<ActionButtonTypes, ReactNode>> = {
  [ActionButtonTypes.DELETE]: <DeleteIcon />,
};

export type ToasterOptions = {
  [k in keyof ToastOptions]?: ToastOptions[k];
} & {
  iconAction?: ActionButtonTypes;
};

const TOASTER_OPTIONS = {
  theme: "colored" as Theme,
  position: toast.POSITION.BOTTOM_LEFT,
  autoClose: 3000,
  transition: Slide,
};

const TOASTER_TOP_OPTIONS = {
  theme: "colored" as Theme,
  position: toast.POSITION.TOP_CENTER,
  transition: Slide,
  autoClose: 5000,
  hideProgressBar: true,
};

export { TOASTER_OPTIONS, TOASTER_TOP_OPTIONS };
