import { useDispatch } from "react-redux";
import { useCallback } from "react";
import { requestsActions } from "entities/requests";
import { CommentSchema, RequestSchema } from "shared/api/requests/types";
import { PaginationParams } from "api/types";

export const useRequests = () => {
  const dispatch = useDispatch();

  const getRequests = useCallback(
    (pagingParams: PaginationParams) => {
      const requestParams: any = {
        ShowArchived: false,
        limit: pagingParams.limit,
        offset: pagingParams.offset,
        SearchExpression: pagingParams.SearchExpression,
        filter: pagingParams.filter,
      };
      if (pagingParams.order) {
        requestParams["SortingPreferences.SortingProperty"] = "RequestTypeName";
        requestParams["SortingPreferences.Kind"] =
          pagingParams.order === "asc" ? "Ascending" : "Descending";
      }
      dispatch({
        type: requestsActions.GET_REQUESTS,
        data: { params: requestParams },
      });
    },
    [dispatch]
  );

  const getCompletedRequests = useCallback(
    (pagingParams: PaginationParams) => {
      const requestParams: any = {
        ShowArchived: false,
        limit: pagingParams.limit,
        offset: pagingParams.offset,
        SearchExpression: pagingParams.SearchExpression,
        filter: pagingParams.filter,
      };
      if (pagingParams.order) {
        requestParams["SortingPreferences.SortingProperty"] = "RequestTypeName";
        requestParams["SortingPreferences.Kind"] =
          pagingParams.order === "asc" ? "Ascending" : "Descending";
      }
      dispatch({
        type: requestsActions.GET_COMPLETED,
        data: { params: requestParams },
      });
    },
    [dispatch]
  );

  const getArchivedRequests = useCallback(
    (pagingParams: PaginationParams) => {
      const requestParams: any = {
        ShowArchived: true,
        limit: pagingParams.limit,
        offset: pagingParams.offset,
        SearchExpression: pagingParams.SearchExpression,
        filter: pagingParams.filter,
      };
      if (pagingParams.order) {
        requestParams["SortingPreferences.SortingProperty"] = "RequestTypeName";
        requestParams["SortingPreferences.Kind"] =
          pagingParams.order === "asc" ? "Ascending" : "Descending";
      }
      dispatch({
        type: requestsActions.GET_ARCHIVED,
        data: { params: requestParams },
      });
    },
    [dispatch]
  );

  const getRequestTypes = useCallback(() => {
    dispatch({ type: requestsActions.GET_TYPES });
  }, [dispatch]);

  const getRequest = useCallback(
    (id: number) => {
      dispatch({ type: requestsActions.GET_REQUEST, data: id });
    },
    [dispatch]
  );

  const countNewRequest = useCallback(() => {
    dispatch({ type: requestsActions.COUNT_NEW_REQUESTS });
  }, [dispatch]);

  const countActiveRequest = useCallback(() => {
    dispatch({ type: requestsActions.COUNT_ACTIVE_REQUESTS });
  }, [dispatch]);

  const countCompletedRequest = useCallback(() => {
    dispatch({ type: requestsActions.COUNT_COMPLETED_REQUESTS });
  }, [dispatch]);

  const countArchivedRequest = useCallback(() => {
    dispatch({ type: requestsActions.COUNT_ARCHIVED_REQUESTS });
  }, [dispatch]);

  const updateStatus = useCallback(
    (params: any, callback?: (data: any) => void) => {
      dispatch({ type: requestsActions.UPDATE_STATUS, data: params, callback });
    },
    [dispatch]
  );

  const deleteRequest = useCallback(
    (data: RequestSchema) => {
      dispatch({ type: requestsActions.DELETE_REQUEST, data });
    },
    [dispatch]
  );

  const restoreRequest = useCallback(
    (id: number, callback?: (data: any) => void) => {
      dispatch({ type: requestsActions.RESTORE_REQUEST, data: id, callback });
    },
    [dispatch]
  );

  const createRequest = useCallback(
    (params: any, callback: (data: any) => void) => {
      dispatch({
        type: requestsActions.CREATE_REQUEST,
        data: params,
        callback,
      });
    },
    [dispatch]
  );

  const getRequestComments = useCallback(
    (id: number) => {
      dispatch({ type: requestsActions.GET_COMMENTS, data: id });
    },
    [dispatch]
  );

  const addRequestComment = useCallback(
    (params: any, callback: (data: any) => void) => {
      dispatch({ type: requestsActions.ADD_COMMENT, data: params, callback });
    },
    [dispatch]
  );

  const orderedComments = (comments: CommentSchema[]) => {
    const sorted = [...comments].sort((a: CommentSchema, b: CommentSchema) => {
      const itemA = a.createdDateUtc;
      const itemB = b.createdDateUtc;

      return new Date(itemA).valueOf() - new Date(itemB).valueOf();
    });
    return sorted;
  };

  return {
    getRequestTypes,
    createRequest,
    getRequests,
    getRequest,
    getRequestComments,
    addRequestComment,
    orderedComments,
    deleteRequest,
    restoreRequest,
    updateStatus,
    countNewRequest,
    countActiveRequest,
    getCompletedRequests,
    countCompletedRequest,
    countArchivedRequest,
    getArchivedRequests,
  };
};
