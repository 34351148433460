import React, { KeyboardEvent } from "react";
import { StyledInput } from "./styles";
import Label from "../Label";

interface InputProps {
  value: string;
  id: string;
  label?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  error?: boolean;
  errorText?: string;
  disabled?: boolean;
  placeholder?: string;
  type?: string;
  danger?: boolean;
  InputProps?: any;
  maxRows?: number;
  multiline?: boolean;
  endAdornment?: any;
}

const FormGroup = ({
  placeholder,
  value,
  id,
  onChange,
  error,
  errorText,
  label,
  danger,
  disabled,
  type,
  InputProps,
  onKeyDown,
  ...props
}: InputProps) => {
  return (
    <>
      {label && <Label label={label} danger={danger} disabled={disabled} />}
      <StyledInput
        {...props}
        fullWidth
        type={type ?? "text"}
        variant="outlined"
        placeholder={placeholder}
        value={value}
        id={id}
        onChange={onChange}
        error={error}
        disabled={disabled}
        helperText={error && (errorText ?? "This field is required.")}
        InputProps={InputProps}
        onKeyDown={onKeyDown}
      />
    </>
  );
};

export default FormGroup;
