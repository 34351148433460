import styled from "styled-components";
import { Button } from "@mui/material";

export const StyledControlHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
`;

export const StyledRightButton = styled(Button)`
  &.MuiButton-root {
    background-color: ${(props) => `var(--${props.color})`};
    border-radius: 100px;
    padding: 16px 32px;
    text-transform: none;
    color: var(--white);
    margin-left: 30px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 2px;
    :hover {
      background-color: ${(props) => `var(--${props.color})`};
    }
  }
`;

export const Row = styled.div`
  display: flex;
  width: calc(100% - 384px);
  justify-content: flex-end;
  .MuiInputBase-root {
    width: 384px;
  }
`;
