import React, { useState } from "react";

// components
import { Menu, Divider } from "@mui/material";
import SearchMultiSelect from "../../FilterModal/SearchMultiSelect";
import FilterButton from "./FilterButton";
// styles
import { ButtonWrap, MenuWrapper, SortLabel } from "./styles";
// icons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// types
import { Column, Order, TenantsRows } from "../types";

interface Props {
  headCell: Column;
  rows: TenantsRows;
  onChange: (filterValue: any) => void;
}

interface OrderTypes {
  type: Order;
  label?: string;
}

enum Orders {
  ASC = "asc",
  DESC = "desc",
}

const sortOrders: OrderTypes[] = [
  {
    type: Orders.ASC,
  },
  {
    type: Orders.DESC,
  },
];

const FilterModal: React.FC<Props> = ({ headCell, rows, onChange }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [order, setOrder] = useState<OrderTypes | null>(null);
  const open = Boolean(anchorEl);

  const handleChange = (sortOrder: OrderTypes | null) => {
    setOrder(sortOrder);
    onChange({
      sortOrder: sortOrder?.type,
    });
  };

  const handleSearch = (searchValue: any) => {
    onChange({
      searchValue,
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div data-testid="tenantFilter">
      <SortLabel>
        Sort by:
        <ButtonWrap
          key={headCell.id}
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          <span>
            Alphabetically{" "}
            {order && order.type === Orders.DESC ? <>(z-a)</> : <>(a-z)</>}
          </span>
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </ButtonWrap>
      </SortLabel>
      <Menu
        keepMounted={true}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        data-testid="tenantFilterMenu"
      >
        <MenuWrapper>
          {sortOrders.map((item: OrderTypes) => (
            <FilterButton
              key={`${item.type}_${item.label}`}
              type={item.type}
              label={item.label}
              order={order}
              handleChange={handleChange}
            />
          ))}
          <Divider sx={{ marginBottom: "15px" }} />
          <SearchMultiSelect
            headCell={headCell}
            rows={rows}
            id="tenantsSearch"
            handleClose={handleClose}
            onChange={handleSearch}
          />
        </MenuWrapper>
      </Menu>
    </div>
  );
};

export default FilterModal;
