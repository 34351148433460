import React, { useState, useEffect } from "react";
import {
  subDays,
  previousMonday,
  previousSunday,
  isMonday,
  format,
  startOfMonth,
  endOfMonth,
  subMonths,
} from "date-fns";
import "react-date-range/dist/styles.css"; // main style file
import { Range, StaticRange } from "react-date-range";
import {
  StyledDateRangePicker,
  StyledWrap,
  StyledButtonsWrap,
  StyledContainer,
  StyledButton,
} from "./styles";

const CustomDateRangePicker: React.FC<{ onCancel: () => void }> = ({
  onCancel,
}) => {
  const [activeRangeLabel, setActiveRangeLabel] = useState<string>("");
  const [ranges, setRanges] = useState<Range[]>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  useEffect(() => {
    const validRanges: string[] = [];
    inputRanges.forEach((inputRange) => {
      if (checkEqualRanges(inputRange.range(), inputRange.label as string)) {
        validRanges.push(inputRange.label as string);
      }
    });
    if (validRanges.length === 0) {
      setActiveRangeLabel("Custom Range");
    }
  }, [ranges]);

  const checkEqualRanges = (staticRange: Range, label: string) => {
    const formatString = "dd-MM-yyyy";
    const currentStartDate = format(ranges[0].startDate as Date, formatString);
    const currentEndDate = format(ranges[0].endDate as Date, formatString);
    const startDate = format(staticRange.startDate as Date, formatString);
    const endDate = format(staticRange.endDate as Date, formatString);
    const equalDates =
      startDate === currentStartDate && endDate === currentEndDate;
    if (equalDates) {
      setActiveRangeLabel(label);
    }
    return equalDates;
  };

  const handleSelectCustom = () => {
    setActiveRangeLabel("");
  };

  const renderCustomRangeLabel = () => {
    return <StyledWrap onClick={handleSelectCustom}>Custom Range</StyledWrap>;
  };

  const dateNow = new Date();
  const isMondayDay = isMonday(dateNow);
  const prevMonday = previousMonday(dateNow);
  const prevSunday = previousSunday(dateNow);
  const mondayBeforePrev = previousMonday(previousMonday(dateNow));
  const inputRanges: StaticRange[] = [
    {
      label: "Today",
      range: () => ({
        startDate: dateNow,
        endDate: dateNow,
      }),
      isSelected() {
        return activeRangeLabel === "Today";
      },
    },
    {
      label: "Yesterday",
      range: () => ({
        startDate: subDays(dateNow, 1),
        endDate: subDays(dateNow, 1),
      }),
      isSelected() {
        return activeRangeLabel === "Yesterday";
      },
    },
    {
      label: "Last 7 Days",
      range: () => ({
        startDate: subDays(dateNow, 6),
        endDate: dateNow,
      }),
      isSelected() {
        return activeRangeLabel === "Last 7 Days";
      },
    },
    {
      label: "Last Week",
      range: () => {
        return {
          startDate: isMondayDay ? prevMonday : mondayBeforePrev,
          endDate: prevSunday,
        };
      },
      isSelected() {
        return activeRangeLabel === "Last Week";
      },
    },
    {
      label: "Last 2 Weeks",
      range: () => ({
        startDate: isMondayDay
          ? mondayBeforePrev
          : previousMonday(mondayBeforePrev),
        endDate: prevSunday,
      }),
      isSelected() {
        return activeRangeLabel === "Last 2 Weeks";
      },
    },
    {
      label: "This Month",
      range: () => ({
        startDate: startOfMonth(dateNow),
        endDate: endOfMonth(dateNow),
      }),
      isSelected() {
        return activeRangeLabel === "This Month";
      },
    },
    {
      label: "Last Month",
      range: () => {
        const lastMonth = subMonths(dateNow, 1);
        return {
          startDate: startOfMonth(lastMonth),
          endDate: endOfMonth(lastMonth),
        };
      },
      isSelected() {
        return activeRangeLabel === "Last Month";
      },
    },
    {
      label: "Custom Range",
      hasCustomRendering: true,
      range: () => {
        return {
          startDate: dateNow,
          endDate: subDays(dateNow, 2),
        };
      },
      isSelected() {
        return activeRangeLabel === "Custom Range";
      },
    },
  ];

  return (
    <StyledContainer data-testid="dateRangePicker_container">
      <StyledDateRangePicker
        showMonthAndYearPickers={false}
        months={2}
        retainEndDateOnFirstSelection={false}
        onChange={(item) => setRanges([item.selection])}
        ranges={ranges}
        preventSnapRefocus={true}
        staticRanges={inputRanges}
        moveRangeOnFirstSelection={false}
        weekStartsOn={1}
        renderStaticRangeLabel={renderCustomRangeLabel}
        direction="horizontal"
        data-testid="dateRangePicker"
      />
      <StyledButtonsWrap>
        <StyledButton
          color={"secondary"}
          variant={"contained"}
          onClick={() => onCancel()}
          data-testid="dateRangePicker_applyButton"
        >
          Apply
        </StyledButton>
        <StyledButton
          color={"primary"}
          variant={"outlined"}
          onClick={() => onCancel()}
          data-testid="dateRangePicker_cancelButton"
        >
          Cancel
        </StyledButton>
      </StyledButtonsWrap>
    </StyledContainer>
  );
};

export default CustomDateRangePicker;
