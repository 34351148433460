import React from "react";

import { WarningWrap, WarningIcon, WarningTextWrap } from "./styles";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const ModalNotification: React.FC<any> = ({ type, subjectName }) => {
  const createdTenant = (name: string) => (
    <span>
      New Tenant <b>{name}</b> has been created. Please create admin in order to
      finish tenant set up.
    </span>
  );
  const deleteTenant = () => (
    <span>
      Users and groups inside this Tenant will be automaticaly archived.
    </span>
  );
  const modalNotificationTypes: any = {
    createdTenant: {
      text: createdTenant,
      icon: <ErrorOutlineIcon />,
    },
    deleteTenant: {
      text: deleteTenant,
      icon: <ErrorOutlineIcon />,
    },
  };

  return (
    <WarningWrap type={type} data-testid="modalWarning">
      <WarningIcon>{modalNotificationTypes[type].icon}</WarningIcon>
      <WarningTextWrap>
        {subjectName
          ? modalNotificationTypes[type].text(subjectName)
          : modalNotificationTypes[type].text()}
      </WarningTextWrap>
    </WarningWrap>
  );
};

export default ModalNotification;
