import styled from "styled-components";
import { TextField } from "@mui/material";

export const StyledInput = styled(TextField)`
  .MuiInputBase-inputMultiline {
    padding: 0 !important;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  .MuiOutlinedInput-root {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--black);
    border: 1px solid var(--primary);
    border-radius: 4px;
    outline: none;
    &.Mui-error {
      background: var(--lightred);
      border: 1px solid var(--darkred);
      color: var(--headings);
      input::placeholder {
        color: var(--darkred);
        opacity: 1;
      }
    }
    + .MuiFormHelperText-root {
      position: absolute;
      left: 0;
      bottom: -27px;
      margin: 0;
    }
    &.Mui-focused {
      border: 1px solid var(--secondary);
      box-shadow: 0px 0px 10px rgba(98, 102, 234, 0.5);
      outline: none;
    }
    &.Mui-disabled {
      background: var(--lightsilver);
      border-color: var(--lightsilver);
      color: var(--black);
      .Mui-disabled {
        -webkit-text-fill-color: var(--black);
      }
    }
  }
  .MuiOutlinedInput-notchedOutline {
    outline: none;
    border: none;
  }
  .MuiInputBase-input {
    padding: 14.5px 14px;
  }

  [data-testid="LocationOnOutlinedIcon"] {
    color: var(--secondary);
  }
  .MuiInputBase-adornedStart {
    .MuiInputBase-input {
      padding-left: 5px;
    }
  }
`;
