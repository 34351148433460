import React, { ReactElement, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { countNew } from "entities/requests";
import { useAppSelector } from "redux/store/hooks/hooks";

import {
  StyledButton,
  TabButton,
  TabButtonWrap,
  TabPanelWrap,
  TabsHeader,
} from "./styles";

import { TabNames } from "pages/types";

// icons
import { ReactComponent as AddTenantIcon } from "assets/icons/addTenant.svg";

const Tabs: React.FC<{
  children: ReactElement;
  tabList: any[];
  setActiveTab: (tab: TabNames) => void;
  handleOpen: () => void;
  activeTab: string;
  basePath: string;
  showCreateButton: boolean;
}> = ({
  children,
  setActiveTab,
  handleOpen,
  tabList,
  activeTab,
  basePath,
  showCreateButton,
}) => {
  const navigate = useNavigate();
  const [newCount, setNewCount] = useState(0);
  const count = useAppSelector(countNew);

  useEffect(() => {
    if (newCount == 0 && count) {
      setNewCount(count);
    }
    if (newCount !== count) {
      setNewCount(count);
    }
  }, [count]);

  const handleActiveTab = (tab: any) => {
    setActiveTab(tab.label);
    navigate(`${basePath}/${tab.link}`);
  };

  const tabNewLabel = (label: string) => {
    if (label !== "New") {
      return label;
    } else if (label === "New" && newCount == 0) {
      return label;
    } else if (label === "New" && newCount !== 0) {
      return `New (${newCount})`;
    }
  };

  const tabs = tabList?.map((tab: any) => (
    <TabButton
      isActive={tab.label === activeTab}
      key={tab.label}
      onClick={() => handleActiveTab(tab)}
    >
      {tabNewLabel(tab.label)}
    </TabButton>
  ));

  return (
    <div>
      <TabsHeader>
        <TabButtonWrap>{tabs}</TabButtonWrap>
        <div>
          {activeTab != "Archive" && showCreateButton && (
            <StyledButton
              color="secondary"
              startIcon={<AddTenantIcon />}
              onClick={() => handleOpen()}
              data-testid="createTenantButton"
            >
              Create {activeTab}
            </StyledButton>
          )}
        </div>
      </TabsHeader>
      <TabPanelWrap>{children}</TabPanelWrap>
    </div>
  );
};

export default Tabs;
