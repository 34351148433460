export enum RoutesList {
  HOME = "/",
  TENANT_MANAGEMENT = "/tenant-management",
  PLATFORM_MEMBERS = "/platform-members",
  PRODUCT_CONFIGURATOR = "/product-configurator",
  REQUESTS = "/requests",
  NOTIFICATIONS = "/notifications",
  ANALYTICS = "/analytics",

  MODULE_TEMPLATES = "module-templates",
  WIDGETS = "widgets",
  CATEGORIES = "categories",
  ATTRIBUTES = "attributes",
  ARCHIVE = "archive",
  NEW = "new",
  COMPLETED = "completed",
  TENANTS = "tenants",
  DISTRIBUTIONS = "distributions",
  WIDGET = "widget",
}
