import styled from "styled-components";
import { Alert, Button } from "@mui/material";
import { AlertColor } from "@mui/material/Alert/Alert";

const handleColorSeverity = (severity: string) => {
  switch (severity) {
    case "error":
      return { background: "var(--mediumred)" };
    case "success":
      return { background: "var(--secondary)" };
    default:
      return { background: "var(--secondary)" };
  }
};

export const StyledAlert = styled(Alert)<{ severity: AlertColor }>`
  &.MuiAlert-root {
    background: ${({ severity }) => handleColorSeverity(severity).background};
    box-shadow: 0px 1px 18px rgb(0 0 0 / 12%), 0px 6px 10px rgb(0 0 0 / 14%),
      0px 3px 5px rgb(0 0 0 / 20%);
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--white);
    padding: 11px 8px 11px 16px;
    margin-top: 10px;
    .MuiAlert-icon {
      color: var(--white);
      display: flex;
      font-size: 20px;
    }
    .MuiAlert-message {
      display: flex;
      width: 100%;
      justify-content: space-between;
      letter-spacing: initial;
      padding: 0;
      align-items: center;
    }
  }
`;
export const StyledButton = styled(Button)`
  &.MuiButton-root {
    padding: 0;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    color: var(--white);
    margin-left: 12px;
  }
`;
export const Container = styled.div`
  position: absolute;
  top: 0;
  max-width: 602px;
  left: 50%;
  z-index: 1400;
  transform: translateX(-50%);
`;
