import { createSlice } from "@reduxjs/toolkit";

export const moduleTemplatesSlice = createSlice({
  name: "moduleTemplates",
  initialState: {
    totalCount: 0,
    items: [],
  },
  reducers: {
    getModuleTemplatesSlice: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { getModuleTemplatesSlice } = moduleTemplatesSlice.actions;
