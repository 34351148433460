import React, { useState, useEffect } from "react";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import OutlinedCheckbox from "../../FormComponents/OutlinedCheckbox";
import { AttributesData } from "api/types";
import {
  StyledTableRow,
  StyledTableCell,
  ExpandTitle,
  StyledCollapse,
  PurpleBlock,
  StyledButton,
  StyledConnectIcon,
  FilterIcon,
} from "./styles";
import {
  Menu,
  MenuItem,
  Paper,
  TableHead,
  IconButton,
  Table,
  TableBody,
  TableContainer,
} from "@mui/material";

interface GroupedAttributesData extends AttributesData {
  groupName: string;
  parentId: string;
}

interface CollapseProps {
  open: boolean;
  row: any;
  handleAllItemSelect: (group: string, checked: boolean) => void;
  showDetail: boolean;
  reset: boolean;
  indexKey: number;
  filterByNames?: (filter: string) => void;
}

const CollapsedItems = ({
  open,
  row,
  handleAllItemSelect,
  showDetail,
  reset,
  indexKey,
  filterByNames,
}: CollapseProps) => {
  const [openning, setOpenning] = React.useState(false);
  const [selected, setSelected] = useState(
    Array<GroupedAttributesData | string>
  );
  const [selectedGroup, setSelectedGroup] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [filters, setFilters] = useState("");

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const sortByName = (order: string) => {
    handleFilterClose();
    setFilters(order);
  };

  useEffect(() => {
    if (filters && filterByNames) {
      filterByNames(filters);
    }
  }, [filters]);

  useEffect(() => {
    if (open) {
      setOpenning(true);
    } else {
      setOpenning(false);
    }
  }, [open]);

  useEffect(() => {
    if (reset) {
      setSelected([]);
      setSelectedGroup([]);
      setFilters("");
    }
  }, [reset]);

  const handleSelectAllClick = (group: string) => {
    const selectedGroupRows = [...selectedGroup];
    if (isSelectedGroup(group)) {
      const elementIndex = selectedGroupRows.indexOf(group);
      selectedGroupRows.splice(elementIndex, 1);
      setSelectedGroup(selectedGroupRows);
      handleAllItemSelect(group, false);

      const selectedRows = [...selected];

      for (const item of row.items) {
        if (isSelected(item.id)) {
          const elementIndex = selectedRows.indexOf(item.id);
          selectedRows.splice(elementIndex, 1);
        }
      }
      setSelected([...selectedRows]);
    } else {
      setSelectedGroup([...selectedGroupRows, group]);
      handleAllItemSelect(group, true);
      const selectedRows = [...selected];
      for (const item of row.items) {
        if (!isSelected(item.id)) {
          selectedRows.push(item.id);
        }
      }
      setSelected([...selectedRows]);
    }
  };

  const isSelected = (id: string) => {
    return selected.some((x: any) => x.id === id);
  };

  const isSelectedGroup = (group: string) => {
    return selectedGroup.some((x) => x === group);
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: "none",
        marginBottom: "7px",
      }}
    >
      <Table
        aria-label="collapsible table"
        sx={{ backgroundColor: "var(--ghost)" }}
      >
        {showDetail && indexKey == 0 && (
          <TableHead>
            <StyledTableRow className="transparent header">
              <StyledTableCell padding="checkbox" />
              <StyledTableCell className="header width grey filtered largeMinWidth">
                Attribute Name
                <IconButton
                  aria-controls="filter-menu"
                  aria-haspopup="true"
                  onClick={handleFilterClick}
                  aria-label="search by name"
                  title="search by name"
                >
                  <FilterIcon />
                </IconButton>
                <Menu
                  id="filter-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleFilterClose}
                >
                  <MenuItem onClick={() => sortByName("asc")}>
                    Sort A-Z
                  </MenuItem>
                  <MenuItem onClick={() => sortByName("desc")}>
                    Sort Z-A
                  </MenuItem>
                </Menu>
              </StyledTableCell>
              <StyledTableCell className="header width grey" align="right">
                Display Name
              </StyledTableCell>
              <StyledTableCell
                className="header grey"
                align="right"
                sx={{ width: "10%" }}
              >
                Required
              </StyledTableCell>
              <StyledTableCell
                className="header right width grey"
                align="right"
              >
                Description
              </StyledTableCell>
              <StyledTableCell className="right" sx={{ width: "25%" }}>
                <StyledButton
                  className="expand"
                  variant="outlined"
                  startIcon={<UnfoldMoreIcon />}
                  onClick={() => setOpenning(!openning)}
                >
                  Expand All
                </StyledButton>
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
        )}
        <TableBody>
          {showDetail && indexKey == 0 && (
            <StyledTableRow className="transparent">
              <StyledTableCell>
                <PurpleBlock />
              </StyledTableCell>
              <StyledTableCell>
                <PurpleBlock />
              </StyledTableCell>
              <StyledTableCell>
                <PurpleBlock />
              </StyledTableCell>
              <StyledTableCell>
                <PurpleBlock />
              </StyledTableCell>
              <StyledTableCell>
                <PurpleBlock />
              </StyledTableCell>
              <StyledTableCell>
                <PurpleBlock />
              </StyledTableCell>
            </StyledTableRow>
          )}
          <StyledTableRow
            sx={{
              "& > *": { borderBottom: "unset" },
              backgroundColor: showDetail
                ? "var(--lightPurple) !important"
                : "var(--ghost) !important",
            }}
          >
            <StyledTableCell
              padding="checkbox"
              sx={{
                display: "flex",
                textTransform: "uppercase",
              }}
            >
              <OutlinedCheckbox
                checked={isSelectedGroup(row.groupBy)}
                onChange={() => handleSelectAllClick(row.groupBy)}
              />
              <ExpandTitle>{row.groupBy}</ExpandTitle>
            </StyledTableCell>
            <StyledTableCell />
            <StyledTableCell />
            <StyledTableCell />
            <StyledTableCell />
            <StyledTableCell className="right">
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpenning(!openning)}
              >
                {openning ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
      <StyledCollapse in={openning} timeout="auto" unmountOnExit>
        <Table
          size="small"
          aria-label="attributes"
          sx={{
            borderCollapse: showDetail ? "separate" : "none",
            borderSpacing: "0px 10px",
          }}
        >
          <TableBody>
            {row.items.map((item: GroupedAttributesData) => (
              <StyledTableRow key={item.id}>
                <StyledTableCell padding="checkbox" className="noWidth" />
                {showDetail && (
                  <StyledTableCell padding="checkbox" className="noWidth">
                    <StyledConnectIcon />
                  </StyledTableCell>
                )}
                <StyledTableCell
                  className="collapsePadding width"
                  sx={{ width: showDetail ? "20%" : "0%" }}
                >
                  {item.attributeName}
                </StyledTableCell>
                {showDetail && (
                  <>
                    <StyledTableCell className="largePadding width">
                      {item.displayName}
                    </StyledTableCell>
                    <StyledTableCell className="largePadding width">
                      {item.isRequired ? "Yes" : "No"}
                    </StyledTableCell>
                    <StyledTableCell
                      className="largePadding"
                      sx={{ width: "34%" }}
                    >
                      {item.attributeDescription}
                    </StyledTableCell>
                  </>
                )}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </StyledCollapse>
    </TableContainer>
  );
};

export default CollapsedItems;
