import React, { useEffect } from "react";

import { ToastContainer } from "react-toastify";
import { Route, Routes } from "react-router-dom";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { useDispatch, useSelector } from "react-redux";
import { loginRequest } from "./authConfig";
import { authActions } from "redux/actions/auth";

import "react-toastify/dist/ReactToastify.css";
import Home from "pages/Home";
import TenantManagement from "pages/TenantManagement";
import ProductConfigurator from "pages/ProductConfigurator";
import Requests from "pages/Requests";
import NotificationsPage from "pages/NotificationsPage";
import Tenants from "components/Tenants";
import Attributes from "components/Attributes";
import Categories from "components/Categories";
import Archive from "components/Archive";
import RequestSection from "components/Requests";
import ModuleTemplates from "pages/ModuleTemplates";
import Distributions from "pages/Distributions";
import { RoutesList } from "shared/routes";
import WidgetsPage from "pages/WidgetsPage/WidgetsPage";
import DistributionDetails from "pages/DistributionDetails";
import WidgetDetails from "./pages/WidgetDetails/WidgetDetails";

const App = () => {
  const { instance, inProgress, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useDispatch();
  const isAuth = useSelector((state: any) => state.auth.accessToken);

  const handleLogin = (instance: any) => {
    instance.loginRedirect(loginRequest).catch((e: any) => {
      console.error(e);
    });
  };
  useEffect(() => {
    if (inProgress === InteractionStatus.None && !isAuthenticated) {
      handleLogin(instance);
    }
  }, [inProgress]);

  useEffect(() => {
    if (isAuthenticated) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      instance
        .acquireTokenSilent(request)
        .then((response) => {
          dispatch({ type: authActions.SIGN_IN, data: response });
        })
        .catch((e) => {
          console.log(e);
          handleLogin(instance);
        });
    }
  }, []);
  return (
    <div>
      <AuthenticatedTemplate>
        {isAuth && (
          <Routes>
            <Route path="*" element={<Home />} />
            <Route path={RoutesList.HOME} element={<Home />}>
              <Route path={RoutesList.HOME} element={"Dashboard"} />
              <Route
                path={RoutesList.TENANT_MANAGEMENT}
                element={<TenantManagement />}
              >
                <Route path={RoutesList.TENANTS} element={<Tenants />} />
                <Route
                  path={RoutesList.ARCHIVE}
                  element={<Tenants status="Archive" />}
                />
              </Route>
              <Route
                path={RoutesList.PLATFORM_MEMBERS}
                element={"Platform Members"}
              />
              <Route
                path={RoutesList.PRODUCT_CONFIGURATOR}
                element={<ProductConfigurator />}
              >
                <Route path={RoutesList.ATTRIBUTES} element={<Attributes />} />
                <Route path={RoutesList.CATEGORIES} element={<Categories />} />
                <Route path={RoutesList.ARCHIVE} element={<Archive />} />
              </Route>
              <Route
                path={`${RoutesList.PRODUCT_CONFIGURATOR}/${RoutesList.WIDGETS}`}
                element={<WidgetsPage />}
              />
              <Route
                path={`${RoutesList.PRODUCT_CONFIGURATOR}/${RoutesList.TENANTS}/:tenantId/${RoutesList.WIDGETS}/:id`}
                element={<WidgetDetails />}
              />
              <Route
                path={`${RoutesList.PRODUCT_CONFIGURATOR}/${RoutesList.MODULE_TEMPLATES}`}
                element={<ModuleTemplates />}
              />
              <Route
                path={`${RoutesList.PRODUCT_CONFIGURATOR}/${RoutesList.DISTRIBUTIONS}`}
                element={<Distributions />}
              />
              <Route
                path={`${RoutesList.PRODUCT_CONFIGURATOR}/${RoutesList.DISTRIBUTIONS}/:id`}
                element={<DistributionDetails />}
              />
              <Route
                path={`${RoutesList.PRODUCT_CONFIGURATOR}/${RoutesList.WIDGET}`}
                element={"widget"}
              />
              <Route path={RoutesList.REQUESTS} element={<Requests />}>
                <Route
                  path={RoutesList.NEW}
                  element={<RequestSection status="New" />}
                />
                <Route
                  path={RoutesList.COMPLETED}
                  element={<RequestSection status="Completed" />}
                />
                <Route
                  path={RoutesList.ARCHIVE}
                  element={<RequestSection status="Archived" />}
                />
              </Route>
              <Route
                path={RoutesList.NOTIFICATIONS}
                element={<NotificationsPage />}
              />
              <Route path={RoutesList.ANALYTICS} element={"Analytics"} />
            </Route>
          </Routes>
        )}
      </AuthenticatedTemplate>
      <ToastContainer />
      <UnauthenticatedTemplate>
        <></>
      </UnauthenticatedTemplate>
    </div>
  );
};

export default App;
