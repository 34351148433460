import styled from "styled-components";
import { Button, Paper, ButtonGroup, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

export const StyledPaper = styled(Paper)`
  &.MuiPaper-root {
    display: grid;
  }
`;

export const StyledSearchIcon = styled(SearchIcon)`
  &.MuiSvgIcon-root {
    font-size: 40px;
    color: var(--secondary);
  }
`;

export const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--black);
    border: 1px solid var(--primary);
    border-radius: 4px;
    outline: none;
    padding-left: 6px;
    width: 35ch;
  }
  .MuiOutlinedInput-notchedOutline {
    outline: none;
    border: none;
  }
  .MuiInputBase-input {
    padding: 14.5px 6px;
  }

  [data-testid="LocationOnOutlinedIcon"] {
    color: var(--secondary);
  }
  .MuiInputBase-adornedStart {
    .MuiInputBase-input {
      padding-left: 5px;
    }
  }
`;

export const StyledButtonGroup = styled(ButtonGroup)`
  &.MuiButtonGroup-root {
    margin: 0 auto 25px;
  }
`;

// use color var(--secondary) (--white) for background and text
export const StyledButton = styled(Button)`
  &.MuiButton-root {
    padding: 0;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    text-transform: none;
    padding: 10px 33px;
  }

  &.MuiButton-contained {
    background-color: var(--secondary);
    color: (--white);
  }

  &.MuiButton-outlined {
    background-color: var(--white);
    color: var(--secondary);
    border-color: var(--secondary);
    border-style: solid;
  }

  &:hover {
    background-color: var(--secondary) !important;
    border-color: var(--secondary) !important;
    color: var(--white) !important;
  }
`;
