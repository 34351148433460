import React from "react";
import { StyledButton } from "../styles";
import { Order } from "../../Table/types";
import EastIcon from "@mui/icons-material/East";
import CheckIcon from "@mui/icons-material/Check";

interface OrderTypes {
  type: Order;
  label?: string;
}

enum Orders {
  ASC = "asc",
  DESC = "desc",
}

interface FilterButtonProps {
  type: Order;
  label?: string;
  order: OrderTypes | null;
  handleClick: () => void;
}

const FilterButton: React.FC<FilterButtonProps> = ({
  type,
  label,
  order,
  handleClick,
}) => {
  const isActive = order?.type === type && order?.label === label;
  return (
    <StyledButton disableRipple onClick={handleClick} isActive={isActive}>
      <div>
        <strong>{label ?? "Sort"}</strong>
        {type === Orders.ASC ? (
          <>
            A <EastIcon width={20} height={20} /> Z
          </>
        ) : (
          <>
            Z <EastIcon width={20} height={20} /> A
          </>
        )}
      </div>
      {isActive && <CheckIcon />}
    </StyledButton>
  );
};

export default FilterButton;
