import React, { memo, useEffect, useState } from "react";
import { format } from "date-fns";
import { useDebounce } from "use-debounce";
import { useDispatch, useSelector } from "react-redux";

// components
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
} from "@mui/material";
import { IXTableRow, TableHeader } from "shared/ui/Table";
import OutlinedCheckbox from "shared/ui/OutlinedCheckbox";
import TablePlaceholder from "shared/ui/TablePlaceholder";
import SortingDefault, {
  SortRenderProps,
} from "shared/ui/Table/SortingDefault/SortingDefault";
// types
import { ModuleTemplateRespose } from "shared/api/moduleTemplates/types";
import { OrderTypes } from "shared/config/types/Sorting";
import { SORT } from "shared/config/enums/Sorting";
import { DateFormat } from "shared/config/enums/DateFormat";

import { initialColumns } from "./types";
import { useModuleTemplates } from "features/moduleTemplates/model/hooks/useModuleTemplates";
import {
  getModuleTemplatesList,
  getModuleTemplatesTotalCount,
} from "entities/moduleTemplates";
import { useAppSelector } from "redux/store/hooks/hooks";
import { PaginationParams } from "api/types";
import { draftItemsActions } from "redux/actions/draftItems";

import TableActions from "components/Table/TableActions";
import TableControls from "components/TableControls";
import DeleteSimpleModal from "components/Modals/DeleteSimpleModal";
import { TabNames } from "pages/types";

const initialPagingData = {
  limit: 10,
  offset: 0,
  page: 0,
};

export const ModuleTemplatesTable = memo(function ModuleTemplatesTable() {
  const { getModuleTemplates } = useModuleTemplates();
  const [order, setOrder] = useState<OrderTypes>({ type: SORT.ASC, label: "" });
  const [renderedItems, setRenderedItems] = useState<ModuleTemplateRespose[]>(
    []
  );
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [pagingParams, setPagingParams] =
    useState<PaginationParams>(initialPagingData);
  const [searchValue, setSearchValue] = useState("");
  const [touched, setTouched] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedModule, setSelectedModule] = useState<ModuleTemplateRespose>();
  const [selected, setSelected] = useState<string[]>([]);

  const dispatch = useDispatch();
  const draftItems = useSelector((state: any) => state.draftItems);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = renderedItems.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  useEffect(() => {
    dispatch({ type: draftItemsActions.ADD_DRAFT_ITEM, data: selected });
  }, [selected]);

  useEffect(() => {
    if (draftItems.length !== selected.length) {
      setSelected([]);
    }
  }, [draftItems]);

  const openCreateModuleModal = () => {
    console.log("openCreateModuleModal");
  };

  const moduleTemplates = useAppSelector(getModuleTemplatesList);
  const total = useAppSelector(getModuleTemplatesTotalCount);

  useEffect(() => {
    getModuleTemplates(pagingParams);
  }, [pagingParams]);

  function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  useEffect(() => {
    if (order) {
      const sortingProperty = order?.label
        ? capitalizeFirstLetter(order.label)
        : "";
      setPagingParams({
        ...pagingParams,
        "SortingPreferences.Kind": order.type,
        "SortingPreferences.SortingProperty": sortingProperty,
      });
    }
  }, [order]);

  useEffect(() => {
    setRenderedItems([...moduleTemplates]);
  }, [moduleTemplates]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    if (setPagingParams) {
      setPagingParams({
        ...pagingParams,
        page: newPage,
        offset: rowsPerPage * newPage,
      });
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    if (setPagingParams) {
      setPagingParams({
        ...pagingParams,
        page: 0,
        limit: Number(event.target.value),
        offset: 0,
      });
    }
  };

  const [searchQuery] = useDebounce(searchValue, 2000);

  useEffect(() => {
    if (searchQuery) {
      if (!touched) setTouched(true);
      setPagingParams({
        ...initialPagingData,
        SearchExpression: searchQuery,
      });
    } else if (!searchQuery && touched) {
      setPagingParams({
        ...initialPagingData,
        SearchExpression: "",
      });
    }
  }, [searchQuery]);

  const deleteSelectedModel = () => {
    setOpenDeleteModal(false);
  };

  const handleDelete = (item: ModuleTemplateRespose) => {
    setOpenDeleteModal(true);
    setSelectedModule(item);
  };

  const isSelected = (id: string) => {
    return selected.some((x) => x === id);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedRows = [...selected];
    if (isSelected(id)) {
      const elementIndex = selectedRows.indexOf(id);
      selectedRows.splice(elementIndex, 1);
      setSelected(selectedRows);
    } else {
      setSelected([...selectedRows, id]);
    }
  };

  return (
    <>
      <TableControls
        activeTab={TabNames.MODULE_TEMPLATES}
        handleOpen={openCreateModuleModal}
        handleSearch={(e) => setSearchValue(e.target.value)}
        searchValue={searchValue}
      />
      <TableContainer
        sx={{
          height: `calc(100vh - ${"470px"})`,
          position: "relative",
        }}
        data-testid="tableContainer"
      >
        <Table stickyHeader>
          <TableHeader
            hideCheckbox={false}
            columns={initialColumns}
            onSelectAllClick={handleSelectAllClick}
            numSelected={selected.length}
            rowCount={renderedItems.length}
          >
            {(props: SortRenderProps) => (
              <SortingDefault
                setOrder={(data: OrderTypes) => setOrder(data)}
                order={order}
                {...props}
              />
            )}
          </TableHeader>
          <TableBody data-testid="tableBody">
            {renderedItems.length === 0 ? (
              <TablePlaceholder colSpan={initialColumns.length + 1} />
            ) : (
              renderedItems.map((row) => {
                return (
                  <IXTableRow
                    hover
                    tabIndex={-1}
                    key={row.id}
                    data-testid={`tableRow_${row.id}`}
                  >
                    <TableCell sx={{ padding: "0 0 0 4px !important" }}>
                      <OutlinedCheckbox
                        onClick={(event) => handleClick(event, row.id)}
                        checked={isSelected(row.id)}
                      />
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.tenantName}</TableCell>
                    <TableCell sx={{ fontWeight: 700 }}>
                      {row.provider}
                    </TableCell>
                    <TableCell>{row.version}</TableCell>
                    <TableCell>{row.type}</TableCell>
                    <TableCell>
                      {row.moduleConfiguration.connectors?.length}
                    </TableCell>
                    <TableCell>
                      {format(new Date(row.createdDateUtc), DateFormat.DEFAULT)}
                    </TableCell>
                    <TableCell>
                      {row?.updatedDateUtc &&
                        format(
                          new Date(row.updatedDateUtc),
                          DateFormat.DEFAULT
                        )}
                    </TableCell>
                    <TableCell>
                      <TableActions
                        activeTab={"Module Templates"}
                        handleDelete={() => handleDelete(row)}
                        handleView={() => console.log("onView")}
                        handleEdit={() => console.log("onEdit")}
                        handleClone={() => console.log("handleClone")}
                      />
                    </TableCell>
                  </IXTableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={{ marginTop: "30px" }}
        rowsPerPageOptions={[10, 25]}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        data-testid="table_pagination"
      />
      <DeleteSimpleModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(!openDeleteModal)}
        onSubmit={deleteSelectedModel}
        title={`Are you sure you want to delete "${selectedModule?.name}" Module Template with Connectors?`}
      />
    </>
  );
});
