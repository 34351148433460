import styled from "styled-components";

export const InfoRow = styled.div`
  display: flex;
  align-items: baseline;
`;
export const DetailedInfoRow = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 20px;
`;
export const InfoRowIntend = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;
export const InfoValues = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #010101;
  width: calc(100% - 200px);
  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    display: block;
  }
`;

export const InfoLabel = styled.p`
  width: 160px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #383874;
  opacity: 0.5;
  margin-right: 10px;
  margin-bottom: 10px;
`;

export const InfoStatus = styled.div<{ bg: string; color: string }>`
  padding: 6px 12px;
  border-radius: 30px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.02em;
  display: inline-block;
  width: fit-content;
  min-width: 80px;
  text-align: center;
  color: ${({ color }) => color};
  background-color: ${({ bg }) => bg};
`;
