import axios, { AxiosPromise } from "axios";
import { defaultHeader } from "./headers";
import { getState } from "redux/store";
import {
  AttributesData,
  AttributesPayload,
  CategoriesData,
  CategoriesPayload,
  CategoryData,
  SystemFieldData,
  TenantsPayload,
  UpdateCategoryPayload,
  WidgetUpdatePayload,
} from "./types";
import { TenantActionPayload } from "entities/tenants";
import { TenantsResponse } from "shared/api/tenant/types";

export const IXAdminApiPrivate = axios.create(defaultHeader);

IXAdminApiPrivate.interceptors.request.use(
  async (config) => {
    const state: any = getState();
    const { accessToken } = state.auth;

    if (accessToken && config.headers) {
      config.headers.authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

IXAdminApiPrivate.interceptors.response.use(
  (value) => value,
  async (err) => {
    if (err?.response?.status === 401) {
      location.reload();
    }
    return Promise.reject(err);
  }
);

export const createAttribute = (params: AttributesPayload): AxiosPromise => {
  return IXAdminApiPrivate.post("/api/Attributes", params);
};

export const updateAttribute = (
  attributeId: string,
  params: AttributesPayload
): AxiosPromise => {
  return IXAdminApiPrivate.put(`/api/Attributes/${attributeId}`, params);
};

export const getAttributes = (params: any): AxiosPromise<AttributesData> => {
  return IXAdminApiPrivate.get("/api/Attributes", params);
};

export const getSystemFieldIds = (): AxiosPromise<SystemFieldData> => {
  return IXAdminApiPrivate.get("/api/systemglossary");
};

export const restoreAttribute = (id: string): AxiosPromise<AttributesData> => {
  return IXAdminApiPrivate.post(`/api/attributes/${id}/undelete`);
};

export const deleteAttribute = (attributeId: string): AxiosPromise => {
  return IXAdminApiPrivate.delete(`/api/Attributes/${attributeId}`);
};

export const detachAttributeFromAttribute = (
  attriuteId: string,
  subAttributeId: string
): AxiosPromise<AttributesData> => {
  return IXAdminApiPrivate.put(
    `/api/Attributes/${attriuteId}/Sub-attributes/${subAttributeId}/detach`
  );
};

export const createCategory = (
  params: CategoriesPayload
): AxiosPromise<CategoriesData> => {
  return IXAdminApiPrivate.post("/api/productcategories", params);
};

export const getCategory = (categoryId: string): AxiosPromise<CategoryData> => {
  return IXAdminApiPrivate.get(`/api/productcategories/${categoryId}`);
};

export const detachAttributeFromCategory = (
  categoryId: string,
  attributeId: string
): AxiosPromise<CategoryData> => {
  return IXAdminApiPrivate.delete(
    `/api/productcategories/${categoryId}/attributes/${attributeId}`
  );
};

export const updateCategoryNames = (
  id: string,
  params: CategoriesPayload
): AxiosPromise<CategoriesData> => {
  return IXAdminApiPrivate.put(`/api/ProductCategories/${id}`, params);
};

export const updateCategory = (
  id: string,
  params: UpdateCategoryPayload
): AxiosPromise<CategoriesData> => {
  return IXAdminApiPrivate.post(
    `/api/ProductCategories/${id}/Attributes`,
    params
  );
};

export const attachAttribute = (
  id: string,
  params: UpdateCategoryPayload
): AxiosPromise<CategoriesData> => {
  return IXAdminApiPrivate.put(
    `/api/Attributes/${id}/sub-attributes/${params.attributeId}/attach`
  );
};

export const getCategories = (params: any): AxiosPromise<CategoriesData> => {
  return IXAdminApiPrivate.get("/api/ProductCategories", params);
};

export const restoreCategory = (id: string): AxiosPromise<CategoriesData> => {
  return IXAdminApiPrivate.post(`/api/ProductCategories/${id}/undelete`);
};

export const deleteCategory = (categoryId: string): AxiosPromise => {
  return IXAdminApiPrivate.delete(`/api/ProductCategories/${categoryId}`);
};

export const getTenants = (): AxiosPromise<TenantsResponse> => {
  return IXAdminApiPrivate.get("/api/Tenants?IncludeDeleted=true");
};

export const getTenant = (tenantId: string): AxiosPromise<CategoryData> => {
  return IXAdminApiPrivate.get(`/api/Tenants/${tenantId}`);
};

export const createTenant = (
  params: TenantActionPayload["data"]
): AxiosPromise => {
  return IXAdminApiPrivate.post("/api/Tenants", params);
};

export const editTenant = (
  tenantId: string,
  params: TenantsPayload
): AxiosPromise => {
  return IXAdminApiPrivate.put(`/api/Tenants/${tenantId}`, params);
};

export const restoreTenant = (tenantId: string): AxiosPromise => {
  return IXAdminApiPrivate.patch(`/api/Tenants/${tenantId}/restore`);
};

export const updateWidget = (
  tenantId: string,
  id: string,
  params: WidgetUpdatePayload
): AxiosPromise => {
  return IXAdminApiPrivate.put(
    `/api/Tenants/${tenantId}/Widgets/${id}`,
    params
  );
};

export const createWidget = (
  tenantId: string,
  params: WidgetUpdatePayload
): AxiosPromise => {
  return IXAdminApiPrivate.post(`/api/Tenants/${tenantId}/Widgets`, params);
};

export const getWidget = (tenantId: string, id: string): AxiosPromise => {
  return IXAdminApiPrivate.get(`/api/Tenants/${tenantId}/Widgets/${id}`);
};
export const deleteTenant = (tenantId: string): AxiosPromise => {
  return IXAdminApiPrivate.delete(`/api/Tenants/${tenantId}`);
};
