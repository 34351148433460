import { call, put, takeLatest } from "redux-saga/effects";
import {
  createTenant,
  deleteTenant,
  editTenant,
  getTenants,
  getTenant,
  restoreTenant,
} from "api/IXAdminApi";
import { tenantsActions } from "entities/tenants/model/actions/tenants";
import { alertActions } from "redux/actions/alerts";
import { modalsActions } from "redux/actions/modals";
import FilesApi from "shared/api/files/filesApi";
import { AxiosResponse } from "axios";
import {
  actions,
  getTenantAction,
  createTenantAction,
  getTenantsAction,
  updateTenantAction,
} from "entities/tenants";
import { Toaster } from "shared/ui/Toast/Toast";
import { SuccessResponse } from "api/types";
import { TenantsResponse, TenantDetail } from "shared/api/tenant/types";
import { sagaErrorBoundary } from "shared/utils/sagaErrorBoundary";

export interface ResponseGenerator {
  id: any;
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

interface Action {
  data: any;
  type: string;
  tenantId: string;
}

export function* getTenantWorker(action: ReturnType<typeof getTenantAction>) {
  let requestError: any;
  const { tenantId } = action.payload;
  const response: AxiosResponse<TenantDetail> = yield call(
    async () =>
      await getTenant(tenantId)
        .then((response) => response)
        .catch((error) => {
          requestError = error;
        })
  );
  if (response) {
    yield put(actions.getTenantSlice(response.data));
  } else {
    yield put({
      type: tenantsActions.GET_TENANT_FAILURE,
      data: requestError.response.data,
    });
  }
}

export function* getTenantWatcher() {
  yield takeLatest(getTenantAction, getTenantWorker);
}

export function* getTenantsWorker() {
  let requestError: any;
  const response: AxiosResponse<TenantsResponse> = yield call(
    async () =>
      await getTenants()
        .then((response) => response)
        .catch((error) => {
          requestError = error;
        })
  );
  if (response) {
    yield put(actions.getTenantsSlice(response.data));
  } else {
    yield put({
      type: tenantsActions.GET_TENANTS_FAILURE,
      data: requestError.response.data,
    });
  }
}

export function* getTenantsWatcher() {
  yield takeLatest(getTenantsAction, getTenantsWorker);
}

function* createTenantWorker(action: ReturnType<typeof createTenantAction>) {
  const { imageLogo, ...payload } = action.payload;
  try {
    yield put(actions.setTenantActionLoading(true));
    const response: AxiosResponse<SuccessResponse> = yield call(
      createTenant,
      payload.data
    );
    if (response.data) {
      if (imageLogo) {
        yield call(
          FilesApi.uploadFile,
          response.data.id,
          "TenantLogo",
          imageLogo
        );
      }
      yield put(actions.setTenantActionItem(response.data.id));
      yield put(getTenantsAction());
      Toaster.infoMessage(`Tenant ${payload.data.companyName} created`);
      yield put({
        type: modalsActions.OPEN_MODAL,
        data: { addAdmin: { tenant: payload.data, isModalOpen: true } },
      });
    }
    yield put(actions.setTenantActionLoading(false));
  } catch (e) {
    sagaErrorBoundary(e);
    yield put(actions.setTenantActionLoading(false));
  }
}

export function* createTenantWatcher() {
  yield takeLatest(createTenantAction, createTenantWorker);
}

function* deleteTenantWorker(action: Action) {
  const { id, name } = action.data;
  let requestError: any;

  const response: ResponseGenerator = yield call(
    async () =>
      await deleteTenant(id)
        .then((response) => response)
        .catch((error) => {
          requestError = error;
        })
  );
  if (response) {
    yield put(getTenantsAction());
    yield put({
      type: alertActions.SHOW_ALERT,
      data: {
        undo: true,
        type: "deleteTenant",
        name,
      },
    });
  } else {
    yield put({
      type: tenantsActions.DELETE_TENANT_FAILURE,
      data: requestError.response.data,
    });
  }
}

export function* deleteTenantWatcher() {
  yield takeLatest(tenantsActions.DELETE_TENANT, deleteTenantWorker);
}

function* updateTenantWorker(action: ReturnType<typeof updateTenantAction>) {
  const { imageLogo, tenantId, ...payload } = action.payload;
  try {
    yield put(actions.setTenantActionLoading(true));
    const response: AxiosResponse<SuccessResponse> = yield call(
      editTenant,
      tenantId,
      payload.data
    );
    if (response.data) {
      if (imageLogo) {
        yield call(
          FilesApi.uploadFile,
          response.data.id,
          "TenantLogo",
          imageLogo
        );
      }
      yield put(actions.setTenantActionItem(response.data.id));
      yield put(getTenantsAction());
      Toaster.infoMessage(`Tenant ${payload.data.companyName} updated`);
    }
    yield put(actions.setTenantActionLoading(false));
  } catch (e) {
    sagaErrorBoundary(e);
    yield put(actions.setTenantActionLoading(false));
  }
}

export function* updateTenantWatcher() {
  yield takeLatest(updateTenantAction, updateTenantWorker);
}

function* restoreTenantWorker(action: Action) {
  let requestError: any;

  const response: ResponseGenerator = yield call(
    async () =>
      await restoreTenant(action.tenantId)
        .then((response) => response)
        .catch((error) => {
          requestError = error;
        })
  );
  if (response) {
    yield put(getTenantsAction());
    yield put({
      type: alertActions.SHOW_ALERT,
      data: {
        type: "restoreTenant",
        name: action.data.companyName,
      },
    });
  } else {
    yield put({
      type: tenantsActions.RESTORE_TENANT_FAILURE,
      data: requestError.response.data,
    });
  }
}

export function* restoreTenantWatcher() {
  yield takeLatest(tenantsActions.RESTORE_TENANT, restoreTenantWorker);
}
