import { Column } from "shared/config/types/Table";

export const initialColumns: Column[] = [
  {
    id: "widgetName",
    label: "Widget Name",
    minWidth: 300,
    filtering: false,
  },
  {
    id: "name",
    label: "Distribution",
    minWidth: 200,
    filtering: false,
  },
  {
    id: "TenantName",
    label: "Tenant",
    minWidth: 300,
    filtering: false,
  },
  {
    id: "CreatedDateUtc",
    label: "Creation",
    minWidth: 200,
    filtering: false,
  },
  {
    id: "UpdatedDateUtc",
    label: "Update",
    minWidth: 200,
    filtering: false,
  },
  {
    id: "actions",
    label: "Actions",
    minWidth: 100,
    filtering: false,
    align: "center",
  },
];
