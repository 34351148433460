/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { format } from "date-fns";
// components
import TableActions from "../TableActions";
import OutlinedCheckbox from "../../FormComponents/OutlinedCheckbox";
import { TableCell, Button } from "@mui/material";
// styles
import {
  StyledTableRow,
  TableText,
  OwnerWrap,
  CountNumber,
  StyledLink,
} from "../styles";
import { TenantImage } from "../../TenantsTable/styles";
// icons
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import TenantLogo from "assets/logo.png";

import { TableRowProps, TableCellProps } from "../types";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

const RenderTableCell = ({
  columnId,
  colValue,
  row,
  attrKey,
  setAttrKey,
  hideActions,
  activeTab,
  onDelete,
  onView,
  onEdit,
}: TableCellProps) => {
  const tenants = useSelector((state: RootState) => state.tenants.items);

  const expandChild = (currentRow: any) => {
    if (currentRow.id === attrKey) {
      setAttrKey("");
    } else {
      setAttrKey(currentRow.id);
    }
  };

  const tenantName = (id: string) => {
    if (tenants.length) {
      const tenant: any = tenants.filter((tenant: any) => tenant.id === id);
      if (tenant) return tenant[0]?.companyName;
    } else {
      return "";
    }
  };

  switch (columnId) {
    case "logo":
      return <TenantImage src={TenantLogo} />;
    case "companyName":
      return (
        <strong>
          <TableText>{colValue}</TableText>
        </strong>
      );
    case "groupOrUser": {
      return row.recordType === "group" ? (
        <StyledLink to={row.id}>
          <strong>
            <TableText>{colValue}</TableText>
          </strong>
        </StyledLink>
      ) : (
        <OwnerWrap>
          <>
            <strong>{colValue.username}</strong>
            <a href={`mailto:${colValue.email}`}> {colValue.email}</a>
          </>
        </OwnerWrap>
      );
    }
    case "owner":
      return (
        <OwnerWrap>
          <>
            <strong>{colValue.username}</strong>
            <a href={`mailto:${colValue.email}`}> {colValue.email}</a>
          </>
        </OwnerWrap>
      );
    case "usersCount":
      return <CountNumber>{colValue}</CountNumber>;
    case "updatedDateUtc":
      return <TableText>{format(new Date(colValue), "dd-MM-yyyy")}</TableText>;
    case "createdDateUtc":
      return <TableText>{format(new Date(colValue), "dd-MM-yyyy")}</TableText>;
    case "requestTenant":
      return <TableText>{tenantName(row.tenantId)}</TableText>;
    case "isRequired":
      return <TableText>{!colValue ? "No" : "Yes"}</TableText>;
    case "subAttributes":
      if (colValue?.length > 0)
        return (
          <Button
            key={`${row.id}${colValue?.length}`}
            onClick={() => expandChild(row)}
            style={{ textTransform: "none", color: "#000" }}
          >
            See {`${colValue.length}`} childs
            {attrKey === row.id ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </Button>
        );
      break;
    case "actions":
      if (!hideActions) {
        return (
          <TableActions
            activeTab={activeTab}
            handleDelete={() => onDelete?.(row)}
            handleView={() => onView?.(row)}
            handleEdit={() => onEdit?.(row)}
            handleRestore={() => onEdit?.(row)}
          />
        );
      }
      break;
    default:
      return <TableText>{colValue}</TableText>;
  }
};

const TableRow: React.FC<TableRowProps> = ({
  row,
  activeTab,
  columns,
  onEdit,
  onDelete,
  onView,
  hideCheckboxes,
  borderCollapse,
  handleClick,
  selected,
  hideActions,
  subAttribute,
}) => {
  const [attrKey, setAttrKey] = useState("");

  const centerCellItems = ["attributesCount", "isRequired", "productsCount"];

  const isSelected = (id: string) => {
    return selected.some((x: any) => x === id);
  };

  return (
    <React.Fragment>
      <StyledTableRow
        selected={subAttribute ? true : selected.indexOf(row.id) !== -1}
        hover
        tabIndex={-1}
        data-testid={`tableRow_${row.id}`}
        borderCollapse={borderCollapse}
      >
        {!hideCheckboxes && (
          <TableCell padding="checkbox">
            {!subAttribute && (
              <OutlinedCheckbox
                onClick={(event) => handleClick(event, row.id)}
                checked={isSelected(row.id)}
              />
            )}
          </TableCell>
        )}
        {columns.map((column: any) => {
          const colValue =
            column.id === "requestStatus" ? row["status"] : row[column.id];
          return (
            column.visible && (
              <React.Fragment key={column.id}>
                <TableCell
                  align={column.align}
                  sx={{
                    maxWidth: column.maxWidth,
                    ...(centerCellItems.includes(column.id) && {
                      paddingLeft: "50px !important",
                    }),
                  }}
                >
                  {RenderTableCell({
                    columnId: column.id,
                    colValue,
                    row,
                    attrKey,
                    setAttrKey,
                    hideActions,
                    activeTab,
                    onDelete,
                    onView,
                    onEdit,
                  })}
                </TableCell>
              </React.Fragment>
            )
          );
        })}
      </StyledTableRow>
      {row["subAttributes"] &&
        row["subAttributes"].length > 0 &&
        attrKey == row.id &&
        row["subAttributes"].map((element: any) => (
          <TableRow
            key={element.id}
            row={element}
            activeTab={activeTab}
            columns={columns}
            selected={selected}
            handleClick={handleClick}
            onEdit={onEdit}
            onDelete={onDelete}
            onView={onView}
            hideActions={true}
            subAttribute={true}
          />
        ))}
    </React.Fragment>
  );
};

export default TableRow;
