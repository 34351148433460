import React, { useEffect, useState } from "react";
import { PageTitle } from "shared/ui/pageTitle";
import TablePaper from "shared/ui/TablePaper";
import {
  HeaderWrap,
  InfoBlock,
  InfoColumn,
  StyledTitleWrap,
  Wrapper,
} from "./styles";
import { Box, Chip, Divider, Table, TableBody, TableCell } from "@mui/material";
import { ControlButtonsWrap } from "../DistributionDetails/styles";
import { RoutesList } from "shared/routes";
import { useWidgetDetails } from "features/widgets";
import { useNavigate, useParams } from "react-router-dom";
import { Spinner } from "shared/ui/Spinner";
import {
  DetailsInfo,
  InfoLabel,
  InfoRow,
  InfoValues,
} from "shared/ui/DetailsInfoBlock";
import { TableContainerWrapper } from "shared/ui/Table/styles";
import { IXTableRow, TableHeader } from "shared/ui/Table";
import { initialColumns } from "./columns";
import OutlinedCheckbox from "shared/ui/OutlinedCheckbox";
import { addOrRemove } from "shared/utils/addOrRemove";
import { ActionButton, ActionButtonsWrapper } from "shared/ui/ActionButton";
import { ActionButtonTypes } from "shared/ui/ActionButton/types";
import { format } from "date-fns";
import { DateFormat } from "shared/config/enums/DateFormat";
import { ApplicationResponse } from "shared/api/applications/types";

const WidgetDetails = () => {
  const navigate = useNavigate();
  const { id, tenantId } = useParams();
  const [selected, setSelected] = useState<string[]>([]);
  const {
    getWidgetById,
    clearWidgetDetailsData,
    isLoading,
    data,
    applications,
    applicationUnlinkFromWidget,
    actionLoading,
  } = useWidgetDetails();
  const redirectBack = () => {
    navigate(`${RoutesList.PRODUCT_CONFIGURATOR}/${RoutesList.WIDGETS}`);
  };
  useEffect(() => {
    id &&
      tenantId &&
      getWidgetById({ id, tenantId: tenantId, cb: redirectBack });
  }, [getWidgetById, id, tenantId]);

  useEffect(() => {
    return () => clearWidgetDetailsData();
  }, []);

  const selectAllClick = () => {
    if (selected.length > 0) {
      setSelected([]);
    } else {
      setSelected(applications.map((item) => item.id));
    }
  };
  const selectRow = (id: string) => () => {
    const newSelected = addOrRemove(selected, id);
    setSelected(newSelected);
  };
  const handleUnlinkDistribution = (item: ApplicationResponse) => () => {
    if (!id || !tenantId || !data) return;
    applicationUnlinkFromWidget({
      tenantId: tenantId,
      applicationId: item.id,
      widgetId: id,
      applicationName: item.name,
      widgetName: data.name,
    });
  };
  return (
    <div data-testid={"program-details"}>
      <PageTitle
        title={"Widget Details"}
        backLink={{
          to: `${RoutesList.PRODUCT_CONFIGURATOR}/${RoutesList.WIDGETS}/`,
          title: "Back to Widget Configurator",
        }}
      />
      <TablePaper>
        {isLoading && <Spinner />}
        {!isLoading && data && (
          <>
            <Box mb={6}>
              <Wrapper>
                <HeaderWrap>
                  <StyledTitleWrap>Widget</StyledTitleWrap>
                  <ControlButtonsWrap>
                    <ActionButtonsWrapper>
                      <ActionButton
                        type={ActionButtonTypes.CLONE}
                        title="Clone widget"
                      />
                      <Divider orientation="vertical" flexItem sx={{ mr: 2 }} />
                      <ActionButton
                        type={ActionButtonTypes.EDIT}
                        title="Edit widget"
                      />
                      <ActionButton
                        type={ActionButtonTypes.DELETE}
                        title="Delete widget"
                      />
                    </ActionButtonsWrapper>
                  </ControlButtonsWrap>
                </HeaderWrap>
                <InfoBlock>
                  <InfoRow>
                    <InfoColumn>
                      <DetailsInfo value={data.name} label={"Widget name"} />
                      <DetailsInfo value={data.id} label={"Widget Id"} />
                      <DetailsInfo
                        value={data.widgetType}
                        label={"Widget Type"}
                      />
                    </InfoColumn>
                    <InfoColumn>
                      <DetailsInfo value={data.tenantName} label={"Tenant"} />
                      <DetailsInfo value={data.name} label={"Budget type"} />
                    </InfoColumn>
                  </InfoRow>
                  <InfoRow>
                    <InfoLabel>Products:</InfoLabel>
                    {data.products && (
                      <InfoValues>
                        <Box sx={{ gap: 1 }} display="flex" flexWrap="wrap">
                          {data.products.map((item) => (
                            <Chip
                              key={item.productId}
                              label={item.productName}
                            />
                          ))}
                        </Box>
                      </InfoValues>
                    )}
                  </InfoRow>
                </InfoBlock>
              </Wrapper>
            </Box>
            <StyledTitleWrap>Associated Distributions</StyledTitleWrap>
            <TableContainerWrapper data-testid="tableContainer" size="xs">
              <Table stickyHeader>
                <TableHeader
                  hideCheckbox={applications.length === 0}
                  columns={initialColumns}
                  numSelected={selected.length}
                  rowCount={applications.length}
                  onSelectAllClick={selectAllClick}
                ></TableHeader>
                <TableBody data-testid="tableBody">
                  {applications.map((row) => {
                    return (
                      <IXTableRow
                        hover
                        tabIndex={-1}
                        key={row.id}
                        data-testid={`tableRow_${row.id}`}
                      >
                        <TableCell sx={{ fontWeight: 700 }} padding="checkbox">
                          <OutlinedCheckbox
                            checked={selected.includes(row.id)}
                            onChange={selectRow(row.id)}
                          />
                        </TableCell>
                        <TableCell sx={{ fontWeight: 700 }}>
                          {data.name}
                        </TableCell>
                        <TableCell sx={{ fontWeight: 700 }}>
                          {row.name}
                        </TableCell>
                        <TableCell sx={{ fontWeight: 700 }}>
                          {data.tenantName}
                        </TableCell>
                        <TableCell sx={{ fontWeight: 700 }}>
                          {format(
                            new Date(row.createdDateUtc),
                            DateFormat.DEFAULT
                          )}
                        </TableCell>
                        <TableCell sx={{ fontWeight: 700 }}>
                          {format(
                            new Date(row.updatedDateUtc),
                            DateFormat.DEFAULT
                          )}
                        </TableCell>
                        <TableCell sx={{ fontWeight: 700, minWidth: "200px" }}>
                          <ActionButtonsWrapper
                            isLoading={row.id === actionLoading}
                          >
                            <ActionButton
                              title={ActionButtonTypes.DELETE}
                              type={ActionButtonTypes.DELETE}
                              onClick={handleUnlinkDistribution(row)}
                            />
                          </ActionButtonsWrapper>
                        </TableCell>
                      </IXTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainerWrapper>
          </>
        )}
      </TablePaper>
    </div>
  );
};

export default WidgetDetails;
