import { takeLatest, put, call } from "redux-saga/effects";
import { AxiosResponse } from "axios";

import { ReduxAction } from "redux/sagas/types";

import { uploadFilesAction } from "entities/files";
import {
  loadNotifications,
  loadNotification,
} from "entities/platformNotifications/model/slice/platformNotificationsSlice";
import { platformNotificationsActions } from "entities/platformNotifications/model/actions";

import PlatformNotificationsApi from "shared/api/platformNotifications/platformNotificationsApi";
import {
  PlatformNotificationsRespose,
  CreateNotificationRespose,
} from "shared/api/platformNotifications/types";
import { sagaErrorBoundary } from "shared/utils/sagaErrorBoundary";

export function* getPlatformNotificationsWorker(action: ReduxAction) {
  try {
    const response: AxiosResponse<PlatformNotificationsRespose> = yield call(
      PlatformNotificationsApi.getPlatformNotifications,
      action.data
    );
    yield put(loadNotifications(response.data));
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* getPlatformNotificationWorker(action: ReduxAction) {
  try {
    const response: AxiosResponse<PlatformNotificationsRespose> = yield call(
      PlatformNotificationsApi.getPlatformNotification,
      action.data
    );
    yield put(loadNotification(response.data));
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* deletePlatformNotificationWorker(action: ReduxAction) {
  try {
    const response: AxiosResponse<PlatformNotificationsRespose> = yield call(
      PlatformNotificationsApi.deletePlatformNotification,
      action.data
    );
    action.callback(response);
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* createPlatformNotificationWorker(action: ReduxAction) {
  try {
    const response: AxiosResponse<CreateNotificationRespose> = yield call(
      PlatformNotificationsApi.createPlatformNotification,
      action.data
    );
    yield put({
      type: platformNotificationsActions.GET_PLATFORM_NOTIFICATIONS,
      data: {
        params: {
          limit: 10,
          offset: 0,
        },
      },
    });
    if (action.files) {
      yield put(
        uploadFilesAction({
          ReferenceId: `${response.data.id}`,
          ReferenceType: "NotificationImage",
          files: action.files,
        })
      );
    }
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* PlatformNotificationsWatcher() {
  yield takeLatest(
    platformNotificationsActions.GET_PLATFORM_NOTIFICATIONS,
    getPlatformNotificationsWorker
  );
  yield takeLatest(
    platformNotificationsActions.GET_PLATFORM_NOTIFICATION,
    getPlatformNotificationWorker
  );
  yield takeLatest(
    platformNotificationsActions.DELETE_PLATFORM_NOTIFICATION,
    deletePlatformNotificationWorker
  );
  yield takeLatest(
    platformNotificationsActions.CREATE_PLATFORM_NOTIFICATION,
    createPlatformNotificationWorker
  );
}
