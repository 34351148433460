import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "use-debounce";
import { Box, InputAdornment, TableContainer, Paper } from "@mui/material";
import { StyledSearchIcon, StyledTextField } from "../../Archive/styles";
import { categoriesActions } from "entities/productCategories";
import { alertActions } from "redux/actions/alerts";
import { AttributesData, CategoriesData } from "api/types";
import { groupedByAttributes, sortByOrder } from "utils/array";

import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import Modal from "../StyledModal";
import Button from "../../Button";
import FormGroup from "../../FormComponents/FormGroup";
import {
  AddAttrDiv,
  AddedWrapper,
  AddCategory,
  Block,
  Description,
  EditCategory,
  Text,
  StyledGrid,
  StyledButton,
  Title,
  AddAttrButton,
  TableWrapper,
} from "./styles";
import CollapsedItems from "./CollapsedItems";
import { attributesActions } from "entities/attributes";

interface CreateCategoryProps {
  open: boolean;
  onClose: () => void;
  category?: CategoriesData;
  type: string;
  onOpenCreateAttributeModal: () => void;
  resetting?: boolean;
}

interface GroupedAttributesData extends AttributesData {
  groupName: string;
  parentId: string;
}

interface ErrorsProps {
  categoryName?: boolean;
  displayName?: boolean;
}

interface GroupedCategories {
  groupBy: string;
  items: Array<GroupedAttributesData>;
}

const CategoryModal = ({
  open,
  onClose,
  category,
  type,
  onOpenCreateAttributeModal,
  resetting,
}: CreateCategoryProps) => {
  const [created, setCreated] = useState(false);
  const [removedSelectedCount, setRemovedSelectedCount] = useState(0);
  const [selectedCount, setSelectedCount] = useState(0);
  const [reset, setReset] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [errors, setErrors] = useState<ErrorsProps>({});
  const [searchValue, setSearchValue] = useState("");
  const [groupAttributes, setGroupAttributes] = useState(
    Array<GroupedCategories>
  );
  const [selectedGroupAttributes, setSelectedGroupAttributes] = useState(
    Array<GroupedCategories>
  );
  const [removeSelectedGroupAttributes, setRemoveSelectedGroupAttributes] =
    useState(Array<GroupedCategories>);
  const [availableAttributes, setAvailableAttributes] = useState(
    Array<GroupedAttributesData>
  );
  const [systemAvailableAttributes, setSystemAvailableAttributes] = useState(
    Array<GroupedAttributesData>
  );
  const [currentAddedAttributes, setCurrentAddedAttributes] = useState<
    Array<GroupedAttributesData>
  >([]);
  const [expand, setExpand] = React.useState(false);
  const [addedAttrs, setAddedAttrs] = useState(Array<GroupedCategories>);

  // current added attributes on a given category upon edit.
  const [currentAddedAttrs, setCurrentAddedAttrs] = useState<
    Array<GroupedCategories>
  >([]);

  // this is for if user adds new attributes that aren't on the current category
  const [newAddedAttrs, setNewAddedAttrs] = useState<Array<GroupedCategories>>(
    []
  );
  // this is for if user removes exisiting attributes from the current category
  const [newRemovedAttrs, setNewRemovedAttrs] = useState<
    Array<GroupedCategories>
  >([]);

  const [searchQuery] = useDebounce(searchValue, 2000);

  const dispatch = useDispatch();
  const attributes = useSelector((state: any) => state.attributes);
  const categoryAttributes = useSelector(
    (state: any) => state.category.attributes
  );

  useEffect(() => {
    if (resetting && !category) {
      setAddedAttrs([]);
      setCategoryName("");
      setDisplayName("");

      if (systemAvailableAttributes.length > 0) {
        setAvailableAttributes(systemAvailableAttributes);
      }
    }
  }, [resetting]);

  useEffect(() => {
    if (category) {
      setCategoryName(category.categoryName);
      setDisplayName(category.displayName);
      dispatch({ type: categoriesActions.GET_CATEGORY, data: category.id });
    } else {
      setAddedAttrs([]);
    }
  }, [category]);

  useEffect(() => {
    if (selectedGroupAttributes) {
      let count = 0;
      for (const item of selectedGroupAttributes) {
        count += item.items.length;
      }
      setSelectedCount(count);
    }
  }, [selectedGroupAttributes]);

  useEffect(() => {
    if (removeSelectedGroupAttributes) {
      let count = 0;
      for (const item of removeSelectedGroupAttributes) {
        count += item.items.length;
      }
      setRemovedSelectedCount(count);
    }
  }, [removeSelectedGroupAttributes]);

  useEffect(() => {
    if (attributes && attributes.items.length > 0) {
      const groupedResult = [];
      for (const attribute of [...attributes.items]) {
        if (attribute.subAttributes.length !== 0) {
          for (const childAttribute of attribute.subAttributes) {
            groupedResult.push({
              ...childAttribute,
              groupName: attribute.displayName,
              parentId: attribute.id,
            });
          }
        }
      }
      setSystemAvailableAttributes(groupedResult);
      setAvailableAttributes(groupedResult);
    }
  }, [attributes]);

  useEffect(() => {
    if (categoryAttributes && categoryAttributes.length > 0) {
      const groupedResult = [];
      for (const attribute of [...categoryAttributes]) {
        if (attribute.subAttributes.length !== 0) {
          for (const childAttribute of attribute.subAttributes) {
            groupedResult.push({
              ...childAttribute,
              groupName: attribute.displayName,
              parentId: attribute.id,
            });
          }
        }
      }
      setCurrentAddedAttributes(groupedResult);
    } else {
      setCurrentAddedAttributes([]);
    }
  }, [categoryAttributes]);

  useEffect(() => {
    if (availableAttributes) {
      const result = groupedByAttributes(availableAttributes);
      setGroupAttributes(result);
    }
  }, [availableAttributes]);

  useEffect(() => {
    if (currentAddedAttributes && currentAddedAttributes.length > 0) {
      const result = availableAttributes.filter(
        (attribute: AttributesData) =>
          !currentAddedAttributes
            .map((addedAttribute: AttributesData) => addedAttribute.id)
            .includes(attribute.id)
      );
      const groupedResult = groupedByAttributes(result);
      setAvailableAttributes(result);
      setGroupAttributes(groupedResult);

      const groupedAddedResult = groupedByAttributes(currentAddedAttributes);
      setCurrentAddedAttrs(groupedAddedResult);
      setAddedAttrs(groupedAddedResult);
    } else if (category && currentAddedAttributes.length === 0) {
      setAddedAttrs([]);
    }
  }, [currentAddedAttributes]);

  useEffect(() => {
    const newAdditions = addedAttrs.filter(
      (attribute: GroupedCategories) =>
        !currentAddedAttrs
          .map((addedAttribute: GroupedCategories) => addedAttribute.groupBy)
          .includes(attribute.groupBy)
    );
    setNewAddedAttrs(newAdditions);

    const newRemovals = currentAddedAttrs.filter(
      (attribute: GroupedCategories) =>
        !addedAttrs
          .map(
            (removedAttribute: GroupedCategories) => removedAttribute.groupBy
          )
          .includes(attribute.groupBy)
    );
    setNewRemovedAttrs(newRemovals);
  }, [addedAttrs]);

  useEffect(() => {
    if (searchQuery) {
      dispatch({
        type: attributesActions.GET_ATTRIBUTES,
        data: {
          params: {
            ShowArchived: false,
            SearchExpression: searchQuery,
          },
        },
      });
    }
  }, [searchQuery]);

  useEffect(() => {
    if (!open) {
      setCreated(false);
    }
  }, [open]);

  useEffect(() => {
    if (created) {
      dispatch({
        type: categoriesActions.GET_CATEGORIES,
        data: { params: { ShowArchived: false } },
      });
      setCreated(false);
    }
  }, [created]);

  const addSelectedAttributes = () => {
    const currentSelectedGroup = [...selectedGroupAttributes];
    const currentAddedOutput = [...addedAttrs].concat(currentSelectedGroup);
    const newGroupedAttributes = [...groupAttributes];

    if (currentAddedOutput && currentAddedOutput.length > 0) {
      for (const item of currentSelectedGroup) {
        const elementIndex = indexOfItem(newGroupedAttributes, item);
        newGroupedAttributes.splice(elementIndex, 1);
      }
      setAddedAttrs(currentAddedOutput);
      setGroupAttributes(newGroupedAttributes);
      setSelectedGroupAttributes([]);
      setReset(true);
    }
  };

  const removeSelectedAttributes = () => {
    const currentGroupedAddedAttrs = [...addedAttrs];
    const itemToAddBack = [...removeSelectedGroupAttributes];
    const newAvailableGroupedAttributes = itemToAddBack.concat([
      ...groupAttributes,
    ]);

    for (const item of itemToAddBack) {
      const elementIndex = indexOfItem(currentGroupedAddedAttrs, item);
      currentGroupedAddedAttrs.splice(elementIndex, 1);
    }
    setRemoveSelectedGroupAttributes([]);
    setReset(true);
    setGroupAttributes(newAvailableGroupedAttributes);
    setAddedAttrs(currentGroupedAddedAttrs);
  };

  const handleClose = () => {
    onClose();
  };

  const validateForm = () => {
    if (categoryName && displayName) {
      return true;
    } else {
      const err: ErrorsProps = {};
      if (!categoryName) err.categoryName = true;
      if (!displayName) err.displayName = true;
      setErrors({ ...errors, ...err });
    }
    return false;
  };

  const handleSubmit = useCallback(() => {
    if (validateForm()) {
      if (category) {
        updateCategory(category.id);
      } else {
        dispatch({
          type: categoriesActions.CREATE_CATEGORY,
          data: {
            categoryName,
            displayName,
          },
          callback,
        });
      }
    }
  }, [validateForm]);

  const updateCategory = (id: string) => {
    if (
      categoryName !== category?.categoryName ||
      displayName !== category?.displayName
    ) {
      dispatch({
        type: categoriesActions.UPDATE_CATEGORY_NAME,
        data: {
          id,
          categoryName,
          displayName,
        },
      });
    }
    if (newAddedAttrs.length > 0) {
      for (const item of newAddedAttrs) {
        dispatch({
          type: categoriesActions.UPDATE_CATEGORY,
          data: {
            id,
            attributeId: item.items[0].parentId,
          },
        });
      }
    }
    if (newRemovedAttrs.length > 0) {
      for (const item of newRemovedAttrs) {
        dispatch({
          type: categoriesActions.DELETE_ATTRIBUTE_FROM_CATEGORY,
          data: {
            id,
            attributeId: item.items[0].parentId,
          },
        });
      }
    }
    alertEvent("updateCategory", categoryName);

    setTimeout(() => {
      setCreated(!created);
    }, 3000);

    handleClose();
  };

  const callback = (data: any) => {
    const categoryId = data.id;
    if (categoryId && addedAttrs.length > 0) {
      for (const item of addedAttrs) {
        dispatch({
          type: categoriesActions.UPDATE_CATEGORY,
          data: {
            id: data.id,
            attributeId: item.items[0].parentId,
          },
        });
      }
      alertEvent("createCategory", categoryName);
      setCreated(!created);
      handleClose();
    } else {
      alertEvent("createCategory", categoryName);
      setCreated(!created);
      handleClose();
    }
  };

  const alertEvent = (type: string, name: string) => {
    dispatch({
      type: alertActions.SHOW_ALERT,
      data: {
        undo: false,
        type,
        name,
      },
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value, id },
    } = event;
    const validLength = value.length <= 250;

    setErrors({
      ...errors,
      [id]: false,
    });

    if (validLength && id === "categoryName") setCategoryName(value);
    if (validLength && id === "displayName") setDisplayName(value);
  };

  const handleRemoveAllItemSelect = (group: string, checked: boolean) => {
    const allItems = [...addedAttrs].filter(
      (x: GroupedCategories) => x.groupBy === group
    );

    const removeSelectedGroup = [...removeSelectedGroupAttributes];
    for (const item of allItems) {
      if (isSelectedGroup(item, removeSelectedGroup) && !checked) {
        const elementIndex = indexOfItem(removeSelectedGroup, item);
        removeSelectedGroup.splice(elementIndex, 1);
      } else if (!isSelectedGroup(item, removeSelectedGroup)) {
        removeSelectedGroup.push(item);
      }
    }
    setRemoveSelectedGroupAttributes(removeSelectedGroup);
  };

  const handleAllItemSelect = (group: string, checked: boolean) => {
    const allItems = [...groupAttributes].filter(
      (x: GroupedCategories) => x.groupBy === group
    );
    const selectedGroupedItems = [...selectedGroupAttributes];

    for (const item of allItems) {
      if (isSelectedGroup(item, selectedGroupedItems) && !checked) {
        const elementIndex = indexOfItem(selectedGroupedItems, item);
        selectedGroupedItems.splice(elementIndex, 1);
      } else if (!isSelectedGroup(item, selectedGroupedItems)) {
        selectedGroupedItems.push(item);
      }
    }
    setSelectedGroupAttributes([...selectedGroupedItems]);
  };

  const isSelectedGroup = (
    item: GroupedCategories,
    groupedAttrs: Array<GroupedCategories>
  ) => {
    return groupedAttrs.some(
      (x: GroupedCategories) => x.groupBy === item.groupBy
    );
  };

  const indexOfItem = (items: Array<object>, item: object) => {
    let index = 0;
    for (const element of items) {
      if (JSON.stringify(element) === JSON.stringify(item)) {
        return index;
      }
      index += 1;
    }
    return -1;
  };

  const filterByNames = (filter: string) => {
    const filterData = { name: "groupBy", sortKey: filter };
    const filterByName = { name: "attributeName", sortKey: filter };
    const filtered = sortByOrder([...addedAttrs], filterData);
    const filteredGrouped = [];

    for (const groupAttr of [...filtered]) {
      const filterGroupAttr = sortByOrder(groupAttr.items, filterByName);
      filteredGrouped.push({
        groupBy: groupAttr.groupBy,
        items: filterGroupAttr,
      });
    }
    setAddedAttrs(filteredGrouped);
  };

  return (
    <Modal
      id="categoryModal"
      onClose={handleClose}
      open={open}
      title={category ? `Edit ${type}` : `Create New ${type}`}
      actions={!created}
      submitButton={
        <Button
          variant="contained"
          onClick={handleSubmit}
          data-testid="categoryModal_button"
        >
          {category ? `Update ${type}` : `Create ${type}`}
        </Button>
      }
      fullScreen={true}
    >
      <Block>
        <AddCategory>
          {type == "Category" && (
            <>
              <Title>Category Details</Title>
              <Description>Support Text</Description>
              <StyledGrid container spacing={3}>
                <StyledGrid item xs={6}>
                  <FormGroup
                    placeholder="-"
                    value={categoryName}
                    id="categoryName"
                    onChange={handleChange}
                    label="Category Name"
                    error={errors?.categoryName}
                  />
                  <Box sx={{ textAlign: "right" }}>
                    <Text>{categoryName.length}/250 characters</Text>
                  </Box>
                </StyledGrid>
                <StyledGrid item xs={6}>
                  <FormGroup
                    placeholder="-"
                    value={displayName}
                    id="displayName"
                    onChange={handleChange}
                    label="Display Name"
                    error={errors?.displayName}
                  />
                  <Box sx={{ textAlign: "right" }}>
                    <Text>{displayName.length}/250 characters</Text>
                  </Box>
                </StyledGrid>
              </StyledGrid>
              <Title className="gray">Data Enrichment Integrations</Title>
            </>
          )}
          <Title className="spacing">Added Attributes</Title>
          {addedAttrs.length > 0 && (
            <TableWrapper fullSize={type !== "Category"}>
              <AddedWrapper>
                {addedAttrs.map((row: GroupedCategories, index) => (
                  <React.Fragment key={row.groupBy}>
                    <CollapsedItems
                      open={false}
                      row={row}
                      key={row.groupBy}
                      handleAllItemSelect={handleRemoveAllItemSelect}
                      showDetail={true}
                      reset={false}
                      indexKey={index}
                      filterByNames={filterByNames}
                    />
                  </React.Fragment>
                ))}
              </AddedWrapper>
              <AddAttrDiv className="left">
                <AddAttrButton
                  variant="outlined"
                  onClick={removeSelectedAttributes}
                  className="remove"
                >
                  {`Remove ${removedSelectedCount} Attributes`}
                </AddAttrButton>
              </AddAttrDiv>
            </TableWrapper>
          )}
        </AddCategory>
        <EditCategory>
          <Title className="bold">Available Attributes</Title>
          <Description className="attrDesc">
            If you can&apos;t find the attribute you need request one&nbsp;
            <StyledButton
              className="text-button"
              onClick={onOpenCreateAttributeModal}
            >
              here.
            </StyledButton>
          </Description>
          <StyledTextField
            id="input-with-sx"
            placeholder="Search Attribute"
            sx={{ padding: "20px" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <StyledSearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <StyledButton
            className="expand"
            variant="outlined"
            startIcon={<UnfoldMoreIcon />}
            onClick={() => setExpand(!expand)}
          >
            Expand All
          </StyledButton>
          <TableContainer
            component={Paper}
            sx={{
              padding: "10px 20px 0px 20px",
              boxShadow: "none",
              maxHeight: "350px",
            }}
          >
            {groupAttributes.map((row: GroupedCategories, index) => (
              <React.Fragment key={row.groupBy}>
                <CollapsedItems
                  open={expand}
                  row={row}
                  key={row.groupBy}
                  handleAllItemSelect={handleAllItemSelect}
                  showDetail={false}
                  reset={reset}
                  indexKey={index}
                />
              </React.Fragment>
            ))}
          </TableContainer>
          <AddAttrDiv>
            <AddAttrButton variant="outlined" onClick={addSelectedAttributes}>
              {`Add ${selectedCount} Attributes`}
            </AddAttrButton>
          </AddAttrDiv>
        </EditCategory>
      </Block>
    </Modal>
  );
};

export default CategoryModal;
