export const categoriesActions = {
  CREATE_CATEGORY: "CREATE_CATEGORY",
  CREATE_CATEGORY_SUCCESS: "CREATE_CATEGORY_SUCCESS",
  CREATE_CATEGORY_FAILURE: "CREATE_CATEGORY_FAILURE",
  UPDATE_CATEGORY: "UPDATE_CATEGORY",
  UPDATE_CATEGORY_SUCCESS: "UPDATE_CATEGORY_SUCCESS",
  UPDATE_CATEGORY_FAILURE: "UPDATE_CATEGORY_FAILURE",
  UPDATE_CATEGORY_NAME: "UPDATE_CATEGORY_NAME",
  UPDATE_CATEGORY_NAME_SUCCESS: "UPDATE_CATEGORY_NAME_SUCCESS",
  UPDATE_CATEGORY_NAME_FAILURE: "UPDATE_CATEGORY_NAME_FAILURE",
  GET_CATEGORIES: "GET_CATEGORIES",
  GET_CATEGORIES_FAILURE: "GET_CATEGORIES_FAILURE",
  GET_ARCHIVED_CATEGORIES: "GET_ARCHIVED_CATEGORIES",
  GET_ARCHIVED_CATEGORIES_FAILURE: "GET_ARCHIVED_CATEGORIES_FAILURE",
  RESTORE_CATEGORY: "RESTORE_CATEGORY",
  RESTORE_CATEGORY_FAILURE: "RESTORE_CATEGORY_FAILURE",
  DELETE_CATEGORY: "DELETE_CATEGORY",
  DELETE_CATEGORY_SUCCESS: "DELETE_CATEGORY_SUCCESS",
  DELETE_CATEGORY_FAILURE: "DELETE_CATEGORY_FAILURE",
  DELETE_ATTRIBUTE_FROM_CATEGORY: "DELETE_ATTRIBUTE_FROM_CATEGORY",
  DELETE_ATTRIBUTE_FROM_CATEGORY_SUCCESS:
    "DELETE_ATTRIBUTE_FROM_CATEGORY_SUCCESS",
  DELETE_ATTRIBUTE_FROM_CATEGORY_FAILURE:
    "DELETE_ATTRIBUTE_FROM_CATEGORY_FAILURE",
  GET_CATEGORY: "GET_CATEGORY",
  GET_CATEGORY_FAILURE: "GET_CATEGORY_FAILURE",
};
