import { createSlice } from "@reduxjs/toolkit";

export const archivedAttributesSlice = createSlice({
  name: "archivedAttributes",
  initialState: {
    items: [],
    totalCount: 0,
  },
  reducers: {
    getArchivedAttributesSlice: (state, action) => {
      return action.payload;
    },
  },
});

export const archivedCategoriesSlice = createSlice({
  name: "archivedCategories",
  initialState: {
    items: [],
    totalCount: 0,
  },
  reducers: {
    getArchivedCategoriesSlice: (state, action) => {
      return action.payload;
    },
  },
});

export const { getArchivedAttributesSlice } = archivedAttributesSlice.actions;
export const { getArchivedCategoriesSlice } = archivedCategoriesSlice.actions;
