import React, { useState } from "react";
import { Box } from "@mui/material";
import { PageTitle } from "shared/ui/pageTitle";
import Notifications from "components/Notifications";
import CreateNotification from "components/Modals/CreateNotification";
import Button from "components/Button";

const NotificationsPage = () => {
  const [open, setOpen] = useState(false);
  return (
    <Box data-testid="products-page">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <PageTitle title={"Notifications"} />
        <Button variant="contained" onClick={() => setOpen(true)}>
          Create Notification
        </Button>
      </Box>
      <Notifications />
      <CreateNotification open={open} handleClose={() => setOpen(false)} />
    </Box>
  );
};

export default NotificationsPage;
