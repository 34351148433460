import React from "react";

import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";
import Modal from "../CenteredModal";
import Button from "../../Button";
import { Title, Container, StyledActions } from "./styles";

interface DeleteSimpleModalProps {
  title: string;
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const DeleteSimpleModal = ({
  open,
  onClose,
  onSubmit,
  title,
}: DeleteSimpleModalProps) => {
  return (
    <Modal id="deleteModal" onClose={onClose} open={open}>
      <Container>
        <DeleteIcon />
        <Title>{title}</Title>
      </Container>
      <StyledActions>
        <Button
          onClick={onClose}
          variant="outlined"
          data-testid="deleteModal_cancelButton"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={onSubmit}
          color="error"
          data-testid="deleteModal_yesButton"
        >
          Yes, Delete
        </Button>
      </StyledActions>
    </Modal>
  );
};

export default DeleteSimpleModal;
