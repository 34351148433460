import styled from "styled-components";
import { Button } from "@mui/material";

export const TabButton = styled.button<{ isActive: boolean }>`
  padding: 20px 35px;
  display: flex;
  height: 100%;
  align-items: center;
  background: ${(props) => (props.isActive ? "var(--white)" : "transparent")};
  color: ${(props) => (props.isActive ? "var(--secondary)" : "var(--primary)")};
  border: none;
  cursor: pointer;
  font-weight: 700;
  border-top-right-radius: 24px;
  transition: 0.2s ease;
  text-transform: uppercase;
  letter-spacing: 2px;
`;

export const TabsHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TabButtonWrap = styled.div`
  background: var(--darksilver);
  display: flex;
  align-items: center;
  border-top-right-radius: 24px;
  border-top-left-radius: 10px;
  overflow: auto;
  height: 70px;
`;

export const TabPanelWrap = styled.div`
  min-height: calc(100vh - 245px);
  background: var(--white);
  border-radius: 0px 20px 20px 20px;
  .MuiPaper-root {
    box-shadow: none;
    border-radius: 0px 20px 20px 20px;
  }
`;

export const StyledButton = styled(Button)`
  &.MuiButton-root {
    background-color: ${(props) => `var(--${props.color})`};
    border-radius: 30px;
    padding: 16px 32px;
    text-transform: none;
    letter-spacing: 2px;
    color: var(--white);
    font-family: "Inter", sans-serif;
    margin-left: 20px;
    font-size: 16px;
    line-height: 20px;
    :hover {
      background-color: ${(props) => `var(--${props.color})`};
    }
  }
`;
