import styled from "styled-components";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { Typography, DialogActions } from "@mui/material";

export const Container = styled.div`
  position: relative;
`;

export const Circle = styled(PanoramaFishEyeIcon)`
  &.MuiSvgIcon-root {
    font-size: 120px;
    position: absolute;
    left: calc(50% - 60px);
    color: var(--secondary);
  }
`;

export const QuestionMark = styled(QuestionMarkIcon)`
  &.MuiSvgIcon-root {
    font-size: 60px;
    position: absolute;
    left: calc(50% - 30px);
    top: 30px;
    color: var(--secondary);
  }
`;

export const Title = styled(Typography)`
  &.MuiTypography-root {
    margin-bottom: 32px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: var(--headings);
    text-align: center;
    margin-top: 120px;
  }
`;

export const StyledActions = styled(DialogActions)`
  &.MuiDialogActions-root {
    padding: 0;
    justify-content: center;

    & > :not(:first-of-type) {
      margin-left: 20px;
    }
  }
`;
