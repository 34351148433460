import styled from "styled-components";

export const InfoBlock = styled.div`
  background: #f2f2fc;
  padding: 16px 16px 0;
  border-radius: 4px;
  display: flex;
  margin-bottom: 24px;
  flex-wrap: wrap;
`;
export const Col = styled.div`
  width: 50%;
  margin-bottom: 16px;
`;
export const SubHeading = styled.p`
  color: #383874;
  font-size: 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  opacity: 0.5;
`;
export const Text = styled.p`
  color: #010101;
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;
export const Paragraph = styled.p`
  color: #010101;
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 16px;
`;
