import styled from "styled-components";
import { Button } from "@mui/material";

export const StyledButton = styled(Button)`
  &.MuiButtonBase-root {
    border-radius: 100px;
    height: 52px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    text-transform: none;
    letter-spacing: 2px;
    padding: 16px 32px;
  }
  &.MuiButton-outlined {
    background: var(--white);
    border: 1px solid var(--grey);
    border-radius: 100px;
    color: var(--secondary);
  }
  &.MuiButton-containedPrimary {
    color: var(--white);
    background: var(--secondary);
    box-shadow: 10px 10px 40px rgba(111, 138, 231, 0.25);
  }
  .MuiSvgIcon-root {
    margin-right: 10px;
  }
  &.MuiButton-containedError {
    background: var(--mediumred);
    box-shadow: 10px 10px 40px rgba(241, 65, 65, 0.25);
    margin-left: 20px;
  }
  &.MuiButton-containedSecondary {
    background: var(--primary);
  }
`;
