import { createSlice } from "@reduxjs/toolkit";

export const systemFieldIdSlice = createSlice({
  name: "systemFieldIds",
  initialState: {
    fields: [],
    totalCount: 0,
  },
  reducers: {
    getSystemFieldIdSlice: (_, action) => {
      return action.payload;
    },
  },
});

export const { getSystemFieldIdSlice } = systemFieldIdSlice.actions;
