import styled from "styled-components";
import { ReactComponent as RequestsIcon } from "../../icons/requests.svg";
import { ReactComponent as ArrowIcon } from "../../icons/leftArrow.svg";
import { ReactComponent as TriangleIcon } from "../../icons/triangle.svg";

export const IconDiv = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
`;

export const StyledRequestsIcon = styled(RequestsIcon)`
  position: absolute;
  top: 0;
  left: 0;
`;

export const StyledArrowIcon = styled(ArrowIcon)`
  position: absolute;
  bottom: 3px;
  left: 6px;
`;

export const StyledTriangleIcon = styled(TriangleIcon)`
  position: absolute;
  right: 0;
`;
