import styled from "styled-components";
import { DialogContent, Typography } from "@mui/material";

export const Wrapper = styled(DialogContent)`
  &.MuiDialogContent-root {
    padding: 40px 50px;
    border-bottom: none;
    .MuiButton-containedSecondary {
      padding: 5px 24px;
      height: 40px;
      font-size: 12px;
      line-height: 16px;
    }
  }
`;

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  align-items: center;
  justify-content: center;
  width: calc(100% - 750px);
  max-width: 100%;
  margin: auto;
  margin-top: 50px;
  text-align: center;
`;
export const Text = styled(Typography)`
  &.MuiTypography-root {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--grey);
    margin-top: 10px;
  }
`;
