import styled from "styled-components";

export const StyledTitle = styled.h3`
  margin: 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #010101;
`;
export const GeneralInfoWrapper = styled.div`
  padding: 20px;
  border: 1px solid var(--silver);
  border-radius: 6px;
`;
export const Row = styled.div`
  display: flex;
`;
export const Col = styled.div`
  width: 50%;
`;
export const InfoBlock = styled.div`
  display: flex;
  margin-bottom: 22px;
  align-items: baseline;
  &:last-child {
    margin-bottom: 0;
  }
`;
export const InfoValues = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #010101;
  width: calc(100% - 200px);
  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    display: block;
    text-transform: capitalize;
  }
`;
export const InfoLabel = styled.p`
  width: 160px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #383874;
  opacity: 0.5;
  margin-right: 10px;
  margin-bottom: 10px;
`;
export const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  align-items: center;
`;
export const ControlButtonsWrap = styled.div`
  display: flex;
  .MuiIconButton-root {
    color: #383874;
    opacity: 0.5;
    margin-left: 20px;
  }
  .MuiDivider-root {
    margin-left: 20px;
    border-color: #d3d3de;
  }
`;
export const TagsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  top: -5px;
`;
export const Tag = styled.div`
  padding: 6px 12px;
  border-radius: 30px;
  margin-right: 10px;
  background: var(--ghost);
  margin-bottom: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #010101;
`;
