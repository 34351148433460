import React, { useState, useEffect, KeyboardEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "use-debounce";
import { attributesActions } from "entities/attributes";
import { AttributesPayload, AttributesData, FieldData } from "api/types";

import AttributeForm from "./AttributeForm";
import Button from "../../Button";
import Modal from "../StyledModal";
import { Toaster } from "shared/ui/Toast/Toast";

interface CreateAttributeProps {
  open: boolean;
  onClose: () => void;
  attribute?: AttributesData;
  onOpenAddAttributes?: (payload: AttributesPayload) => void;
  openAttachAttribute?: () => void;
  justCreated?: boolean;
  pagingParams?: any;
  filterParams?: any;
}

interface ErrorsProps {
  attributeName?: boolean;
  displayName?: boolean;
  description?: boolean;
  valueType?: boolean;
  fillmentType?: boolean;
  formatType?: boolean;
}

interface SystemField {
  name: string;
  id: string;
}

const CreateAttribute = ({
  open,
  onClose,
  onOpenAddAttributes,
  attribute,
  openAttachAttribute,
  justCreated,
  pagingParams,
  filterParams,
}: CreateAttributeProps) => {
  const [attributeName, setAttributeName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [defaultValue, setDefaultValue] = useState("");
  const [description, setDescription] = useState("");
  const [fillmentType, setFillmentType] = useState("");
  const [attributeType, setAttributeType] = useState("Category");
  const [systemId, setSystemId] = useState<SystemField | null>(null);
  const [regularExpression, setRegularExpression] = useState("");
  const [format, setFormat] = useState("");
  const [values, setValues] = useState<string[]>([]);
  const [valueType, setValueType] = useState("");
  const [errors, setErrors] = useState<ErrorsProps>({});
  const [checked, setChecked] = useState(false);
  const [isArrayed, setIsArrayed] = useState(false);
  const [systemFieldIds, setSystemFieldIds] = useState<SystemField[]>([]);
  const [fieldNames, setFieldNames] = useState<string[]>([]);
  const [valueEntered, setValueEntered] = useState("");
  const [created, setCreated] = useState(false);

  const dispatch = useDispatch();

  const systemFields = useSelector((state: any) => state.systemFieldIds);

  const [currentValue] = useDebounce(valueEntered, 100);
  const [pendingDefaultValue] = useDebounce(defaultValue, 1000);
  const [defaultValid, setDefaultValid] = useState(true);

  const fillmentTypes = [
    "BaseInformation",
    "PostfillmentPrefill",
    "PostfillmentSensitive",
    "Hidden",
    "AdditionalInfo",
    "SystemContainer",
  ];

  useEffect(() => {
    if (pendingDefaultValue && !valueValid(pendingDefaultValue)) {
      setDefaultValid(false);
      setDefaultValue("");
    } else setDefaultValid(true);
  }, [pendingDefaultValue]);

  useEffect(() => {
    if (!open) {
      setCreated(false);
      resetForm();
    }
  }, [open]);

  useEffect(() => {
    if (justCreated) {
      setCreated(justCreated);
    }
  }, [justCreated]);

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (
      event.code === "Enter" &&
      valueValid(currentValue.toLowerCase().trim())
    ) {
      const newValues = [...values];
      newValues.push(currentValue);
      setValues(newValues);
      setValueEntered("");
    }
  };

  const checkNumber = (number: number) => {
    return number % 1 === 0;
  };

  const integerValid = (number: number) => {
    if (!checkNumber(number)) {
      Toaster.error("Please provide a valid integer");
      return false;
    }
    return true;
  };

  const decimalValid = (number: number) => {
    if (checkNumber(number)) {
      Toaster.error("Please provide a valid decimal");
      return false;
    }
    return true;
  };

  const valueValid = (pendingValue: any) => {
    if (!pendingValue) return true;
    switch (valueType) {
      case "String":
        return true;
      case "Decimal":
        if (isNaN(Number(pendingValue))) {
          Toaster.error("Please provide a valid number");
          return false;
        }
        return decimalValid(Number(pendingValue));
      case "Integer":
        if (isNaN(Number(pendingValue))) {
          Toaster.error("Please provide a valid number");
          return false;
        }
        return integerValid(Number(pendingValue));
      case "Boolean":
        if (pendingValue === "true") return true;
        if (pendingValue === "false") return true;
        Toaster.error("Please provide a valid boolean value");
        return false;
      default:
        return true;
    }
  };

  useEffect(() => {
    if (systemFields && systemFields.totalCount > 0) {
      const fieldNames = [...systemFields.fields].map(
        (element: FieldData) => element.fieldName
      );
      const fieldNameWithIds = [...systemFields.fields].map(
        (element: FieldData) => {
          const field: SystemField = {
            name: "",
            id: "",
          };
          field.name = element.fieldName;
          field.id = element.id;
          return field;
        }
      );
      setFieldNames(fieldNames);
      setSystemFieldIds(fieldNameWithIds);
    }
  }, [systemFields]);

  useEffect(() => {
    if (attribute && open) {
      setAttributeName(attribute.attributeName);
      setDisplayName(attribute.displayName);
      setDescription(attribute.attributeDescription);
      setRegularExpression(attribute.regexExpression);
      setFillmentType(attribute.defaultFillmentType);
      setFormat(attribute.format);
      setValues(attribute.values);
      setValueType(attribute.valueType);
      setChecked(attribute.isRequired);
      setDefaultValue(attribute.defaultValue);
      setIsArrayed(attribute.isArray);

      if (attribute.systemFieldId) {
        const field = systemFieldIds.find(
          (x: SystemField) => x.id === attribute.systemFieldId
        );
        if (field) setSystemId(field);
      }
    }
  }, [attribute, open]);

  useEffect(() => {
    if (created) resetForm();
  }, [created]);

  useEffect(() => {
    if (attribute) {
      if (
        (fillmentType &&
          attribute.valueType === "CompoundAttribute" &&
          fillmentType !== "SystemContainer") ||
        (attribute.valueType !== "CompoundAttribute" &&
          fillmentType === "SystemContainer")
      ) {
        setErrors({ fillmentType: true });
      }
    }
    if (!attribute) {
      if (fillmentType === "SystemContainer") {
        setValueType("CompoundAttribute");
      }
    }
  }, [fillmentType]);

  useEffect(() => {
    if (valueType === "CompoundAttribute") {
      setFillmentType("SystemContainer");
    }
    if (valueType !== "String") {
      setValues([]);
    }
  }, [valueType]);

  useEffect(() => {
    if (defaultValue) {
      setDefaultValid(false);
    } else setDefaultValid(true);
  }, [defaultValue]);

  const resetForm = () => {
    setAttributeName("");
    setDisplayName("");
    setDescription("");
    setRegularExpression("");
    setSystemId(null);
    setFormat("");
    setFillmentType("");
    setValues([]);
    setValueType("");
    setChecked(false);
    setDefaultValue("");
    setErrors({});
    setIsArrayed(false);
  };

  const validateForm = () => {
    if (attributeName && displayName && description && valueType) {
      return true;
    } else {
      const err: ErrorsProps = {};
      if (!attributeName) err.attributeName = true;
      if (!displayName) err.displayName = true;
      if (!description) err.description = true;
      if (!valueType) err.valueType = true;
      if (!fillmentType) err.fillmentType = true;
      setErrors({ ...errors, ...err });
    }
    return false;
  };

  const handleTypeChange = (value: string) => {
    if (valueType !== value && values.length) {
      setValues([]);
    }
    setErrors({
      ...errors,
      valueType: false,
    });
    if (value !== "String" && format) setFormat("");
    if (fillmentType === "SystemContainer" && value !== "CompoundAttribute") {
      setFillmentType("");
    }
    setValueType(value);
  };

  const handleFormatChange = (value: string) => {
    setErrors({
      ...errors,
      formatType: false,
    });
    setFormat(value);
  };

  const handleSystemIdValueChange = (value: string) => {
    if (value === "") {
      setSystemId(null);
    } else {
      const field = systemFieldIds.find((x: SystemField) => x.name === value);
      if (field) {
        setSystemId(field);
      }
    }
  };

  const handleFillmentChange = (value: string) => {
    setErrors({
      ...errors,
      fillmentType: false,
    });
    if (value === "") {
      setFillmentType("");
      if (valueType === "CompoundAttribute") {
        setDefaultValue("");
        setValueType("");
      }
    } else {
      const field = fillmentTypes.find((x: string) => x === value);
      if (field) {
        setFillmentType(field);
      }
      if (
        field &&
        valueType === "CompoundAttribute" &&
        field !== "SystemContainer"
      ) {
        setFillmentType(field);
        setDefaultValue("");
        setValueType("");
      }
    }
  };

  const onSubmit = () => {
    const valid = validateForm();
    const payload = attributePayload();
    if (valid && !attribute) {
      if (valueType == "CompoundAttribute" && onOpenAddAttributes) {
        onOpenAddAttributes(payload);
      } else {
        dispatch({
          type: attributesActions.CREATE_ATTRIBUTE,
          data: attributePayload(),
          callback,
        });
      }
    } else {
      updateAttribute();
    }
  };

  const callback = () => {
    setCreated(true);
  };

  const updateAttribute = () => {
    if (!attribute) {
      return false;
    }
    if (
      attribute.attributeName !== attributeName ||
      attribute.displayName !== displayName ||
      attribute.values !== values ||
      attribute.valueType !== valueType ||
      attribute.attributeDescription !== description ||
      attribute.regexExpression !== regularExpression ||
      attribute.format !== format ||
      attribute.isRequired !== checked ||
      attribute.defaultFillmentType !== fillmentType ||
      attribute.systemFieldId !== systemId?.id ||
      attribute.defaultValue !== defaultValue
    ) {
      handleUpdateAttribute();
    } else {
      if (attribute.valueType == "CompoundAttribute") {
        attachAttributes(attribute);
        onClose();
      }
      onClose();
    }
  };

  const attributePayload = () => {
    const payload: any = {
      attributeDescription: description,
      displayName,
      isRequired: checked,
      values,
      regexExpression: regularExpression === "" ? null : regularExpression,
      isArray: isArrayed,
      format: format === "" ? null : format,
      defaultValue: defaultValue === "" ? null : defaultValue,
      attributeName,
      valueType: valueType === "Boolean" ? "Bool" : valueType,
      attributeType,
      defaultFillmentType: fillmentType,
    };
    if (attribute) {
      payload.id = attribute.id;
    }
    if (systemId) {
      payload.systemFieldId = systemId.id;
    }
    return payload;
  };

  const attachAttributes = (attribute: AttributesData) => {
    if (attribute.valueType == "CompoundAttribute" && openAttachAttribute) {
      openAttachAttribute();
      onClose();
    }
  };

  const handleUpdateAttribute = () => {
    if (attribute) {
      dispatch({
        type: attributesActions.UPDATE_ATTRIBUTE,
        data: attributePayload(),
        pagingParams,
        filterParams,
      });
      attachAttributes(attribute);
      onClose();
    }
  };

  const handleClose = () => {
    if (created) dispatch({ type: attributesActions.GET_ATTRIBUTES });
    resetForm();
    onClose();
  };

  const deleteValue = (valueToRemove: string) => {
    setValues((values) => values.filter((value) => value !== valueToRemove));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value, id },
    } = event;

    setErrors({
      ...errors,
      [id]: false,
    });

    if (id === "attributeName") setAttributeName(value);
    if (id === "displayName") setDisplayName(value);
    if (id === "defaultValue") setDefaultValue(value);
    if (id === "description") setDescription(value);
    if (id === "attributeType") setAttributeType(value);
    if (id === "regularExpression") setRegularExpression(value);
    if (id === "valueType") setValueType(value);
  };

  return (
    <Modal
      id="attributeModal"
      onClose={handleClose}
      open={open}
      title={attribute ? "Edit Attribute" : "Create New Attribute"}
      actions={!created}
      submitButton={
        <Button
          disabled={!defaultValid}
          variant="contained"
          onClick={onSubmit}
          data-testid="attributeModal_button"
        >
          {attribute ? "Update Attribute" : "Create Attribute"}
        </Button>
      }
      fullScreen={true}
    >
      <AttributeForm
        attribute={attribute}
        attributeName={attributeName}
        displayName={displayName}
        defaultValue={defaultValue}
        description={description}
        regularExpression={regularExpression}
        fillmentType={fillmentType}
        valueEntered={valueEntered}
        format={format}
        valueType={valueType}
        created={created}
        errors={errors}
        systemId={systemId}
        checked={checked}
        isArrayed={isArrayed}
        fieldNames={fieldNames}
        fillmentTypes={fillmentTypes}
        values={values}
        handleClose={handleClose}
        handleTypeChange={handleTypeChange}
        handleFormatChange={handleFormatChange}
        handleSystemIdValueChange={handleSystemIdValueChange}
        setValueEntered={setValueEntered}
        handleFillmentChange={handleFillmentChange}
        deleteValue={deleteValue}
        setChecked={() => setChecked(!checked)}
        setIsArrayed={() => setIsArrayed(!isArrayed)}
        resetCreated={() => setCreated(false)}
        handleChange={handleChange}
        handleKeyPress={handleKeyPress}
      />
    </Modal>
  );
};

export default CreateAttribute;
