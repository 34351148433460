import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useDebounce } from "use-debounce";
// components
import ArchiveTable from "../Table";
import TableControls from "../TableControls";
import RestoreModal from "../Modals/RestoreModal";
// styles
import { StyledButton, StyledPaper, StyledButtonGroup } from "./styles";
// actions
import { attributesActions } from "entities/attributes";
import { categoriesActions } from "entities/productCategories";
// types
import { Column } from "../Table/types";
import { initialColumns as attributeColumns } from "../Attributes/types";
import { initialColumns as categoriesColumns } from "../Categories/types";

import { updateValueByKey } from "utils/array";
import { PaginationParams } from "api/types";

const initialPagingData = {
  totalCount: 0,
  page: 0,
  limit: 10,
  offset: 0,
  SearchExpression: "",
};

const tabList = [
  {
    label: "Categories",
  },
  {
    label: "Attributes",
  },
];

const Archive: React.FC<any> = () => {
  const categories = useSelector((state: any) => state.archivedCategories);
  const attributes = useSelector((state: any) => state.archivedAttributes);
  const isLoading = useSelector((state: any) => state.isLoading);

  const [columns, setColumns] = useState<Column[]>(categoriesColumns);
  const [archivedItems, setArchivedItems] = useState(categories);
  const [selected, setSelected] = useState("Categories");
  const [restoreType, setRestoreType] = useState("Category");
  const [openRestoreModal, setOpenRestoreModal] = useState(false);
  const [itemToRestore, setItemToRestore] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [filterParams, setFilterParams] = useState("");
  const [pagingParams, setPagingParams] =
    useState<PaginationParams>(initialPagingData);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [touched, setTouched] = useState(false);
  const [searchQuery] = useDebounce(searchValue, 2000);

  const dispatch = useDispatch();

  const archivedAttributeColumns = updateValueByKey(
    attributeColumns,
    "id",
    "productActions",
    "actions"
  );
  const archivedCategoriesColumns = updateValueByKey(
    categoriesColumns,
    "id",
    "productActions",
    "actions"
  );

  useEffect(() => {
    getAttributesData();
    getCategoriesData();
  }, []);

  useEffect(() => {
    if (!isLoading && pagingParams) getData();
  }, [pagingParams]);

  useEffect(() => {
    if (filterParams) getData();
  }, [filterParams]);

  useEffect(() => {
    if (searchQuery) {
      if (!touched) setTouched(true);
      setPagingParams({
        ...initialPagingData,
        SearchExpression: searchQuery,
      });
    } else if (!searchQuery && touched) {
      setPagingParams({
        ...initialPagingData,
        SearchExpression: "",
      });
    }
  }, [searchQuery]);

  useEffect(() => {
    if (selected === "Attributes") {
      setPagingParams(initialPagingData);
      getAttributesData();
    }
    if (selected === "Categories") {
      setPagingParams(initialPagingData);
      getCategoriesData();
    }
  }, [selected]);

  const getData = () => {
    if (selected === "Attributes") getAttributesData();
    else getCategoriesData();
  };

  const getAttributesData = () => {
    dispatch({
      type: attributesActions.GET_ARCHIVED_ATTRIBUTES,
      data: {
        params: filterPayload(filterParams, "AttributeName"),
      },
    });
  };

  const getCategoriesData = () => {
    dispatch({
      type: categoriesActions.GET_ARCHIVED_CATEGORIES,
      data: {
        params: filterPayload(filterParams, "CategoryName"),
      },
    });
  };

  const filterPayload = (filterParams: any, filterKey: string) => {
    const params: any = {
      ShowArchived: true,
      limit: pagingParams.limit,
      offset: pagingParams.offset,
      SearchExpression: pagingParams.SearchExpression,
    };
    let filterOptions = "";
    if (filterParams.order === "desc") filterOptions = "Descending";
    if (filterParams.order === "asc") filterOptions = "Ascending";
    if (filterOptions !== "") {
      params["SortingPreferences.Kind"] = filterOptions;
      params["SortingPreferences.SortingProperty"] = filterKey;
    }
    return params;
  };

  const handleRestore = (item: any) => {
    setOpenRestoreModal(true);
    setItemToRestore(item);
  };

  const restoreArchive = () => {
    const restoreActionType =
      selected == "Attributes"
        ? attributesActions.RESTORE_ATTRIBUTES
        : categoriesActions.RESTORE_CATEGORY;

    dispatch({
      type: restoreActionType,
      data: itemToRestore,
    });
    setOpenRestoreModal(false);
  };

  const handleArchivedSelect = (e: React.MouseEvent<HTMLElement>) => {
    const value = e.currentTarget.id;

    setSelected(value);
    findArchivedItem(value);
  };

  const findArchivedItem = (value: string) => {
    if (value === "Attributes") {
      setColumns(archivedAttributeColumns);
      setArchivedItems(attributes);
      setRestoreType("Attribute");
      setTotalCount(attributes.totalCount);
    } else {
      setColumns(archivedCategoriesColumns);
      setArchivedItems(categories);
      setRestoreType("Category");
      setTotalCount(categories.totalCount);
    }
  };

  useEffect(() => {
    findArchivedItem(selected);
  }, [categories, attributes]);

  return (
    <StyledPaper
      sx={{
        width: "100%",
        overflow: "hidden",
        padding: "40px 40px 10px 40px",
        borderRadius: "20px",
        boxShadow: "none",
      }}
    >
      <StyledButtonGroup
        disableElevation
        aria-label="Disabled elevation buttons"
      >
        {tabList.map((tab) => {
          return (
            <StyledButton
              variant={selected === tab.label ? "contained" : "outlined"}
              onClick={handleArchivedSelect}
              key={tab.label}
              id={tab.label}
            >
              {tab.label}
            </StyledButton>
          );
        })}
      </StyledButtonGroup>

      {archivedItems && archivedItems.items.length != 0 && (
        <TableControls
          activeTab={"Archive"}
          handleSearch={(e) => setSearchValue(e.target.value)}
          searchValue={searchValue}
          hideCreate={true}
        />
      )}
      <ArchiveTable
        rows={archivedItems.items}
        columns={columns}
        activeTab={"Archive"}
        onEdit={handleRestore}
        onDelete={() => console.log("onDelete")}
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        totalRows={totalCount}
        pagingParams={pagingParams}
        setPagingParams={setPagingParams}
      />
      <RestoreModal
        onClose={() => setOpenRestoreModal(false)}
        open={openRestoreModal}
        restoreType={restoreType}
        handleSubmit={restoreArchive}
      />
    </StyledPaper>
  );
};

export default Archive;
