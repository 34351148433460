import { Column } from "../Table/types";

export const initialColumns: Column[] = [
  {
    id: "notificationType",
    label: "Type",
    minWidth: 200,
    filtering: true,
    visible: true,
  },
  {
    id: "title",
    label: "Title",
    minWidth: 200,
    filtering: false,
    visible: true,
  },
  {
    id: "id",
    label: "id ",
    minWidth: 200,
    filtering: true,
    visible: true,
  },
  {
    id: "createdDateUtc",
    label: "Creation Date",
    minWidth: 200,
    filtering: false,
    visible: true,
  },
  {
    id: "actions",
    label: "Actions",
    align: "center",
    minWidth: 150,
    filtering: false,
    visible: true,
  },
];
