import { API_PATH, TENANT_NAME } from "../constants";

export const defaultHeader = {
  baseURL: API_PATH,
  headers: {
    ["ixadmin-tenant-hostname"]: TENANT_NAME,
    ["accept-language"]: "en-us",
    accept: "text/plain",
  },
};
