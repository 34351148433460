import styled from "styled-components";
import { Skeleton } from "@mui/material";

export const SkeletonWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 50px;
  width: 100%;
`;

export const StyledSkeleton = styled(Skeleton)<{ lineHeight: string }>`
  height: ${(props) => props.lineHeight};
`;
