import { Column } from "shared/config/types/Table";

export const initialColumns: Column[] = [
  {
    id: "name",
    label: "Name",
    minWidth: 200,
    filtering: true,
  },
  {
    id: "tenant",
    label: "Tenant",
    minWidth: 200,
    filtering: false,
  },
  {
    id: "provider",
    label: "Provider",
    minWidth: 200,
    filtering: true,
  },
  {
    id: "version",
    label: "Version",
    minWidth: 200,
    filtering: false,
  },
  {
    id: "type",
    label: "Type",
    minWidth: 200,
    filtering: true,
  },
  {
    id: "connector",
    label: "Connector Counter",
    minWidth: 200,
    filtering: false,
  },
  {
    id: "createdDateUtc",
    label: "Creation",
    minWidth: 150,
    filtering: false,
  },
  {
    id: "updatedDateUtc",
    label: "Update",
    minWidth: 150,
    filtering: false,
  },
  {
    id: "actions",
    label: "Actions",
    minWidth: 150,
    filtering: false,
  },
];
