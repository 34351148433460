import React from "react";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { StyledTableContainer } from "../styles";
import CloseIcon from "@mui/icons-material/Close";
import { useFiles } from "features/files/model/hooks/useFiles";
import { CommentSchema, RequestFile } from "shared/api/requests/types";

const UploadedFiles: React.FC<{
  files: RequestFile[];
  comments: CommentSchema[];
  removeUploadedFile: (file: RequestFile) => void;
}> = ({ files, comments, removeUploadedFile }) => {
  const { retrieveFile } = useFiles();
  const commentsWithFile = comments.filter((comment) => comment.file);

  const download = (id: string) => {
    retrieveFile(id, callback);
  };

  const callback = (data: any) => {
    if (data?.id) {
      const a = document.createElement("a");
      a.href = data.uri;
      a.download = data.fileName;
      a.click();
    }
  };
  return (
    <StyledTableContainer>
      <Table
        aria-label="uploadedfiles-table"
        sx={{ borderTop: "1px solid rgba(224, 224, 224, 1)" }}
      >
        <TableBody>
          {files.map((file: RequestFile) => {
            return (
              <TableRow key={file.fileId}>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ borderLeft: "1px solid rgba(224, 224, 224, 1)" }}
                  onClick={() => download(file.fileId)}
                >
                  {file.fileName}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
                >
                  <IconButton
                    aria-label="close"
                    onClick={() => removeUploadedFile(file)}
                    sx={{ color: "var(--grey)", padding: "0" }}
                  >
                    <CloseIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {commentsWithFile.length ? (
        <Typography>Files from Comments</Typography>
      ) : (
        <></>
      )}
      <Table
        aria-label="commentFiles-table"
        sx={{ borderTop: "1px solid rgba(224, 224, 224, 1)" }}
      >
        <TableBody>
          {commentsWithFile.map((comment: CommentSchema) => {
            const commentFile = comment.file;
            return (
              <TableRow key={comment.id}>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ borderLeft: "1px solid rgba(224, 224, 224, 1)" }}
                  onClick={() => download(commentFile.fileId)}
                >
                  {commentFile.fileName}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
                >
                  <IconButton
                    aria-label="close"
                    onClick={() => removeUploadedFile(commentFile)}
                    sx={{ color: "var(--grey)", padding: "0" }}
                  >
                    <CloseIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

export default UploadedFiles;
