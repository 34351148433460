import { takeLatest, put, call } from "redux-saga/effects";
import { AxiosResponse } from "axios";

import { ReduxAction } from "redux/sagas/types";

import ApplicationsApi from "shared/api/applications/applicationsApi";
import {
  ApplicationsResponse,
  CreateApplicationResponse,
} from "shared/api/applications/types";
import { sagaErrorBoundary } from "shared/utils/sagaErrorBoundary";
import { Toaster } from "shared/ui/Toast/Toast";

import { applicationsActions } from "../actions";
import {
  getApplications,
  loadSecret,
  loadApplication,
} from "../slice/applicationsSlice";

export function* getApplicationsWorker(action: ReduxAction) {
  try {
    const response: AxiosResponse<ApplicationsResponse> = yield call(
      ApplicationsApi.getApplications,
      action.data
    );
    yield put(getApplications({ ...response.data, tenantId: action.data.id }));
  } catch (e) {
    sagaErrorBoundary(e);
  }
}
export function* createApplicationWorker(action: ReduxAction) {
  try {
    const response: AxiosResponse<CreateApplicationResponse> = yield call(
      ApplicationsApi.createApplication,
      action.data
    );
    Toaster.infoMessage(
      `${action.data.data.name} Distribution has been created.`
    );
    yield put(loadSecret(response.data));
    if (action.callback) {
      action.callback({
        data: response.data,
        tenantId: action.data.tenantId,
      });
    }
  } catch (e) {
    sagaErrorBoundary(e);
  }
}
export function* getApplicationWorker(action: ReduxAction) {
  try {
    const response: AxiosResponse<ApplicationsResponse> = yield call(
      ApplicationsApi.getApplication,
      action.data
    );
    yield put(loadApplication(response.data));
  } catch (e) {
    sagaErrorBoundary(e);
  }
}
export function* deleteApplicationWorker(action: ReduxAction) {
  try {
    yield call(ApplicationsApi.deleteApplication, action.data);
    Toaster.infoMessage(`Distribution ${action.data.name} has been deleted.`);
    yield put({
      type: applicationsActions.GET_APPLICATIONS,
      data: {
        data: {
          params: {
            limit: 10,
            offset: 0,
            page: 0,
          },
        },
        id: action.data.tenantId,
      },
    });
  } catch (e) {
    sagaErrorBoundary(e);
  }
}
export function* regenerateClientSecretWorker(action: ReduxAction) {
  try {
    const response: AxiosResponse<CreateApplicationResponse> = yield call(
      ApplicationsApi.regenerateClientSecret,
      action.data
    );
    yield put(loadSecret(response.data));
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* ApplicationsWatcher() {
  yield takeLatest(applicationsActions.GET_APPLICATIONS, getApplicationsWorker);
  yield takeLatest(applicationsActions.GET_APPLICATION, getApplicationWorker);
  yield takeLatest(
    applicationsActions.CREATE_APPLICATION,
    createApplicationWorker
  );
  yield takeLatest(
    applicationsActions.DELETE_APPLICATION,
    deleteApplicationWorker
  );
  yield takeLatest(
    applicationsActions.REGENERATE_CLIENT_SECRET,
    regenerateClientSecretWorker
  );
}
