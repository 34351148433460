import { takeLatest, put, call } from "redux-saga/effects";
import { AxiosResponse } from "axios";

import { ReduxAction } from "redux/sagas/types";
import ModuleTemplatesApi from "shared/api/moduleTemplates/moduleTemplatesApi";
import { ModuleTemplatesRespose } from "shared/api/moduleTemplates/types";
import { sagaErrorBoundary } from "shared/utils/sagaErrorBoundary";

import { moduleTemplatesActions } from "../actions";
import { getModuleTemplatesSlice } from "../slice/moduleTemplatesSlice";

export function* getModuleTemplatesWorker(action: ReduxAction) {
  try {
    const response: AxiosResponse<ModuleTemplatesRespose> = yield call(
      ModuleTemplatesApi.getModuleTemplates,
      action.data
    );
    yield put(getModuleTemplatesSlice(response.data));
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* ModuleTemplatesWatcher() {
  yield takeLatest(
    moduleTemplatesActions.GET_MODULE_TEMPLATES,
    getModuleTemplatesWorker
  );
}
